import React from 'react';

import { Options, Items, Sidebuttons } from './styles';

import SearchIcon from '../../assets/images/search.svg';
import { FormInputIconizedWithButton } from '../FormInputs';

export default class ItensAndButtons extends React.Component {
  renderImportButton() {
    return (
      <button
        onClick={() => {
          this.props.openModal();
        }}
      >
        <span href="#!">importar</span>
      </button>
    );
  }
  getTotalItens(total) {
    if (Number(total) === 1) {
      return '1 ITEM';
    }
    return `${total || 0} ITENS`;
  }

  render() {
    const { total, isLoading } = this.props;
    return (
      <Options>
        <Items>
          <span>{isLoading ? 'Carregando' : this.getTotalItens(total)}</span>
        </Items>

        <Sidebuttons>
          <div className="search">
            <FormInputIconizedWithButton
              placeholder="Buscar"
              name="busca"
              type="text"
              icon={SearchIcon}
              onSubmit={this.props.onSubmit}
            />
          </div>
          <button
            onClick={() => {
              this.props.history.push(this.props.newUrl);
            }}
          >
            <span>novo</span>
          </button>
          {this.props.dadosGerais ? this.renderImportButton() : ''}
        </Sidebuttons>
      </Options>
    );
  }
}
