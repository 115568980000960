import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  overflow-y: scroll;
`;

export const Back = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 25px 0 25px;

  img {
    height: 25px;
    width: 25px;
  }

  span {
    color: #00468e;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 25px 10px 25px;
  padding: 47px 400px;

  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
`;

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;

  .input {
    border: #ccc 1px solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    height: 45px;
    width: 1000px;

    input {
      border: 0;
      font-size: 16px;
      line-height: 22px;
      color: #666666;
    }

    img {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 180px;

  border: 0;
  border-radius: 4px;

  background-color: #00468e;

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
`;

export const ActivityBox = styled.div`
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  width: 700px;
  height: 160px;
  border-radius: 4px;
  margin-top: 35px;

  .top {
    height: 50px;
    border-bottom: #ccc 2px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #999999;
      font-size: 16px;
      line-height: 22px;
      margin-left: 20px;
    }

    div img {
      height: 25px;
      width: 25px;
      margin-right: 20px;
    }
  }

  .mid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    border-bottom: #ccc 2px solid;
    height: 60px;

    span {
      color: #666666;
      font-size: 16px;
      line-height: 22px;

      :first-child {
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  .bot {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    span {
      color: #999999;
      font-size: 16px;
      line-height: 22px;
      margin-right: 10px;
    }
  }
`;

export const ActivityDetailsBox = styled.div`
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  width: 700px;
  height: 420px;
  border-radius: 4px;
  margin-top: 35px;

  .top {
    height: 50px;
    border-bottom: #ccc 2px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: #999999;
      font-size: 16px;
      line-height: 22px;
      margin-left: 20px;
    }

    div img {
      height: 25px;
      width: 25px;
      margin-right: 20px;
    }
  }

  .mid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 24px;
    padding-top: 20px;
    border-bottom: #ccc 2px solid;
    height: 320px;

    div span {
      color: #666666;
      font-size: 16px;
      line-height: 22px;

      :first-child {
        font-weight: bold;
        margin-right: 10px;
      }
    }

    label {
      margin-top: 30px;
      line-height: 22px;
      font-weight: 600;
      font-size: 16px;
      color: #666666;
    }

    .text {
      margin-right: 20px;
      border: #ccc 1px solid;
      border-radius: 4px;
      background-color: #fafafa;
      padding: 5px 5px;

      p {
        color: #999999;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .url {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 20px;

      .input {
        display: flex;
        align-items: center;
        width: 100%;
        border: #ccc 1px solid;
        border-radius: 4px;
        background-color: #fafafa;
        padding: 5px;
        p {
          margin-top: 5px;
          color: #999999;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .bot {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    span {
      color: #999999;
      font-size: 16px;
      line-height: 22px;
      margin-right: 10px;
    }
  }
`;
