import styled from "styled-components";

export const Content = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);

  border-radius: 4px;
  padding: 35px 25px;

  .titulo {
    flex: 1;

    span {
      color: #00468e;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
      line-height: 19px;
      color: #666666;
      padding: 10px 5px;
    }
  }

  .botoes {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: center;

    button {
      width: 340px;
      height: 40px;
      border: 0;
      border-radius: 4px;
      background-color: #00468e;
      text-transform: uppercase;
      :hover {
        opacity: 0.8;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        color: #fff;
      }

      :first-child {
        margin-bottom: 90px;
      }
    }
  }
`;

export const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .titulo {
    width: 100%;
    border-bottom: 2px #ccc solid;
    padding: 15px;
    margin-bottom: 20px;

    span {
      color: #666666;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .content {
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      color: #333333;
      margin-bottom: 10px;
    }
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 140px;
      height: 40px;
      border: 0;
      border-radius: 4px;
      background-color: #00468e;
      font-size: 16px;
      color: #fff;
      line-height: 22px;
      text-transform: uppercase;
      margin-top: 20px;

      :hover {
        opacity: 0.8;
      }
    }
  }
`;
