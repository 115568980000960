import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Creators as MeetingActions } from '../../ducks/supervisor/meeting';
import { toast } from 'react-toastify';

export function* postMeeting(action) {
  const { id, data, horario, local } = action.payload;
  try {
    let response = yield call(api.post, 'api/v1/cycles/new-meeting', {
      user_id: id,
      data,
      horario,
      local,
    });

    toast.success(response.data.messages[0]);
    yield put(MeetingActions.setMeetingSuccess(response.data));
  } catch (error) {
    toast.error('Ocorreu um erro no envio do convite');
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        yield put(MeetingActions.setMeetingErro(error.data));
      }
    }
  }
}
