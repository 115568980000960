import React, { Component, Fragment } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as CategoryActions } from "../../../../../../store/ducks/modules/courses/category";
import { Creators as EnrollmentsActions } from "../../../../../../store/ducks/modules/courses/enrollments";

import { CategoryHeader } from "../../../../../User/Components/Headers";
import { ResultsContent } from "../styles";

import CourseItem from "../components/CourseItem";
import { CourseItemList } from "../components/CourseItem/styles";
import ResultCountAndFilter from "../components/ResultCountAndFilter";

import NotFoundItems from "../../../../../../Components/NotFoundItems";
import Loading from "../../../../../../Components/Loading";

class CategoryInProgress extends Component {
  state = {
    text: null,
    order: "desc"
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCategoryRequest(id);
    this.setState({ fetch: true });
  }

  componentWillUnmount() {
    this.props.filterEnrollmentsResetRequest();
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      const { id } = this.props.match.params;
      this.setState({ fetch: false }, () => {
        this.props.filterEnrollmentsRequest({
          categoryId: id,
          situation: "completed",
          order: this.state.order,
          text: this.state.text
        });
      });
    }
  }

  renderItemsHeader = () => {
    const { id } = this.props.match.params;

    return (
      <CategoryHeader
        active={3}
        id={id}
        labelTitle="Categoria"
        title={this.props.category.data.title}
        onChange={text => {
          this.setState({ text, fetch: true });
        }}
      />
    );
  };

  renderCoursesItem() {
    return this.props.enrollments.data.map((enrollment, i) => (
      <CourseItem isFinished key={i} item={enrollment.offer} enrollment={enrollment} />
    ));
  }

  renderLoading = () => <Loading loading={this.props.enrollments.loading} />;

  renderNotFound = () =>
    !this.props.enrollments.loading &&
    this.props.enrollments.data.length !== 0 ? null : (
      <NotFoundItems />
    );

  renderItems = () => (
    <ResultsContent>
      {this.props.enrollments.data.length !== 0 && (
        <ResultCountAndFilter
          count={this.props.enrollments.data.length}
          defaultActive={this.state.order}
          onChangeOrder={order => {
            this.setState({ order, fetch: true });
          }}
        />
      )}
      <CourseItemList>{this.renderCoursesItem()}</CourseItemList>
    </ResultsContent>
  );

  render() {
    return (
      <Fragment>
        {this.renderItemsHeader()}
        {this.renderLoading()}
        {this.renderNotFound()}
        {this.renderItems()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  category: state.category,
  enrollments: state.enrollments
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CategoryActions, ...EnrollmentsActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryInProgress);
