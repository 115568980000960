import styled from 'styled-components'

export const ResultadoRevisadoBoxWrapper = styled.div`
  background-color: #009B4C;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 30px 50px;
  width: 100%;

  span {
    font-size: 18px;
    text-transform: uppercase;
    :first-child {
      margin-bottom: 15px;
      width: 80%;
      text-align: center;
    }

    &.points {
      font-weight: bold;
      font-size: 28px;
    }
  }
`
