import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as CoursesActions } from "../../../../../store/ducks/modules/courses/courses";
import { Creators as CategoryActions } from "../../../../../store/ducks/modules/courses/category";

import { CategoryHeader } from "../../../../User/Components/Headers";
import { ResultsContent } from "./styles";

import CourseItem from "./components/CourseItem";
import { CourseItemList } from "./components/CourseItem/styles";
import ResultCountAndFilter from "./components/ResultCountAndFilter";
import NotFoundItems from "../../../../../Components/NotFoundItems";
import Loading from "../../../../../Components/Loading";

class Category extends Component {
  state = {
    categoryId: null,
    text: null,
    order: "desc"
  };

  componentDidMount() {
    if (this.props.match.params && this.props.match.params.id) {
      this.props.getCategoryRequest(this.props.match.params.id);
      this.setState({ fetch: true, categoryId: this.props.match.params.id });
    }
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false }, () => {
        this.props.getCoursesRequest({
          categoryId: this.state.categoryId,
          order: this.state.order,
          text: this.state.text
        });
      });
    }
  }

  renderItemsHeader = () => {
    const { id } = this.props.match.params;

    return (
      <CategoryHeader
        active={1}
        id={id}
        labelTitle="Categoria"
        title={this.props.category.data.title}
        onChange={text => {
          this.setState({ text, fetch: true });
        }}
      />
    );
  };

  renderItems = () => {
    if (this.state.loading) {
      return null;
    }
    return (
      <ResultsContent>
        {this.props.courses.data.length !== 0 && (
          <ResultCountAndFilter
            count={this.props.courses.data.length}
            defaultActive={this.state.order}
            onChangeOrder={order => {
              this.setState({ order, fetch: true });
            }}
          />
        )}
        <CourseItemList>
          {this.props.courses.data.map((item, i) => (
            <CourseItem key={`course-${item.id}`} item={item} />
          ))}
        </CourseItemList>
      </ResultsContent>
    );
  };

  renderLoading = () => <Loading loading={this.props.courses.loading} />;

  renderNotFound = () =>
    !this.props.courses.loading &&
    this.props.courses.data.length !== 0 ? null : (
      <NotFoundItems />
    );

  render() {
    return (
      <Fragment>
        {this.renderItemsHeader()}
        {this.renderLoading()}
        {this.renderNotFound()}
        {this.renderItems()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CoursesActions,
      ...CategoryActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  courses: state.courses,
  category: state.category
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Category);
