export function convertCycleStatus(status) {
  if (
    status.toLowerCase() === 'ativo' ||
    status.toLowerCase() === 'não iniciado'
  ) {
    return 'Não iniciou o preenchimento';
  } else if (status.toLowerCase() === 'em preenchimento') {
    return 'Em preenchimento';
  } else if (status.toLowerCase() === 'preenchido') {
    return 'Preenchido';
  } else if (status.toLowerCase() === 'em revisão') {
    return 'Em revisão do superior';
  } else if (status.toLowerCase() === 'em revisão colaborador') {
    return 'Em revisão do colaborador';
  } else if (
    status.toLowerCase() === 'finalizado' ||
    status.toLowerCase() === 'recursos humanos'
  ) {
    return 'Concluído';
  } else if (status.toLowerCase() === 'excluído') {
    return 'Removido do ciclo';
  }
  return status;
}

export function getLevelStatus(status) {
  if (
    status.toLowerCase() === 'em preenchimento' ||
    status.toLowerCase() === 'preenchido'
  ) {
    return 1;
  } else if (status.toLowerCase() === 'em revisão') {
    return 2;
  } else if (status.toLowerCase() === 'em revisão colaborador') {
    return 3;
  } else if (
    status.toLowerCase() === 'finalizado' ||
    status.toLowerCase() === 'recursos humanos'
  ) {
    return 4;
  }
  return 0;
}
