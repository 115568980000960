import styled from "styled-components";

export const Content = styled.div`
  height: calc(100% - 50px);
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 35px 25px;

  .text {
    span {
      color: #00468e;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }

    p {
      color: #666666;
      font-size: 14px;
      line-height: 19px;
      margin-left: 5px;
      margin-top: 10px;
    }
  }

  .content {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1;

    .content-inner {
      margin-top: 10%;
    }

    .media {
      height: 140px;
      width: 320px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-radius: 4px;
      border: 1px solid #00468e;
      border-radius: 5px;
      margin-bottom: 10px;

      span {
        color: #00468e;
        font-size: 18px;
        line-height: 18px;
        text-transform: uppercase;

        :last-child {
          font-size: 28px;
          line-height: 38px;
          font-weight: bold;
        }
      }
    }

    .button {
      button {
        width: 320px;
        height: 40px;
        border: 0;
        border-radius: 4px;
        background-color: #00468e;
        margin-top: 25px;
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;

        :hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
