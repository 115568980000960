import React from 'react'

import { ProgressBarStyled } from './styles'

function ProgressBar(props) {
    return (
        <ProgressBarStyled>
            <div className='progress'>
                <div className='progress-bar' style={{ width: `${props.progress}%` }} />
            </div>
        </ProgressBarStyled>
    )
}

export default ProgressBar;