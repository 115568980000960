import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import IosMedal from "react-ionicons/lib/IosMedal";
import moment from "moment";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as CertificateActions } from "../../../../../../../store/ducks/modules/courses/certificate";

import {
  CourseItemWrapper,
  CourseItemRatingAndNewWrapper,
  CourseItemTitleAndNotaWrapper,
  CourseItemInfosWrapper
} from "./styles";
import RatingStars from "../../../../../../../Components/RatingStars";
import ProgressBar from "../../../../../../../Components/ProgressBarSmall";

class CourseItem extends Component {
  calcProgress = (enrollId, topics) => {
    let completed = 0;
    if (topics && topics.length > 0) {
      for (let i = 0; i < topics.length; i++) {
        if (topics[i].scormUser) {
          if (
            topics[i].scormUser.enrollment_id === enrollId &&
            (topics[i].scormUser.session_status === "completed" ||
              topics[i].scormUser.session_status === "passed")
          ) {
            completed++;
          }
        }
      }
      return (completed / topics.length) * 100;
    }
    return 0;
  };

  downloadCertificate = () => {
    const { downloadCertificateRequest, enrollment = { } } = this.props;
    let { certificate } = enrollment;

    downloadCertificateRequest(certificate.id);
  }

  render() {
    const { isFinished, item: offer, enrollment = {}, certificateProgress } = this.props;
    let { course } = offer;
    let { certificate } = enrollment;

    return (
      <CourseItemWrapper>
        <CourseItemRatingAndNewWrapper>
          <div className="rating-wrapper">
            <RatingStars
              color="#F03B42"
              fontSize="12px"
              rating={course.rating}
            />
          </div>

          {course.new && <div className="badge">Novo</div>}
        </CourseItemRatingAndNewWrapper>

        <CourseItemTitleAndNotaWrapper>
          <h1>
            {enrollment.id ?
              <Link to={`/usuario/cursos/oferta/${offer.id}/matricula/${enrollment.id}`}>
                {offer.course.title}
              </Link>
            :
              <Link to={`/usuario/cursos/oferta/${offer.id}`}>
                {offer.course.title}
              </Link>
            }

          </h1>

          {isFinished && (
            <div className="nota-wrapper">
              <span>Nota</span>
              <span>{certificate && certificate.record}</span>
            </div>
          )}
        </CourseItemTitleAndNotaWrapper>

        <CourseItemInfosWrapper>
          {!isFinished ? (
            <Fragment>
              <div className="info-wrapper">
                <span className="title">Progresso</span>
                <span className="info">{this.calcProgress(enrollment.id, course.topics)}%</span>
              </div>
              <div className="info-wrapper">
                <span className="title">Nível</span>
                <span className="info">{course.level}</span>
              </div>
              <div className="info-wrapper">
                <span className="title">Início</span>
                <span className="info">
                  {moment(offer.begin).format("DD/MM/YYYY")}
                </span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="info-wrapper">
                <span className="title">Conclusão</span>
                <span className="info">
                  {moment(offer.end).format("DD/MM/YYYY")}
                </span>
              </div>
              <div className="info-wrapper">
                <span className="title">Renovação</span>
                <span className="info">
                  {moment(offer.begin).add(course.time_renew, 'days').format("DD/MM/YYYY")}
                </span>
              </div>
              {certificate && (
                <div className="info-wrapper">
                  <span className="title">Certificado</span>
                  <span className="info" style={{ marginTop: "5px" }}>
                    <div onClick={() => this.downloadCertificate()} className="view-cert">
                      <IosMedal fontSize="12px" color="#fff" />
                      <span>{certificateProgress.downloading ? 'Salvando...' : 'Baixar'}</span>
                    </div>
                  </span>
                </div>
              )}
            </Fragment>
          )}
        </CourseItemInfosWrapper>

        <ProgressBar progress={enrollment.id ? this.calcProgress(enrollment.id, course.topics) : 0} />
      </CourseItemWrapper>
    );
  }
}

const mapStateToProps = state => ({
  certificateProgress: state.certificate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CertificateActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseItem);
