import styled from 'styled-components'

export const CourseInfoWrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-right: 15px;
    }

    span, a {
      font-size: 12px;
      color: #ccc;
    }
    span:nth-child(2),
    a:nth-child(2) {
      font-family: 'OS Bold', sans-serif;
      color: #aaa;
      font-size: 18px;
      margin-top: 5px;
    }
  }
`

export const CourseContentWrapper = styled.div`
  display: flex;
  width: 100%;

  div {
    flex: 1;

    h1 {
      color: #535353;
      font-size: 24px;
      margin-bottom: 20px;
      font-family: 'OS Bold', sans-serif;
    }

    p {
        font-size: 15px;
        color: #666;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
  }

  div:first-child {
    flex:2;
    margin-right: 20px;
  }

  @media screen and (max-width: 900px){
    flex-direction: column;
    div:first-child {
      margin-right: 0;
    }
  }

`

export const CourseContentTopicsWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 10px;
  flex: 2;
`

export const CourseCommentariesWrapper = styled.div`
  background-color: #e5e5e5;
  padding: 20px;
  margin-top: 20px;

  > h1 {
    color: #535353;
    font-size: 24px;
    font-family: 'OS Bold',sans-serif;
  }

  .carousel {
    max-width: 1000px;
    margin-left: 20px;

    .arrow-wrapper {
      background-color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-radius: 5px;
    }

    @media screen and (max-width: 1360px) {
      max-width: 700px;
    }

    @media screen and (max-width: 1090px) {
      max-width: 500px;
    }
  }
`
