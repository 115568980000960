import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthActions from '../../store/ducks/auth';
import ErrorMsg from '../ErrorMsg';
import Loading from '../Loading';

import { LoginWrapper, Form, DivInput, Button, HelpBox } from './styles';

class LoginForm extends React.Component {
  static propTypes = {
    signInRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    const { signInRequest } = this.props;
    signInRequest(username, password);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderError = () => {
    if (!this.props.auth.error) {
      return null;
    }
    return <ErrorMsg msg={this.props.auth.error} />;
  };

  renderLoading = () => {
    return <Loading loading={this.props.auth.loading} />;
  };

  componentDidMount() {
    this.props.signInClearError();
    const query = new URLSearchParams(this.props.location.search);
    const username = query.get('email');

    if (username) {
      this.setState({
        username,
      });
    }
  }

  render() {
    if (this.props.auth.success) {
      if (
        !this.props.userInfo.loading &&
        this.props.userInfo.error &&
        this.props.auth.signedIn
      ) {
        toast.error('Erro de comunicação com o servidor');
        this.props.signOutRequest();
        return null;
      }
      let userInfo = this.props.userInfo.data;
      if (this.props.userInfo.loading || !userInfo.hasOwnProperty('roles')) {
        return <Loading loading={true} />;
      }
      switch (userInfo.profile) {
        case 'admin':
          return <Redirect to="/home" />;
        case 'manager':
          return <Redirect to="/choice" />;
        case 'user':
          return <Redirect to="/user/home" />;
        default:
          toast.error('Erro de comunicação com o servidor');
          break;
      }
    }
    const { username, password } = this.state;
    return (
      <LoginWrapper>
        {this.renderError()}
        {this.renderLoading()}
        <Form onSubmit={this.handleSubmit}>
          <DivInput>
            <label>login</label>
            <input
              type="username"
              name="username"
              defaultValue={username}
              onChange={this.handleInputChange}
            />
          </DivInput>
          <DivInput>
            <label>senha</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={this.handleInputChange}
            />
          </DivInput>
          <Button type="submit">ENTRAR</Button>
        </Form>
        <HelpBox>
          <a href="/recuperar-senha" className="forgotPass">
            Esqueci minha senha
          </a>
        </HelpBox>
      </LoginWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);

const mapStateToProps = state => ({
  auth: state.auth,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
