import styled, { css } from 'styled-components';

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  button {
    width: min-content;
    font-size: 14px;
    color: #fff;
    background-color: #00468e;
    padding: 5px 30px;
    border: 0;
    border-radius: 5px;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const LeftItem = styled.div`
  width: 50%;
  padding-right: 20px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const RightItem = styled.div`
  width: 50%;
  padding-top: 26px;
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 10px;
  }
`;

export const LabelStyled = styled.label`
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;

  ${props =>
    props.labelSecondary &&
    css`
      text-transform: none;
      font-size: 14px;
    `}
`;
