import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #009B4C !important;
  color: #fff !important;
  display: block !important;
  width: 100%;
  border-color: #009B4C !important;
  z-index: 1;

  .header-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .header {
    display: flex;
    background-color: #19A55E;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    padding-right: 0;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #009B4C;
    padding: 5px;

    div {

      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.nao-respondida {
      div {
        background-color: #fff;
        cursor: pointer;

        :hover {
          opacity: 0.8;
        }
      }
    }

    &.respondida {
      div {
        display: none;
        svg {
          fill: #fff
        }

        &.chosen {
          display: flex;
        }
      }
    }
  }
`
