import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

import DownloadActions from "../../../store/ducks/grade/download";
import { ButtonsWrapper } from "./styles";

class QuizDownload extends React.Component {
  static propTypes = {
    cycleUserId: PropTypes.any.isRequired,
    renderButton: PropTypes.func
  };

  state = {
    cycleUserId: undefined,
    loading: false,
    error: false,
    success: false
  };

  static getDerivedStateFromProps(props, state) {
    state.loading = props.download.loading;
    state.success = props.download.success;
    if (!state.error && props.download.error) {
      toast.error(props.download.error);
      props.gradeDownloadRequestResetAll();
    }
    state.error = props.download.error;
    state.cycleUserId = props.cycleUserId;
    return state;
  }

  componentWillUnmount() {
    this.props.gradeDownloadRequestResetAll();
  }

  onDownload = () => {
    if (this.state.loading) {
      return;
    }
    this.props.gradeDownloadRequest(this.state.cycleUserId);
  };

  renderButton = () => {
    if (this.state.loading) {
      return (
        <ButtonsWrapper>
          <button disabled={true}>Aguarde ...</button>
        </ButtonsWrapper>
      );
    }
    return (
      <ButtonsWrapper>
        <button onClick={this.onDownload}>Baixar Avaliação</button>
      </ButtonsWrapper>
    );
  };

  render() {
    if (this.props.renderButton) {
      return this.props.renderButton(this.onDownload, this.state.loading);
    }
    return <ButtonsWrapper>{this.renderButton()}</ButtonsWrapper>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...DownloadActions }, dispatch);

const mapStateToProps = state => ({
  download: state.gradeDownload
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizDownload);
