import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from 'react-responsive-modal';

import UserCycleActions from '../../store/ducks/cycle/userCycle';

import { ModalDefaultInner } from '../../styles/global';

import { ButtonWrapper, ButtonOk } from './styles';

class ModalNoCycle extends Component {
  state = {
    closeModal: false,
    isModalNoCycleOpen: false,
    requestedCycleUser: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.userCycle.error && !!this.props.userCycle.error) {
      this.setState({ isModalNoCycleOpen: true });
    }
  }

  componentDidMount() {
    if (this.props.requestCycleUsers && !this.state.requestedCycleUser) {
      this.props.userCycleRequest();
      this.setState({ requestedCycleUser: true });
    }
  }

  handleCloseModal = () => {
    this.setState({ closeModal: true });
  };

  shouldOpenModal = () => {
    if (!this.props.requestCycleUsers) {
      return this.props.isModalNoCycleOpen;
    }
    return this.state.isModalNoCycleOpen;
  };

  render() {
    return (
      <Fragment>
        {this.shouldOpenModal() && (
          <Modal
            open={this.shouldOpenModal && !this.state.closeModal}
            onClose={this.handleCloseModal}
            styles={{
              modal: {
                borderRadius: '5px',
                width: '800px',
                padding: '20px',
              },
            }}
            showCloseIcon={false}
          >
            <ModalDefaultInner>
              <h1>
                Você não participa de nenhum ciclo. Por favor, entre em contato
                com o seu superior imediato para adicioná-lo em um ciclo.
              </h1>
              <ButtonWrapper>
                <ButtonOk type="button" onClick={this.handleCloseModal}>
                  Ok
                </ButtonOk>
              </ButtonWrapper>
            </ModalDefaultInner>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserCycleActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  userCycle: state.userCycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNoCycle);
