import React from "react";
import PropTypes from "prop-types";

import { ProgressBar, ProgressBarWrapper, ProgressIndicator } from "./styles";
import NotFoundItems from "../../../Components/NotFoundItems";

export default class Progress extends React.Component {
  static propTypes = {
    stages: PropTypes.array.isRequired,
    activeStageId: PropTypes.number,
    lastStageActive: PropTypes.bool
  };

  state = {
    progresses: [],
    progressActiveIndex: 1,
    progressPercent: 0,
    activeStageId: null
  };

  static getDerivedStateFromProps(props, state) {
    state.activeStageId = props.activeStageId;
    state.stages = props.stages;
    if (state.stages) {
      let progresses = [...state.stages].map(stage => {
        return { id: stage.id, title: stage.title, ordem: stage.ordem };
      });
      state.progresses = progresses;
      if (props.lastStageActive === true) {
        let progress = progresses[progresses.length - 1];
        if (progress) {
          state.activeStageId = progress.id;
        }
      }
    }
    return state;
  }

  render() {
    if (!this.state.stages) {
      return <NotFoundItems />;
    }
    const { progresses, progressPercent } = this.state;
    let index = progresses.findIndex(
      stage => stage.id == this.state.activeStageId
    );
    return (
      <ProgressBar>
        <ProgressBarWrapper progress={progressPercent}>
          <div className="progress-back" />
          <div className="progress-fill" />
        </ProgressBarWrapper>

        <ProgressIndicator>
          <ol>
            {progresses.map((prog, i) => (
              <li key={i} className={i <= index ? "active" : null}>
                {prog.id == this.state.activeStageId ? prog.title : null}
              </li>
            ))}
          </ol>
        </ProgressIndicator>
      </ProgressBar>
    );
  }
}
