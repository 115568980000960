import React, { Component } from 'react';

import { Div } from '../../../styles/global';
import { Content, Title } from './styles';

import Back from '../../../Components/Back';
import SubHeader from '../components/SubHeader';
import TableNaoSelecionados from './TableNaoSelecionados';
import TableSelecionados from './TableSelecionados';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuizActions } from '../../../store/ducks/quiz/quiz';
import { Creators as RolesActions } from '../../../store/ducks/role/roles';
import { Creators as TemplateQuizActions } from '../../../store/ducks/quiz/templateQuiz';
import { Creators as QuizRolesActions } from '../../../store/ducks/quiz/quizRoles';
import { Creators as TemplateQuizRolesActions } from '../../../store/ducks/quiz/templateQuizRoles';
import Loading from '../../../Components/Loading';

class DestinatariosFormularios extends Component {
  state = {
    quiz: null,
    page: 1,
    naoSelecionados: [],
    selecionados: [],
    search: '',
    isTemplate: true,
    quizId: null,
    cycleId: null,
    loading: false,
    showDeniedModal: false,
    rolesDenied: [],
    totalNaoSelecionados: 0,
    editable: true,
    isEditting: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (String(this.props.location.pathname).includes('formularios')) {
      this.setState({ isTemplate: false, quizId: id });
      this.props.getQuizRequest(id, true);
      this.props.getRolesRequest({
        usable: false,
        quizId: id,
        status: 'Ativo',
      });
    } else {
      this.setState({ quizId: id });
      this.props.getTemplateQuizRequest(id, true);
      this.props.getRolesRequest({ usable: true, quizId: id, status: 'Ativo' });
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!prevProps.quiz.success && this.props.quiz.success) {
      let quiz = this.props.quiz.details;
      let selecionados = quiz.roles;
      const cycle = quiz.cycles[0];
      this.setState({
        quiz,
        selecionados,
        cycleId: cycle.id,
        editable: quiz.editable,
      });
      this.props.setTitle(`Formulário do ciclo - ${cycle.title}`);
    }
    if (!prevProps.templateQuiz.success && this.props.templateQuiz.success) {
      let quiz = this.props.templateQuiz.details;
      let selecionados = quiz.roles;
      this.setState({ quiz, selecionados });
      this.props.setTitle(`Modelo de formulário - ${quiz.title}`);
    }

    if (!prevProps.roles.success && this.props.roles.success) {
      this.setState({
        naoSelecionados: this.props.roles.data,
        totalNaoSelecionados: Number(this.props.roles.total),
      });
    }

    const { templateQuizRoles, quizRoles } = this.props;
    if (this.props.isTemplate) {
      if (
        templateQuizRoles &&
        !prevProps.templateQuizRoles.success &&
        templateQuizRoles.success
      ) {
        this.setState({ loading: false });
      }
    } else {
      if (quizRoles && !prevProps.quizRoles.success && quizRoles.success) {
        const { details } = quizRoles;

        if (details.type === 'denied') {
          this.setState({
            loading: false,
            showDeniedModal: true,
            rolesDenied: details.rolesDenied,
          });
          this.setState({ selecionados: details.roles });
        } else {
          this.setState({ loading: false });
        }
        this.onSearch();
      }
    }
  }

  onSubmit = () => {
    let { quizId, selecionados, isTemplate } = this.state;
    if (isTemplate) {
      this.props.updateTemplateQuizRolesRequest(
        quizId,
        selecionados.map(r => r.id)
      );
    } else {
      this.props.updateQuizRolesRequest(
        quizId,
        selecionados.map(r => r.id)
      );
    }
    this.setState({ loading: true, isEditting: false });
  };

  onSearch = text => {
    this.setState(
      {
        page: 1,
        search: text,
      },
      () => {
        this.props.clearRoles();
        this.props.getRolesRequest({
          page: 1,
          text,
          usable: this.state.isTemplate,
          quizId: this.state.quizId,
          status: 'Ativo',
          ignoreRoles: this.state.selecionados.map(r => r.id),
        });
      }
    );
  };

  onCloseModal = () => {
    this.setState({ showDeniedModal: false });
  };

  handleChangeCheckbox = (value, from) => {
    value = Number(value);
    if (from === 'selecionados') {
      const chosen = this.state.selecionados.find(role => {
        return value === role.id;
      });
      const selecionados = this.state.selecionados.filter(
        r => r.id !== chosen.id
      );
      this.setState({
        naoSelecionados: [chosen, ...this.state.naoSelecionados],
        selecionados,
        totalNaoSelecionados: this.state.totalNaoSelecionados + 1,
        isEditting: true,
      });
    } else if (from === 'naoSelecionados') {
      const chosen = this.state.naoSelecionados.find(role => {
        return value === role.id;
      });
      const naoSelecionados = this.state.naoSelecionados.filter(
        r => r.id !== chosen.id
      );
      this.setState({
        selecionados: [...this.state.selecionados, chosen],
        naoSelecionados,
        totalNaoSelecionados: this.state.totalNaoSelecionados - 1,
        isEditting: true,
      });
    }
  };

  addSelecionados = (value, from) => {
    if (from === 'selecionados') {
      const chosen = this.state.selecionados.find(role => {
        return value === role.id;
      });
      const selecionados = this.state.selecionados.filter(
        r => r.id !== chosen.id
      );
      this.setState({
        naoSelecionados: [...this.state.naoSelecionados, chosen],
        selecionados,
      });
    } else if (from === 'naoSelecionados') {
      const chosen = this.state.naoSelecionados.find(role => {
        return value === role.id;
      });
      const naoSelecionados = this.state.naoSelecionados.filter(
        r => r.id !== chosen.id
      );
      this.setState({
        selecionados: [...this.state.selecionados, chosen],
        naoSelecionados,
      });
    }
  };

  removeSelecionados = (value, from) => {
    if (from === 'selecionados') {
      const newArray = this.state.selecionados.filter(sel => value !== sel.id);
      this.setState({ selecionados: newArray });
    } else if (from === 'naoSelecionados') {
      const newArray = this.state.naoSelecionados.filter(
        sel => value !== sel.id
      );
      this.setState({ naoSelecionados: newArray });
    }
  };

  loadMoreRoles = page => {
    let { search: text } = this.state;
    this.props.getRolesRequest({
      page,
      text,
      usable: this.state.isTemplate,
      quizId: this.state.quizId,
      status: 'Ativo',
    });
    this.setState({ page, loading: true });
  };

  renderNaoSelecionadosTitle() {
    const { totalNaoSelecionados } = this.state;

    if (!this.state.isTemplate && totalNaoSelecionados === 0) {
      return 'Não há cargos restantes no formulário modelo';
    }

    const isSearching = this.state.search !== '';
    if (totalNaoSelecionados === 0) {
      return isSearching
        ? 'Nenhum cargo encontrado'
        : 'Nenhum cargo não vinculado';
    }
    if (totalNaoSelecionados === 1) {
      return isSearching ? '1 cargo encontrado' : '1 cargo não vinculado';
    }
    if (totalNaoSelecionados > 1) {
      return isSearching
        ? `${totalNaoSelecionados} cargos encontrados`
        : `${totalNaoSelecionados} cargos não vinculados`;
    }
  }

  render() {
    let { quiz, selecionados } = this.state;
    const isSearching = this.state.search !== '';
    if (
      !quiz ||
      !('id' in quiz) ||
      this.props.quiz.loading ||
      this.props.templateQuiz.loading
    ) {
      return (
        <Div>
          {this.state.isTemplate && (
            <Back path="/modelos" title="Voltar para listagem" />
          )}
          <SubHeader
            page="destinatarios"
            isTemplate={this.state.isTemplate}
            id={this.props.match.params.id}
          />
          <Loading loading={true} />
        </Div>
      );
    }

    return (
      <Div>
        {this.state.isTemplate ? (
          <Back path="/modelos" title="Voltar para listagem" />
        ) : (
          <Back
            path={`/ciclos/${this.state.cycleId}/formularios`}
            title="Voltar para listagem"
          />
        )}
        <SubHeader
          page="destinatarios"
          isTemplate={this.state.isTemplate}
          id={this.props.match.params.id}
        />

        <Title>
          <span>
            Selecione todos os cargos que deverão ser associados a este
            formulário.
          </span>
        </Title>
        <Content>
          <div className="itensButton">
            <span>
              {selecionados.length === 0 && 'Nenhum cargo vinculado'}
              {selecionados.length === 1 && '1 cargo vinculado'}
              {selecionados.length > 1 &&
                `${selecionados.length} cargos vinculados`}
            </span>
            <button type="button" onClick={this.onSubmit}>
              Salvar
            </button>
          </div>

          <div className="selected">
            <TableSelecionados
              selecionados={this.state.selecionados}
              onChange={this.handleChangeCheckbox}
              isTemplate={this.state.isTemplate}
              editable={this.state.editable}
              showDeniedModal={this.state.showDeniedModal}
              rolesDenied={this.state.rolesDenied}
              onCloseModal={this.onCloseModal}
            />
          </div>
        </Content>
        {!this.state.isTemplate && this.state.totalNaoSelecionados !== 0 && (
          <Title>
            <span>
              Abaixo estão todos os cargos não vinculados neste formulário e
              vinculados ao formulário modelo.
            </span>
          </Title>
        )}
        <Content style={{ margin: '4px 0 10px' }}>
          <div className="itensButton">
            <span>{this.renderNaoSelecionadosTitle()}</span>
          </div>

          <Loading loading={this.props.roles.loading && !isSearching} />

          <TableNaoSelecionados
            roles={this.state.naoSelecionados}
            onChange={this.handleChangeCheckbox}
            eventSearch={this.onSearch}
            page={this.state.page}
            showLoadMore={
              this.state.page !== this.props.roles.lastPage &&
              !this.state.isEditting
            }
            loadMore={this.loadMoreRoles}
          />
        </Content>
      </Div>
    );
  }
}

const mapStateToProps = state => ({
  quiz: state.quiz,
  roles: state.roles,
  templateQuiz: state.templateQuiz,
  quizRoles: state.quizRoles,
  templateQuizRoles: state.templateQuizRoles,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...QuizActions,
      ...RolesActions,
      ...TemplateQuizActions,
      ...QuizRolesActions,
      ...TemplateQuizRolesActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinatariosFormularios);
