import styled from 'styled-components';

export const QuestionGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  .perguntas-wrapper {
    padding-left: 20px;
    margin-top: 20px;

    > div {
      margin-bottom: 15px;
      height:51px;

      &.active {
        height: auto;
      }
    }
  }
`;
