import React from 'react';
import PropTypes from 'prop-types';

import Pagination from '../Pagination';
import Loading from '../Loading';
import { TableDiv, Thead, Tbody, Tr } from '../Table/styles';
import ErrorMsg from '../ErrorMsg';
import NotFoundItems from '../NotFoundItems';

class UserCycles extends React.Component {
  state = {
    page: 1,
    fetch: false,
    userId: null,
  };

  componentDidMount() {
    this.setState({
      userId: this.props.userId,
      fetch: true,
    });
  }

  componentWillUnmount() {
    this.props.clear();
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false });
      this.props.request({ ...this.state });
    }
  }

  handlerItemSelected = item => {
    if (this.props.onSelect instanceof Function) {
      this.props.onSelect(item);
    }
  };

  renderHeader() {
    return (
      <Thead>
        <Tr>
          <th>ID</th>
          <th>Título</th>
          <th>Última interação</th>
          <th>Status</th>
        </Tr>
      </Thead>
    );
  }

  renderItem(item) {
    return (
      <Tr key={item.id}>
        <td>{item.cycle}</td>
        <td>
          <a href="#" onClick={() => this.handlerItemSelected(item)}>
            {item.cycle_title}
          </a>
        </td>
        <td>{item.updated_at}</td>
        <td>{item.status}</td>
      </Tr>
    );
  }

  renderItems() {
    let data = this.props.data;
    return <Tbody>{data.map(item => this.renderItem(item))}</Tbody>;
  }

  renderData() {
    let data = this.props.data;
    if (data.length === 0) {
      return <NotFoundItems />;
    }
    return (
      <TableDiv>
        {this.renderHeader()}
        {this.renderItems()}
      </TableDiv>
    );
  }

  renderPagination() {
    let { page, loading, success, data, lastPage } = this.props;
    if (loading || (success && data.length === 0)) {
      return null;
    }
    if (page === lastPage) {
      return null;
    }
    return (
      <Pagination
        paginate={{ page, loading }}
        onClick={page => {
          this.setState({ page, append: true, fetch: true });
        }}
      />
    );
  }

  renderLoading() {
    return <Loading loading={this.props.loading} />;
  }

  render() {
    const { error } = this.props;

    if (error) {
      return <ErrorMsg msg={error} />;
    }

    return (
      <>
        {this.renderData()}
        {this.renderLoading()}
        {this.renderPagination()}
      </>
    );
  }
}

UserCycles.propTypes = {
  userId: PropTypes.any.isRequired,
  page: PropTypes.any,
  success: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  request: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};

export default UserCycles;
