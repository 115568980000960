import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import CycleDataActions from "../../../store/ducks/cycle/cycleData";

import SubHeader from "../../../Components/SubHeader";
import Back from "../../../Components/Back";

import { Div } from "../../../styles/global";

import CycleDataForm from "../Form/CycleDataForm";
import Loading from "../../../Components/Loading";
import ErrorMsg from "../../../Components/ErrorMsg";

class CycleData extends React.Component {
  state = {
    cycle: null,
    cycleId: null,
    ready: false
  };

  renderContent = () => {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.props.cycleData.error) {
      return <ErrorMsg msg={this.props.cycleData.error} />;
    }
    return (
      <CycleDataForm
        key={this.state.cycle ? this.state.cycle.updated_at : "novo"}
        history={this.props.history}
        cycle={this.state.cycle}
      />
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.cycleData.error || props.cycleData.success) {
      state.ready = true;
      if (props.cycleData.success) {
        state.cycle = props.cycleData.cycle;
        props.setTitle(`Ciclo - ${state.cycle.title}`);
      }
    }
    return state;
  }

  componentDidMount() {
    let params = this.props.match.params;
    if (!params || !params.id || !params.id.match(/^[0-9]+$/)) {
      return this.setState({ ready: true });
    }
    this.setState(
      {
        cycleId: params.id
      },
      () => {
        this.props.cycleDataRequest(params.id);
      }
    );
  }

  render() {
    return (
      <Fragment>
        <Div>
          <Back path="/ciclos" title="Voltar para listagem" />
          <SubHeader page="dados" cycle={this.state.cycle} />
          {this.renderContent()}
        </Div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CycleDataActions }, dispatch);

const mapStateToProps = state => ({
  cycleData: state.cycleData
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CycleData);
