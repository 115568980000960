import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import RemoveParticipantsActions from '../../ducks/participant/removeParticipants';

export function* removeParticipantsResetStatus() {
  yield put(RemoveParticipantsActions.removeParticipantsResetStatus());
}

export function* requestRemoveParticipants({ cycleId, usersReasons }) {
  yield put(RemoveParticipantsActions.removeParticipantsClearError());
  yield put(RemoveParticipantsActions.removeParticipantsLoading());
  try {
    let url = 'api/v1/admin/cycles/users/remove';
    let result = yield call(api.delete, url, {
      data: {
        cycle_id: cycleId,
        users_reasons: usersReasons,
      },
    });
    yield put(
      RemoveParticipantsActions.removeParticipantsSuccess(
        result.data.messages[0]
      )
    );
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            RemoveParticipantsActions.removeParticipantsError(
              response.messages[0]
            )
          );
        }
      }
    }
    yield put(
      RemoveParticipantsActions.removeParticipantsError(
        'Ocorreu um erro no servidor, tente mais tarde.'
      )
    );
  }
}
