import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MdClose from 'react-ionicons/lib/MdClose';
import { Link } from 'react-router-dom';

import SideMenu from '../SideMenu';
import UserHeaderDetail from '../../../Components/UserComponents/UserHeaderDetail';

import QuizShow from '../../../Components/Quiz/Show';
import UserInfo from '../../../Components/UserComponents/UserInfo';

import { ContentWrapper } from '../styles';
import { DivTabs, DivQuiz, DivClose } from './styles';

export default class UsuarioCiclo extends Component {
  state = {
    cycleUserId: null,
  };

  static getDerivedStateFromProps(props, state) {
    state.cycleUserId = props.match.params.cycleUserId;
    state.userId = props.match.params.userId;
    return state;
  }

  render() {
    let { userId, cycleUserId } = this.state;
    return (
      <ContentWrapper>
        <SideMenu page={1} />
        <div className="content">
          <UserInfo userId={userId}>
            <UserHeaderDetail />
          </UserInfo>
          <DivTabs>
            <Tabs defaultIndex={1}>
              <TabList>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}`}>Dados</Link>
                </Tab>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}/ciclos`}>
                    Ciclos
                  </Link>
                </Tab>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}/hierarquias`}>
                    Hierarquias
                  </Link>
                </Tab>
              </TabList>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
            </Tabs>
          </DivTabs>
          <DivQuiz>
            <DivClose>
              <Link to={`/dadosgerais/usuarios/${userId}/ciclos`}>
                <MdClose fontSize="32px" color="#000" />
              </Link>
            </DivClose>
            <QuizShow cycleUserId={cycleUserId} />
          </DivQuiz>
        </div>
      </ContentWrapper>
    );
  }
}
