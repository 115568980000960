import styled from "styled-components";

export const SuccessStyled = styled.div`
  width: 100%;
  padding: 10px;
  background-color: green;
  border-radius: 5px;
  margin-bottom: 10px;
  span {
    color: #fff;
    font-size: 14px;
  }
`;
