import React, { Component } from "react";
import { CourseItemStyled } from "./styles";
import { Link } from "react-router-dom";

export default class CourseItem extends Component {
  render() {
    const { item } = this.props;
    return (
      <CourseItemStyled>
        <Link to={`/usuario/cursos/oferta/${item.id}`}>
          <h1>{item.title}</h1>
          {item.count_new_courses !== null && item.count_new_courses !== 0 && (
            <span>{}</span>
          )}
        </Link>
      </CourseItemStyled>
    );
  }
}
