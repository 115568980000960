import React, { Fragment } from "react";
import { UserContentDiv } from "../../../../styles/components";
import Progress from "../../../../Components/UserComponents/Progress";

import AvatarIcon from "../../../../assets/images/ava-desempenho.jpg";

import { Content } from "./styles";

export default class RevisaoIniciar extends React.Component {
  render() {
    let { userCycle } = this.props;
    return (
      <Fragment>
        <Progress stages={userCycle.stages} />
        <UserContentDiv>
          <Content>
            <div className="title">
              <span>{userCycle.quiz.title}</span>
            </div>
            <div className="header">
              <img src={AvatarIcon} alt="" />
              <span>faça desse momento uma reflexão profissional</span>
            </div>
            <div className="text">
              <p>{userCycle.quiz.description}</p>
            </div>

            <div className="button" onClick={this.props.onInitQuiz}>
              <a href="#!">iniciar</a>
            </div>
          </Content>
        </UserContentDiv>
      </Fragment>
    );
  }
}
