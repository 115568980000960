import styled from 'styled-components';

export const ResponsiveWrapper = styled.div`
  @media (max-width: 768px) {
    .hideResponsive {
      display: none;
      width: 100%;
    }
  }
`;
