import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: "Open Sans", sans-serif;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #DDDDDD;

    .helper-drag-class {
      border: 1px dashed #00468e;
      border-radius: 5px;
    }

  }

  button {
    cursor: pointer;
  }
`;

export const Div = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 22px 28px;
  margin: 10px 0;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;

  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;

    span {
      text-transform: uppercase;
      color: #666;
      font-size: 18px;
    }
  }

  .modal-body {
    p {
      font-size: 16px;
      color: #333333;
      text-align: center;
      width: 80%;
      margin: 0 auto;
    }

    button {
      margin: 0 auto;
      text-transform: uppercase;
      padding: 10px 50px;
      background-color: #00468e;
      color: #fff;
      border-radius: 5px;
      border: 0;
      display: flex;
      align-self: center;
      margin-top: 25px;

      :hover {
        opacity: 0.8;
      }
    }
  }
`;

export const BoxWhiteRounded = styled.div`
  padding: 15px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-bottom: 3px solid #f5f5f5;
  margin-bottom: 15px;
`;

export const ModalDefaultInner = styled.div`
  padding: 20px;
  h1 {
    font-family: 'OS Bold', sans-serif;
    font-size: 18px;
    color: #535353;
    margin: 0;
  }

  .btns-wrapper {
    display: flex;
    align-items: center;
    margin-top: 20px;

    a,
    button {
      background-color: #999;
      color: #fff;
      padding: 10px 20px;
      border: 0px;
      border-radius: 5px;
      font-family: 'OS Bold', sans-serif;

      :not(:last-child) {
        margin-right: 10px;
      }

      :hover {
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }
`;

export const WarningMessage = styled.div`
  background: #fffbea;
  padding: 10px;
  border-radius: 3px;
  color: #46453f;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #fdd13a;
`;
