import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import UserCycleActions from '../../../store/ducks/cycle/userCycle';
import NotFoundItems from '../../../Components/NotFoundItems';
import UserCycleInfo from './Components/UserCycleInfo';
import ParticipantsList from './Components/Participants/List';
import ModalNoCycle from '../../../Components/ModalNoCycle';

class Notification extends Component {
  state = {
    redirect: false,
    isModalNoCycleOpen: false,
  };

  componentDidMount() {
    this.props.userCycleRequest();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.userCycle.error && this.props.userCycle.error) {
      this.setState({ isModalNoCycleOpen: true });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/user/stage`} />;
    }
    if (this.props.userCycle.error) {
      return (
        <Fragment>
          <NotFoundItems />
          <ModalNoCycle isModalNoCycleOpen={this.state.isModalNoCycleOpen} />
        </Fragment>
      );
    }
    let userCycle = this.props.userCycle.userCycle;
    if (!userCycle) {
      return <NotFoundItems />;
    }
    return (
      <div>
        <UserCycleInfo userCycle={userCycle} title={'Minha avaliação'} />
        <ParticipantsList userCycle={userCycle} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UserCycleActions }, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
