import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'templateQuizzes/GET_REQUEST',
  GET_SUCCESS: 'templateQuizzes/GET_SUCCESS',
  CLEAR: 'templateQuizzes/CLEAR',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  total: null,
  page: 1,
  lastPage: null,
});

export default function templateQuizzes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      let data;
      if (!!action.payload.data.details) {
        data = [...state.data, ...action.payload.data.details];
      } else if (!!action.payload.data.data) {
        data = [...action.payload.data.data];
      }
      return {
        ...action.payload.data,
        data,
        page: action.payload.data.page,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getTemplateQuizzesRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  getTemplateQuizzesSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  clearTemplateQuizzes: () => ({
    type: Types.CLEAR,
  }),
};
