import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
`;

export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  h3 {
    margin-bottom: 30px;
  }
`;

export const Back = styled.div`
  margin-top: 30px;
  margin-left: 25px;

  a {
    text-decoration: none;
    color: #00468e;
    font-family: 'Open Sans';
    line-height: 19px;
  }
`;

export const Content = styled.div``;

export const MsgStatus = styled.p`
  margin: 20px 2px;
  font-size: 16px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;

  button {
    margin: 0;
    border: 0;
    padding: 0;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: #fff;

      background-color: #00468e;
      height: 40px;
      width: 160px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
    :first-child {
      margin-right: 50px;
      background-color: #00468e;
      height: 40px;
      width: 160px;
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
    :first-child:hover {
      background-color: #236db9;
    }
  }
`;


