import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 2;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  margin-left: 220px;

  @media screen and (max-width: ${props =>
      props.fromDadosGerais ? '1000px' : '768px'}) {
    margin-left: ${props => (props.isActive ? `220px` : `0`)};
  }
`;

export const UserContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  padding: 25px;

  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`;

export const UserContentDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;
