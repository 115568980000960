import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DefinirSenhaWrapper, LeftSide, RightSide } from './styles';
import Brand from '../../assets/images/logo-emae.png';

import DefinePasswordActions from '../../store/ducks/definePassword';
import ErrorMsg from '../../Components/ErrorMsg';
import { FormItemError } from '../../Components/FormFieldError';
import SuccessMsg from '../../Components/SuccessMsg';
import Loading from '../../Components/Loading';

class DefinirSenha extends Component {
  static propTypes = {
    definePasswordRequest: PropTypes.func.isRequired,
  };

  state = {
    error: false,
    success: false,
    password: '',
    repassword: '',
  };

  handleSubmitForm = e => {
    e.preventDefault();
    this.setState({ error: '' });
    if (!this.state.password) {
      return this.setState({ error: 'Preencha todos os campos' });
    }
    if (this.state.password !== this.state.repassword) {
      return this.setState({ error: 'Senhas diferentes' });
    }
    const urlParams = new URLSearchParams(this.props.location.search);
    const token = urlParams.get('token');
    if (!token) {
      return this.setState({ error: 'Token não informado' });
    }
    this.props.definePasswordRequest(token, this.state.password);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isLoading = () => {
    return this.props.definePassword.loading;
  };

  renderFormError = fieldName => {
    let reducer = this.props.definePassword;

    return <FormItemError reducer={reducer} fieldName={fieldName} />;
  };

  renderError = () => {
    if (this.state.error) {
      return <ErrorMsg msg={this.state.error} />;
    }
    let error = this.props.definePassword.error;
    if (!error) {
      return null;
    }
    if (error instanceof String) {
      return <ErrorMsg msg={error} />;
    }
    return <ErrorMsg msg={error.title} />;
  };

  renderSuccess = () => {
    if (!this.props.definePassword.success) {
      return null;
    }
    return <SuccessMsg msg={'Senha alterada com sucesso.'} />;
  };

  renderLoading = () => {
    return <Loading loading={this.isLoading()} />;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.definePassword.success && !state.success) {
      state.error = '';
      state.password = '';
      state.repassword = '';
      state.success = true;
    }
    return state;
  }

  render() {
    let { password, repassword } = this.state;
    return (
      <DefinirSenhaWrapper>
        <LeftSide>
          <img src={Brand} alt="" />

          <p>Gestão de ciclos e avaliações de desempenho</p>
        </LeftSide>

        <RightSide>
          <form onSubmit={this.handleSubmitForm}>
            <h3>Definir Senha</h3>
            {this.renderError()}
            {this.renderLoading()}
            {this.renderSuccess()}
            <div>
              <label htmlFor="senha">Senha</label>
              <input
                type="password"
                name="password"
                id="senha"
                value={password}
                onChange={this.handleInputChange}
              />
              {this.renderFormError('password')}
            </div>
            <div>
              <label htmlFor="repetir-senha">Repetir Senha</label>
              <input
                type="password"
                name="repassword"
                id="repetir-senha"
                value={repassword}
                onChange={this.handleInputChange}
              />
            </div>
            <div>
              <button disabled={this.isLoading()} type="submit">
                Definir senha
              </button>
            </div>
            <a href="/">Voltar para a tela inicial</a>
          </form>
        </RightSide>
      </DefinirSenhaWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...DefinePasswordActions }, dispatch);

const mapStateToProps = state => ({ definePassword: state.definePassword });

export default connect(mapStateToProps, mapDispatchToProps)(DefinirSenha);
