import React, { Component, Fragment } from 'react';

import { MainWrapper, Content } from '../styles/components';
import { AppDiv } from '../styles/app';

import { GlobalStyle } from '../styles/global';

import { Route } from 'react-router-dom';
import FooterOutisde from '../Components/FooterOutside';

export default class UserBasicRoute extends Component {
  render() {
    if (this.props.profile !== 'manager') {
      return null;
    }

    const { component: Component, ...props } = this.props;

    return (
      <Fragment>
        <MainWrapper
          style={{ backgroundColor: '#3562a5', flexDirection: 'column' }}
        >
          <GlobalStyle />
          <Content style={{ marginLeft: 0 }}>
            <AppDiv
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Route {...props} render={props => <Component {...props} />} />
            </AppDiv>
          </Content>
          <FooterOutisde />
        </MainWrapper>
      </Fragment>
    );
  }
}
