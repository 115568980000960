import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  > .content {
    flex: 1;
    margin: 0 10px;
  }

  .ml20 {
    margin-left: 20px;
  }
`;
