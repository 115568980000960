import React, { Fragment } from "react";

import Progress from "../../../Components/UserComponents/Progress";
import Buttons from "../../../Components/UserComponents/Buttons";

import { Content, TextArea } from "./styles";

export default class UserComents extends React.Component {
  render() {
    const page = "comentarios";
    return (
      <Fragment>
        <Progress page={page} percentage={50} />
        <Content>
          <span>comentários</span>
          <p>
            Demonstra foco no alcance dos resultados estabelecidos. Apresenta
            trabalhos de forma completa e precisa, dentro do prazo.
          </p>
          <div>
            <label>pontos positivos</label>
            <TextArea placeholder="..." />
          </div>

          <div>
            <label>pontos de melhora</label>
            <TextArea placeholder="..." />
          </div>
          <Buttons />
        </Content>
      </Fragment>
    );
  }
}
