import React, { Component } from 'react';
import { WrapperStyled } from './styles';
import LogoEmae from '../../assets/images/logo-emae.png';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <WrapperStyled>
        <div>
          <div className="header">
            <Link to="/">
              <img src={LogoEmae} alt="EMAE" />
            </Link>
          </div>

          <div className="body">
            <span>Desculpe, esta página não está disponível.</span>
            <span>O link que você acessou não existe.</span>
            <button onClick={() => this.props.history.goBack()}>
              Retornar à página anterior
            </button>
          </div>
        </div>
      </WrapperStyled>
    );
  }
}
