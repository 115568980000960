import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { convertCycleStatus } from '../../services/cycle/status';

import {
  FilterWrapper,
  LeftItem,
  RightItem,
  ButtonWrapper,
  LabelStyled,
} from './styles';

import QuizByCycleActions from '../../store/ducks/quiz/quizByCycle';

import SelectWithPopup from '../../Components/SelectWithPopup';

const statusList = [
  { name: convertCycleStatus('Ativo'), value: 'Ativo' },
  { name: convertCycleStatus('Preenchido'), value: 'Preenchido' },
  {
    name: convertCycleStatus('Em Revisão Colaborador'),
    value: 'Em Revisão Colaborador',
  },
  { name: convertCycleStatus('Em Revisão'), value: 'Em Revisão' },
  { name: convertCycleStatus('Finalizado'), value: 'Finalizado' },
  { name: convertCycleStatus('Excluído'), value: 'Excluído' },
];

class FilterQuiz extends React.Component {
  state = {
    fetch: false,
    quizzes: [],
    selectedQuiz: null,
    selectedStatus: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.quizByCycle.success) {
      state.quizzes = props.quizByCycle.quizzes;
    }
    return state;
  }

  componentDidUpdate() {
    const cycleId = this.props.cycleId;
    if (!!cycleId && !this.state.fetch) {
      this.setState({ fetch: true });
      this.props.quizByCycleRequest(cycleId);
    }
  }

  render() {
    return (
      <div>
        <FilterWrapper>
          <LeftItem>
            <LabelStyled htmlFor="selectedQuiz">
              filtrar por formulário
            </LabelStyled>
            <SelectWithPopup
              placeholder="formulário"
              list={this.state.quizzes.map(quiz => {
                return { value: quiz.id, name: quiz.title };
              })}
              onSelectItem={quiz =>
                this.setState({ selectedQuiz: quiz ? quiz.id : null })
              }
              defaultoption="Selecione"
              name="selectedQuiz"
            />
          </LeftItem>
          <RightItem>
            <SelectWithPopup
              placeholder="status"
              list={statusList}
              onSelectItem={status =>
                this.setState({ selectedStatus: status ? status.value : null })
              }
              name="selectedStatus"
            />
          </RightItem>
        </FilterWrapper>
        <ButtonWrapper>
          <button
            type="button"
            onClick={() =>
              this.props.onFilterQuiz(
                this.state.selectedQuiz,
                this.state.selectedStatus
              )
            }
          >
            Filtrar
          </button>
        </ButtonWrapper>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuizByCycleActions }, dispatch);

const mapStateToProps = state => ({
  quizByCycle: state.quizByCycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterQuiz);
