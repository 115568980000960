import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import Creators from '../../ducks/select/selectUsers';

export function* requestUsers({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.selectUsersClearError());
  yield put(Creators.selectUsersLoading());

  try {
    let url = `api/v1/select/users`;
    if (!!text) {
      url += '?text=' + text;
    }

    let response = yield call(api.get, encodeURI(url));
    yield put(Creators.selectUsersSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.selectUsersError(error));
      }
    }
    yield put(
      Creators.selectUsersError('Ocorreu um erro no servidor, tente mais tarde.')
    );
  }
}

export function* requestResetUsers(z) {
  yield put(Creators.selectUsersReset());
}
