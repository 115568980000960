import React from "react";

import { Tr, Tbody, Thead, TableDiv } from "./styles";

import AddIcon from "../../assets/images/add.svg";

const Table = props => (
  <TableDiv>
    <Thead>
      <Tr>
        <th>ID</th>
        <th>Título</th>
        <th>Descrição</th>
        <th>Criado em</th>
        <th> </th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <td>ID 231</td>
        <td>Form. Adm</td>
        <td>Descrição do form</td>
        <td>05/02/2018</td>
        <td>
          <img src={AddIcon} alt="add" />
        </td>
      </Tr>
      <tr>
        <td>ID 231</td>
        <td>Form. Adm</td>
        <td>Descrição do form</td>
        <td>05/02/2018</td>
        <td>
          <img src={AddIcon} alt="add" />
        </td>
      </tr>
      <tr>
        <td>ID 231</td>
        <td>Form. Adm</td>
        <td>Descrição do form</td>
        <td>05/02/2018</td>
        <td>
          <img src={AddIcon} alt="add" />
        </td>
      </tr>
      <tr>
        <td>ID 231</td>
        <td>Form. Adm</td>
        <td>Descrição do form</td>
        <td>05/02/2018</td>
        <td>
          <img src={AddIcon} alt="add" />
        </td>
      </tr>
      <tr>
        <td>ID 231</td>
        <td>Form. Adm</td>
        <td>Descrição do form</td>
        <td>05/02/2018</td>
        <td>
          <img src={AddIcon} alt="add" />
        </td>
      </tr>
    </Tbody>
  </TableDiv>
);

export default Table;
