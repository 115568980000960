import React, { Component } from "react";
import { ResultCountAndFilterWrapper } from "./styles";
import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";

export default class ResultCountAndFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownActive: false,
      active: props.defaultActive ? props.defaultActive : "desc",
      items: [
        { value: "desc", name: "Recentes" },
        { value: "asc", name: "Mais Antiga" }
      ]
    };
    this.dropdownActiveRef = React.createRef();
  }

  get itemActive() {
    return this.state.items.find(item => item.value == this.state.active);
  }

  handleChangeDrop = value => {
    this.setState(
      { isDropdownActive: !this.state.isDropdownActive, active: value },
      () => {
        this.props.onChangeOrder(value);
      }
    );
  };

  render() {
    return (
      <ResultCountAndFilterWrapper>
        <span>{this.props.count} cursos</span>
        <div className="dropdown-btn-wrapper">
          <div
            onClick={() =>
              this.setState({ isDropdownActive: !this.state.isDropdownActive })
            }
          >
            <span ref={this.dropdownActiveRef}>{this.itemActive.name}</span>
            <MdArrowDropdown color="#535353" fontSize="24px" />
          </div>

          {this.state.isDropdownActive && (
            <div className="dropdown">
              <ul>
                {this.state.items.map((item, i) => (
                  <li key={i} onClick={() => this.handleChangeDrop(item.value)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </ResultCountAndFilterWrapper>
    );
  }
}
