import styled from "styled-components";

export const ContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100% - 30px);
`;

export const IframeStyled = styled.div`
  flex: 5;
`;

export const ModalConclusaoInner = styled.div`
  h2 {
    font-family: "Futura-Bold", sans-serif;
    color: #535353;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .input-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .btn-wrapper {
    button {
      background-color: #fbce07;
      color: #535353;
      padding: 15px 20px;
      text-transform: uppercase;
      font-family: "Futura-Bold", sans-serif;
      border: 0;
      cursor: pointer;

      :hover {
        opacity: 0.8;
      }

      :first-child {
        margin-right: 15px;
      }
    }
  }
`;
