import styled from "styled-components";

export const Menu = styled.div`
  background: #e7e7e7;
  border-bottom: #ccc solid 1px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  width: 100%;

  li {
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    color: #666666;
    font-family: "Open Sans";
    font-size: 1em;
    padding: 10px;

    a {
      color: #666666;
      text-decoration: none;
      font-size: 16px;
    }

    &.active {
      border-bottom: #00468e solid 8px;
      color: #00468e;
      font-weight: bold;
    }

    &:hover {
      font-weight: 600;
    }
  }
`;
