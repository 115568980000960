import styled from 'styled-components'

export const HeaderMenuStyled = styled.div`
  margin-top: 10px;

  ul {
    list-style: none;
    margin:0;
    display:flex;

    li {
      :not(:last-child) {
        margin-right: 20px;
      }
      a {
        color: #535353;
        font-size: 12px;
      }

      &.active {
        a {
          font-family: "OS Bold", sans-serif;
        }
      }
    }
  }
`
