import React, { Component } from 'react';
import Collapsible from '../../../Components/Collapsible';
import {
  FormInputLabelled,
  FormTextArea,
} from '../../../Components/FormInputs';
import QuestionGroup from '../QuestionGroup';

import IconEscala from '../../../assets/images/questoes/ico-escala.svg';
import IconText from '../../../assets/images/questoes/ico-textsize.svg';
import IconSimNao from '../../../assets/images/questoes/ico-simnao.svg';
import { SquaresWrapper } from './styles';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../../store/ducks/questionCreation/questionCreation';

class QuestionQuestion extends Component {
  changeCollOpen = op => {
    const { uid } = this.props;
    const el = document.getElementById(`question-wrapper-${uid}`);
    if (op) el.classList.add('active');
    else el.classList.remove('active');
  };

  render() {
    const {
      type,
      uidStage,
      uidBlock,
      uid,
      title,
      description,
      questionChangeQuestionEnun,
      questionChangeQuestionDesc,
      questionRemoveQuestion,
      dragHandle,
      editable,
    } = this.props;

    const renderTitle = type =>
      ({
        numeric_interval: 'Escala de opinião',
        text: 'Texto livre',
        single_choice: 'Sim/Não',
      }[type] || 'Titulo Tipo Error');

    const renderIcon = type =>
      ({
        numeric_interval: IconEscala,
        text: IconText,
        single_choice: IconSimNao,
      }[type] || '~');

    const renderContent = type =>
      ({
        numeric_interval: renderEscalaContent(),
        single_choice: renderSimNaoContent(),
      }[type] || '');

    function renderEscalaContent() {
      return (
        <SquaresWrapper isNumbers>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
        </SquaresWrapper>
      );
    }

    function renderSimNaoContent() {
      return (
        <SquaresWrapper>
          <div>Sim</div>
          <div>Não</div>
        </SquaresWrapper>
      );
    }

    return (
      <Collapsible
        title={renderTitle(type)}
        icon={renderIcon(type)}
        onRemove={() => questionRemoveQuestion(uidStage, uidBlock, uid)}
        dragHandle={dragHandle}
        verifyCollOpen={this.changeCollOpen}
        editable={editable}
      >
        <FormTextArea
          label="Enunciado"
          style={{ marginBottom: 10 }}
          onChange={e =>
            questionChangeQuestionEnun(e.target.value, uidStage, uidBlock, uid)
          }
          labelSecondary
          sizeSecondary
          defaultValue={title}
          disabled={!editable}
        />

        <FormTextArea
          label="Descrição da Questão"
          onChange={e =>
            questionChangeQuestionDesc(e.target.value, uidStage, uidBlock, uid)
          }
          labelSecondary
          sizeSecondary
          defaultValue={description}
          disabled={!editable}
        />

        <div style={{ marginTop: 15 }}>{renderContent(type)}</div>
      </Collapsible>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions }, dispatch);

export default connect(null, mapDispatchToProps)(QuestionQuestion);
