import React, { Component, Fragment } from "react";
import { Wrapper } from "./styles";

import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdClose from "react-ionicons/lib/MdClose";

export default class SingleChoiceRevision extends Component {
  state = {
    choiceSelected: null,
    reviewChoiceSelected: null
  };

  static getDerivedStateFromProps(props, state) {
    if (state.choiceSelected === null && props.defaultChoice) {
      state.choiceSelected = props.defaultChoice;
    }
    if (props.defaultChoiceReviewer) {
      state.reviewChoiceSelected = props.defaultChoiceReviewer;
    }
    return state;
  }

  handleAcceptNota(e, accepted) {
    const choice = accepted ? this.state.reviewChoiceSelected : this.props.defaultChoice;
    this.setState({
      choiceSelected: choice,
      isAwnsered: true
    });
    this.props.onChange(choice);
    e.currentTarget.classList.add("chosen");
  }

  renderSingleChoiceRevision = (choice, i) => {
    const { isAwnsered } = this.state;
    return (
      <div key={i}>
        <Wrapper>
          <div className="header-wrapper">
            <div className="header">{choice.title}</div>
          </div>
          <div className={`btns ${!isAwnsered ? `nao-respondida` : "respondida"}`}>
            <div onClick={!isAwnsered ? e => this.handleAcceptNota(e, true) : null}>
              <MdCheckmark fontSize="32px" color="#009B4C" />
            </div>
            <div onClick={!isAwnsered ? e => this.handleAcceptNota(e, false) : null}>
              <MdClose fontSize="32px" color="#009B4C" />
            </div>
          </div>
        </Wrapper>
      </div>
    );
  }

  renderItem = (choice, i) => {
    if (this.state.reviewChoiceSelected.id == choice.id
      && this.state.choiceSelected.id != choice.id) {
      return this.renderSingleChoiceRevision(choice, i);
    }
    let className = '';
    if (this.state.choiceSelected.id == choice.id) {
      className = 'active';
    } else if (this.state.reviewChoiceSelected.id == choice.id) {
      className = 'active-reviwer';
    }
    return (
      <div key={i} className={className}>
        {choice.title}
      </div>
    );
  };

  render() {
    const { question } = this.props;
    return (
      <Fragment>
        {question.questionsSingleChoice.map(this.renderItem)}
      </Fragment>
    );
  }
}
