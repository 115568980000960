import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

import { Box } from "../../styles/global";
import { ButtonsWrapper, LogoContainer, TextCenter } from "./styles";
import Brand from "../../assets/images/logo-emae.png";

class Detalhes extends React.Component {
  state = {
    redirect: null
  };
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Fragment>
        <Box>
          <LogoContainer>
            <img src={Brand} alt="logo EMAE" />
          </LogoContainer>
          <TextCenter>
            <h4>Escolha uma ação</h4>
          </TextCenter>
          <ButtonsWrapper>
            <button
              type="button"
              className="primary"
              onClick={() => {
                this.setState({ redirect: "/user/home" });
              }}
            >
              Responder Questionário
            </button>
            <button
              type="button"
              className="primary"
              onClick={() => {
                this.setState({ redirect: "/gestor" });
              }}
            >
              Avaliar Equipe
            </button>
          </ButtonsWrapper>
        </Box>
      </Fragment>
    );
  }
}

export default Detalhes;
