import styled from 'styled-components';

export const SubHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #e7e7e7;
  height: 50px;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    height: 50px;
    align-items: center;

    .disable {
      a {
        color: #bbbbbb;
      }
      a:hover {
        text-decoration: none;
        border-bottom: none;
        cursor: default;
      }
    }

    li {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 30px;

      a {
        font-size: 16px;
        color: #666666;
        text-transform: uppercase;
      }

      &.active {
        border-bottom: 6px solid #00468e;
        padding-top: 6px;
        a {
          color: #00468e;
        }
      }
    }
  }
`;
