import styled from "styled-components";

export const Back = styled.div`
  display: flex;
  height: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: flex-start;

  .content {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;

    img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      color: #00468e;
      text-transform: uppercase;
    }
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7;
  border-bottom: #ccc 2px solid;
  margin: 0 25px;
  min-height: 70px;

  span {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;

    margin-right: 70px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin: 10px;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
  min-height: 500px;
  padding: 20px 25px;
  align-items: center;
  justify-items: center;

  span {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
  }
`;
