import React from 'react';

import { Switch, BrowserRouter } from 'react-router-dom';

import OutsideRoute from './OutsideRoute';
import AdminRoute from './AdminRoute';
import UserRoute from './UserRoutes';
import UserBasicRoute from './UserBasicRoute';
import ManagerRoute from './ManagerRoute';

import Login from '../pages/login';
import Home from '../pages/home';
import CycleList from '../pages/Cycle/List';
import CycleData from '../pages/Cycle/CycleData';
import CycleQuizzes from '../pages/Cycle/CycleQuizzes';
import CycleStatus from '../pages/Cycle/CycleStatus';

import DetalhesParticipantes from '../pages/Ciclos/Novo/DetalhesParticipantes';
import Participantes from '../pages/Ciclos/Novo/Participantes';
import Selecionados from '../pages/Ciclos/Novo/Selecionado';
import Fluxo from '../pages/Ciclos/Novo/Fluxo';
import Detalhes from '../pages/Ciclos/destinatario/detalhes';
import DadosGeraisUsuarios from '../pages/dadosGerais/Usuario';
import DetailUsuario from '../pages/dadosGerais/Usuario/Detail';
import UsuarioCiclos from '../pages/dadosGerais/Usuario/Ciclos';
import HierarchiesUser from '../pages/dadosGerais/Usuario/Hierarchies';
import DadosGeraisCargos from '../pages/dadosGerais/Cargos';
import DetailCargos from '../pages/dadosGerais/Cargos/Detail';
import DadosGeraisPerfis from '../pages/dadosGerais/Perfis';
import DadosGeraisJustificativas from '../pages/dadosGerais/Justificativas';
import DetailJustificativas from '../pages/dadosGerais/Justificativas/Detail';

import TabelaModelos from '../pages/modelos';
import DadosModelos from '../pages/modelos/dados';
import QuestoesModelos from '../pages/modelos/questoes';
import DestinatariosModelos from '../pages/modelos/destinatarios';
import TabelaEmail from '../pages/emails/tabela';
import NovoEmail from '../pages/emails/novo';
import PerfilLogin from '../pages/perfis';
import NovoFluxo from '../pages/Ciclos/fluxo/novo';
import AtividadeFluxo from '../pages/Ciclos/fluxo/atividade';
import Emails from '../pages/Ciclos/emails';
import DefinirSenha from '../pages/DefinirSenha';
import RecuperarSenha from '../pages/RecuperarSenha';

import HomeUser from '../pages/User/Home';
import UserStage from '../pages/User/Stage';
import UserComents from '../pages/User/Coments';
import UserSendForm from '../pages/User/SendForm';
import UserSendRevision from '../pages/User/SendToRevision';
import UserResults from '../pages/User/Resultado';

import Logout from '../pages/Logout';
import Choice from '../pages/Choice';

//MANAGER PAGES
import ManagerHome from '../pages/Manager/Home';
import ManagerNotification from '../pages/Manager/Notification';
import ManagerUserDetail from '../pages/Manager/UserDetail';

//Module Courses
import UserCourses from '../pages/Modules/Courses/User';
import UserCoursesCategory from '../pages/Modules/Courses/User/Category';
import UserCoursesCategoryInProgress from '../pages/Modules/Courses/User/Category/InProgress';
import UserCoursesCategoryFinished from '../pages/Modules/Courses/User/Category/Finished';
import UserCoursesCourse from '../pages/Modules/Courses/User/Course';
import UserContent from '../pages/Modules/Conteudo';
import UsuarioCiclo from '../pages/dadosGerais/UsuarioCiclo';

import Relatorios from '../pages/relatorios';
import NotFound from '../pages/NotFound';

const Routes = ({ profile, hasSubordinates }) => (
  <BrowserRouter>
    <Switch>
      <OutsideRoute exact path="/" component={Login} />
      <OutsideRoute exact path="/logout" component={Logout} />
      <OutsideRoute exact path="/definir-senha" component={DefinirSenha} />
      <OutsideRoute exact path="/recuperar-senha" component={RecuperarSenha} />
      <OutsideRoute
        profile={profile}
        exact
        path="/perfis"
        component={PerfilLogin}
      />

      <UserBasicRoute
        profile={profile}
        exact
        path="/choice"
        component={Choice}
      />

      <ManagerRoute
        profile={profile}
        title="Avaliar"
        exact
        path="/gestor"
        hasSubordinates={hasSubordinates}
        component={ManagerHome}
      />

      <AdminRoute profile={profile} exact path="/home" component={Home} />

      <AdminRoute
        profile={profile}
        exact
        path="/ciclos"
        component={CycleList}
      />
      <AdminRoute
        profile={profile}
        path="/ciclos/:id/dados"
        component={CycleData}
      />
      <AdminRoute
        profile={profile}
        path="/ciclos/:id/formularios"
        component={CycleQuizzes}
      />
      <AdminRoute
        profile={profile}
        path="/ciclos/:id/status"
        component={CycleStatus}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/:id/participantes"
        component={Participantes}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/:id/participantes/detalhes/:cycleUserId"
        component={DetalhesParticipantes}
      />

      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/novo/formulario/selecionados"
        component={Selecionados}
      />

      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/novo/fluxo"
        component={Fluxo}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/usuario/detalhes"
        component={Detalhes}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/usuarios"
        component={DadosGeraisUsuarios}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/usuarios/:id"
        component={DetailUsuario}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/usuarios/:id/ciclos"
        component={UsuarioCiclos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/usuarios/:id/hierarquias"
        component={HierarchiesUser}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/usuarios/:userId/ciclo-usuario/:cycleUserId"
        component={UsuarioCiclo}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/cargos"
        component={DadosGeraisCargos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/cargos/:id"
        component={DetailCargos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/perfis"
        component={DadosGeraisPerfis}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/justificativas"
        component={DadosGeraisJustificativas}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/dadosgerais/justificativas/:id"
        component={DetailJustificativas}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/modelos/"
        component={TabelaModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/modelos/:id/dados"
        component={DadosModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/modelos/:id/questoes"
        component={QuestoesModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/modelos/:id/destinatarios"
        component={DestinatariosModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/formularios/:id/dados"
        component={DadosModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/formularios/:id/questoes"
        component={QuestoesModelos}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/formularios/:id/destinatarios"
        component={DestinatariosModelos}
      />

      <AdminRoute
        profile={profile}
        exact
        path="/emails/tabela"
        component={TabelaEmail}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/emails/novo"
        component={NovoEmail}
      />

      <AdminRoute
        profile={profile}
        exact
        path="/relatorios"
        component={Relatorios}
      />

      <ManagerRoute
        profile={profile}
        exact
        path="/gestor/ciclo-usuario/:id"
        hasSubordinates={hasSubordinates}
        component={ManagerUserDetail}
      />

      <ManagerRoute
        profile={profile}
        exact
        path="/gestor/notificacao"
        hasSubordinates={hasSubordinates}
        component={ManagerNotification}
      />

      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/fluxo/novo"
        component={NovoFluxo}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/fluxo/atividade"
        component={AtividadeFluxo}
      />
      <AdminRoute
        profile={profile}
        exact
        path="/ciclos/emails"
        component={Emails}
      />

      <UserRoute
        profile={profile}
        exact
        path="/user/home"
        component={HomeUser}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        path="/user/stage"
        component={UserStage}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/user/comments"
        component={UserComents}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/user/send-revision"
        component={UserSendRevision}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/user/send"
        component={UserSendForm}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/user/resultados"
        component={UserResults}
        hasSubordinates={hasSubordinates}
      />

      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos"
        component={UserCourses}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos/categoria/:id"
        component={UserCoursesCategory}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos/categoria/:id/em-andamento"
        component={UserCoursesCategoryInProgress}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos/categoria/:id/concluidos"
        component={UserCoursesCategoryFinished}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos/oferta/:id"
        component={UserCoursesCourse}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/cursos/oferta/:id/matricula/:idMatricula"
        component={UserCoursesCourse}
        hasSubordinates={hasSubordinates}
      />
      <UserRoute
        profile={profile}
        exact
        path="/usuario/curso/conteudo/:id/oferta/:idOferta/matricula/:idMatricula/topico/:idTopico/rating/:idRating/rever/:reverCurso"
        component={UserContent}
        hasSubordinates={hasSubordinates}
      />

      {/* Tela de NotFound, deixar sempre como última rota */}
      <OutsideRoute component={NotFound} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
