import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import shave from '../../../services/shave';

import { Creators as RolesActions } from '../../../store/ducks/role/roles';

import { ContentWrapper } from '../styles';
import {
  TableDefault,
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../Components/Table/styles';
import ItensAndButtons from '../../../Components/ItensAndButtons';
import Pagination from '../../../Components/Pagination';

import SideMenu from '../SideMenu';
import NotFoundItems from '../../../Components/NotFoundItems';

export class DadosGeraisCargos extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    this.props.clearRoles();
    this.props.getRolesRequest();
  }

  render() {
    const { roles, history } = this.props;
    return (
      <ContentWrapper>
        <SideMenu page={2} />

        <div className="content">
          <ItensAndButtons
            history={history}
            newUrl="/dadosgerais/cargos/novo"
            total={roles.total}
            onSubmit={text => {
              this.setState({ text });
              this.props.clearRoles();
              this.props.getRolesRequest({ page: 1, text });
            }}
            isLoading={roles.loading}
          />

          {roles && roles.data.length > 0 ? (
            <TableDiv>
              <TableDefault>
                <Thead>
                  <Tr>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th>Status</th>
                  </Tr>
                </Thead>
                <Tbody>
                  {roles.data.map(r => (
                    <Tr key={r.id}>
                      <td>{r.id}</td>
                      <td>
                        <Link to={`/dadosgerais/cargos/${r.id}`}>{r.name}</Link>
                      </td>
                      <td>{shave(r.description, 50)}</td>
                      <td>{r.status}</td>
                    </Tr>
                  ))}
                </Tbody>
              </TableDefault>
              {roles.lastPage !== roles.page && (
                <Pagination
                  onClick={page =>
                    this.props.getRolesRequest({ page, text: this.state.text })
                  }
                  paginate={this.props.roles}
                />
              )}
            </TableDiv>
          ) : roles.loading ? (
            ''
          ) : (
            <NotFoundItems />
          )}
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.roles,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RolesActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DadosGeraisCargos);
