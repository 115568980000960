import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Modal from "react-responsive-modal";

import { Creators as RatingsActions } from "../../../../../store/ducks/modules/courses/rating";
import { Creators as RatingAnswerActions } from "../../../../../store/ducks/modules/courses/ratingAnswer";
import { Creators as EnrollActions } from "../../../../../store/ducks/modules/courses/enroll";

import { FormInput } from "../../../../FormInputs";
import RatingModal from "../RatingModal";

import Loading from "../../../../../Components/Loading";

import { ModalConclusaoInner } from "./styles";
import { FormItemError } from "../../../../FormFieldError";
import FormError from "../../../../Form/Error";

/**
 * @example
  <CompletedEnrollmentModal
      visible={this.state.modalVisible}
      enrollmentId={1}
      ratingId={1}
      course={offer.data.course}
      onSuccess={() => this.setState({modalVisible: false})}
      onClose={() => this.setState({modalVisible: false})}
      onError={() => {}}
    />
 */
class CompletedEnrollmentModal extends Component {
  static propTypes = {
    course: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    enrollmentId: PropTypes.number.isRequired,
    ratingId: PropTypes.number.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  state = { comment: "", star: 0, loading: false };

  static getDerivedStateFromProps(props, state) {
    state.visible = props.visible;
    state.enrollmentId = props.enrollmentId;
    state.ratingId = props.ratingId;
    state.course = props.course;
    state.loading = props.ratingAnswer.loading;
    return state;
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.props.resetRatingsAnswerRequest();
    this.props.resetRatingsRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ratingAnswer.success && !prevProps.ratingAnswer.success) {
      toast.success(this.props.ratingAnswer.success);
      this.completeCourse();
    }
    if (
      this.props.enroll.successConclude &&
      !prevProps.enroll.successConclude
    ) {
      toast.success(this.props.enroll.successConclude);
      this.props.onSuccess();
    }
    if (this.props.enroll.errorConclude && !prevProps.enroll.errorConclude) {
      toast.error(this.props.enroll.errorConclude);
      this.props.resetEnrollConclude();
      this.props.onError();
    }
    if (this.props.ratingAnswer.error && !prevProps.ratingAnswer.error) {
      this.props.onError();
    }
  }

  completeCourse = () => {
    const { postEnrollConclude } = this.props;
    postEnrollConclude(this.state.enrollmentId);
  };

  saveRatingAnswer() {
    const { postRatingAnswerRequest } = this.props;
    postRatingAnswerRequest(
      this.state.ratingId,
      this.state.star,
      this.state.comment
    );
  }

  renderError = () => {
    let error = this.props.ratingAnswer.error;
    if (!error) {
      return null;
    }
    return <FormError error={error} />;
  };

  renderFormError = fieldName => {
    let reducer = this.props.ratingAnswer;
    return <FormItemError reducer={reducer} fieldName={fieldName} />;
  };

  renderButtons = () => {
    if (this.state.loading) {
      return <Loading loading={true} />;
    }
    return (
      <div className="btn-wrapper">
        <button
          type="button"
          onClick={() => {
            this.completeCourse();
          }}
        >
          Pular
        </button>
        <button
          onClick={() => {
            this.saveRatingAnswer();
          }}
          type="button"
        >
          Enviar
        </button>
      </div>
    );
  };

  render() {
    const { course } = this.state;
    return (
      <Fragment>
        <Modal
          open={this.state.visible}
          showCloseIcon={false}
          onClose={this.props.onClose}
          styles={{
            overlay: { backgroundColor: "rgba(255,255,255,0.7)" },
            modal: { boxShadow: "none", maxWidth: "375px", padding: "40px" }
          }}
        >
          <ModalConclusaoInner>
            <h2>
              Parabéns! Você concluiu o curso {course.title}. Como avalia o
              curso?
            </h2>
            {this.renderError()}
            {this.renderFormError("course_rating_id")}
            <RatingModal onChange={value => this.setState({ star: value })} />
            {this.renderFormError("record")}
            <div className="input-wrapper">
              <FormInput
                label="Gostaria de deixar um comentário?"
                type="text"
                id="comentario-curso"
                name="inputComentarioCurso"
                onChange={e => this.setState({ comment: e.target.value })}
              />
              {this.renderFormError("comment")}
            </div>
            {this.renderButtons()}
          </ModalConclusaoInner>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ratingAnswer: state.ratingAnswer,
  enroll: state.enroll
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RatingsActions,
      ...RatingAnswerActions,
      ...EnrollActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompletedEnrollmentModal);
