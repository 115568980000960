import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  gradeDownloadRequest: ["payload"],
  gradeDownloadSuccess: [],
  gradeDownloadError: ["error"],
  gradeDownloadRequestResetAll: [],
  gradeDownloadResetAll: [],
  gradeDownloadLoading: null
});

export const GradeDownloadTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  error: false,
  success: false,
  loading: false
});

export const success = state => {
  return state.merge({
    success: true,
    error: false,
    loading: false
  });
};

export const resetAll = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GRADE_DOWNLOAD_SUCCESS]: success,
  [Types.GRADE_DOWNLOAD_ERROR]: error,
  [Types.GRADE_DOWNLOAD_LOADING]: loading,
  [Types.GRADE_DOWNLOAD_RESET_ALL]: resetAll
});
