import React, { Component } from "react";
import { NumericIntervalWrapper } from "./styles";
import Numbers from "./Numbers";
import NumbersRevisao from "./NumbersRevisao";

export default class NumericInterval extends Component {
  render() {
    const { question, number, isReviewer, cycleUserStatus } = this.props;

    return (
      <NumericIntervalWrapper>
        <div className="header">
          <div>{number})</div>
          <div>
            <p>
              <b>{question.title}:</b>
              {question.description}
            </p>
          </div>
        </div>
        <div className="header">
          <span>Demonstra</span>
        </div>

        {!isReviewer && cycleUserStatus == "Em Revisão Colaborador" ? (
          <NumbersRevisao
            defaultNumUser={this.props.notaUser}
            defaultNumReviewer={this.props.notaReviewer}
            disabled={this.props.disabled}
            options={this.props.options}
            onChange={this.props.onChange}
          />
        ) : (
          <Numbers
            disabled={this.props.disabled}
            isReviewer={this.props.isReviewer}
            defaultNumUser={this.props.notaUser}
            defaultNumReviewer={this.props.notaReviewer}
            options={this.props.options}
            onChange={this.props.onChange}
          />
        )}

        <div className="footer">
          <span className="borderLeft">pouco</span>
          <span className="borderLeft">parcialmente</span>
          <span className="borderLeft borderRight">plenamente</span>
          <span className="small borderRight">com excelência</span>
        </div>
      </NumericIntervalWrapper>
    );
  }
}
