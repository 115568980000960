import styled from 'styled-components';

export const Back = styled.div`
  display: flex;
  height: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: flex-start;

  .content {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;

    img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      color: #00468e;
      text-transform: uppercase;
    }
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7;
  border-bottom: #ccc 2px solid;
  margin: 20px 25px;
  min-height: 70px;

  span {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;

    margin-right: 70px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin: 10px 0;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
  min-height: 180px;
  padding-top: 20px;
  align-items: center;
  justify-items: center;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 30px 10px;

  span {
    color: #4d4d4d;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const TabWrapper = styled.div`
  .tablist {
    display: flex;
    list-style: none;
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
    width: 100%;

    li {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 50px;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      color: #666666;

      &.react-tabs__tab--selected {
        color: #00468e;
        border-bottom: 6px solid #00468e;
        padding-top: 6px;

        @media screen and (max-width: 495px) {
          border-bottom: none;
        }
      }
    }
  }
`;

export const DataTableWrapper = styled.div`
  overflow-x: auto;
  width: ${props => props.currentWidth}px;
`;

