import styled from 'styled-components';

export const ButtonDiv = styled.div`
  justify-content: center;
  margin-top: 1px;

  button {
    margin: 0;
    border: 0;
    padding: 0;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: #fff;

      background-color: #00468e;
      height: 25px;
      width: 75px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
    :first-child {
      margin-right: 0px;
      background-color: #00468e;
      height: 25px;
      width: 75px;
      color: #fff;
      font-size: 14px;
      line-height: 10px;
      border-radius: 4px;
      font-family: 'Open Sans';
    }
    :first-child:hover {
      background-color: #236db9;
    }
  }
`;

export const ButtonDisabled = styled.div`
  justify-content: center;
  margin-top: 1px;

  button {
    margin: 0;
    border: 0;
    padding: 0;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: #fff;

      background-color: #00468e;
      height: 25px;
      width: 75px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
    :first-child {
      margin-right: 0px;
      background-color: #b3b3b3;
      height: 25px;
      width: 75px;
      color: #fff;
      font-size: 14px;
      line-height: 10px;
      border-radius: 4px;
      font-family: 'Open Sans';
    }
    :first-child:hover {
      cursor: default;
    }
  }
`;
