import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ItensAndButtons from '../../../Components/ItensAndButtons';

import { Creators as CyclesActions } from '../../../store/ducks/cycle/cycles';
import ErrorMsg from '../../../Components/ErrorMsg';

import { Div } from '../../../styles/global';

import Table from './components/table';
import NotFoundItems from '../../../Components/NotFoundItems';
import Pagination from '../../../Components/Pagination';

class CycleList extends Component {
  static propTypes = {
    cyclesRequest: PropTypes.func.isRequired,
  };

  state = {
    text: '',
    page: 1,
    append: false,
    fetch: false,
  };

  isLoading = () => {
    return this.props.cycles.loading;
  };

  renderError = () => {
    if (!this.props.cycles.error) {
      return null;
    }
    return <ErrorMsg msg={this.props.cycles.error} />;
  };

  renderCycles = () => {
    const { cycles, history } = this.props;
    return (
      <Div>
        <ItensAndButtons
          history={history}
          newUrl="/ciclos/novo/dados"
          total={cycles.total}
          onSubmit={text => {
            this.setState({ page: 1, append: false, text, fetch: true });
          }}
          isLoading={cycles.loading}
        />

        {cycles && cycles.success && cycles.data.length > 0 ? (
          <>
            <Table cycles={cycles} />
            {cycles.lastPage !== cycles.page && (
              <Pagination
                onClick={page => {
                  this.setState({ page, fetch: true });
                }}
                paginate={cycles}
              />
            )}
          </>
        ) : cycles.loading ? (
          ''
        ) : (
          <NotFoundItems />
        )}
      </Div>
    );
  };

  componentDidMount() {
    this.setState({ fetch: true });
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false });
      this.props.cyclesRequest({ ...this.state });
    }
  }

  render() {
    return (
      <Div>
        {this.renderError()}
        {this.renderCycles()}
      </Div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CyclesActions }, dispatch);

const mapStateToProps = state => ({ cycles: state.cycles });

export default connect(mapStateToProps, mapDispatchToProps)(CycleList);
