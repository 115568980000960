import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as SearchActions } from "../../../../store/ducks/modules/courses/search";
import { Creators as CategoriesActions } from "../../../../store/ducks/modules/courses/categories";

import { CategoriesList } from "./styles";

import { Header } from "../../../User/Components/Headers";
import SearchBox from "../../../User/Components/SearchBox";
import CategorieItem from "../../../User/Components/CategorieItem";
import CourseItem from "../../../User/Components/CourseItem";
import NotFoundItems from "../../../../Components/NotFoundItems";
import Loading from "../../../../Components/Loading";

class Courses extends Component {
  state = {
    categories: [],
    searchResult: [],
    fetch: false,
    text: null
  };

  static getDerivedStateFromProps(props, state) {
    state.searchResult = props.search.data;

    return state;
  }

  componentDidMount() {
    this.props.getCategoriesRequest();
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false }, () => {
        this.props.getSearchRequest({ text: this.state.text });
      });
    }
  }

  renderSearchResults = () => {
    if (this.props.search.loading) {
      return <Loading loading={true} />;
    }
    if (this.props.search.data.length === 0) {
      return <NotFoundItems />;
    }
    return (
      <CategoriesList>
        {this.props.search.data.map((item, i) =>
          item.type === "course" ? (
            <CourseItem key={`course-${item.id}`} item={item} />
          ) : (
            <CategorieItem key={`categoria-${item.id}`} item={item} />
          )
        )}
      </CategoriesList>
    );
  };

  renderCategories = () => {
    if (this.props.categories.loading) {
      return <Loading loading={true} />;
    }
    if (this.props.categories.length === 0) {
      return <NotFoundItems />;
    }
    return (
      <CategoriesList>
        {this.props.categories.data.map((item, i) => (
          <CategorieItem key={`categoria-${item.id}`} item={item} />
        ))}
      </CategoriesList>
    );
  };

  renderItems = () => {
    if (!this.state.text) {
      return this.renderCategories();
    }
    return this.renderSearchResults();
  };

  render() {
    return (
      <Fragment>
        <Header title="Cursos" />
        <SearchBox onChange={text => this.setState({ text, fetch: true })} />
        {this.renderItems()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.categories,
  search: state.search
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CategoriesActions, ...SearchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Courses);
