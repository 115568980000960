import api from "../../../../services/api";
import { call, put } from "redux-saga/effects";

import { Creators as SearcActions } from "../../../ducks/modules/courses/search";
import { Creators as ErrorActions } from "../../../ducks/error";

export function* getSearch(action) {
  try {
    let { text, categoryId } = action.payload;
    let params = "?text=" + (!text ? "" : text);

    if (categoryId) {
      params += "&category_id=" + categoryId;
    }
    const response = yield call(api.get, "/api/v1/courses-categories" + params);

    yield put(SearcActions.getSearchSuccess(response.data.data));
  } catch (err) {
    if (err.response.status === 401) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro ao realizar a busca"));
    }
  }
}
