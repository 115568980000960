import styled from 'styled-components'

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 30px;
  border-top: 1px solid #CCCCCC;

  span {
    color: #CCCCCC;
    font-size: 12px;

    :nth-child(2) {
      a {
        font-weight: bold;
        color: #fff;
        img {
          margin-right: 10px;
        }
      }
    }
  }
`
