import React, { Component } from 'react'

export class IconNotifications extends Component {
  render() {
    return (
      <svg width="22px" height="24px" viewBox="0 0 22 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>3CD1E990-2B58-4A55-A748-E7CFF8DA3582-577-0000C165E2324442</title>
        <defs>
          <path d="M22.0742109,10.453083 C22.0852324,10.3642084 22.0909091,10.2736762 22.0909091,10.1818182 C22.0909091,8.97683327 21.1140758,8 19.9090909,8 C18.704106,8 17.7272727,8.97683327 17.7272727,10.1818182 C17.7272727,10.2737547 17.7329591,10.3643632 17.7439993,10.453311 C13.9767739,11.4174401 11.1818182,14.8376843 11.1818182,18.9090909 L11.1818182,22.3669715 C9.91226208,22.8153895 9,24.0284455 9,25.4545455 C9,27.258345 10.4624181,28.7272727 12.2664023,28.7272727 L16.6364574,28.7272727 C16.6499113,30.5375142 18.1099649,32 19.9090909,32 C21.7026523,32 23.1651324,30.5375984 23.1816765,28.7272727 L27.5517795,28.7272727 C29.3527505,28.7272727 30.8181818,27.2620228 30.8181818,25.4545455 C30.8181818,24.030904 29.9072281,22.8158572 28.6363636,22.3669393 L28.6363636,18.9090909 C28.6363636,14.844808 25.8468128,11.4180559 22.0742109,10.453083 Z M18.818464,28.7272727 L20.9995731,28.7272727 C20.9832157,29.3213129 20.4988101,29.799784 19.9090909,29.799784 C19.3144371,29.799784 18.8317786,29.3217863 18.818464,28.7272727 Z M12.2736782,24.3636364 C12.8696825,24.3636364 13.3636364,23.8753137 13.3636364,23.2729373 L13.3731579,18.7901057 C13.3731579,15.1751509 16.3110134,12.2446511 19.9186124,12.2446511 C23.5335671,12.2446511 26.4640669,15.1825066 26.4640669,18.7901057 L26.4545455,23.2729373 C26.4545455,23.8795819 26.9425364,24.3636364 27.5445036,24.3636364 C28.1475213,24.3636364 28.6363636,24.8478525 28.6363636,25.4545455 C28.6363636,26.0570379 28.1513117,26.5454545 27.5445036,26.5454545 L12.2736782,26.5454545 C11.6706605,26.5454545 11.1818182,26.0612384 11.1818182,25.4545455 C11.1818182,24.8520531 11.66687,24.3636364 12.2736782,24.3636364 Z" id="path-notification"></path>
        </defs>
        <g id="EMAE---GESTOR" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="gestor-minha-avaliação" transform="translate(-17.000000, -180.000000)">
            <g id="Menu-lateral/minha-avaliacao">
              <g id="Group-3" transform="translate(0.000000, 172.000000)">
                <g id="link-menu-lateral/link">
                  <g id="ico-cursos-copy" transform="translate(8.000000, 0.000000)">
                    <mask id="mask-notification" fill="white">
                      <use xlinkHref="#path-notification"></use>
                    </mask>
                    <use id="ico-notificacao" fill="#000000" fillRule="nonzero" xlinkHref="#path-notification"></use>
                    <g id="🎨-color" mask="url(#mask-notification)" fill="#000000">
                      <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export class IconUsers extends Component {
  render() {
    return (
      <svg width="24px" height="17px" viewBox="0 0 24 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>5A2FC216-2EF4-4015-97E8-69122D0F82A2-577-0000C165E23445BB</title>
        <defs>
          <path d="M16.3076923,19.6614154 C13.9131415,19.6614154 11.9719745,17.7224915 11.9719745,15.3307077 C11.9719745,12.9389239 13.9131415,11 16.3076923,11 C18.7022432,11 20.6434102,12.9389239 20.6434102,15.3307077 C20.6434102,17.7224915 18.7022432,19.6614154 16.3076923,19.6614154 Z M16.3076923,21.3600615 C23.2768533,21.3600615 24.6153846,24.7922462 24.6153846,25.8923692 C24.6153846,26.9923077 23.7071351,27.6153846 22.8593246,27.6153846 L16.3076923,27.6153846 L9.75605999,27.6153846 C8.90824953,27.6153846 8,26.9923077 8,25.8923692 C8,24.7922462 9.33853136,21.3600615 16.3076923,21.3600615 Z M25.5384615,20.5058872 C23.6760331,20.5058872 22.1662365,18.9978353 22.1662365,17.137559 C22.1662365,15.2772827 23.6760331,13.7692308 25.5384615,13.7692308 C27.40089,13.7692308 28.9106865,15.2772827 28.9106865,17.137559 C28.9106865,18.9978353 27.40089,20.5058872 25.5384615,20.5058872 Z M25.5384615,21.8270564 C30.7016301,21.8270564 32,24.4965333 32,25.3521846 C32,26.2076923 31.2935837,26.6923077 30.6341756,26.6923077 C29.1741259,26.6923077 27.8088879,26.6923077 26.5384615,26.6923077 C24.6474609,26.6923077 26.6386547,26.6834033 26.0769231,24.8461538 C25.1538462,21.8270564 21.8082275,21.8270564 25.5384615,21.8270564 Z" id="path-users"></path>
        </defs>
        <g id="EMAE---GESTOR" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="gestor-minha-avaliação" transform="translate(-16.000000, -239.000000)">
            <g id="Menu-lateral/minha-avaliacao">
              <g id="Group-3" transform="translate(0.000000, 172.000000)">
                <g id="link-menu-lateral/link-copy-2" transform="translate(0.000000, 56.000000)">
                  <g id="ico-cursos-copy" transform="translate(8.000000, 0.000000)">
                    <mask id="mask-users" fill="white">
                      <use xlinkHref="#path-users"></use>
                    </mask>
                    <use id="ico-equipe" fill="#000000" fillRule="nonzero" xlinkHref="#path-users"></use>
                    <g id="🎨-color" mask="url(#mask-users)" fill="#333333">
                      <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export class IconQuestionary extends Component {
  render() {
    return (
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>C8082BCE-568F-455B-937A-FB1D428F4424-27824-00011DF40D496556</title>
        <defs>
          <path d="M16.1902908,26.5067655 L15.4041631,27.2928932 C15.0136388,27.6834175 14.3804738,27.6834175 13.9899495,27.2928932 L13.7071068,27.0100505 C13.3165825,26.6195262 13.3165825,25.9863612 13.7071068,25.5958369 L14.0514719,25.2514719 L11.7071068,22.9071068 C11.3165825,22.5165825 11.3165825,21.8834175 11.7071068,21.4928932 L11.9899495,21.2100505 C12.3804738,20.8195262 13.0136388,20.8195262 13.4041631,21.2100505 L16.7899495,24.5958369 C17.1804738,24.9863612 17.1804738,25.6195262 16.7899495,26.0100505 L16.5071068,26.2928932 C16.4132672,26.3867328 16.3054175,26.4580236 16.1902908,26.5067655 Z M9,8 L31,8 C31.5522847,8 32,8.44771525 32,9 L32,31 C32,31.5522847 31.5522847,32 31,32 L9,32 C8.44771525,32 8,31.5522847 8,31 L8,9 C8,8.44771525 8.44771525,8 9,8 Z M10,10 L10,30 L30,30 L30,10 L10,10 Z M25,12.8 C25.5522847,12.8 26,13.2477153 26,13.8 L26,18.8 C26,19.3522847 25.5522847,19.8 25,19.8 C24.4477153,19.8 24,19.3522847 24,18.8 L24,13.8 C24,13.2477153 24.4477153,12.8 25,12.8 Z M16,12.8 C16.5522847,12.8 17,13.2477153 17,13.8 L17,18.8 C17,19.3522847 16.5522847,19.8 16,19.8 C15.4477153,19.8 15,19.3522847 15,18.8 L15,13.8 C15,13.2477153 15.4477153,12.8 16,12.8 Z M24,27 C22.3431458,27 21,25.6568542 21,24 C21,22.3431458 22.3431458,21 24,21 C25.6568542,21 27,22.3431458 27,24 C27,25.6568542 25.6568542,27 24,27 Z M24,25 C24.5522847,25 25,24.5522847 25,24 C25,23.4477153 24.5522847,23 24,23 C23.4477153,23 23,23.4477153 23,24 C23,24.5522847 23.4477153,25 24,25 Z" id="path-questionary"></path>
        </defs>
        <g id="RaÍzen---ADMIN" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Cursos/VIEW-2" transform="translate(-290.000000, -638.000000)">
            <g id="tópico01" transform="translate(242.000000, 298.000000)">
              <g id="table/grupo/linha-questionario-copy-7" transform="translate(0.000000, 327.000000)">
                <g id="ico-questionary" transform="translate(40.000000, 5.000000)">
                  <mask id="mask-questionary" fill="white">
                    <use xlinkHref="#path-questionary"></use>
                  </mask>
                  <use fill="#000000" transform="translate(20.000000, 20.000000) rotate(-270.000000) translate(-20.000000, -20.000000) " xlinkHref="#path-questionary"></use>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
