import React, { Component, Fragment } from 'react';
import { FormInputIconized, FormInput } from '../../../Components/FormInputs';
import SearchIcon from '../../../assets/images/search.svg';
import { SearchBoxWrapper, NotFoundText } from './styles';
import Pagination from '../../../Components/Pagination';
import TableDataRelatorio from '../tableData';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ParticipantReportActions } from '../../../store/ducks/reports/participantReport';
import Loading from '../../../Components/Loading';
import SelectWithPopupRole from '../../../Components/SelectWithPopupRole';

class ColaboradorRelatorio extends Component {
  state = {
    name: '',
    registration: '',
    role: null,
    ready: false,
    loading: false,
    page: 1,
    report: null,
    notFound: true,
  };

  componentDidMount() {}
  componentDidUpdate(prevProps, prevStates) {
    if (
      !prevProps.participantReport.success &&
      this.props.participantReport.success
    ) {
      this.setState({
        report: this.props.participantReport.data.details,
        ready: true,
        loading: false,
        notFound: false,
      });
    } else if (
      !prevProps.participantReport.error &&
      this.props.participantReport.error
    ) {
      this.setState({
        report: null,
        ready: false,
        loading: false,
        notFound: true,
      });
    }
  }

  changeFilter = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  search = e => {
    e.preventDefault();
    if (
      this.state.name !== '' ||
      this.state.registration !== '' ||
      this.state.role !== ''
    ) {
      let filters = {
        name: this.state.name,
        registration: this.state.registration,
        role: this.state.role,
      };
      this.setState(
        {
          page: 1,
          loading: true,
        },
        () => {
          this.props.requestParticipantReport(this.state.page, filters);
        }
      );
    }
  };

  loadMoreData = page => {
    let filters = {
      name: this.state.name,
      registration: this.state.registration,
    };
    this.props.requestParticipantReport(this.state.page, filters);
    this.setState({ page, loading: true, ready: false });
  };

  handleKeyPress = event => {
    this.props.eventSearch(this.state.text);
  };

  renderPagination() {
    let { page, loading } = this.props;
    if (this.state.report && this.state.report.length > 19) {
      return (
        <Pagination
          paginate={{ page, loading }}
          onClick={page => {
            this.props.loadMore(page);
          }}
        />
      );
    }
  }

  prepareData = () => {
    const participantReport = this.state.report;
    if (participantReport.length > 0) {
      let lastSize = 0;
      participantReport.map(data => {
        if (data.blocks.length > lastSize) {
          lastSize = data.blocks.length;
        }
      });
      let header = ['ID', 'Ciclo', 'Escritório', 'Departamento', 'Média Final'];
      for (let i = 1; i <= lastSize; i++) {
        header.push('Competência ' + i);
      }
      let dataCycle = [];
      participantReport.map(data => {
        let cycle = {
          id: data.cycle.id,
          title: data.cycle.title,
          role: data.user.office,
          department: data.user.organization_unit,
          media: 0,
        };
        let blocks = [];
        let mediaComp = 0;
        let contTotal = 0;
        for (let i = 0; i < lastSize; i++) {
          if (data.blocks[i]) {
            contTotal++;
            let avg = parseFloat(data.blocks[i].avg);
            mediaComp += avg;
            blocks.push(avg.toFixed(2));
          } else {
            blocks.push(' - ');
          }
        }
        cycle.media =
          mediaComp > 0 ? (mediaComp / contTotal).toFixed(2) : '0.00';
        cycle.blocks = blocks;
        dataCycle.push(cycle);
      });
      return [header, dataCycle];
    }
    return [];
  };

  render() {
    const { report } = this.state;
    let tableData = [];
    if (this.state.ready && report) {
      tableData = this.prepareData();
    }
    return (
      <Fragment>
        <form onSubmit={this.search}>
          <SearchBoxWrapper>
            <h3>Histórico por Colaborador</h3>
            <div>
              <FormInputIconized
                type="text"
                placeholder="Nome"
                name="name"
                icon={SearchIcon}
                onChange={this.changeFilter}
              />
              <FormInput
                type="number"
                min="1"
                placeholder="Matricula"
                name="registration"
                onChange={this.changeFilter}
              />
              <div className="select">
                <SelectWithPopupRole
                  name="role"
                  value={this.state.role || ''}
                  valueBy={'id'}
                  onSelect={value =>
                    value
                      ? this.setState({ role: value.id })
                      : this.setState({ role: null })
                  }
                />
              </div>
              <div>
                <button>Buscar</button>
              </div>
            </div>
          </SearchBoxWrapper>
        </form>
        {this.state.loading && <Loading text={'Buscando...'} loading={true} />}

        {(!this.state.loading && this.state.notFound) ||
        tableData.length === 0 ? (
          <NotFoundText>Nenhum colaborador encontrado.</NotFoundText>
        ) : (
          <TableDataRelatorio
            entity="colaborador"
            dataHeader={tableData[0]}
            dataRow={tableData[1]}
            currentWidth={this.props.currentWidth}
          />
        )}
        {!this.state.loading && !this.state.notFound && this.renderPagination()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ParticipantReportActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  participantReport: state.participantReport,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColaboradorRelatorio);
