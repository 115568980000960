import React, { Fragment } from 'react';
import InputMask from 'react-input-mask';

import {
  SelectStyled,
  DefaultStyled,
  ButtonSearchStyled,
  LabelStyled,
  InputRadioStyled,
} from './styles.js';

export const FormInput = props => (
  <DefaultStyled>
    <input {...props} />
  </DefaultStyled>
);

export function FormInputLabelled({
  name,
  label,
  onChange,
  mask,
  labelSecondary,
  required,
  ...props
}) {
  return (
    <Fragment>
      <LabelStyled htmlFor={'input_' + name} labelSecondary={labelSecondary}>
        {label}
      </LabelStyled>
      <DefaultStyled>
        <InputMask
          mask={mask}
          name={name}
          id={'input_' + name}
          onChange={e => onChange(e)}
          required={required}
          {...props}
        />
      </DefaultStyled>
    </Fragment>
  );
}

export function FormTextArea({
  label,
  name,
  labelSecondary,
  sizeSecondary,
  required,
  ...props
}) {
  return (
    <Fragment>
      {label && (
        <LabelStyled
          htmlFor={'textarea_' + name}
          labelSecondary={labelSecondary}
        >
          {label}
        </LabelStyled>
      )}
      <DefaultStyled sizeSecondary={sizeSecondary}>
        <textarea
          name={name}
          id={'textarea_' + name}
          required={required}
          {...props}
        />
      </DefaultStyled>
    </Fragment>
  );
}

export const FormInputIconizedWithButton = props => (
  <DefaultStyled>
    <input
      {...props}
      id={'search' + props.name}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          props.onSubmit(e.target.value);
        }
      }}
    />
    <ButtonSearchStyled>
      <button
        onClick={e => {
          let value = document.getElementById('search' + props.name).value;
          props.onSubmit(value);
        }}
      >
        <img alt="Buscar" src={props.icon} />
      </button>
    </ButtonSearchStyled>
  </DefaultStyled>
);

export const FormInputIconized = props => (
  <DefaultStyled icon={props.icon}>
    <input {...props} />
  </DefaultStyled>
);

export const FormSelect = props => (
  <SelectStyled>
    <select {...props}>
      {props.defaultoption && (
        <option defaultValue={null}>{props.defaultoption}</option>
      )}
      {props.options.map((option, i) => (
        <option key={i} value={option[props.valueattr || 'value']}>
          {option[props.nameattr || 'name']}
        </option>
      ))}
    </select>
  </SelectStyled>
);

export const FormSelectLabelled = props => (
  <Fragment>
    <LabelStyled htmlFor={props.name}>{props.label}</LabelStyled>
    <DefaultStyled>
      <SelectStyled>
        <select {...props}>
          {props.defaultoption && (
            <option defaultValue={null}>{props.defaultoption}</option>
          )}
          {props.options.map((option, i) => (
            <option key={i} value={option[props.valueattr || 'value']}>
              {option[props.nameattr || 'name']}
            </option>
          ))}
        </select>
      </SelectStyled>
    </DefaultStyled>
  </Fragment>
);

export const InputRadioLabelled = ({
  name,
  label,
  data,
  onChange,
  defaultChecked,
}) => (
  <>
    <LabelStyled htmlFor={name}>{label}</LabelStyled>
    <InputRadioStyled>
      {data.map(prop => (
        <>
          <input
            onClick={onChange}
            type="radio"
            value={prop.value}
            name={prop.name}
            checked={prop.checked}
            defaultChecked={prop.value === defaultChecked}
          />
          <label for={prop.name}>{prop.title}</label>
        </>
      ))}
    </InputRadioStyled>
  </>
);
