import styled from "styled-components";

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  button {
    width: 160px;
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: #00468e;
    padding: 5px;
    text-transform: uppercase;
    color: #fff;

    :first-child {
      margin-right: 25px;
    }

    &[disabled] {
      background-color: #ccc;
    }
  }

  @media screen and (max-height: 900px) {
    min-height: 0;
  }
`;
