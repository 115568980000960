import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as RoleActions } from '../../ducks/role/role';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';

export function* getRole(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/admin/roles/${action.payload.id}`
    );

    yield put(RoleActions.getRoleSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* updateRole(action) {
  const { id, notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.put, `/api/v1/admin/roles/${id}`, data);
    yield put(RoleActions.updateRoleSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Cargo atualizado com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* insertRole(action) {
  const { notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.post, '/api/v1/admin/roles/', data);
    yield put(RoleActions.insertRoleSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Cargo incluído com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
