import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/answer/answer";

export function* requestAnswer({ cycleUserId, answers }) {
  yield put(Actions.answersClearError());
  yield put(Actions.answersLoading());

  try {
    let url = `api/v1/answers/${cycleUserId}`;
    let result = yield call(api.put, url, {
      answers
    });
    yield put(Actions.answersSuccess(result.data.messages[0]));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(Actions.answersError(response.messages[0]));
        }
      }
    }
    yield put(
      Actions.answersError("Ocorreu um erro no servidor, tente mais tarde.")
    );
  }
}
