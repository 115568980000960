import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ContentWrapper } from '../../styles';
import {
  DetailWrapper,
  DetailBody,
  ModeloWrapper,
  BodyWrapper,
  SuperiorWrapper,
} from './styles';
import {
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../../Components/Table/styles';

import UserInfo from '../../../../Components/UserComponents/UserInfo';
import UserHeaderDetail from '../../../../Components/UserComponents/UserHeaderDetail';
import SelectWithPopupUsers from '../../../../Components/SelectWithPopupUsers';
import SideMenu from '../../SideMenu';

import { Creators as HierarchyActions } from '../../../../store/ducks/hierarchy';

class HierarchiesUser extends Component {
  state = {
    superior: {},
    subordinates: [],
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getHierarchyRequest(id);
  }

  componentDidUpdate(prevProps) {
    const { hierarchy } = this.props;
    if (hierarchy.success && !prevProps.hierarchy.success) {
      this.setState({
        superior: hierarchy.details.superior,
        subordinates: hierarchy.details.subordinates,
      });
    }
    if (hierarchy.updated && !prevProps.hierarchy.updated) {
      this.setState({
        superior: hierarchy.details.superior,
      });
    }
  }

  onSaveSuperior = () => {
    const value = this.state.superior ? this.state.superior.id : null;
    this.props.putHierachyRequest(this.props.match.params.id, value);
  };

  renderSuperiorTable = superior => (
    <BodyWrapper>
      <div className="table-title">
        <span>Superior</span>
      </div>
      <SuperiorWrapper>
        <div className="users-select">
          <SelectWithPopupUsers
            name="user_id"
            value={(superior && superior.name) || ''}
            valueBy={'id'}
            onSelect={v => {
              this.setState({ superior: v });
            }}
            placeholder="Sem superior"
          />
        </div>
        <div className="save-button">
          <button type="button" onClick={this.onSaveSuperior}>
            Salvar
          </button>
        </div>
      </SuperiorWrapper>
    </BodyWrapper>
  );

  renderSubordinatesTable = subordinates => (
    <BodyWrapper>
      <div className="table-title">
        <span>Equipe</span>
      </div>
      {!subordinates.length ? (
        <div>
          <span>Não há membros da equipe para este usuário.</span>
        </div>
      ) : (
        <TableDiv>
          <Thead>
            <Tr>
              <th>ID</th>
              <th>Nome</th>
            </Tr>
          </Thead>
          <Tbody>
            {subordinates.map(subordinate => (
              <Tr key={subordinate.id}>
                <td>{subordinate.id}</td>
                <td>{subordinate.name}</td>
              </Tr>
            ))}
          </Tbody>
        </TableDiv>
      )}
    </BodyWrapper>
  );

  renderUserInfo = ({ id }) => (
    <UserInfo userId={id}>
      <UserHeaderDetail />
    </UserInfo>
  );

  renderTabsAndTables = ({ id }) => (
    <DetailWrapper>
      <DetailBody>
        <Tabs defaultIndex={2}>
          <TabList>
            <Tab>
              <Link to={`/dadosgerais/usuarios/${id}`}>Dados</Link>
            </Tab>
            <Tab>
              <Link to={`/dadosgerais/usuarios/${id}/ciclos`}>Ciclos</Link>
            </Tab>
            <Tab>
              <Link to={`/dadosgerais/usuarios/${id}/hierarquias`}>
                Hierarquias
              </Link>
            </Tab>
          </TabList>
          <TabPanel />
          <TabPanel />
          <TabPanel>
            <ModeloWrapper>
              <>
                {this.renderSuperiorTable(this.state.superior)}
                {this.renderSubordinatesTable(this.state.subordinates)}
              </>
            </ModeloWrapper>
          </TabPanel>
        </Tabs>
      </DetailBody>
    </DetailWrapper>
  );

  render() {
    return (
      <ContentWrapper>
        <SideMenu page={1} />
        <div className="content">
          {this.renderUserInfo(this.props.match.params)}
          {this.renderTabsAndTables(this.props.match.params)}
        </div>
      </ContentWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...HierarchyActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  hierarchy: state.hierarchy,
});

export default connect(mapStateToProps, mapDispatchToProps)(HierarchiesUser);
