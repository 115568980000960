import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import {
  convertCycleStatus,
  getLevelStatus,
} from '../../../services/cycle/status';

/* Types & Action Creators */

const { Types, Creators } = createActions({
  participantsRequest: ['payload'],
  participantsResetRequest: null,
  participantsReset: null,
  participantsSuccess: ['payload', 'append'],
  participantsError: ['error'],
  participantsClearError: null,
  participantsLoading: null,
  participantsReportDownloadRequest: ['cycleId', 'filters'],
  participantsReportDownloadSuccess: ['data'],
});

export const ParticipantsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  total: null,
  perPage: null,
  page: null,
  lastPage: null,
  data: [],
  error: false,
  success: false,
  loading: false,
});

/* Reducers */

export const success = (state, { payload, append }) => {
  let data = state.data;
  if (!!append) {
    data = data.concat(payload.data);
    if (payload.data.length === 0) {
      payload.page--;
    }
  } else {
    data = payload.data;
  }
  if (data instanceof Array) {
    data = data.map(item => ({
      ...item,
      statusText: convertCycleStatus(item.status),
      statusLevel: getLevelStatus(item.status),
    }));
  }
  return state.merge({
    ...payload,
    data,
    error: false,
    loading: false,
    success: true,
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false,
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false,
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false,
  });
};

export const reset = state => {
  return state.merge({
    total: null,
    perPage: null,
    page: null,
    lastPage: null,
    data: [],
    error: false,
    success: false,
    loading: false,
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PARTICIPANTS_SUCCESS]: success,
  [Types.PARTICIPANTS_ERROR]: error,
  [Types.PARTICIPANTS_CLEAR_ERROR]: clearError,
  [Types.PARTICIPANTS_RESET]: reset,
  [Types.PARTICIPANTS_LOADING]: loading,
});
