import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "courses/GET_REQUEST",
  GET_SUCCESS: "courses/GET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false
});

export default function courses(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getCoursesRequest: payload => ({
    type: Types.GET_REQUEST,
    payload
  }),

  getCoursesSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  })
};
