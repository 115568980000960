import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin-top: 15px;
  padding: 30px 0;

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;

    .label {
      display: flex;
      align-self: flex-start;
      padding: 0;
      margin-bottom: 5px;

      label {
        color: #666666;
        margin: 0;
        padding: 0;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 22px;
      }
    }

    .content {
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
      justify-content: space-between;

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 400px;
        height: 40px;
        border: #ccc 1px solid;
        border-radius: 4px;
        flex:2
      }

      input {
        border: 0;
        margin-left: 5px;
      }

      img {
        height: 30px;
        width: 30px;
        margin-right: 10px;
      }
    }

    .button {
      margin-left: 25px;
      margin-right: 0;
      button {
        border: 0;
        border-radius: 5px;
        background-color: #00468e;
        width: 150px;
        height: 40px;

        a {
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
`;

export const Atividade = styled.div`
    border: 2px solid #e5e5e5;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    
    .atv-header {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #e5e5e5;

      input {
        border: 0;
        font-size: 16px;
      }

      .icons {
        img {
          width: 20px;
          cursor: pointer;

          :first-child {
            margin-right: 15px;
          }
        }
      }
    }

    .atv-tipo {
      padding: 15px 15px 15px 25px;
      border-bottom: 2px solid #e5e5e5;

      span {
        font-size: 16px;
        color: #666666;
        margin-right: 10px;

        :first-child {
          font-weight: bold;
        }
      }
    }

    .atv-acoes {
      padding: 15px;
      display: flex;
      align-items: center;

      span {
        font-size: 16px;
        color: #999999;
        margin-right: 15px;
      }
    }

  `