import styled from 'styled-components';

export const DetailWrapper = styled.div``;
export const DetailHeader = styled.div`
  background-color: #3b6ab1;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;

  .back-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;
    cursor: pointer;
    color: #fff;

    span {
      margin-top: 10px;
    }
  }

  .detail-info {
    display: flex;

    > div {
      :first-child {
        margin-right: 20px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }

      :nth-child(2) {
        display: flex;
        flex-direction: column;

        span {
          color: #fff;
          font-size: 16px;
          :not(:last-child) {
            margin-bottom: 5px;
          }

          :first-child {
            font-size: 26px;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

export const DetailBody = styled.div`
  .react-tabs {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

      li {
      }

      li {
        display: flex;
        a {
          padding: 0 40px;
          display: flex;
          align-items: center;
          color: #666666;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 16px;
        }

        :not(:last-child) {
          margin-right: 20px;
        }

        &.react-tabs__tab--selected {
          color: #00468e;
          border-bottom: 6px solid #00468e;
          padding-top: 6px;
        }
      }
    }
  }
`;

export const ModeloWrapper = styled.div`
  background-color: #fff;
  border-top-left-radius: ${props => (props.novo ? '5px' : '0px')};
  border-top-right-radius: ${props => (props.novo ? '5px' : '0px')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 30px 45px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);

  > div {
    margin-bottom: 20px;
  }

  button {
    height: 40px;
    margin: 0 auto;
    display: block;
    padding: 10px 25px;
    color: #fff;
    background-color: #00468e;
    border: 0;
    border-radius: 5px;
    font-size: 15px;
    text-transform: uppercase;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const SelectDiv = styled.div`
  label {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }
  > div {
  }
  > button {
    margin-top: 10px;
    width: min-content;
    font-size: 14px;
    color: #fff;
    background-color: #00468e;
    padding: 5px 30px;
    border: 0;
    border-radius: 5px;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const Selects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;

  .selects {
    flex: 1;
    :not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 30px;
`;

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  span {
    padding-left: 14px;
  }
`;
