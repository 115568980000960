import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/**
 * Types
 */

const { Types, Creators } = createActions({
  importRequest: ["file"],
  importSuccess: ["success"],
  importLoading: null,
  importError: ["error"]
});

export const ImportTypes = Types;
export default Creators;

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  fileImport: null,
  loading: false,
  error: null,
  success: null
});

/* Reducers */

export const success = (state, { fileImport }) => {
  return state.merge({
    fileImport,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.IMPORT_SUCCESS]: success,
  [Types.IMPORT_ERROR]: error,
  [Types.IMPORT_LOADING]: loading
});
