import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import UserCyclesActions from "../../ducks/userCycles/userCycles";

export function* requestUserCycles({ payload }) {
  const { userId, page, append = false } = payload;
  yield put(UserCyclesActions.userCyclesClearError());
  yield put(UserCyclesActions.userCyclesLoading());
  try {
    let url = `api/v1/admin/user-cycles`;
    url += "?page=" + page;
    url += "&user_id=" + userId;
    let response = yield call(api.get, url);
    yield put(UserCyclesActions.userCyclesSuccess(response.data, append));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(UserCyclesActions.userCyclesError(error));
      }
    }
    yield put(
      UserCyclesActions.userCyclesError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* requestResetUserCycles() {
  yield put(UserCyclesActions.userCyclesReset());
}
