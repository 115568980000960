import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
 *{
     margin: 0;
     padding: 0;
     box-sizing: border-box;
     outline: 0;
 }

 html, body, #root {
     height: 100% !important;
 }

 body {
     font-family: "Open Sans";
     text-rendering: optimizeLegibility !important;
     background: #467CC2;
     height: 100% !important;
 }

 button {
     cursor: pointer;
 }
`;

export default GlobalStyle;
