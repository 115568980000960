import styled from 'styled-components';

export const CollapsibleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e7e7e7;
  border-radius: 4px;

  > .header {
    border-bottom: 1px solid #e7e7e7;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    > div {
      display: flex;
      align-items: center;

      &.left-side {
        display: flex;
        align-items: center;

        > img {
          width: 20px;
          filter: contrast(20%);
        }

        > span {
          margin-left: 15px;
          font-size: 13px;
        }
      }

      &.right-side {
        > button {
          background: none;
          border: 0;
          display: flex;
          align-items: center;

          > svg {
            width: 24px;
            height: 24px;
            transition: ease transform 0.2s;

            path {
              fill: #666666;
            }
          }

          &:hover {
            opacity: 0.8;
          }

          &:not(:last-child) {
            margin-right: 10px;
          }

          &.btn-drag {
            cursor: grab;
          }
        }
      }
    }
  }

  > .body {
    padding: 10px 30px 30px;
    display: ${props => !props.isOpen && 'none'};
  }
`;
