import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';

import SideMenu from '../../SideMenu';
import UserCycles from '../../../../Components/UserCycles';
import UserHeaderDetail from '../../../../Components/UserComponents/UserHeaderDetail';
import UserInfo from '../../../../Components/UserComponents/UserInfo';

import { ContentWrapper } from '../../styles';
import { DivTabs, CyclesWrapper } from './styles';

export default class UsuarioCiclos extends Component {
  render() {
    let userId = this.props.match.params.id;
    return (
      <ContentWrapper>
        <SideMenu page={1} />
        <div className="content">
          <UserInfo userId={userId}>
            <UserHeaderDetail />
          </UserInfo>
          <DivTabs>
            <Tabs defaultIndex={1}>
              <TabList>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}`}>Dados</Link>
                </Tab>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}/ciclos`}>
                    Ciclos
                  </Link>
                </Tab>
                <Tab>
                  <Link to={`/dadosgerais/usuarios/${userId}/hierarquias`}>
                    Hierarquias
                  </Link>
                </Tab>
              </TabList>
              <TabPanel />
              <TabPanel>
                <CyclesWrapper>
                  <UserCycles
                    userId={userId}
                    onSelect={item => {
                      let url = `/dadosgerais/usuarios/${item.user_id}/ciclo-usuario/${item.id}`;
                      window.history.pushState(null, 'usuário ciclo', url);
                    }}
                  />
                </CyclesWrapper>
              </TabPanel>
              <TabPanel />
            </Tabs>
          </DivTabs>
        </div>
      </ContentWrapper>
    );
  }
}
