import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import UpdateCycleUserStatusActions from './../../../store/ducks/cycleUser/updateCycleUserStatus';
import UserCycleActions from './../../../store/ducks/cycle/userCycle';

import NotFoundItems from '../../../Components/NotFoundItems';
import RevisaoIniciar from '../Components/RevisaoIniciar';
import RevisaoSupervisor from '../Components/RevisaoSupervisor';
import ModalNoCycle from '../../../Components/ModalNoCycle';

class HomeUser extends Component {
  state = {
    redirect: false,
    isModalNoCycleOpen: false,
  };

  onInitQuiz = () => {
    this.props.updateCycleUserStatusRequest(
      this.props.userCycle.userCycle.id,
      'Em Preenchimento'
    );
  };

  componentWillUnmount() {
    this.props.updateCycleUserStatusRequestReset();
  }

  componentDidMount() {
    this.props.userCycleRequest();
  }

  shouldRedirectOnMount = prevProps => {
    const { userCycle } = this.props;
    return (
      userCycle.success &&
      !prevProps.userCycle.success &&
      userCycle.userCycle &&
      userCycle.userCycle.statusLevel >= 1
    );
  };

  shouldRedirectOnInitQuiz = prevProps => {
    const { updateCycleUserStatus } = this.props;
    return (
      updateCycleUserStatus.success && !prevProps.updateCycleUserStatus.success
    );
  };

  componentDidUpdate(prevProps) {
    if (
      this.shouldRedirectOnMount(prevProps) ||
      this.shouldRedirectOnInitQuiz(prevProps)
    ) {
      this.setState({ redirect: true });
    }

    if (!prevProps.userCycle.error && this.props.userCycle.error) {
      this.setState({ isModalNoCycleOpen: true });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/user/stage" />;
    }
    const { userCycle, success } = this.props.userCycle;
    if (!userCycle) {
      return (
        <Fragment>
          <NotFoundItems />
          <ModalNoCycle isModalNoCycleOpen={this.state.isModalNoCycleOpen} />
        </Fragment>
      );
    }
    if (userCycle.statusLevel === 3) {
      return <RevisaoSupervisor userCycle={userCycle} />;
    }
    return (
      <Fragment>
        {success && (
          <RevisaoIniciar onInitQuiz={this.onInitQuiz} userCycle={userCycle} />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UpdateCycleUserStatusActions,
      ...UserCycleActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  updateCycleUserStatus: state.updateCycleUserStatus,
  userCycle: state.userCycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeUser);
