import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import UserCycleActions from "../../../store/ducks/cycle/userCycle";

import RevisaoFinal from "../Components/RevisaoFinal";
import RevisaoFim from "../Components/RevisaoFim";
import NotFoundItems from "../../../Components/NotFoundItems";
import Loading from "../../../Components/Loading";

class SendToRevision extends React.Component {
  state = {
    ready: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.userCycle.success || props.userCycle.error) {
      state.ready = true;
    }
    return state;
  }

  componentDidMount() {
    this.props.userCycleRequest();
  }

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    let userCycle = this.props.userCycle.userCycle;
    if (this.props.userCycle.error) {
      return <NotFoundItems />;
    }
    if (
      userCycle.status == "Em Revisão" ||
      userCycle.status == "Finalizado" ||
      userCycle.status == "Recursos Humanos"
    ) {
      return <Redirect to="/user/resultados" />;
    }
    if (userCycle.status == "Em Revisão Colaborador") {
      return (
        <RevisaoFim
          updateCycleUser={() => {
            this.props.userCycleRequest();
          }}
        />
      );
    } else {
      return <RevisaoFinal userCycle={userCycle} />;
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UserCycleActions }, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
  updateCycleUserStatus: state.updateCycleUserStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendToRevision);
