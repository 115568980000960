import React, { useState } from 'react';
import { CollapsibleWrapper } from './styles';

import IosCloseCircleOutline from 'react-ionicons/lib/IosCloseCircleOutline';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import ReactTooltip from 'react-tooltip';

export default function Collapsible({
  title,
  icon,
  children,
  onRemove,
  dragHandle,
  verifyCollOpen,
  editable,
  defaultOpen,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen ? defaultOpen : false);

  function handleSetOpen(op) {
    setIsOpen(op);
    if (verifyCollOpen) verifyCollOpen(op);
  }
  return (
    <CollapsibleWrapper className="collapsible" isOpen={isOpen} {...props}>
      <ReactTooltip effect="solid" />
      <div className="header">
        <div className="left-side">
          {icon && <img src={icon} alt={title} />}
          <span>{title}</span>
        </div>
        <div className="right-side">
          {editable && dragHandle && !isOpen && (
            <button type="button" data-tip="Ordenar" className="btn-drag">
              <ReactTooltip effect="solid" />
              {dragHandle}
            </button>
          )}
          <button
            type="button"
            data-tip={!isOpen ? 'Abrir' : 'Fechar'}
            onClick={() => {
              handleSetOpen(!isOpen);
            }}
          >
            <IosArrowDown
              style={isOpen ? { transform: `rotate(180deg)` } : null}
            />
          </button>
          {editable && (
            <button type="button" onClick={onRemove} data-tip="Remover">
              <IosCloseCircleOutline />
            </button>
          )}
        </div>
      </div>

      <div className="body">{children}</div>
    </CollapsibleWrapper>
  );
}
