import React, { Fragment } from "react";

import { ContainerLogin, Content, LogoContainer, LoginBox } from "./styles";

import Globalstyle from "../../styles/login";

import LoginForm from "../../Components/LoginForm";

import Brand from "../../assets/images/logo-emae-v2.png";

class Login extends React.Component {
  render() {
    return (
      <Fragment>
        <Globalstyle />
        <Content>
          <ContainerLogin>
            <LogoContainer>
              <img src={Brand} alt="logo EMAE" />
              <span>gestão de ciclos e avaliações de desempenho</span>
            </LogoContainer>
            <LoginBox>
              <LoginForm location={this.props.location} />
            </LoginBox>
          </ContainerLogin>
        </Content>
      </Fragment>
    );
  }
}

export default Login;
