import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as EnrollActions } from '../../../ducks/modules/courses/enroll';
import { Creators as EnrollmentsCompletedActions } from '../../../ducks/modules/courses/enrollmentCompleted';
import { Creators as EnrollmentsInProgressActions } from '../../../ducks/modules/courses/enrollmentInProgress';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* postEnroll(action) {
  try {
    const response = yield call(api.post, '/api/v1/enrollments', {
      offer_id: action.payload.offerId,
    });

    yield put(
      EnrollActions.postEnrollSuccess(
        response.data.details,
        response.data.messages[0]
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* postConcludeEnroll(action) {
  try {
    const response = yield call(
      api.put,
      '/api/v1/enrollments/' + action.payload.enrollId + '/conclusion'
    );
    yield put(
      EnrollActions.postEnrollConcludeSuccess(response.data.messages[0])
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* getEnrollmentCompleted(action) {
  try {
    const response = yield call(
      api.get,
      '/api/v1/enrollments?situation=completed'
    );

    yield put(
      EnrollmentsCompletedActions.getEnrollmentCompletedSuccess(
        response.data.data
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* getEnrollmentInProgress(action) {
  try {
    const response = yield call(
      api.get,
      '/api/v1/enrollments?situation=in_progress'
    );

    yield put(
      EnrollmentsInProgressActions.getEnrollmentInProgressSuccess(
        response.data.data
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* resetEnroll() {
  yield put(EnrollActions.resetEnrollSuccess());
}

export function* resetConcludeEnroll() {
  yield put(EnrollActions.resetEnrollConclude());
}
