import React from "react";
import PropTypes from "prop-types";

import MediaNotaBox from "../../UserComponents/MediaNotaBox";

export class Grade extends React.Component {
  static propTypes = {
    questions: PropTypes.array.isRequired,
    answers: PropTypes.array.isRequired,
    isReviewer: PropTypes.bool.isRequired
  };

  state = {
    questions: [],
    answers: [],
    isReviewer: false
  };

  static getDerivedStateFromProps(props, state) {
    state.questions = props.questions;
    state.answers = props.answers;
    state.isReviewer = props.isReviewer;
    return state;
  }

  getMedia = () => {
    let questions = this.state.questions;
    questions.filter(q => q.type == "numeric_interval");
    if (questions.length === 0) {
      return null;
    }
    let answers = this.state.answers;
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id == answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id == answer.question_interval_numerics_id
      );
      return parseInt(answer.value);
    });
    if (grades.length == 0) {
      return 0;
    }
    let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
    return Number(sum / questions.length).toFixed(2);
  };

  renderMedia = (media, isReviewer) => {
    return (
      <MediaNotaBox
        key={`media-${isReviewer}`}
        isMediaRevisado={isReviewer}
        nota={media}
      />
    );
  };

  render() {
    let media = this.getMedia();
    if (!media) {
      media = 0;
    }
    return (
      <MediaNotaBox
        key={`media-${this.state.isReviewer}`}
        isMediaRevisado={this.state.isReviewer}
        nota={media}
      />
    );
  }
}
