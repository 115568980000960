import React, { Component, Fragment } from 'react';

import {
  Tr,
  Tbody,
  Thead,
  TableDiv,
} from '../../../../../Components/Table/styles';
import UsuarioSVG from '../../../../../assets/images/usuario.svg';
import { TrJustificativa } from './styles';
import ReasonFormSelect from '../../../../../Components/ReasonFormSelect';
import FormError from '../../../../../Components/Form/Error';

export default class JustificativaIndividual extends Component {
  state = {
    populatedParticipantsFromProps: false,
  };

  getFormData = () => {
    let result = [];
    for (let name in this.state) {
      if (name.match('participant_')) {
        result.push(this.state[name]);
      }
    }
    return result;
  };

  static getDerivedStateFromProps(props, state) {
    if (
      !state.populatedParticipantsFromProps &&
      props.participantes.length > 0
    ) {
      state.populatedParticipantsFromProps = true;
      for (let i = 0; i < props.participantes.length; i++) {
        let participant = props.participantes[i];
        state[`participant_${participant.cycle_user_id}`] = {
          id: participant.id,
          reason_id: null,
        };
      }
    }
    return state;
  }

  render() {
    const { participantes, submitted } = this.props;
    return participantes.map((part, i) => {
      let key = `participant_${part.cycle_user_id}`;
      return (
        <Fragment key={i}>
          <TableDiv>
            <Thead style={i > 0 ? { display: 'none' } : {}}>
              <Tr>
                <th />
                <th>ID</th>
                <th>Nome</th>
                <th>Matrícula</th>
                <th>Escritório</th>
              </Tr>
            </Thead>
            <Tbody>
              <tr key={i}>
                <td style={{ width: 50 }}>
                  <img
                    src={UsuarioSVG}
                    alt={part.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                    }}
                  />
                </td>
                <td style={{ width: 30 }}>{part.id}</td>
                <td style={{ width: '40%' }}>{part.name}</td>
                <td>{part.registration}</td>
                <td>{part.role_name}</td>
              </tr>
            </Tbody>
          </TableDiv>
          <TrJustificativa>
            {submitted && !this.state[key].reason_id && (
              <FormError error="É necessário preencher esse campo!" />
            )}
            <ReasonFormSelect
              onChange={reasonId => {
                this.setState({
                  [key]: {
                    id: part.id,
                    reason_id: reasonId,
                  },
                });
              }}
            />
          </TrJustificativa>
        </Fragment>
      );
    });
  }
}
