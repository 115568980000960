import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-responsive-modal";
import { Redirect } from "react-router-dom";

import UpdateCycleUserStatusActions from "./../../../../store/ducks/cycleUser/updateCycleUserStatus";
import UserCycleActions from "../../../../store/ducks/cycle/userCycle";

import { UserContainer } from "../../../../Components/UserComponents/styles";
import ResultadoRevisadoBox from "../../../../Components/UserComponents/ResultadoRevisadoBox";
import Progress from "../../../../Components/UserComponents/Progress";
import ResultadoRevisadoTitle from "../../../../Components/UserComponents/ResultadoRevisadoTitle";
import { ResultadoFimMiddleWrapper } from "./styles";
import { ModalInner } from "../../../../styles/global";
import Loading from "../../../../Components/Loading";

class RevisaoFim extends React.Component {
  state = {
    ready: false,
    openModal: false,
    redirect: false,
    userCycle: null,
    isModalOpen: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.userCycle.success || props.userCycle.error) {
      state.ready = true;
      if (props.userCycle.success) {
        state.userCycle = props.userCycle.userCycle;
      }
    }
    if (state.ready && props.updateCycleUserStatus.success) {
      state.openModal = true;
      props.updateCycleUserStatusRequestReset();
    }
    return state;
  }

  getCountDiffAnswers = () => {
    return this.state.userCycle.answers.filter(answerUser => {
      if (!answerUser.question_interval_numerics_id) {
        return false;
      }
      let answerReviewer = this.state.userCycle.answersReviewer.find(
        a => a.question_awnser_id == answerUser.id
      );
      return (
        answerReviewer.question_interval_numerics_id ==
        answerUser.question_interval_numerics_id
      );
    }).length;
  };

  getCountQuestionsNumerics = () => {
    return this.state.userCycle.answers.filter(answerUser => {
      if (!answerUser.question_interval_numerics_id) {
        return false;
      }
      return true;
    }).length;
  };

  getQuestionById = id => {
    let question = null;
    this.state.userCycle.stages.map(stage => {
      stage.blocks.map(block => {
        let q = block.questions.find(q => q.id == id);
        if (q) {
          question = q;
        }
      });
    });
    return question;
  };

  getMedia = () => {
    let questions = [];
    this.state.userCycle.stages.map(stage => {
      stage.blocks.map(block => {
        block.questions.map(question => {
          questions.push(question);
        });
      });
    });
    questions = questions.filter(q => q.type == "numeric_interval");
    if (questions.length === 0) {
      return null;
    }
    let answers = this.state.userCycle.answers.filter(
      answer => !!answer.question_interval_numerics_id
    );
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id == answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id == answer.question_interval_numerics_id
      );
      return parseInt(answer.value);
    });
    if (grades.length == 0) {
      return 0;
    }
    let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
    return Number(sum / questions.length).toFixed(2);
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onSendQuiz = () => {
    let status = this.state.userCycle.status;
    if (status == "Finalizado" || status == "Recursos Humanos" || status == "Em Revisão") {
      this.setState({ openModal: true });
    } else {
      this.props.updateCycleUserStatusRequest(
        this.state.userCycle.id,
        status == "Em Revisão Colaborador" ? "Revisão/Validação" : "Em Revisão"
      );
    }
  };

  componentDidMount() {
    this.props.userCycleRequest();
  }

  renderModal = () => {
    const { openModal } = this.state;
    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        styles={{
          modal: {
            borderRadius: "5px",
            width: "500px",
            padding: "0"
          }
        }}
        showCloseIcon={false}
      >
        <ModalInner>
          <div className="modal-header">
            <span>Sucesso!</span>
          </div>
          <div className="modal-body">
            <p>
              Sua revisão foi enviada com sucesso. Aguarde a avaliação de seu
              superior imediato para prosseguir.
            </p>
            <button onClick={this.props.updateCycleUser}>Ok</button>
          </div>
        </ModalInner>
      </Modal>
    );
  };

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.state.redirect) {
      return <Redirect to="/user/stage" />;
    }
    let cycleUser = this.state.userCycle;
    let totalQuestions = this.getCountQuestionsNumerics();
    let agreeQuestions = this.getCountDiffAnswers();
    let media = this.getMedia();
    let textLink =
      totalQuestions !== agreeQuestions
        ? 'Solicitar nova revisão'
        : `Eu, ${this.props.userInfo.data.name}, concordo com a revisão feita pelo meu superior imediato`;
    return (
      <Fragment>
        <Progress stages={cycleUser.stages} />
        <UserContainer>
          <ResultadoRevisadoTitle
            userAccept={agreeQuestions}
            totalQuestions={totalQuestions}
          />
          <ResultadoFimMiddleWrapper>
            <ResultadoRevisadoBox points={media} />
            <div className="btns">
              <a href="#!" onClick={() => this.onSendQuiz()}>
                {textLink}
              </a>
              <a
                href="#!"
                onClick={() => {
                  this.setState({ redirect: true });
                }}
              >
                Revisitar revisão
              </a>
            </div>
          </ResultadoFimMiddleWrapper>
        </UserContainer>
        {this.renderModal()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserCycleActions,
      ...UpdateCycleUserStatusActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  userInfo: state.userInfo,
  userCycle: state.userCycle,
  updateCycleUserStatus: state.updateCycleUserStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevisaoFim);
