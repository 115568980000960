import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ContainerLogin = styled.div`
  display: flex;
  flex-direction: row;
  height: 400px;
`;

export const LogoContainer = styled.div`
  background: #fff;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    margin-top: 50px;
    width: 100px;
  }

  span {
    color: #8c8c8c;
    text-transform: uppercase;
    text-align: center;
    margin-top: 70px;
    font-weight: 600;
  }
`;

export const Footer = styled.footer`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px 0 15px;
  color: #fff;
  border-top: #fff solid 1px;
  font-size: 12px;

  span {
    height: 17px;
    width: 129.1px;
    color: #cccccc;
    font-family: "open Sans";
    line-height: 17px;
    font-size: 12px;
  }

  div .logoDiv {
    display: flex;
    align-items: center;

    height: 40px;
    width: 119px;
  }

  .digitalPages {
    height: 13.37px;
    width: 80.51px;
  }

  .color {
    height: 24px;
    width: 19.2px;
  }
`;

export const LoginBox = styled.div`
  width: 440px;
  background: #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
