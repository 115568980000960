import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  UPDATE_REQUEST: 'templateQuizRoles/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'templateQuizRoles/UPDATE_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  loading: false,
  success: false,
  updated: false,
});

export default function templateQuizRoles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        updated: false,
      };
    case Types.UPDATE_SUCCESS:
      return {
        details: action.payload,
        loading: false,
        success: true,
        updated: true,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  updateTemplateQuizRolesRequest: (id, role_ids) => ({
    type: Types.UPDATE_REQUEST,
    payload: { id, role_ids },
  }),

  updateTemplateQuizRolesSuccess: payload => ({
    type: Types.UPDATE_SUCCESS,
    payload,
  }),
};
