import styled from 'styled-components'

export const CommentaryStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  .left-side {
    width: 100px;
    margin-right: 20px;
    .img-wrapper {
      img {
        width: 100px;
        border-radius: 50%;
      }
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;

    p {
      font-size: 12px;
      margin: 10px 0;
      color: #666;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      span {
        font-size:12px;
        :first-child {
          font-weight: bold;
        }
      }
    }
  }
`
