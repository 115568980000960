import React, { Component } from 'react';

import {
  Tr,
  Tbody,
  Thead,
  TableDiv,
} from '../../../../../Components/Table/styles';

import { ResponsiveWrapper } from './styles';

import UsuarioSVG from '../../../../../assets/images/usuario.svg';
import AddIcon from '../../../../../assets/images/add.svg';

export default class ParticipantsTable extends Component {
  state = {
    canSelectItem: true,
    participantes: [],
    inputsCheckeds: [],
  };

  handleClickSelectAll = e => {
    let checked = e.target.checked;
    let inputs = document.getElementsByClassName('input-participante');
    let inputsCheckeds = [...this.state.inputsCheckeds];
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = checked;
      let participantId = inputs[i].value;
      let index = inputsCheckeds.findIndex(item => item.id == participantId);
      let item = { id: participantId, checked };
      if (index !== -1) {
        inputsCheckeds[index] = item;
      } else {
        inputsCheckeds.push(item);
      }
    }
    this.setState({ inputsCheckeds }, () => this.sendToProps());
  };

  handleInputCheck = e => {
    let checked = e.target.checked;
    let participantId = e.target.value;
    let inputsCheckeds = [...this.state.inputsCheckeds];
    let index = inputsCheckeds.findIndex(item => item.id == participantId);
    let item = { id: participantId, checked };
    if (index !== -1) {
      inputsCheckeds[index] = item;
    } else {
      inputsCheckeds.push(item);
    }
    this.setState({ inputsCheckeds }, () => this.sendToProps());
  };

  sendToProps = () => {
    let participantesSelecionados = this.state.inputsCheckeds
      .filter(item => item.checked)
      .map(item => {
        return this.state.participantes.find(
          participant => participant.id == item.id
        );
      });
    this.props.handleParticipantesSelecionados(participantesSelecionados);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.canSelectItem === false) {
      state.canSelectItem = false;
    }
    if (state.participantes != props.participantes) {
      state.inputsCheckeds = [];
      try {
        document.getElementById('input-select-all').checked = false;
      } catch (e) {}
    }
    if (state.participantes.length > 0) {
      try {
        let itemActives = state.inputsCheckeds.filter(item => item.checked)
          .length;
        let checked = state.participantes.length == itemActives;
        document.getElementById('input-select-all').checked = checked;
      } catch (e) {}
    }
    state.participantes = props.participantes;
    return state;
  }

  renderItem = participant => {
    let checked = false;
    let item = this.state.inputsCheckeds.find(
      item => item.id == participant.id
    );
    if (item) {
      checked = item.checked;
    }
    return (
      <Tr key={participant.id}>
        {this.state.canSelectItem ? (
          <td className="center check-wrapper">
            <input
              value={participant.id}
              defaultChecked={checked}
              type="checkbox"
              className="input-participante"
              onChange={this.handleInputCheck}
            />
          </td>
        ) : null}
        <td className="center">
          <img src={UsuarioSVG} alt={participant.nome} className="avatar" />
        </td>
        <td>{participant.id}</td>
        <td>{participant.name}</td>
        <td className="center hideResponsive">{participant.registration}</td>
        <td className="hideResponsive">{participant.role_name}</td>
        <td className="hideResponsive">{participant.statusText}</td>
        {this.props.onItemClicked ? (
          <td>
            <img
              src={AddIcon}
              alt="add"
              className="btn-click"
              onClick={() => this.props.onItemClicked(participant)}
            />
          </td>
        ) : null}
      </Tr>
    );
  };

  render() {
    const { participantes } = this.state;
    return (
      <ResponsiveWrapper>
        <TableDiv>
          <Thead>
            <Tr>
              {this.state.canSelectItem ? (
                <th className="center">
                  <input
                    onChange={e => this.handleClickSelectAll(e)}
                    type="checkbox"
                    defaultChecked={false}
                    id="input-select-all"
                  />
                </th>
              ) : null}
              <th />
              <th>ID</th>
              <th>Nome</th>
              <th className="center hideResponsive">Matrícula</th>
              <th className="hideResponsive">Escritório</th>
              <th className="hideResponsive">Status</th>
              {this.props.onItemClicked ? <th /> : null}
            </Tr>
          </Thead>
          <Tbody>
            {participantes.map(participant => this.renderItem(participant))}
          </Tbody>
        </TableDiv>
      </ResponsiveWrapper>
    );
  }
}
