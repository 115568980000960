import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonOk = styled.button`
  margin-top: 30px;
  width: min-content;
  font-size: 14px;
  color: #fff;
  background-color: #00468e;
  padding: 5px 30px;
  border: 0;
  border-radius: 5px;
  :hover {
    opacity: 0.8;
  }
`;
