import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UpdateCycleUserStatusActions from "../../store/ducks/cycleUser/updateCycleUserStatus";

import ButtonCycleUser from "./ButtonCycleUser";

const mapDispatchToProps = dispatch => bindActionCreators({
  request: UpdateCycleUserStatusActions.updateCycleUserStatusRequest,
  reset: UpdateCycleUserStatusActions.updateCycleUserStatusRequestReset
}, dispatch);

const mapStateToProps = state => ({
  requestSuccess: state.updateCycleUserStatus.success,
  requestError: state.updateCycleUserStatus.error,
  requestLoading: state.updateCycleUserStatus.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonCycleUser);