import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Creators as CyclesActions } from '../../ducks/cycle/cycles';

export function* requestCycles({ payload }) {
  const { page = 1, text = null } = payload;
  try {
    let url = 'api/v1/admin/cycles';
    url += '?page=' + page;
    if (!!text) {
      url += '&text=' + text;
    }
    let response = yield call(api.get, url);
    yield put(CyclesActions.cyclesSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(CyclesActions.cyclesError(error));
      }
    }
    yield put(
      CyclesActions.cyclesError(
        'Ocorreu um erro no servidor, tente mais tarde.'
      )
    );
  }
}
