import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import UserCycleActions from "../../../store/ducks/cycle/userCycle";

import QuizShow from "./QuizShow";

const mapDispatchToProps = dispatch => bindActionCreators({
  userCycleRequest: UserCycleActions.userCycleRequest,
  userCycleResetRequest: UserCycleActions.userCycleResetRequest
}, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
  currentCycle: state.currentCycle
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizShow);
