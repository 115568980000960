import React from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { ButtonsWrapper } from "./styles";

/**
 * Component para alterar status de um cycleUser
 * @example <ButtonCycleUser status="status-foo" renderButton={(onClick, loading) => <Button onClick={onClick} /> } />
 */
class ButtonCycleUser extends React.Component {

  componentDidUpdate(prevProps) {
    const {
      requestError,
      reset,
      onError,
      requestSuccess,
      onSuccess
    } = this.props;
    if (requestError && !prevProps.requestError) {
      toast.error(requestError);
      reset();
      onError instanceof Function && onError(requestError);
    }
    if (requestSuccess && !prevProps.requestSuccess) {
      toast.success(requestSuccess);
      reset();
      onSuccess instanceof Function && onSuccess(requestSuccess);
    }
  }

  onClickHandler = () => {
    const { cycleUserId, request, requestLoading, disabled, status } = this.props;
    if (requestLoading || disabled) {
      return;
    }
    request(cycleUserId, status);
  };

  renderButton = () => {
    const { status, requestLoading } = this.props;
    if (requestLoading) {
      return (
        <ButtonsWrapper>
          <button disabled={true}>Aguarde ...</button>
        </ButtonsWrapper>
      );
    }
    return (
      <ButtonsWrapper>
        <button onClick={this.onClickHandler}>Mudar para {status}</button>
      </ButtonsWrapper>
    );
  };

  render() {
    const { renderButton, requestLoading, disabled } = this.props;
    if (renderButton instanceof Function) {
      return renderButton(this.onClickHandler, requestLoading, disabled);
    }
    return this.renderButton();
  }

}

ButtonCycleUser.propTypes = {
  cycleUserId: PropTypes.any.isRequired,
  status: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  request: PropTypes.func.isRequired,
  requestSuccess: PropTypes.any.isRequired,
  requestError: PropTypes.any.isRequired,
  requestLoading: PropTypes.any.isRequired,
  renderButton: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
};

ButtonCycleUser.defaultProps = {
  disabled: false
}

export default ButtonCycleUser;