import React, { Component } from 'react';
import { DescriptiveWrapper } from './styles';

export default class Descriptive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberCharacters: 0,
      isDisabled: true,
    };
  }

  componentDidMount() {
    const { onChange, defaultText } = this.props;
    const { isDisabled } = this.state;

    if (!!defaultText && onChange) {
      this.setState({ numberCharacters: defaultText.length });
      onChange({ text: defaultText }, isDisabled);
    }
  }

  limitCharacter(event) {
    const { onChange, numberCharactersLimit } = this.props;
    const { isDisabled, numberCharacters } = this.state;

    const { value } = event.target;

    if (value.length <= numberCharactersLimit) {
      this.setState({ isDisabled: true });
    } else {
      this.setState({ isDisabled: false });
    }

    this.setState({ numberCharacters: value.length });

    onChange({ text: value }, isDisabled);
  }

  render() {
    const {
      question,
      onChange,
      defaultText,
      isReviewer,
      blockType,
      disabled,
      numberCharactersLimit = 300,
    } = this.props;

    const { numberCharacters } = this.state;

    const numberOfCharacter = numberCharactersLimit - numberCharacters;

    return (
      <DescriptiveWrapper>
        <label>
          {blockType !== 'default' && (
            <span className="title">
              {question.title}
              {isReviewer ? ' (Superior Imediato)' : ' (Empregado)'}
            </span>
          )}
          {blockType === 'default' && (
            <div>
              <span className="title">
                {question.title}
                {isReviewer ? ' (Superior Imediato)' : ' (Empregado)'}
              </span>
              <span className="description"> {question.description}</span>
            </div>
          )}
          <textarea
            required
            disabled={disabled}
            defaultValue={defaultText}
            onChange={e => this.limitCharacter(e)}
          />
          {!disabled && numberOfCharacter >= 0 && (
            <p>
              <strong>{numberCharactersLimit - numberCharacters} </strong> {''}
              caracteres restantes
            </p>
          )}
        </label>
      </DescriptiveWrapper>
    );
  }
}
