import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import Creators from '../../ducks/select/cicles';

export function* requestCicles({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.ciclesClearError());
  yield put(Creators.ciclesLoading());

  try {
    let url = `api/v1/select/cycle`;
    if (!!text) {
      url += '?text=' + text;
    }

    let response = yield call(api.get, encodeURI(url));

    yield put(Creators.ciclesSuccess(response.data.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.ciclesError(error));
      }
    }
    yield put(
      Creators.ciclesError('Ocorreu um erro no servidor, tente mais tarde.')
    );
  }
}

export function* requestResetCicles() {
  yield put(Creators.ciclesReset());
}
