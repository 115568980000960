import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Creators from "../../ducks/select/offices";

export function* requestOffices({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.officesClearError());
  yield put(Creators.officesLoading());

  try {
    let url = `api/v1/select/offices`;
    if (!!text) {
      url += "?text=" + text;
    }

    let response = yield call(api.get, encodeURI(url));

    yield put(Creators.officesSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.officesError(error));
      }
    }
    yield put(
      Creators.officesError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* requestResetOffices() {
  yield put(Creators.officesReset());
}
