import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { prepare } from '../../../services/params';

import { Creators as ReasonsActions } from '../../ducks/reason/reasons';
import { Creators as ErrorActions } from '../../ducks/error';

export function* getReasons(action) {
  try {
    let url = '/api/v1/admin/reasons';

    if (action.payload) {
      const params = [];
      if (action.payload.page) {
        params.push(`page=${action.payload.page}`);
      }
      if (action.payload.text) {
        params.push(`text=${action.payload.text}`);
      }
      if (action.payload.all) {
        params.push(`all=${action.payload.all}`);
      }

      url += prepare(params);
    }

    const response = yield call(api.get, url);
    yield put(ReasonsActions.getReasonsSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
