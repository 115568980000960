import styled from 'styled-components'

export const DetailWrapper = styled.div`

`
export const DetailHeader = styled.div`
  background-color: #3B6AB1;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;

  .back-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 30px;
    cursor:pointer;
    color: #fff;

    span {
      margin-top: 10px;
    }
  }

  .detail-info {
    display: flex;

    > div {
      :first-child {
        margin-right: 20px;
        img {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }

      :nth-child(2) {
        display: flex;
        flex-direction: column;

        span {
          color: #fff;
          font-size:16px;
          :not(:last-child) {
            margin-bottom: 5px;
          }

          :first-child {
            font-size: 26px;
            font-weight: 600;
          }
        }
      }

    }

  }

`