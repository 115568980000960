import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  cycleDataRequest: ["cycleId"],
  cycleDataSuccess: ["cycle"],
  cycleDataError: ["error"],
  cycleDataClearError: null,
  cycleDataLoading: null
});

export const CycleDataTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  cycle: null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { cycle }) => {
  return state.merge({
    cycle,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CYCLE_DATA_SUCCESS]: success,
  [Types.CYCLE_DATA_ERROR]: error,
  [Types.CYCLE_DATA_CLEAR_ERROR]: clearError,
  [Types.CYCLE_DATA_LOADING]: loading
});
