import React from 'react';

import { SubHeaderStyled } from './styles';

function SubHeader(props) {
  const { page, isTemplate } = props;
  const classDisabled = props.id === 'novo' ? 'disable' : undefined;

  const getFirstPath = () => {
    return isTemplate ? 'modelos' : 'formularios';
  };

  return (
    <SubHeaderStyled>
      <ul>
        <li className={page === 'dados' ? 'active' : undefined}>
          <a href={`/${getFirstPath()}/${props.id}/dados`}>Dados</a>
        </li>
        <li className={page === 'questoes' ? 'active' : classDisabled}>
          <a
            href={props.id !== 'novo' ? `/${getFirstPath()}/${props.id}/questoes` : '#'}
          >
            Questões
          </a>
        </li>
        <li className={page === 'destinatarios' ? 'active' : classDisabled}>
          <a
            href={
              props.id !== 'novo' ? `/${getFirstPath()}/${props.id}/destinatarios` : '#'
            }
          >
            Destinatários
          </a>
        </li>
      </ul>
    </SubHeaderStyled>
  );
}

export default SubHeader;
