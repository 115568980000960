import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import AnswerAgreeRevisionActions from './../../../../store/ducks/answer/answerAgreeRevision';

import { UserContentDiv } from '../../../../styles/components';
import Progress from '../../../../Components/UserComponents/Progress';
import AvatarIcon from '../../../../assets/images/ava-desempenho.jpg';

import { Content } from './styles';
import Loading from '../../../../Components/Loading';
import ErrorMsg from '../../../../Components/ErrorMsg';

class RevisaoSupervisor extends React.Component {
  state = {
    onAgreeReview: false,
  };

  onAgreeReview = () => {
    this.props.answerAgreeRevisionRequest(this.props.userCycle.id);
  };

  componentDidUpdate() {
    if (this.props.answerAgreeRevision.success && !this.state.onAgreeReview) {
      this.props.answerAgreeRevisionResetRequest();
      this.setState(
        {
          onAgreeReview: true,
        },
        () => {
          this.props.onAgreeReview();
        }
      );
    }
  }

  componentWillUnmount() {
    this.props.answerAgreeRevisionResetRequest();
  }

  renderActions = () => {
    if (this.props.answerAgreeRevision.loading) {
      return <Loading loading={true} />;
    }
    return this.renderButtons();
  };

  renderError = () => {
    let error = this.props.answerAgreeRevision.error;
    if (!error) {
      return null;
    }
    return <ErrorMsg msg={error} />;
  };

  renderButtons() {
    return (
      <div className="buttons">
        <a
          href="#!"
          onClick={() => {
            this.setState({ redirect: true });
          }}
        >
          Revisar antes de concordar
        </a>
        <a href="#!" onClick={this.onAgreeReview}>
          concordar sem revisar
        </a>
      </div>
    );
  }

  render() {
    let { userCycle } = this.props;
    if (this.state.redirect) {
      return <Redirect to="/user/stage" />;
    }
    return (
      <Fragment>
        <Progress stages={userCycle.stages} />
        <UserContentDiv>
          <Content>
            <div className="title">
              <span>{userCycle.quiz.title}</span>
            </div>
            <div className="header">
              <img src={AvatarIcon} alt="" />
              <span>Avaliação do gestor</span>
            </div>
            <div className="text">
              <p>
                Suas respostas foram revisadas e editadas pelo seu superior
                imediato. Revise o formilário e indique se concorda ou não com
                as alterações realziadas. <br />
                As respostas revisadas estarão marcadas em <b>VERDE</b>, e as
                suas em <b>AZUL</b>.
              </p>
            </div>
            <div className="notas">
              <div>
                <span>Resposta revisada</span>
                <div className="bloco revisada">
                  <div className="number">7</div>
                </div>
              </div>
              <div>
                <span>Sua resposta</span>
                <div className="bloco">
                  <div className="number">7</div>
                </div>
              </div>
            </div>
            {this.renderActions()}
            {this.renderError()}
          </Content>
        </UserContentDiv>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AnswerAgreeRevisionActions }, dispatch);

const mapStateToProps = state => ({
  answerAgreeRevision: state.answerAgreeRevision,
});

export default connect(mapStateToProps, mapDispatchToProps)(RevisaoSupervisor);
