import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import QuizByCycleActions from "../../ducks/quiz/quizByCycle";

export function* quizByCycleData({ cycleId }) {
  yield put(QuizByCycleActions.quizByCycleClearError());
  yield put(QuizByCycleActions.quizByCycleLoading());
  try {
    let url = `api/v1/admin/quizzes/?all=true&cycle_id=${cycleId}`;
    let result = yield call(api.get, url);
    yield put(QuizByCycleActions.quizByCycleSuccess(result.data.details));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            QuizByCycleActions.quizByCycleError(response.messages[0])
          );
        }
      }
    }
    yield put(
      QuizByCycleActions.quizByCycleError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
