import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Actions from '../../store/ducks/userCycles/userCycles';
import UserCycles from './UserCycles';

const mapDispatchToProps = dispatch => bindActionCreators({
  request: Actions.userCyclesRequest,
  clear: Actions.userCyclesReset
}, dispatch);

const mapStateToProps = state => ({
  page: state.userCycles.page,
  lastPage: state.userCycles.lastPage,
  error: state.userCycles.error,
  success: state.userCycles.success,
  loading: state.userCycles.loading,
  data: state.userCycles.data
});


export default connect(mapStateToProps, mapDispatchToProps)(UserCycles);
