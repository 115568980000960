import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import CycleQuizzesFormActions from '../../ducks/cycle/cycleQuizzesForm';
import { parseErrorResponse } from '../../../utils/FormErrorResponse';
import { toast } from "react-toastify";

export function* updateCycleQuizzes({ payload }) {
  yield put(CycleQuizzesFormActions.cycleQuizzesFormClearError());
  yield put(CycleQuizzesFormActions.cycleQuizzesFormLoading());
  try {
    let result = yield call(
      api.put,
      `api/v1/admin/cycles/${payload.cycleId}/quizzes`,
      {
        template_to_add: payload.templates_to_add,
        forms_to_remove: payload.forms_to_remove,
      }
    );
    yield put(
      CycleQuizzesFormActions.cycleQuizzesFormSuccess(result.data.messages[0])
    );
  } catch (e) {
    if (!!e && !!e.request) {
      let request = e.request;
      if (request.status === 403) {
        let error = parseErrorResponse(request);
        toast.error(error.title);
        return yield put(CycleQuizzesFormActions.cycleQuizzesFormError(error));
      }
    }
    yield put(
      CycleQuizzesFormActions.cycleQuizzesFormError(
        'Ocorreu um erro no servidor, tente mais tarde.'
      )
    );
  }
}

export function* resetStatusCycleQuizzesForm() {
  yield put(CycleQuizzesFormActions.cycleQuizzesFormResetStatus());
}
