import React from "react";
import {Link } from "react-router-dom";


export default class Logout extends React.Component {
  render() {
    return (
      <Link to='/logout' style={{color: '#666666'}}>
        {this.props.renderItem}
      </Link>
    );
  }
}
