import styled from 'styled-components';

export const DivTabs = styled.div`
  .react-tabs {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

      li {
        display: flex;
        a {
          padding: 0 40px;
          display: flex;
          align-items: center;
          color: #666666;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 16px;
        }

        :not(:last-child) {
          margin-right: 20px;
        }

        &.react-tabs__tab--selected {
          color: #00468e;
          border-bottom: 6px solid #00468e;
          padding-top: 6px;
        }
      }
    }
  }
`;

export const CyclesWrapper = styled.div`
  background-color: #fff;
  border-top-left-radius: ${props => (props.novo ? '5px' : '0px')};
  border-top-right-radius: ${props => (props.novo ? '5px' : '0px')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 30px 45px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);

  > div {
    margin-bottom: 20px;
  }
`;
