import styled, {css} from 'styled-components';

export const ErrorStyled = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #fc0204;
  border-radius: 5px;
  margin-bottom: 10px;
  span {
    color: #fff;
    font-size: 14px;
  }

  ${props =>
    props.isWarning &&
    css`
      background-color: #fffbea;
      border: 1px solid #fdd13a;
      > span {
        color: #46453f;
      }
    `}
`;
