import React, { Component, Fragment } from 'react';
import shave from '../../../../services/shave';
import Modal from 'react-responsive-modal';
import {
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../../Components/Table/styles';
import { ModalDefaultInner, WarningMessage } from '../../../../styles/global';

import { ResponsiveWrapper } from '../styles';

export default class TableSelecionados extends Component {
  getModalTitle = () => {
    if (this.props.rolesDenied.length === 1) {
      return 'Você não pode excluir o cargo a seguir por já terem respostas nos questionários referentes a este cargo:';
    } else {
      return 'Você não pode excluir os cargos a seguir por já terem respostas nos questionários referentes a estes cargos:';
    }
  };

  handleChangeInput = e => {
    this.props.onChange(e.target.value, 'selecionados');
  };

  render() {
    const { selecionados, showDeniedModal, rolesDenied } = this.props;
    return (
      <Fragment>
        <Modal
          open={showDeniedModal && rolesDenied.length > 0}
          onClose={() => this.props.onCloseModal()}
          styles={{
            modal: {
              borderRadius: '5px',
              width: '800px',
              padding: '20px',
            },
          }}
          showCloseIcon={false}
        >
          <ModalDefaultInner>
            <Fragment>
              <h1 style={{ marginBottom: '20px' }}>{this.getModalTitle()}</h1>
              <div>
                <TableDiv style={{ marginTop: '10px' }}>
                  <Thead>
                    <Tr>
                      <th style={{ paddingLeft: '10px' }} />
                      <th>ID</th>
                      <th>Cargo</th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {rolesDenied.map(r => (
                      <Tr key={'sel-' + r.id}>
                        <td style={{ paddingLeft: '10px' }} />
                        <td>{r.id}</td>
                        <td>{r.name}</td>
                      </Tr>
                    ))}
                  </Tbody>
                </TableDiv>
                <div
                  className="btns-wrapper"
                  style={{ justifyContent: 'space-between' }}
                >
                  <div />
                  <button
                    type="button"
                    onClick={() => this.props.onCloseModal()}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </Fragment>
          </ModalDefaultInner>
        </Modal>
        {!this.props.editable && !this.props.isTemplate && (
          <WarningMessage>
            Este formulário já foi respondido e por esse motivo alguns cargos
            não podem ser removidos.
          </WarningMessage>
        )}

        <ResponsiveWrapper>
          <TableDiv style={{ marginTop: '10px' }}>
            <Thead>
              <Tr>
                <th></th>
                <th style={{ padding: '0 10px' }}>ID</th>
                <th>Cargo</th>
                <th className="hideResponsive">Descrição</th>
                <th className="hideResponsive">Status</th>
              </Tr>
            </Thead>
            <Tbody>
              {selecionados.map(r => (
                <Tr key={'sel-' + r.id}>
                  <td>
                    <input
                      id={`role-sel-${r.id}`}
                      type="checkbox"
                      value={r.id}
                      checked={true}
                      onChange={this.handleChangeInput}
                    />
                  </td>
                  <td style={{ padding: '0 10px' }}>{r.id}</td>
                  <td>{r.name}</td>
                  <td className="hideResponsive">{shave(r.description, 50)}</td>
                  <td className="hideResponsive">{r.status}</td>
                </Tr>
              ))}
            </Tbody>
          </TableDiv>
        </ResponsiveWrapper>
      </Fragment>
    );
  }
}
