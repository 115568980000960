import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as InfoActions } from '../../../ducks/modules/courses/info';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* getCourse(action) {
  try {
    const response = yield call(
      api.get,
      '/api/v1/courses/' + action.payload.id
    );
    yield put(InfoActions.getCourseSuccess(response.data.details));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
