import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import { Creators as EnrollmentActions } from "../../../../../store/ducks/modules/courses/enroll";
import { Creators as EnrollmentInProgressActions } from "../../../../../store/ducks/modules/courses/enrollmentInProgress";

import RatingStars from "../../../../../Components/RatingStars";
import Loading from "../../../../../Components/Loading";
import {
  BoxWhiteRounded,
  ModalDefaultInner
} from "../../../../../styles/global";
import { HeaderStyled } from "../../../../../pages/User/Components/Headers/styles";

class CourseHeader extends Component {
  state = {
    offer: null,
    isModalOpen: false,
    rotaScorm: null
  };

  static getDerivedStateFromProps(props, state) {
    state.offer = props.offer;
    return state;
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  enrollOnOffer() {
    let offerId = this.state.offer.id;
    const { postEnrollRequest } = this.props;
    postEnrollRequest(offerId);
  }

  componentDidUpdate(prevProps, prevState) {
    const { offer } = this.props;
    if (this.props.enroll.enrolled || this.props.enroll.error) {
      this.props.resetEnrollRequest();
    }
    if (this.props.enroll.enrolled && !prevProps.enroll.enrolled) {
      toast.success(this.props.enroll.success);
      this.setState({ isModalOpen: false });
      this.props.history.push(
        `/usuario/curso/conteudo/${offer.course_id}/oferta/${
          offer.id
        }/matricula/${this.props.enroll.data.id}/topico/initial/rating/${
          offer.course.courseRatings[0].id
        }/rever/false`
      );
    }
    if (this.props.enroll.error && !prevProps.enroll.error) {
      toast.error(this.props.enroll.error);
    }
  }

  renderModalAction = () => {
    if (this.props.enroll.loading) {
      return <Loading loading={true} />;
    }
    return (
      <div className="btns-wrapper">
        <Link to="#!" onClick={this.handleCloseModal}>
          Não
        </Link>
        <Link to="#!" onClick={() => this.enrollOnOffer()}>
          Sim
        </Link>
      </div>
    );
  };

  renderModal = () => {
    const { title } = this.props;
    const { isModalOpen } = this.state;
    return (
      <Modal
        open={isModalOpen}
        onClose={() => this.handleCloseModal}
        styles={{
          modal: {
            borderRadius: "5px",
            width: "500px",
            padding: "0"
          }
        }}
        showCloseIcon={false}
      >
        <ModalDefaultInner>
          <h1>Gostaria de confirmar sua inscrição no curso {title}</h1>
          {this.renderModalAction()}
        </ModalDefaultInner>
      </Modal>
    );
  };

  renderButtonCourse() {
    const {
      enrollmentSuccess,
      enrollmentProgress,
      offer
    } = this.props;
    if (enrollmentSuccess) {
      return (
        <Link
          to={`/usuario/curso/conteudo/${offer.course_id}/oferta/${
            offer.id
          }/matricula/${offer.currentEnrollment.id}/topico/initial/rating/${
            offer.course.courseRatings[0].id
          }/rever/true`}
        >
          Rever
        </Link>
      );
    }

    if (enrollmentProgress) {
      return (
        <Link
          to={`/usuario/curso/conteudo/${offer.course_id}/oferta/${
            offer.id
          }/matricula/${offer.currentEnrollment.id}/topico/initial/rating/${
            offer.course.courseRatings[0].id
          }/rever/false`}
        >
          Continuar
        </Link>
      );
    }

    return (
      <Link to="#!" onClick={() => this.setState({ isModalOpen: true })}>
        Iniciar o Curso
      </Link>
    );
  }

  render() {
    const { title, labelTitle, rating } = this.props;
    return (
      <BoxWhiteRounded>
        <HeaderStyled isCourse>
          <div>
            <RatingStars color="#F03B42" fontSize="12px" rating={rating} />
            <span className="label-title">{labelTitle}</span>
            <div className="title-wrapper">
              <h1>{title}</h1>
            </div>
          </div>

          <div className="access-course-wrapper">
            {this.renderButtonCourse()}
          </div>
        </HeaderStyled>
        {this.renderModal()}
      </BoxWhiteRounded>
    );
  }
}

const mapStateToProps = state => ({
  enroll: state.enroll,
  enrollmentInProgress: state.enrollmentInProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...EnrollmentActions, ...EnrollmentInProgressActions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseHeader);
