import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #dddddd;

  h1 {
    color: #00468e;
    font-family: "Open Sans";
    height: 30px;
    width: 380px;
    line-height: 30px;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
`;

export const MainBox = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 5%;

  :first-child {
    width:100%;
    margin-right:0;
  }

  .title {
    font-size: 18px;
    color: #00468e;
    text-transform:uppercase;
    display: flex;
    margin-bottom:10px;
  }

  .boxes {
    display: flex;
    flex: 1;
  }

  .box-item {
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background-color: #467CC2;
    color: #fff;
    height: 90px;
    padding: 10px;
    flex: 1;
    min-width: 240px;
    max-width: 400px;

    :not(:last-child) {
      margin-right: 15px;
    }

    .box-title {
      font-size: 18px;
      font-weight: 400;
    }

    .amount {
      font-size:28px;
      font-weight: bold;
    }

    @media screen and (max-width: 1270px) {
      min-width: auto;
      height:auto;

      .box-title {
        font-size: 14px;
      }
    }
  }

`;
