import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { prepare } from '../../../services/params';

import { Creators as RolesActions } from '../../ducks/role/roles';
import { Creators as ErrorActions } from '../../ducks/error';

export function* getRoles({ payload }) {
  try {
    let url = '/api/v1/admin/roles';

    if (payload) {
      const params = [];
      if (payload.page) {
        params.push(`page=${payload.page}`);
      }
      if (payload.text) {
        params.push(`text=${payload.text}`);
      }
      if (payload.usable) {
        params.push(`usable=${payload.usable}`);
      }
      if (payload.quizId) {
        params.push(`quizId=${payload.quizId}`);
      }
      if (payload.paginate) {
        params.push(`paginate=${payload.paginate}`);
      }
      if (payload.status) {
        params.push(`status=${payload.status}`);
      }
      if (payload.ignoreRoles) {
        params.push(`ignoreRoles=${payload.ignoreRoles}`);
      }

      url += prepare(params);
    }

    const response = yield call(api.get, url);
    yield put(RolesActions.getRolesSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
