import React from "react";

import { ButtonDiv } from "./styles";

const Button = props => (
  <ButtonDiv>
    <button type="button">anterior</button>
    <button type="submit" disabled={props.disabled}>
      próximo
    </button>
  </ButtonDiv>
);

export default Button;
