import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  overflow-y: scroll;
`;
export const Content = styled.div`
  display: flex;
  margin: 10px 25px;
  border-radius: 5px;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
  min-height: 250px;
  padding-top: 20px;
  align-items: center;
  justify-items: center;
`;

export const ContentOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: #ccc 2px solid;

  span {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    margin-right: 90px;
    margin-bottom: 15px;
  }
`;

export const SearchDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  height: 100px;
  padding: 0 25px;
  margin-top: 10px;

  label {
    color: #666666;

    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .input {
    display: flex;
    justify-content: space-between;
    border: #ccc 1px solid;
    border-radius: 4px;

    input {
      color: #666666;
      padding-left: 5px;
      border: 0;
    }

    img {
      height: 40px;
      width: 40px;
    }
  }
`;

export const Items = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 25px;

  span {
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  button {
    height: 40px;
    width: 230px;
    background-color: #00468e;
    border: 0;
    border-radius: 4px;

    a {
      font-size: 16px;
      line-height: 22px;
      color: #fff;
      text-transform: uppercase;
    }
  }
`;

export const Table = styled.table`
  display: table;
  margin: 0 25px 50px;
  background-color: #fff;

  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: middle;
  border-color: inherit;

  th {
    display: table-cell;
    text-align: center;
    vertical-align: inherit;
    margin: 0;
    padding: 0;
    height: 40px;
    background-color: #e7e7e7;
    color: #666666;
    font-size: 14px;

    img {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }

    input:hover {
      cursor: pointer;
    }
  }
`;

export const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  border-spacing: 0px;
  border-collapse: separate;

  tr {
    display: table-row;
    vertical-align: middle;
    border-color: inherit;

    td {
      height: 50px;

      border-bottom: #ccc solid 1px;
      font-size: 14px;
      color: #666666;
      padding-left: 25px;
      vertical-align: left;
    }
  }
`;
