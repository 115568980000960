import React, { Component } from 'react';
import { TreeWrapper } from './styles';
import QuestionTopic from './QuestionTopic';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../store/ducks/questionCreation/questionCreation';
import QuestionAddBtn from '../../Components/QuestionAddBtn';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import QuestionDragHandle from '../../Components/QuestionDragHandle';

class QuestionTree extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    hidePopupBtn(false);
    if (oldIndex === newIndex) return false;
    this.props.questionOrderStage(oldIndex - 1, newIndex - 1);
  };

  render() {
    const { topicos, editable } = this.props;
    return (
      <TreeWrapper>
        {topicos.length > 0 && (
          <SortableList
            onSortEnd={this.onSortEnd}
            onSortStart={() => {
              hidePopupBtn(true);
            }}
            items={topicos}
            useDragHandle
            lockAxis="y"
            lockToContainerEdges={true}
            helperClass="helper-drag-class"
            disableAutoscroll={true}
            editable={editable}
          />
        )}
      </TreeWrapper>
    );
  }
}

const SortableItem = SortableElement(({ stage, editable }) => {
  return (
    <div
      key={stage.uid}
      id={`stage-wrapper-${stage.uid}`}
      className={stage.isNew ? 'active' : ''}
    >
      <QuestionTopic
        {...stage}
        dragHandle={<QuestionDragHandle />}
        hidePopupBtn={hidePopupBtn}
        editable={editable}
      />
      {editable && (
        <div className="question-add-wrapper">
          <QuestionAddBtn etapa={2} uidStage={stage.uid} />
        </div>
      )}
    </div>
  );
});

const SortableList = SortableContainer(({ items, editable }) => {
  return (
    <div className="topics-wrapper">
      {items.length > 0 &&
        items.map(stage => (
          <SortableItem
            stage={stage}
            key={stage.uid | 0}
            index={stage.ordem}
            editable={editable}
          />
        ))}
    </div>
  );
});

function hidePopupBtn(op) {
  let btns = document.getElementsByClassName('question-add-wrapper');

  if (op) {
    for (let index = 0; index < btns.length; index++) {
      const element = btns[index];
      element.classList.add('hidden');
    }
  } else {
    for (let index = 0; index < btns.length; index++) {
      const element = btns[index];
      element.classList.remove('hidden');
    }
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions }, dispatch);

export default connect(null, mapDispatchToProps)(QuestionTree);
