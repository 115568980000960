import React, { Fragment } from 'react';

import { Head, Right, Notification, Avatar } from './styles';

import NotificationIcon from '../../assets/images/notification.svg';
import AvatarIcon from '../../assets/images/avatar.jpg';
import DrawerToggleButton from '../SideDrawer/ToggleButton';
import MenuIcon from '../../assets/images/menu.svg';

import Backdrop from '../SideDrawer/BackDrop';
import SideDrawer from '../SideDrawer';

class Header extends React.Component {
  state = {
    SideDrawerOpen: false,
    pathname: '',
  };

  componentDidMount() {
    this.setState({ title: '' });
  }

  componentDidUpdate() {
    const { pathname } = this.props.location;
    if (pathname !== this.state.pathname) {
      this.setState({ pathname });
      this.props.setTitle('');
    }
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { SideDrawerOpen: !prevState.SideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ SideDrawerOpen: false });
  };

  renderTitle = pathname => {
    if (this.props.title) {
      return this.props.title;
    }

    const path = pathname.split('/')[1];
    const path2 = pathname.split('/')[2];
    switch (path) {
      case 'ciclos':
        return 'Ciclos';
      case 'modelos':
        return 'Modelos de formulários';
      case 'formularios':
        return 'Formulário';
      case 'relatorios':
        return 'Relatórios';
      case 'emails':
        return 'E-mails';
      case 'dadosgerais':
        if (path2) {
          if (path2 === 'usuarios') {
            return 'Usuários';
          } else if (path2 === 'cargos') {
            return 'Cargos';
          } else if (path2 === 'justificativas') {
            return 'Justificativas';
          }
        }
        return 'Dados gerais';
      case 'gestor':
        if (!path2) return 'Meus Colaboradores';
        if (path2 === 'notificacao') return 'Notificações';
      default:
        break;
    }
  };

  render() {
    const { pathname } = this.props.location;

    const routesToHide = ['home', 'gestor/ciclo-usuario/'];
    const hideHeader = routesToHide.some(p => pathname.includes(p));

    return (
      <Fragment>
        {!hideHeader ? (
          <Head>
            {this.props.isAdmin && (
              <Fragment>
                <DrawerToggleButton
                  img={MenuIcon}
                  click={this.drawerToggleClickHandler}
                />
                {this.state.SideDrawerOpen && (
                  <Fragment>
                    <SideDrawer />
                    <Backdrop click={this.backDropClickHandler} />
                  </Fragment>
                )}
              </Fragment>
            )}

            <h1> {this.renderTitle(pathname)} </h1>
          </Head>
        ) : (
          <Head removeHeight={true}>
            <Fragment>
              <DrawerToggleButton
                img={MenuIcon}
                click={this.drawerToggleClickHandler}
              />
              {this.state.SideDrawerOpen && (
                <Fragment>
                  <SideDrawer />
                  <Backdrop click={this.backDropClickHandler} />
                </Fragment>
              )}
            </Fragment>
          </Head>
        )}
      </Fragment>
    );
  }
}

export default Header;
