import React from 'react';
import { WrapperStyled, ButtonStyled, PopupStyled } from './styles';
import IconAdd from '../../assets/images/questoes/ico-adicionar.svg';
import PopupOptions from '../../containers/QuestionAddPopup';

export default function QuestionAddBtn({
  startOpen,
  etapa,
  uidStage,
  uidBlock,
  createItem,
  ...props
}) {
  return (
    <WrapperStyled {...props}>
      <ButtonStyled type="button">
        <img src={IconAdd} alt="adicionar" />
      </ButtonStyled>
      <PopupStyled startOpen={startOpen} etapa={etapa}>
        {renderArrow()}
        <ul>
          <PopupOptions etapa={etapa} uidStage={uidStage} uidBlock={uidBlock} />
        </ul>
      </PopupStyled>
    </WrapperStyled>
  );
}

function renderArrow() {
  return (
    <div className="arrow-wrapper">
      <div>
        <div />
      </div>
    </div>
  );
}
