import styled from 'styled-components'

export const SingleChoiceWrapper = styled.div`
  margin-bottom: 30px;
  border: 1px solid #666;
  border-radius: 5px;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  padding: 10px;

  .header {
    flex: 1;
    padding-right: 10px;
  }

  .choices {
    flex: 1;
    display: flex;
    width: 100%;
    max-width: 200px;

    >div {
      flex: 1;
      cursor: pointer;
      background: #E5ECF3;
      color: #00468E;
      border: 1px solid #00468E;
      height: 100%;
      min-height: 80px;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      :first-child {
        border-right: 0;
      }
      :last-child {
        border-left: 0;
      }
      :nth-child(2) {
        border-left: 1px solid;
      }

      &.active {
        background-color: #195899;
        color: #fff;
      }
      &.active-reviwer {
        background-color: #009b4c;
        color: #fff;
      }
    }
  }
`
