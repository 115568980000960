import styled from 'styled-components'

export const DescriptiveWrapper = styled.div`
  margin-bottom: 30px;

  label {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 16px;
      text-transform: uppercase;
    }

    .description {
      font-size: 14px;
      font-weight: 100;
    }
    span {
      color: #666666;
      margin-bottom: 10px;
    }
  }

  textarea {
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    font-weight: normal;
    min-height: 100px;
    resize: none;
  }
`
