import React, { useState } from "react";

import Switch from "react-switch";
import CancelIcon from "../../../../assets/images/error.svg";
import MenuIcon from "../../../../assets/images/menu.svg";
import { Atividade } from "./styles";

export default function AtividadeFluxo() {
  const [isAcaoActive, toggleAcao] = useState(false);

  return (
    <Atividade>
      <div className="atv-inner">
        <div className="atv-header">
          <input type="text" placeholder="Nome da atividade" />

          <div className="icons">
            <img src={MenuIcon} alt="Menu" />
            <img src={CancelIcon} alt="Cancelar" />
          </div>
        </div>
        <div className="atv-tipo">
          <span>Tipo da atividade:</span>
          <span>Tipo da atividade selecionada</span>
        </div>

        <div className="atv-acoes">
          <span>Ações</span>
          <Switch
            onChange={() => toggleAcao(!isAcaoActive)}
            checked={isAcaoActive}
            onColor="#467CC2"
          />
        </div>

        {isAcaoActive && <h1>true</h1>}
      </div>
    </Atividade>
  );
}
