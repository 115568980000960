import styled from 'styled-components'

export const DivQuiz = styled.div`
  background: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`

export const DivTabs = styled.div`


  .react-tabs {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);


      li {
        display: flex;
        a {
          padding: 0 40px;
          display: flex;
          align-items: center;
          color: #666666;
          cursor: pointer;
          text-transform: uppercase;
          font-size: 16px;
        }

        :not(:last-child) {
          margin-right: 20px;
        }

        &.react-tabs__tab--selected {
          color: #00468E;
          border-bottom: 6px solid #00468E;
          padding-top: 6px;
        }
      }
    }

  }
`

export const DivClose = styled.div`
  float: right;
`