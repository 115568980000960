import styled from "styled-components";

export const Button = styled.button`
  height: 100%;
  width: 35px;
  background-color: #000;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 13px;

    img {
      height: 25px;
      width: 25px;

      :hover {
        height: 35px;
        width: 35px;
        margin-left: 5px;
      }
    }
  }

  display: none;

  background-color: transparent;
  border: 0;
  cursor: pointer;

  padding: 0;
  box-sizing: border-box;

  :focus {
    outline: 0;
  }

  .toggleButtonLine {
    width: 35px;
    height: 2px;
    background-color: #000;
  }
`;
