import styled from 'styled-components';

export const Content = styled.div``;

export const Form = styled.form`
  margin-top: 15px;
  align-items: center;

  .form-inner {
    padding: 50px 0 80px 0;
    background-color: #fff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Open Sans';
    width: 100%;
    display: flex;

    .form-inner-content {
      margin-left: 100px;

      @media (max-width: 650px) {
        margin-left: 20px;
      }
      @media (max-width: 1000px) {
        margin-left: 50px;
      }
      @media (min-width: 1300px) {
        margin-left: 10%;
      }
      @media (min-width: 1500px) {
        margin-left: 250px;
      }

      width: 80%;
    }
  }

  div .formControl {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin-bottom: 15px;
  }

  input:not([type='date']),
  textarea {
    padding: 10px;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  font-size: 18px;
  color: #666666;
  margin-bottom: 5px;

  :last-child {
    margin-right: 64px;
  }
`;

export const Field = styled.div`
  margin-bottom: 20px;
`;

export const TextArea = styled.textarea`
  border-radius: 4px;
  height: 80px;
  border: #ccc solid 1px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666666;
  width: 100%;
  font-weight: normal;
  ::placeholder {
    color: rgba(6, 6, 6, 0.4);
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;

  button {
    margin: 0;
    border: 0;
    padding: 0;
    text-transform: uppercase;

    a {
      text-decoration: none;
      color: #fff;

      background-color: #00468e;
      height: 40px;
      width: 160px;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
    :first-child {
      margin-right: 50px;
      background-color: #999999;
      height: 40px;
      width: 160px;
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      border-radius: 5px;
      font-family: 'Open Sans';
    }
  }
`;

export const Dates = styled.div`
  .bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .inputDate {
      border: #ccc 1px solid;
      border-radius: 4px;
      max-width: 160px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }
      input {
        flex: 1;
        border: 0;
        margin-left: 5px;
        max-width: 160px;
      }
    }
  }
`;
