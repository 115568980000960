import styled from 'styled-components'

export const DropdownMenuItemStyled = styled.li`
 display: flex;
  padding-right: 25px;
  min-height: 40px;
  position: relative;
  padding-bottom: 25px;
  cursor: pointer;
  :hover {
    .left-side {
      .ball-wrapper {
        .ball {
          background-color: #03a954;
        }
      }
    }
    .right-side {
      span {
        opacity: 0.8;
      }
    }
  }

  .left-side {
    padding-left: 25px;
    margin-right: 25px;

    .ball-wrapper {
      border: 1px solid #DDD;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      z-index: 1;
      position: absolute;
      background-color: #FFF;

      .ball {
        width: 14px;
        height: 14px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: ${props => props.isActive || props.completed ? '#009B4C' : 'transparent'}
      }
    }

    .stroke {
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: #DDD;
      top: 0;
      left: 35px;
      z-index: 0;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;

    span {
      font-size: 16px;
      font-family: "OS Bold", sans-serif;
      color: ${props => props.isActive ? '#009B4C' : '#666'}
    }
  }

  :first-child {
    .stroke {
      top: 20px;
    }
  }

  :last-child {
    .stroke {
      height: 0;
    }
  }
`
