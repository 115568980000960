import React from 'react';

import { Nav, Logo } from './styles';

import HomeIcon from '../../assets/images/home.svg';
import CheckIcon from '../../assets/images/Check.svg';
import CicloIcon from '../../assets/images/ico-ciclos.svg';
import RelatorioIcon from '../../assets/images/Relatorio.svg';
import ConfigIcon from '../../assets/images/Config.svg';
import LogoEmae from '../../assets/images/logo-emae.png';

const SideDrawer = props => {
  return (
    <Nav>
      <Logo>
        <img src={LogoEmae} alt="emae" />
      </Logo>
      <ul>
        <li>
          {' '}
          <a href="/home">
            <img src={HomeIcon} alt="home" /> Home
          </a>
        </li>
        <li>
          <a href="/ciclos/">
            <img src={CicloIcon} alt="home" /> ciclos
          </a>
        </li>
        <li>
          <a href="/modelos">
            <img src={CheckIcon} alt="home" /> formulários
          </a>
        </li>
        <li>
          <a href="/relatorios">
            <img src={RelatorioIcon} alt="home" /> relatórios
          </a>
        </li>
        <li>
          <a href="/dadosgerais/usuarios">
            <img src={ConfigIcon} alt="home" /> dados gerais
          </a>
        </li>
      </ul>
    </Nav>
  );
};

export default SideDrawer;
