import styled, { css } from 'styled-components';

export const WrapperStyled = styled.div`
  position: relative;
  width: 240px;

  &:hover {
    > div {
      display: block;
    }
  }
`;

export const ButtonStyled = styled.button`
  background: transparent;
  border: 0;
  width: 22px;
  display: block;
  margin: 0 auto;
  cursor: default;

  > img {
    width: 22px;
  }
`;

export const PopupStyled = styled.div`
  background-color: #fafafa;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* display: ${props => (!props.isOpen ? 'none' : null)}; */
  position: absolute;
  z-index: 10;
  display: ${props => (props.startOpen ? 'block' : 'none')};

  > .arrow-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    > div {
      position: absolute;
      width: 40px;
      height: 17px;
      overflow: hidden;

      > div {
        width: 15px;
        height: 15px;
        transform: translate(12px, 9px) rotate(45deg);
        background: #fafafa;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
      }
    }
  }

  ${props =>
    props.etapa === 1 &&
    css`
      transform: translate(0px, 16px);
      > .arrow-wrapper > div {
        transform: translate(0px, -17px) rotate(0deg);
      }
    `};

  ${props =>
    props.etapa === 2 &&
    css`
      transform: translate(0px, -120px);
      > .arrow-wrapper > div {
        transform: translate(0px, 85px) rotate(180deg);
      }
    `};

  ${props =>
    props.etapa === 3 &&
    css`
      transform: translate(0px, -164px);

      > .arrow-wrapper > div {
        transform: translate(0px, 128px) rotate(180deg);
      }
    `};

  > ul {
    list-style: none;
    width: 100%;
    margin: 0;

    > li {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      > img {
        width: 22px;
        margin-right: 15px;
      }

      > span {
        font-size: 14px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }
`;
