import styled from 'styled-components'

export const CourseContentTopicsList = styled.ul`

  li {
    display: flex;

    .marker-wrapper {
      flex: 1;
      display: block;
      position: relative;
      margin: 0 10px;

      .marker {
        margin-top: 15px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 2px solid #d8d8d8;
        z-index: 2;
        position: absolute;
      }

      .reta {
        background-color: #d8d8d8;
        width: 2px;
        height: 100%;
        position: absolute;
        z-index: 1;
        left: 10px;
      }
    }

    .info-and-seta-wrapper {
      flex: 10;
      display: flex;
      justify-content: space-between;
      padding: 15px 0;

      .info-wrapper {
        display: flex;
        flex-direction: column;
        flex: 10;

        span {
          font-size: 14px;
          color: #4a4a4a;
          :first-child {
            font-family: "OS Bold", sans-serif;
          }
          :nth-child(2) {
            color: #9b9b9b;
            margin-top: 5px;
            font-size: 12px
          }
        }
      }

      .seta-wrapper {
        display: flex;
        align-items: center;
        padding-right: 15px;
      }
    }

    :not(:last-child) {
      .info-and-seta-wrapper {
        border-bottom: 1px solid #d8d8d8;
      }
    }

    :first-child {
      .reta {
        top: 15px;
        height: calc(100% - 10px);
      }
    }

    :last-child {
      .reta {
        height: 20px;
      }
    }
  }
`;
