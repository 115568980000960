import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { Div } from '../../styles/global';
import { TabWrapper, Title } from './styles';

import ColaboradorRelatorio from './colaborador';
import DepartamentoRelatorio from './departamento';

export default class Relatorios extends Component {
  constructor(props) {
    super(props);
    this.tabs = React.createRef();
  }

  state = {
    ready: true,
    currentWidth: 0,
  };

  componentDidMount() {
    this.setState({ currentWidth: this.tabs.current.offsetWidth - 5 });
  }

  componentDidUpdate() {
    if (this.state.currentWidth !== this.tabs.current.offsetWidth - 5)
      this.setState({ currentWidth: this.tabs.current.offsetWidth - 5 });
  }

  render() {
    return (
      <Div>
        <Title>
          <span>Gere relatórios de competência.</span>
        </Title>
        <TabWrapper>
          <Tabs>
            <div ref={this.tabs}>
              <TabList className="tablist">
                <Tab>Colaborador</Tab>
                <Tab>Departamento</Tab>
              </TabList>
            </div>
            <TabPanel>
              <ColaboradorRelatorio currentWidth={this.state.currentWidth} />
            </TabPanel>
            <TabPanel>
              <DepartamentoRelatorio currentWidth={this.state.currentWidth} />
            </TabPanel>
          </Tabs>
        </TabWrapper>
      </Div>
    );
  }
}
