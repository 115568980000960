import styled from 'styled-components'

export const UserContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 20px;
`

export const UserPageTitle = styled.h1`
  font-size: 18px;
  color: #00468E;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-top: 10px;
`
export const UserPageSubtitle = styled.p`
  padding-left: 10px;
  color: #666666;
`
