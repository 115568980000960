import React, { Component } from 'react';
import Collapsible from '../../../Components/Collapsible';
import { FormInputLabelled } from '../../../Components/FormInputs';
import QuestionGroup from '../QuestionGroup';
import { GroupsWrapperStyled } from './styles';
import IconFlag from '../../../assets/images/questoes/ico-flag.svg';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../../store/ducks/questionCreation/questionCreation';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import QuestionDragHandle from '../../../Components/QuestionDragHandle';

class QuestionTopic extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.hidePopupBtn(false);
    if (oldIndex === newIndex) return false;
    this.props.questionOrderBlock(oldIndex - 1, newIndex - 1, this.props.uid);
  };

  changeCollOpen = op => {
    const { uid } = this.props;
    const el = document.getElementById(`stage-wrapper-${uid}`);
    if (op) el.classList.add('active');
    else el.classList.remove('active');
  };

  render() {
    const {
      uid,
      blocks,
      title,
      questionChangeStageTitle,
      questionRemoveStage,
      dragHandle,
      hidePopupBtn,
      editable,
      isNew,
    } = this.props;
    return (
      <Collapsible
        title={'Tópico'}
        icon={IconFlag}
        onRemove={() => questionRemoveStage(uid)}
        dragHandle={dragHandle}
        verifyCollOpen={this.changeCollOpen}
        editable={editable}
        defaultOpen={isNew ? true : false}
      >
        <FormInputLabelled
          label="Título do tópico"
          labelSecondary
          onChange={e => questionChangeStageTitle(e.target.value, uid)}
          style={{ marginBottom: 30 }}
          defaultValue={title}
          disabled={!editable}
        />

        {blocks && (
          <SortableList
            onSortEnd={this.onSortEnd}
            onSortStart={() => this.props.hidePopupBtn(true)}
            items={blocks}
            uidStage={uid}
            useDragHandle
            lockAxis="y"
            lockToContainerEdges={true}
            disableAutoscroll={true}
            hidePopupBtn={hidePopupBtn}
            helperClass="helper-drag-class"
            editable={editable}
          />
        )}
      </Collapsible>
    );
  }
}

const SortableItem = SortableElement(
  ({ block, uidStage, hidePopupBtn, editable }) => {
    return (
      <QuestionGroup
        {...block}
        uidStage={uidStage}
        key={block.uid}
        dragHandle={<QuestionDragHandle />}
        hidePopupBtn={hidePopupBtn}
        editable={editable}
        required={false}
      />
    );
  }
);

const SortableList = SortableContainer(
  ({ items, uidStage, hidePopupBtn, editable }) => {
    return (
      <GroupsWrapperStyled className="blocks-wrapper">
        {items.length > 0 &&
          items.map(block => (
            <SortableItem
              block={block}
              key={block.uid}
              uidStage={uidStage}
              index={block.ordem}
              hidePopupBtn={hidePopupBtn}
              editable={editable}
            />
          ))}
      </GroupsWrapperStyled>
    );
  }
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions }, dispatch);

export default connect(null, mapDispatchToProps)(QuestionTopic);
