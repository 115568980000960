import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'quiz/GET_REQUEST',
  GET_SUCCESS: 'quiz/GET_SUCCESS',
  UPDATE_REQUEST: 'quiz/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'quiz/UPDATE_SUCCESS',
  INSERT_REQUEST: 'quiz/INSERT_REQUEST',
  INSERT_SUCCESS: 'quiz/INSERT_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  total: null,
  page: null,
  lastPage: null,
  updated: false,
  inserted: false,
});

export default function quiz(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        ...action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        updated: false,
      };
    case Types.UPDATE_SUCCESS:
      return {
        details: { ...state, ...action.payload },
        loading: false,
        success: true,
        updated: true,
      };
    case Types.INSERT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        inserted: false,
      };
    case Types.INSERT_SUCCESS:
      return {
        details: { ...state, ...action.payload },
        loading: false,
        success: true,
        inserted: true,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getQuizRequest: (id, roles = false) => ({
    type: Types.GET_REQUEST,
    payload: { id, roles },
  }),

  getQuizSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  updateQuizRequest: payload => ({
    type: Types.UPDATE_REQUEST,
    payload,
  }),

  updateQuizSuccess: payload => ({
    type: Types.UPDATE_SUCCESS,
    payload,
  }),

  insertQuizRequest: payload => ({
    type: Types.INSERT_REQUEST,
    payload,
  }),

  insertQuizSuccess: payload => ({
    type: Types.INSERT_SUCCESS,
    payload,
  }),
};
