import React, { Component, Fragment } from 'react';
import shave from '../../../../services/shave';
import { FormInputIconized } from '../../../../Components/FormInputs';
import SearchIcon from '../../../../assets/images/search.svg';
import { SearchBoxWrapper, ResponsiveWrapper } from '../styles';
import {
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../../Components/Table/styles';
import Pagination from '../../../../Components/Pagination';

export default class TableNaoSelecionados extends Component {
  state = {
    text: '',
  };

  handleChangeInput = e => {
    this.props.onChange(e.target.value, 'naoSelecionados');
  };

  changeFilter = e => {
    this.setState({ text: e.target.value }, () =>
      this.props.eventSearch(this.state.text)
    );
  };

  render() {
    const { roles, showLoadMore } = this.props;
    let showSearchBox = !(roles && !roles.length && !this.state.text);
    return (
      <Fragment>
        {showSearchBox && (
          <SearchBoxWrapper>
            <label>Selecionar destinatário</label>
            <FormInputIconized
              icon={SearchIcon}
              type="text"
              placeholder="Buscar"
              onChange={this.changeFilter}
            />
          </SearchBoxWrapper>
        )}

        {roles && !!roles.length && (
          <ResponsiveWrapper>
            <TableDiv>
              <Thead>
                <Tr>
                  <th></th>
                  <th style={{ padding: '0 10px' }}>ID</th>
                  <th>Cargo</th>
                  <th className="hideResponsive">Descrição</th>
                  <th className="hideResponsive">Status</th>
                </Tr>
              </Thead>
              <Tbody>
                {roles.map(r => (
                  <Tr key={r.id}>
                    <td>
                      <input
                        id={`role-${r.id}`}
                        type="checkbox"
                        value={r.id}
                        checked={false}
                        onChange={this.handleChangeInput}
                      />
                    </td>
                    <td style={{ padding: '0 10px' }}>{r.id}</td>
                    <td>{r.name}</td>
                    <td className="hideResponsive">
                      {shave(r.description, 50)}
                    </td>
                    <td className="hideResponsive">{r.status}</td>
                  </Tr>
                ))}
              </Tbody>
            </TableDiv>
          </ResponsiveWrapper>
        )}

        {showSearchBox && showLoadMore && (
          <Pagination
            paginate={{ page: this.props.page, loading: false }}
            onClick={this.props.loadMore}
          />
        )}
      </Fragment>
    );
  }
}
