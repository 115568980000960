import React, { Fragment } from 'react'
import { UserPageTitle, UserPageSubtitle } from '../styles';

export default function ResultadoRevisadoTitle(props) {
  return (
    <Fragment>
      <UserPageTitle>Revisão realizada com sucesso</UserPageTitle>
      <UserPageSubtitle>
        Você concordou com <b style={{ color: '#00468E' }}>{props.userAccept}</b> das <b style={{color: '#009B4C'}}>{props.totalQuestions}</b> revisões realizadas pelo seu superior imediato. Confira abaixo o seu resultado atualizado.
      </UserPageSubtitle>
    </Fragment>
  )
}
