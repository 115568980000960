import React, { Component, Fragment } from 'react';
import UserNav from '../Components/UserComponents/UserNav';
import Backdrop from '../Components/SideDrawer/BackDrop';
import { MainWrapper, Content } from '../styles/components';
import { AppDiv } from '../styles/app';
import { GlobalStyle } from '../styles/global';
import rightArrow from '../assets/images/rightArrow.svg';

import DrawerToggleButton from '../Components/SideDrawer/ToggleButton';

import { Route, Redirect } from 'react-router-dom';

export default class UserRouter extends Component {
  state = {
    SideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { SideDrawerOpen: !prevState.SideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ SideDrawerOpen: false });
  };

  render() {
    if (this.props.profile !== 'user' && this.props.profile !== 'manager') {
      return <Redirect to="/" />;
    }
    const {
      component: Component,
      location,
      hasSubordinates,
      ...props
    } = this.props;

    let backDrop = null;
    let isActive = null;

    if (this.state.SideDrawerOpen) {
      isActive = true;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
    }

    return (
      <Fragment>
        <MainWrapper>
          <GlobalStyle />

          <UserNav
            isActive={isActive}
            location={location.pathname}
            hasSubordinates={hasSubordinates}
          />
          <DrawerToggleButton
            click={this.drawerToggleClickHandler}
            img={rightArrow}
          />
          {backDrop}
          <Content isActive={isActive}>
            <AppDiv>
              <Route {...props} render={props => <Component {...props} />} />
            </AppDiv>
          </Content>
        </MainWrapper>
      </Fragment>
    );
  }
}
