import styled from 'styled-components'

export const NumberNotaRevisorWrapper = styled.li`
  background-color: #009B4C !important;
  color: #fff !important;
  display: block !important;
  border-color: #009B4C !important;
  z-index: 1;

  .header-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px;

  }

  .header {
    background-color: #19A55E;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin: 0 auto;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #009B4C;
    padding: 5px;

    div {

      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.nao-respondida {
      div {
        background-color: #fff;
        cursor: pointer;

        :hover {
          opacity: 0.8;
        }
      }
    }

    &.respondida {
      div {
        display: none;
        svg {
          fill: #fff
        }

        &.chosen {
          display: flex;
        }
      }
    }
  }
`
