import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthActions from './../../store/ducks/auth';
import UserInfoActions from './../../store/ducks/user/userInfo';
import Loading from './../../Components/Loading';
import Routes from './../../routes/index';

class LoadingApp extends Component {
  static propTypes = {
    loadLocalUser: PropTypes.func.isRequired,
    signInClearError: PropTypes.func.isRequired,
  };

  state = { load: false, fetch: true };

  componentDidMount() {
    this.props.loadLocalUser();
  }

  componentWillUpdate(prevProps, prevState) {
    if (prevProps.auth.success || prevProps.auth.error) {
      this.props.signInClearError();
      if (prevProps.auth.success && prevState.fetch) {
        prevState.fetch = false;
        this.props.userInfoRequest();
      }
    }
    if (
      prevProps.auth.error ||
      !!prevProps.userInfo.success ||
      !!prevProps.userInfo.error
    ) {
      prevState.load = true;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.userInfo.data.id !== nextProps.userInfo.data.id) {
      return true;
    }
    return !this.state.load;
  }

  render() {
    let userInfo = this.props.userInfo.data;
    if (this.state.load) {
      return (
        <Routes
          profile={userInfo.profile}
          hasSubordinates={userInfo.profile === 'manager'}
        />
      );
    }
    return <Loading loading={true} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions, ...UserInfoActions }, dispatch);

const mapStateToProps = state => ({
  auth: state.auth,
  userInfo: state.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingApp);
