import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as QuestionActions } from '../../ducks/questionCreation/questionCreation';
import { Creators as ErrorActions } from '../../ducks/error';

export function* getQuestions(action) {
  try {
    const { id } = action.payload;
    const response = yield call(api.get, `/api/v1/admin/quiz/${id}/questions/`);
    yield put(QuestionActions.getQuestionsTreeSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );

      yield put(QuestionActions.setFailure({ getError: true }));
    }
  }
}

export function* defineQuestions(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(
      api.post,
      `/api/v1/admin/quiz/${id}/define-questions`,
      normalizeStages(data)
    );
    yield put(QuestionActions.defineQuestionsTreeSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.',
          { toasted: true }
        )
      );

      yield put(QuestionActions.setFailure());
    }
  }
}

function normalizeStages({ stages }) {
  stages.map(stage => {
    stage.blocks.map(block => {
      block.questions.map(question => {
        delete question.uid;
      });
      delete block.uid;
    });

    delete stage.uid;
    delete stage.countQuestions;
    delete stage.isNew

  });

  return { stages };
}
