import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Modal from 'react-responsive-modal';

import ParticipantDetailActions from '../../../store/ducks/participant/participantDetail';
import UserCycleActions from '../../../store/ducks/cycle/userCycle';
import UpdateCycleUserStatusActions from '../../../store/ducks/cycleUser/updateCycleUserStatus';

import { Div, ModalInner } from '../../../styles/global';
import Collaborator from '../../../Components/Collaborator';
import { Content, Grade } from './styles';
import Loading from '../../../Components/Loading';
import ErrorMsg from '../../../Components/ErrorMsg';

import QuizList from '../../../Components/Quiz/List';
import QuizForm from '../../../Components/Quiz/Form';
import { ResultadoFimMiddleWrapper } from '../../User/Components/RevisaoFim/styles';
import FormReuniao from '../../../Components/FormReuniao';
import ButtonCycleUser from '../../../Components/ButtonCycleUser';
import Back from "../../../Components/Back";

class ManagerUserDetail extends React.Component {
  static propTypes = {
    userCycle: PropTypes.object.isRequired,
    userCycleRequest: PropTypes.func.isRequired,
    userCycleResetRequest: PropTypes.func.isRequired,
    participantDetailRequest: PropTypes.func.isRequired,
    participantDetail: PropTypes.object.isRequired,
  };

  state = {
    ready: false,
    cycleUserId: null,
    participantDetail: null,
    openModal: false,
    openModalSend: false,
    openModalAgendar: false,
  };

  get media() {
    let blocks = this.state.participantDetail.blocks;
    if (blocks.length === 0) {
      return 0;
    }
    let pontuation = blocks
      .map(block => Number(block.avg))
      .reduce((v1, v2) => v1 + v2);
    return Number(pontuation / blocks.length);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.id) {
      state.cycleUserId = props.match.params.id;
    }
    let participantDetailReady = false;
    if (props.participantDetail.success || props.participantDetail.error) {
      participantDetailReady = true;
      if (props.participantDetail.success) {
        state.participantDetail = props.participantDetail.data;
      }
    }
    let userCycleReady = false;
    if (props.userCycle.success || props.userCycle.error) {
      userCycleReady = true;
      if (props.userCycle.success) {
        state.userCycle = props.userCycle.userCycle;
      }
    }
    if (userCycleReady && participantDetailReady) {
      state.ready = true;
    }
    return state;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.userCycleResetRequest();
  }

  fetchData = () => {
    let { cycleUserId } = this.state;
    this.props.updateCycleUserStatusRequestReset();
    this.props.participantDetailRequest({ cycleUserId });
    this.props.userCycleRequest(cycleUserId);
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onCloseModalEncaminhar = () => {
    this.setState({ openModalSend: false });
  };

  onCloseModalAgendar = () => {
    this.setState({ openModalAgendar: false });
  };

  renderBlock = block => {
    return (
      <div key={`block-${block.id}`} className="points">
        <span>{block.title}</span>
        <label>{Number(block.avg).toFixed(2)} pontos</label>
      </div>
    );
  };

  renderBlocks = () => {
    let blocks = this.state.participantDetail.blocks;
    if (!blocks || blocks.length === 0) {
      return null;
    }
    return (
      <div className="grade">
        {blocks.map(block => this.renderBlock(block))}
      </div>
    );
  };

  renderDate = () => {
    let cycle = this.state.participantDetail.cycle;
    let begin = moment(cycle.begin).format('DD/MM/YYYY');
    let end = moment(cycle.end).format('DD/MM/YYYY');
    let completedFormAt = this.state.participantDetail.completed_form_at;
    if (completedFormAt) {
      completedFormAt = moment(completedFormAt).format('DD/MM/YYYY');
    } else {
      completedFormAt = 'Não informado';
    }
    return (
      <div className="options">
        <span>formulário gestor</span>
        <span>
          Prazo: {begin} - {end}
        </span>
        <span>Data de entrega: {completedFormAt}</span>
      </div>
    );
  };

  renderGrade = () => {
    return (
      <Grade>
        {this.renderDate()}
        {this.renderBlocks()}
      </Grade>
    );
  };

  renderModalAvaliar = () => {
    const { openModal, userCycle } = this.state;
    let options = {
      cycleUserId: userCycle.id,
      status: userCycle.status,
      stages: userCycle.stages,
      answersUser: userCycle.answers,
      answersReviewer: userCycle.answersReviewer,
      isReviewer: true,
    };
    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        center
        showCloseIcon={true}
        closeOnOverlayClick={false}
        styles={{
          modal: {
            minWidth: '50%',
          },
        }}
      >
        <QuizForm
          {...options}
          updateUserCycle={() => this.props.userCycleRequest(userCycle.id)}
          supervisorComplete={this.onCloseModal}
          onComplete={() => {
            if (userCycle.status === 'Em Revisão') {
              this.setState({ openModalSend: true });
            }
          }}
        />
      </Modal>
    );
  };

  renderModalEmcaminhar = () => {
    const { openModalSend, openModal, userCycle } = this.state;
    return (
      <Modal
        open={openModalSend && !openModal}
        onClose={this.onCloseModalEncaminhar}
        center
        showCloseIcon={true}
        styles={{
          modal: {
            minWidth: '50%',
          },
        }}
      >
        <ModalInner>
          <div className="modal-header">
            <span>Avaliação Revisada</span>
          </div>
          <ResultadoFimMiddleWrapper>
            <div className="btns">
              <ButtonCycleUser
                disabled={userCycle.status !== 'Em Revisão'}
                status="Em Revisão Colaborador"
                cycleUserId={userCycle.id}
                renderButton={(onClick, loading, disabled) => (
                  <a href="#!" onClick={onClick}>
                    {loading
                      ? 'Aguarde ...'
                      : 'Encaminhar para ciência do empregado'}
                  </a>
                )}
                onSuccess={() => {
                  this.fetchData();
                  this.onCloseModalEncaminhar();
                }}
              />
              <a href="#!" onClick={this.onCloseModalEncaminhar}>
                Encaminhar mais tarde
              </a>
            </div>
          </ResultadoFimMiddleWrapper>
        </ModalInner>
      </Modal>
    );
  };

  renderModalAgendar = () => {
    const { openModalAgendar, userCycle } = this.state;
    return (
      <Modal
        open={openModalAgendar}
        onClose={this.onCloseModalAgendar}
        center
        showCloseIcon={false}
        styles={{
          modal: {
            minWidth: '30%',
          },
        }}
      >
        <ModalInner>
          <div className="modal-header">
            <span>Agendar Revisão Conjunta</span>
          </div>
          <ResultadoFimMiddleWrapper>
            <div>
              <p>
                Selecione a data e local em que deseja realizar a revisão
                juntamente com o colaborador.
              </p>
              <p>Enviaremos um e-mail de convite com a data escolhida.</p>
              <FormReuniao
                userCycleId={userCycle.user_id}
                closeModal={this.onCloseModalAgendar}
              />
            </div>
          </ResultadoFimMiddleWrapper>
        </ModalInner>
      </Modal>
    );
  };

  render() {
    let { ready, participantDetail, userCycle } = this.state;
    let { error } = this.props.participantDetail;
    if (!ready) {
      return <Loading loading={true} />;
    }
    if (error) {
      return <ErrorMsg msg={error} />;
    }
    return (
      <Fragment>
        <Div>
          <Back path="/gestor" title="lista completa" />
          <div className="collaborador">
            <Collaborator
              cycleUserId={this.state.cycleUserId}
              userInfo={participantDetail.user}
              media={this.media}
              status={participantDetail.status}
              statusText={participantDetail.statusText}
              onClickAgendar={() => {
                this.setState({ openModalAgendar: true });
              }}
              onClickAvaliar={() => {
                this.setState({ openModal: true });
              }}
              onClickGiveBack={this.fetchData}
              onSendRH={this.fetchData}
            />
          </div>
          <Content>
            {this.renderGrade()}
            <QuizList key={1} userCycle={userCycle} />
          </Content>
        </Div>
        {this.renderModalAvaliar()}
        {this.renderModalEmcaminhar()}
        {this.renderModalAgendar()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ParticipantDetailActions,
      ...UserCycleActions,
      ...UpdateCycleUserStatusActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  updateCycleUserStatus: state.updateCycleUserStatus,
  participantDetail: state.participantDetail,
  userCycle: state.userCycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManagerUserDetail);
