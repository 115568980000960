import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { ResponsiveWrapper } from '../styles';

import {
  Tr,
  Tbody,
  Thead,
  TableDiv,
} from '../../../../Components/Table/styles';
import ProgressBar from '../../../../Components/ProgressBar';

export default class Table extends Component {
  formatDate = date => {
    date = moment(date);
    if (date.isValid()) {
      return moment(date).format('DD/MM/YYYY');
    }
    return 'Não informado';
  };

  progress(cycle) {
    let participants = cycle.__meta__.participants;
    let participantsCompleted = cycle.__meta__.participants_completed;
    try {
      let result = (participantsCompleted * 100) / participants;
      return isNaN(result) ? 0 : result;
    } catch (e) {}
    return 10;
  }

  render() {
    return (
      <ResponsiveWrapper>
        <TableDiv>
          <Thead>
            <Tr>
              <th>ID</th>
              <th>Título</th>
              <th>Início</th>
              <th>Fim</th>
              <th className="center">Participantes</th>
              <th className="hideResponsive">Status</th>
              <th className="hideResponsive">Situação</th>
              <th className="hideResponsive">Progresso</th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.cycles.data.map(cycle => (
              <Tr key={cycle.id}>
                <td>{cycle.id}</td>
                <td>
                  <Link to={`/ciclos/${cycle.id}/dados`}>{cycle.title}</Link>
                </td>
                <td>{this.formatDate(cycle.begin)}</td>
                <td>{this.formatDate(cycle.end)}</td>
                <td className="center">{cycle.__meta__.participants}</td>
                <td className="hideResponsive">{cycle.status}</td>
                <td className="hideResponsive">{cycle.situation}</td>
                <td className="hideResponsive">
                  <ProgressBar progress={this.progress(cycle)} />
                </td>
              </Tr>
            ))}
          </Tbody>
        </TableDiv>
      </ResponsiveWrapper>
    );
  }
}
