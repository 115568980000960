import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducer as auth } from './auth';
import { reducer as forgotPassword } from './forgotPassword';
import { reducer as definePassword } from './definePassword';
import cycle from './cycle';
import users from './admin/users';
import user from './admin/user';
import cycles from './cycle/cycles';
import { reducer as cycleDataForm } from './cycle/cycleDataForm';
import { reducer as cycleData } from './cycle/cycleData';
import { reducer as cycleQuizzes } from './cycle/cycleQuizzes';
import { reducer as cycleQuizzesForm } from './cycle/cycleQuizzesForm';
import { reducer as quizByCycle } from './quiz/quizByCycle';
import { reducer as participants } from './participant/participants';
import { reducer as participantDetail } from './participant/participantDetail';
import { reducer as removeParticipants } from './participant/removeParticipants';
import { reducer as importUpload } from './user/importUpload';
import { reducer as userInfo } from './user/userInfo';
import currentCycle from './cycle/currentCycle';
import { reducer as userCycle } from './cycle/userCycle';
import { reducer as updateCycleUserStatus } from './cycleUser/updateCycleUserStatus';
import { reducer as answer } from './answer/answer';
import { reducer as answerAgreeRevision } from './answer/answerAgreeRevision';
import { reducer as findAnswer } from './answer/findAnswer';
import { reducer as gradeDownload } from './grade/download';
import { reducer as selectRoles } from './select/roles';
import { reducer as functions } from './select/functions';
import { reducer as offices } from './select/offices';
import { reducer as organizationsUnit } from './select/organizationsUnit';
import { reducer as userCycles } from './userCycles/userCycles';
import { reducer as cicles } from './select/cicles';
import meeting from './supervisor/meeting';
import roles from './role/roles';
import role from './role/role';
import reasons from './reason/reasons';
import reason from './reason/reason';
import quiz from './quiz/quiz';
import quizRoles from './quiz/quizRoles';
import participantReport from './reports/participantReport';
import departmentReport from './reports/departmentReport';
import questionCreation from './questionCreation/questionCreation';
import templateQuiz from './quiz/templateQuiz';
import templateQuizzes from './quiz/templateQuizzes';
import templateQuizRoles from './quiz/templateQuizRoles';
import hierarchy from './hierarchy';
import { reducer as selectUsers } from './select/selectUsers';

import ModuleCoursesReducers from './modules/courses';

export default combineReducers({
  form: formReducer,
  auth,
  forgotPassword,
  definePassword,
  cycles,
  cycle,
  users,
  user,
  cycleDataForm,
  cycleData,
  userCycle,
  currentCycle,
  cycleQuizzes,
  cycleQuizzesForm,
  quizByCycle,
  participants,
  removeParticipants,
  participantDetail,
  importUpload,
  userInfo,
  updateCycleUserStatus,
  answer,
  answerAgreeRevision,
  findAnswer,
  gradeDownload,
  functions,
  selectRoles,
  offices,
  organizationsUnit,
  userCycles,
  meeting,
  roles,
  role,
  reasons,
  reason,
  quiz,
  quizRoles,
  participantReport,
  departmentReport,
  cicles,
  templateQuiz,
  templateQuizzes,
  templateQuizRoles,
  questionCreation,
  hierarchy,
  selectUsers,

  ...ModuleCoursesReducers,
});
