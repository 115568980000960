import styled from "styled-components";

export const Content = styled.div`
  display: block;
  flex-direction: column;
  background-color: #fff;
  height: 100%;

  padding: 15px 25px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
`;

export const Grade = styled.div`
  display: flex;
  flex-direction: column;

  .options {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 16px;
      line-height: 22px;
      color: #999999;

      :first-child {
        text-transform: uppercase;
        color: #00468e;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .grade {
    display: flex;
    align-items: center;
    justify-content: space-around;

    margin-top: 10px;
    min-height: 100px;

    .points {
      height: 48px;
      width: 136px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #467cc2;
      border-radius: 4px;

      span {
        color: #fff;
        font-size: 8px;
        line-height: 11px;
      }

      label {
        color: #fff;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
`;
