import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import CycleDataActions from "../../ducks/cycle/cycleData";

export function* cycleData({ cycleId }) {
  yield put(CycleDataActions.cycleDataClearError());
  yield put(CycleDataActions.cycleDataLoading());
  try {
    let result = yield call(api.get, `api/v1/admin/cycles/${cycleId}`);
    yield put(CycleDataActions.cycleDataSuccess(result.data.details));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            CycleDataActions.cycleDataError(response.messages[0])
          );
        }
      }
    }
    yield put(
      CycleDataActions.cycleDataError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
