import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import shave from '../../../services/shave';

import { Creators as ReasonsActions } from '../../../store/ducks/reason/reasons';
import { Creators as ReasonActions } from '../../../store/ducks/reason/reason';

import {
  TableDefault,
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../Components/Table/styles';
import ItensAndButtons from '../../../Components/ItensAndButtons';
import Pagination from '../../../Components/Pagination';

import SideMenu from '../SideMenu';
import { ContentWrapper } from '../styles';
import NotFoundItems from '../../../Components/NotFoundItems';

export class DadosGeraisJustificativas extends Component {
  state = {
    text: '',
  };

  deleteReason(id) {
    this.props.getDeleteRequest(id)
  }

  componentDidMount() {
    this.props.clearReasons();
    this.props.getReasonsRequest();
  }

  render() {
    const { reasons, history } = this.props;
    return (
      <ContentWrapper>
        <SideMenu page={4} />

        <div className="content">
          <ItensAndButtons
            history={history}
            newUrl="/dadosgerais/justificativas/novo"
            total={reasons.total}
            onSubmit={text => {
              this.setState({ text });
              this.props.clearReasons();
              this.props.getReasonsRequest({ page: 1, text });
            }}
            isLoading={reasons.loading}
          />

          {reasons &&
            (reasons.data.length > 0 ? (
              <TableDiv>
                <TableDefault>
                  <Thead>
                    <Tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Descrição</th>
                      <th>Status</th>
                      <th>Ação</th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reasons.data.map(r => (
                      <Tr key={r.id}>
                        <td>{r.id}</td>
                        <td>
                          <Link to={`/dadosgerais/justificativas/${r.id}`}>
                            {r.title}
                          </Link>
                        </td>
                        <td>{shave(r.description, 50)}</td>
                        <td>{r.status ? 'Ativo' : 'Inativo'}</td>
                        <td>
                        <button className="delete" onClick={() => this.deleteReason(r.id)}>
                          Deletar
                        </button>
                        </td>
                      </Tr>
                    ))}
                  </Tbody>
                </TableDefault>
                {reasons.lastPage !== reasons.page && (
                  <Pagination
                    onClick={page =>
                      this.props.getReasonsRequest({
                        page,
                        text: this.state.text,
                      })
                    }
                    paginate={reasons}
                  />
                )}
              </TableDiv>
            ) : reasons.loading ? (
              ''
            ) : (
              <NotFoundItems />
            ))}
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  reasons: state.reasons,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReasonsActions,
      ...ReasonActions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DadosGeraisJustificativas);
