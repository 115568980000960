import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';

import { Creators as RoleActions } from '../../../../store/ducks/role/role';

import SideMenu from '../../SideMenu';
import { ContentWrapper } from '../../styles';

import {
  FormInputLabelled,
  FormTextArea,
  FormSelectLabelled,
} from '../../../../Components/FormInputs';
import FormError from '../../../../Components/Form/Error';

import {
  DetailBody,
  DetailWrapper,
  HeaderWrapper,
  BackWrapper,
} from './styles';

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
  description: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
});

const initialState = {
  name: '',
  description: '',
  status: '',
  isNew: false,
};

export class DetailCargos extends Component {
  state = Object.assign({}, initialState);

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== 'novo') {
      this.props.getRoleRequest(id);
    } else {
      this.setState({ ...initialState, isNew: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.role.updated && this.props.role.updated) ||
      (!prevProps.role.inserted && this.props.role.inserted)
    ) {
      this.props.history.push('/dadosgerais/cargos');
    }
  }

  handleSubmit = values => {
    if (this.state.isNew) {
      this.props.insertRoleRequest(values);
    } else {
      this.props.updateRoleRequest(values);
    }
  };

  renderForm = ({ handleSubmit, errors, handleChange, values }) => {
    const { name, description, status } = values;

    const statusValues = [
      {
        value: 'Ativo',
        name: 'Ativo',
      },
      {
        value: 'Inativo',
        name: 'Inativo',
      },
    ];

    return (
      <Fragment>
        {errors.name && <FormError error={errors.name} />}
        <FormInputLabelled
          label="Título"
          name="name"
          type="text"
          value={name}
          onChange={handleChange}
        />
        {errors.description && <FormError error={errors.description} />}
        <FormTextArea
          label="Descrição"
          name="description"
          value={description}
          onChange={handleChange}
        />
        {!this.state.isNew && (
          <Fragment>
            {errors.status && <FormError error={errors.status} />}
            <FormSelectLabelled
              label="Status"
              name="status"
              value={status}
              onChange={handleChange}
              options={statusValues}
              style={{ maxWidth: '100px' }}
            />
          </Fragment>
        )}
        <button type="button" onClick={handleSubmit}>
          SALVAR
        </button>
      </Fragment>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <SideMenu page={2} />

        <div className="content">
          <DetailBody>
            <DetailWrapper>
              <HeaderWrapper>
                <BackWrapper
                  onClick={() => this.props.history.push('/dadosgerais/cargos')}
                >
                  <MdArrowBack color="#666" fontSize="26px" />
                  <span>VOLTAR</span>
                </BackWrapper>
              </HeaderWrapper>
              {!this.state.isNew ? (
                this.props.role &&
                this.props.role.success && (
                  <Formik
                    enableReinitialize
                    render={this.renderForm}
                    validationSchema={ValidationSchema}
                    onSubmit={values => this.handleSubmit(values)}
                    initialValues={{ ...this.props.role.details }}
                  />
                )
              ) : (
                <Formik
                  enableReinitialize
                  render={this.renderForm}
                  validationSchema={ValidationSchema}
                  onSubmit={values => this.handleSubmit(values)}
                  initialValues={{ ...initialState }}
                />
              )}
            </DetailWrapper>
          </DetailBody>
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  role: state.role,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...RoleActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailCargos);
