import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  answerAgreeRevisionRequest: ["cycleUserId"],
  answerAgreeRevisionResetRequest: null,
  answerAgreeRevisionSuccess: ["success"],
  answerAgreeRevisionError: ["error"],
  answerAgreeRevisionReset: null,
  answerAgreeRevisionLoading: null
});

export const AnswerAgreeRevisionTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { success }) => {
  return state.merge({
    error: false,
    loading: false,
    success
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    answer: [],
    loading: false,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ANSWER_AGREE_REVISION_SUCCESS]: success,
  [Types.ANSWER_AGREE_REVISION_ERROR]: error,
  [Types.ANSWER_AGREE_REVISION_LOADING]: loading,
  [Types.ANSWER_AGREE_REVISION_RESET]: reset
});
