import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import { Creators as CurrentCycleActions } from '../../../store/ducks/cycle/currentCycle';
import ParticipantsActions from '../../../store/ducks/participant/participants';

import FilterParticipants from '../../../Components/FilterParticipants';
import { Filtro } from '../../Ciclos/Novo/Participantes/styles';
import NotFoundItems from '../../../Components/NotFoundItems';
import Pagination from '../../../Components/Pagination';
import ParticipantsTable from '../../Ciclos/Novo/Participantes/ParticipantsTable';
import { Div } from '../../../styles/global';
import Loading from '../../../Components/Loading';
import ErrorMsg from '../../../Components/ErrorMsg';
import ModalNoCycle from '../../../Components/ModalNoCycle';

class Home extends React.Component {
  static propTypes = {
    participantsRequest: PropTypes.func.isRequired,
  };

  state = {
    filters: {},
    ready: false,
    page: 1,
    participants: [],
    redirectToUser: null,
  };

  static getDerivedStateFromProps(props, state) {
    state.participants = props.participants.data;
    if (props.currentCycle.success || props.currentCycle.error) {
      state.ready = true;
      state.currentCycle = props.currentCycle.details;
      state.error = props.currentCycle.error;
    }
    return state;
  }

  componentDidMount() {
    this.setState({ fetch: true });
    this.props.currentCycleRequest();
  }

  componentDidUpdate() {
    if (this.state.fetch && this.state.ready && !this.state.error) {
      this.setState({ fetch: false });
      this.props.participantsRequest({
        ...this.state,
        cycleId: this.state.currentCycle.id,
      });
    }
  }

  onFilterParticipants = filters => {
    this.setState({ participants: [], fetch: true, filters });
  };

  renderParticipants = () => {
    const { participants } = this.props;
    if (this.state.participants.length === 0) {
      return <NotFoundItems />;
    }
    return (
      <Div>
        <ParticipantsTable
          participantes={this.state.participants}
          onItemClicked={participant => {
            this.setState({ redirectToUser: participant.cycle_user_id });
          }}
          canSelectItem={false}
        />
        {participants && participants.lastPage !== participants.page && (
          <Pagination
            onClick={page => this.setState({ page, append: true, fetch: true })}
            paginate={this.props.participants}
          />
        )}
      </Div>
    );
  };

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.state.redirectToUser) {
      return (
        <Redirect to={`/gestor/ciclo-usuario/${this.state.redirectToUser}`} />
      );
    }
    if (this.state.error) {
      return <ErrorMsg msg={this.state.error} />;
    }
    return (
      <Fragment>
        <Filtro>
          <FilterParticipants onSubmit={this.onFilterParticipants} />
        </Filtro>
        <ModalNoCycle requestCycleUsers={true} />
        {this.renderParticipants()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ParticipantsActions,
      ...CurrentCycleActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  currentCycle: state.currentCycle,
  participants: state.participants,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
