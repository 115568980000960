import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'cycle/GET_REQUEST',
  PUT_REQUEST: 'cycle/PUT_REQUEST',
  GET_SUCCESS: 'cycle/GET_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
});

export default function cycle(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.PUT_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getCycleRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  setCycleInit: id => ({
    type: Types.PUT_REQUEST,
    payload: { id },
  }),

  getCycleSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),
};
