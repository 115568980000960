import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as CurrentCycleActions } from '../../store/ducks/cycle/currentCycle';
import { Div } from '../../styles/global';
import { Content } from './styles';

import Dashboard from '../../Components/Dashboard';
import Loading from '../../Components/Loading';

class Home extends React.Component {
  getProcessos(item, index) {
    if (item.type === 'processos') {
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  getRevisoes(item, index) {
    if (item.type === 'revisao') {
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  getExclusoes(item, index) {
    if (item.type === 'exclusao') {
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  componentDidMount() {
    this.props.currentCycleRequest({ dashboard: true });
  }

  render() {
    const { currentCycle } = this.props;

    if (
      currentCycle &&
      !currentCycle.loading &&
      !('id' in currentCycle.details)
    ) {
      return (
        <Fragment>
          <Div>
            <Content>
              <h1>Não há ciclo ativo atualmente</h1>
            </Content>
          </Div>
        </Fragment>
      );
    }

    if (currentCycle && currentCycle.loading) {
      return (
        <Fragment>
          <Div>
            <Content>
              <Loading loading={currentCycle.loading} />
            </Content>
          </Div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Div>
          <Content>
            <h1>{currentCycle.details.title}</h1>
            <Dashboard currentCycle={currentCycle.details} />
          </Content>
        </Div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentCycle: state.currentCycle,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CurrentCycleActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
