import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/cycle/userCycle";

export function* requestUserCycle({ userCycleId }) {
  yield put(Actions.userCycleClearError());
  yield put(Actions.userCycleLoading());
  try {
    let result = yield call(api.get, `api/v1/cycles/users/${userCycleId || 'current'}`);
    yield put(Actions.userCycleSuccess(result.data.details));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(Actions.userCycleError(response.messages[0]));
        }
      }
    }
    yield put(
      Actions.userCycleError("Ocorreu um erro no servidor, tente mais tarde.")
    );
  }
}
