import api from "../../../../services/api";
import { download } from "../../../../services/download";
import { call, put } from "redux-saga/effects";

import { Creators as CertificateActions } from "../../../ducks/modules/courses/certificate";
import { Creators as ErrorActions } from "../../../ducks/error";

export function* downloadCertificate(action) {
  try {
    const generate = yield call(api.get, `/api/v1/certificates/generate/${action.payload.id}`);
    const { data } = yield call(api.get, `/api/v1/certificates/view/${action.payload.id}`, {
      responseType: 'blob'
    });

    const filename = `certificado_${generate.data.details.id}_${generate.data.details.user_id}.pdf`;

    yield call(download, { filename, data});
    yield put(CertificateActions.downloadCertificateSuccess());
  } catch (err) {
    yield put(ErrorActions.setError("Não foi possível obter o certificado"));
  }
}
