import React, { Component, Fragment } from "react";
import { CourseContentHeader } from "../../../pages/User/Components/Headers";
import { CourseContentContainer } from "../Courses/User/Content/styles";
import { IframeStyled } from "./styles";
import IframeComm from "react-iframe-comm";
import Loading from "../../../Components/Loading";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as EnrollmentActions } from "../../../store/ducks/modules/courses/enroll";
import { Creators as CourseActions } from "../../../store/ducks/modules/courses/info";
import { Creators as TopicsActions } from "../../../store/ducks/modules/courses/topics";
import { Creators as ScormActions } from "../../../store/ducks/modules/courses/scorm";
import { Creators as RatingAnswerActions } from "../../../store/ducks/modules/courses/ratingAnswer";
import { Creators as SuccessActions } from "../../../store/ducks/success";
import CompletedEnrollmentModal from "../../../Components/Modules/Courses/Modals/CompletedEnrollmentModal";

class Conteudo extends Component {
  state = {
    fetch: true,
    ready: false,
    forceRender: null,
    scormInject: false,
    topicSelected: null,
    topicActive: null,
    chaptersReady: false,
    isSidenavOpen: false,
    topicsCompleted: 0,
    isModalConclusaoOpen: false,
    chapters: [],
    inputComentarioCurso: ""
  };

  fetchInfo() {
    const user = JSON.parse(localStorage.getItem("@emae:userinfo"));
    const { getTopicsRequest, getCourseRequest } = this.props;

    getCourseRequest(this.props.match.params.id);

    getTopicsRequest(
      this.props.match.params.id,
      this.props.match.params.idMatricula
    );

    this.setState({
      userId: user.id,
      forceRender: null,
      scormInject: false,
      topicSelected: this.props.match.params.idTopico,
      topicActive: null,
      chaptersReady: false,
      isSidenavOpen: false,
      topicsCompleted: 0,
      isModalConclusaoOpen: false,
      reverCurso: this.props.match.params.reverCurso == "true" ? true : false,
      chapters: [
        {
          name: "Introducao",
          topic: null,
          completed: false,
          isActive: true
        }
      ],
      inputComentarioCurso: ""
    });
  }

  navTopic = chapter => {
    if (!chapter.isActive) {
      this.props.history.push(chapter.url);
      this.setState({ fetch: true });
    }
  };

  handleOpenSidenav = () => {
    this.setState({ isSidenavOpen: !this.state.isSidenavOpen });
  };

  onCloseModalConclusao = () => {
    this.setState({ isModalConclusaoOpen: false });
  };

  handleInputComentarioModal = e => {
    this.setState({ inputComentarioCurso: e.target.value });
  };

  componentDidMount() {
    this.setState({ fetch: false }, () => {
      this.fetchInfo();
    });
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false }, () => {
        this.fetchInfo();
      });
    }
  }

  saveRatingAnswer() {
    const { postRatingAnswerRequest } = this.props;
    if (this.state.inputComentarioCurso != "") {
      postRatingAnswerRequest(
        this.props.match.params.idRating,
        this.props.ratingAnswer.value ? this.props.ratingAnswer.value : 1,
        this.state.inputComentarioCurso
      );
      this.completeCourse();
    }
  }

  verifyScormData(data) {
    if (data.session_time == "") data.session_time = "00:00:00";
    if (data.lesson_location == "") data.lesson_location = "0";
    if (data.lesson_status == "") data.lesson_status = "started";
    if (data.score_raw == "") data.score_raw = "0";
    if (data.score_max == "") data.score_max = "0";
    if (data.score_min == "") data.score_min = "0";
    return data;
  }

  verifyCourseIsCompleted(topicId, lessonStatus) {
    let topics = this.props.topics.data;
    let completed = 0;
    for (let i = 0; i < topics.length; i++) {
      if (topics[i].scormUser && topics[i].id != topicId) {
        if (
          topics[i].scormUser.session_status == "completed" ||
          topics[i].scormUser.session_status == "passed"
        ) {
          completed++;
        }
      }
    }
    if (lessonStatus == "completed" || lessonStatus == "passed") {
      completed++;
    }
    if (completed >= topics.length) {
      return true;
    }
    return false;
  }

  updateScormData(updateScormRequest, data, enrollId) {
    let idTopic = this.state.topicActive.id;
    data = this.verifyScormData(data);
    updateScormRequest(enrollId, idTopic, data);
  }

  //Recebe mensagem
  receiveMessage = e => {
    let data = JSON.parse(e.data);
    const { updateScormRequest } = this.props;
    let enrollId = this.props.match.params.idMatricula;
    if (data.suspend_data && enrollId) {
      if (!this.state.reverCurso) {
        data = this.verifyScormData(data);
        this.updateScormData(updateScormRequest, data, enrollId);
      }
      if (
        data.lesson_status == "completed" ||
        data.lesson_status == "passed" ||
        data.lesson_status == "failed"
      ) {
        if (
          (this.props.topics.data.length === 1 ||
            this.verifyCourseIsCompleted(data.topic_id, data.lesson_status)) &&
          !this.state.reverCurso
        ) {
          setTimeout(() => {
            this.setState({ isModalConclusaoOpen: true });
          }, 2000);
        } else {
          setTimeout(() => {
            this.setState({ isSidenavOpen: true });
          }, 500);
        }
      }
    }
  };

  iframeReady = e => {};

  updateChapters(topics) {
    let chapters = [];
    let topicoAtivo = null;
    let hasActive = false;
    let countCompleted = 0;
    for (let i = 0; i < topics.length; i++) {
      let topic = topics[i];
      let chapter = { title: topic.title };

      if (
        this.state.topicSelected !== "initial" &&
        this.state.topicSelected == topic.id &&
        !hasActive
      ) {
        hasActive = true;
        topicoAtivo = topic;
        chapter.isActive = true;
      }
      if (topic.scormUser) {
        if (
          topic.scormUser.session_status == "completed" ||
          topic.scormUser.session_status == "passed" ||
          topic.scormUser.session_status == "failed"
        ) {
          chapter.completed = true;
          countCompleted++;
        } else {
          chapter.completed = false;
        }
        if (
          !hasActive &&
          (!chapter.completed || this.state.reverCurso) &&
          this.state.topicSelected == "initial"
        ) {
          hasActive = true;
          topicoAtivo = topic;
          chapter.isActive = true;
        }
      } else {
        chapter.completed = false;

        if (!hasActive && this.state.topicSelected == "initial") {
          hasActive = true;
          topicoAtivo = topic;
          chapter.isActive = true;
        }
      }
      chapter.url = this.returnTopicUrl(topic.id);
      chapters.push(chapter);
    }
    this.props.getScormRequest(
      this.props.match.params.idMatricula,
      topicoAtivo.id
    );
    this.setState({
      topicActive: topicoAtivo,
      chapters: chapters,
      chaptersReady: true,
      topicsCompleted: countCompleted
    });
  }

  returnTopicUrl(idTopic) {
    return `/usuario/curso/conteudo/${this.props.match.params.id}/oferta/${
      this.props.match.params.idOferta
    }/matricula/${
      this.props.match.params.idMatricula
    }/topico/${idTopic}/rating/${this.props.match.params.idRating}/rever/${
      this.props.match.params.reverCurso
    }`;
  }

  completeCourse = () => {
    const { history } = this.props;
    //this.props.setSuccess("Curso concluido com sucesso!", { toasted: true });
    this.onCloseModalConclusao();
    history.push(`/usuario/cursos`);
  };

  render() {
    const { topics } = this.props;

    if (!topics) {
      return <Loading text={"Carregando contéudo..."} loading={true} />;
    }
    if (!topics.success) {
      return <Loading text={"Carregando contéudo..."} loading={true} />;
    }

    if (topics.success && !this.state.chaptersReady) {
      setTimeout(() => {
        this.updateChapters(topics.data);
      }, 500);
    }

    if (
      !topics.success ||
      !this.state.chaptersReady ||
      !this.state.topicActive
    ) {
      return <Loading text={"Preparando contéudo..."} loading={true} />;
    }
    if (!this.state.scormInject && this.props.scorm.getData) {
      let scormInject = this.props.scorm.data;
      scormInject.user_id = this.state.userId;
      scormInject.course_id = this.props.match.params.id;
      scormInject.topic_id = this.state.topicActive.id;
      this.setState({ scormInject: scormInject });
    }
    if (!this.state.scormInject) {
      return <Loading text={"Preparando contéudo..."} loading={true} />;
    }

    return (
      <Fragment>
        <CourseContentHeader
          history={this.props.history}
          backRoute={`/usuario/cursos/oferta/${
            this.props.match.params.idOferta
          }/matricula/${this.props.match.params.idMatricula}`}
          courseTitle={this.props.course.data.title}
          topicClick={this.navTopic}
          countCompleted={this.state.topicsCompleted}
          chapters={this.state.chapters}
        />
        <CourseContentContainer>
          <CompletedEnrollmentModal
            visible={this.state.isModalConclusaoOpen}
            enrollmentId={Number(this.props.match.params.idMatricula)}
            ratingId={Number(this.props.match.params.idRating)}
            course={this.props.course.data}
            onSuccess={this.completeCourse}
            onClose={() => {}}
            onError={() => {}}
          />

          <IframeStyled>
            <IframeComm
              attributes={{
                src:
                  this.state.topicActive.url_server +
                  this.state.topicActive.scormUpload.route,
                id: "iframeId",
                sandbox:
                  "allow-scripts allow-forms allow-same-origin allow-pointer-lock allow-presentation allow-modals allow-popups",
                allowFullScreen: true,
                height: "100%",
                width: "100%"
              }}
              handleReceiveMessage={this.receiveMessage}
              handleReady={this.iframeReady}
              postMessageData={JSON.stringify(this.state.scormInject)}
              ref={f => (this.ifr = f)}
            />
          </IframeStyled>
        </CourseContentContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ratingAnswer: state.ratingAnswer,
  topics: state.topics,
  scorm: state.scorm,
  enrollment: state.enrollment,
  course: state.course
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...EnrollmentActions,
      ...RatingAnswerActions,
      ...TopicsActions,
      ...ScormActions,
      ...SuccessActions,
      ...CourseActions
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Conteudo);
