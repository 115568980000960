import styled from 'styled-components'

export const NumberRevisaoWrapper = styled.div`
ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0 15px;

    li {
      flex: 1;
      background: #E5ECF3;
      color: #00468E;
      border: 1px solid #00468E;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      height: 100px;
      display:flex;
      align-items: center;
      justify-content: center;

      :not(:last-child) {
        border-right: 0;
      }

      &.active {
        background-color: #195899;
        color: #fff;
      }
    }
  }
`
