import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'cycles/GET_REQUEST',
  GET_SUCCESS: 'cycles/GET_SUCCESS',
  SET_FAILURE: 'cycles/SET_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  total: null,
  perPage: null,
  page: null,
  lastPage: null,
  data: [],
  error: false,
  success: false,
  loading: false,
});

export default function cycles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false, error: false };
    case Types.GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        loading: false,
        success: true,
      };
    case Types.SET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  cyclesRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  cyclesSuccess: payload => ({
    type: Types.GET_SUCCESS,
    payload,
  }),

  cyclesError: error => ({
    type: Types.SET_FAILURE,
    payload: { error },
  }),
};
