import styled from 'styled-components';

export const NotFoundText = styled.p`
  text-align: center;
  width: 100%;
  background-color: #e7e7e7;
  padding: 8px 0px;
  font-size: 16px;
  color: #666666;
`;

export const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 20px 30px 50px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;

  .user-data {
    margin-top: 10px;
    list-style: none;
  }
  .user-data li {
    margin-bottom: 5px;
  }

  .user-data span {
    display: block;
    color: #00468e;
    font-size: 15px;
    text-transform: uppercase;
  }

  > div {
    display: flex;
    flex: 1;

    div {
      max-width: 400px;
      margin-right: 10px;

      label {
        font-size: 16px;
        color: #666666;
        text-transform: uppercase;
      }

      input {
        height: 42px;
        border-radius: 3px;
        margin: 10px 0 0;
      }

      :last-child {
        margin-right: 0;
      }

      @media (max-width: 768px) {
        margin-right: 0;
      }
    }

    .right-side button {
      height: 20px;
      padding: 1px 4px !important;
    }
    span {
      color: #00468e;
      font-size: 12px;
      text-transform: uppercase;
    }

    button {
      margin: 10px 0 0 0;
      border: 0;
      height: 40px;
      color: #fff;
      text-transform: uppercase;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #00468e;

      :not([disabled]):hover {
        opacity: 0.8;
      }

      &[disabled] {
        background-color: #eee;
      }
    }

    .select {
      padding-top: 10px;
      width: 100%;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
`;
