import styled from 'styled-components';

export const WrapperStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20%;

  > div {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    width: 500px;
    box-shadow: 0 0 4px 4px rgba(0,0,0,0.1);

    > .header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 20px;
    }

    > .body {
      display: flex;
      flex-direction: column;
      padding: 20px;

      > span {
        text-align: center;
        &:first-child {
          font-size: 22px;
        }
        &:nth-child(2) {
          font-size: 14px;
          margin: 15px 0;
        }
      }

      > button {
        background-color: #00468e;
        color: #fff;
        border: 0;
        border-radius: 4px;
        padding: 10px;
        margin-top: 20px;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`;
