import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as TemplateQuizActions } from '../../ducks/quiz/templateQuiz';
import { Creators as TemplateQuizRolesActions } from '../../ducks/quiz/templateQuizRoles';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';
import { toast } from 'react-toastify';

export function* getTemplateQuiz(action) {
  let withRoles = '';
  if (action.payload.roles) {
    withRoles = '?roles=true';
  }
  try {
    const response = yield call(
      api.get,
      `/api/v1/admin/template-quizzes/${action.payload.id}` + withRoles
    );
    yield put(TemplateQuizActions.getTemplateQuizSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* updateTemplateQuiz(action) {
  const { id, notify = true, ...data } = action.payload;
  try {
    const response = yield call(
      api.put,
      `/api/v1/admin/template-quizzes/${id}`,
      data
    );
    yield put(
      TemplateQuizActions.updateTemplateQuizSuccess(response.data.details)
    );
    yield put(
      SuccessActions.setSuccess('Formulário atualizado com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* insertTemplateQuiz(action) {
  const { notify = true, ...data } = action.payload;
  try {
    const response = yield call(
      api.post,
      '/api/v1/admin/template-quizzes/',
      data
    );
    yield put(
      TemplateQuizActions.insertTemplateQuizSuccess(response.data.details)
    );
    yield put(
      SuccessActions.setSuccess('Formulário incluído com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* templateDefineRoles(action) {
  const { id, role_ids } = action.payload;
  try {
    const response = yield call(
      api.post,
      `/api/v1/admin/template-quiz/${id}/define-roles`,
      { role_ids: role_ids }
    );
    yield put(
      TemplateQuizRolesActions.updateTemplateQuizRolesSuccess(
        response.data.details
      )
    );
    toast.success('Destinatários atualizados com sucesso!');
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
