import React, { Component } from 'react';
import Collapsible from '../../../Components/Collapsible';
import {
  FormInputLabelled,
  FormTextArea,
} from '../../../Components/FormInputs';
import { QuestionGroupStyled } from './styles';
import QuestionQuestion from '../QuestionQuestion';
import IconGroup from '../../../assets/images/questoes/ico-group.svg';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../../store/ducks/questionCreation/questionCreation';
import QuestionAddBtn from '../../../Components/QuestionAddBtn';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import QuestionDragHandle from '../../../Components/QuestionDragHandle';

class QuestionGroup extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.hidePopupBtn(false);
    if (oldIndex === newIndex) return false;
    const { questionOrderQuestion, uidStage, uid } = this.props;
    questionOrderQuestion(oldIndex - 1, newIndex - 1, uidStage, uid);
  };

  changeCollOpen = op => {
    const { uid } = this.props;
    const el = document.getElementById(`block-wrapper-${uid}`);
    if (op) el.classList.add('active');
    else el.classList.remove('active');
  };

  render() {
    const {
      uidStage,
      uid,
      title,
      description,
      questions,
      questionChangeBlockTitle,
      questionChangeBlockDesc,
      questionRemoveBlock,
      dragHandle,
      editable,
    } = this.props;

    return (
      <QuestionGroupStyled id={`block-wrapper-${uid}`}>
        <Collapsible
          title={'Grupo'}
          icon={IconGroup}
          onRemove={() => questionRemoveBlock(uidStage, uid)}
          dragHandle={dragHandle}
          verifyCollOpen={this.changeCollOpen}
          editable={editable}
        >
          <FormInputLabelled
            label="Título do grupo"
            onChange={e =>
              questionChangeBlockTitle(e.target.value, uidStage, uid)
            }
            labelSecondary
            style={{ marginBottom: 10 }}
            defaultValue={title}
            disabled={!editable}
          />
          <FormTextArea
            label="Descrição do grupo"
            labelSecondary
            sizeSecondary
            onChange={e =>
              questionChangeBlockDesc(e.target.value, uidStage, uid)
            }
            style={{ marginBottom: 15 }}
            defaultValue={description}
            disabled={!editable}
            required={false}
            name="description"
          />

          {questions.length > 0 && (
            <SortableList
              onSortEnd={this.onSortEnd}
              onSortStart={() => this.props.hidePopupBtn(true)}
              items={questions}
              uidStage={uidStage}
              uidBlock={uid}
              useDragHandle
              lockAxis="y"
              lockToContainerEdges={true}
              disableAutoscroll={true}
              helperClass="helper-drag-class"
              editable={editable}
            />
          )}
        </Collapsible>
        {editable && (
          <div className="question-add-wrapper">
            <QuestionAddBtn etapa={3} uidStage={uidStage} uidBlock={uid} />
          </div>
        )}
      </QuestionGroupStyled>
    );
  }
}

const SortableItem = SortableElement(
  ({ question, uidStage, uidBlock, hidePopupBtn, editable }) => {
    return (
      <div id={`question-wrapper-${question.uid}`} index={question.uid}>
        <QuestionQuestion
          {...question}
          uidStage={uidStage}
          uidBlock={uidBlock}
          key={question.uid}
          dragHandle={<QuestionDragHandle />}
          hidePopupBtn={hidePopupBtn}
          editable={editable}
        />
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, uidStage, uidBlock, hidePopupBtn, editable }) => {
    return (
      <div className="perguntas-wrapper">
        {items.map((question, i) => (
          <SortableItem
            key={i}
            question={question}
            key={question.uid}
            uidStage={uidStage}
            uidBlock={uidBlock}
            index={question.ordem}
            hidePopupBtn={hidePopupBtn}
            editable={editable}
          />
        ))}
      </div>
    );
  }
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions }, dispatch);

export default connect(null, mapDispatchToProps)(QuestionGroup);
