import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as CurrentCycleActions } from '../../../store/ducks/cycle/currentCycle';
import { Creators as CycleActions } from '../../../store/ducks/cycle';

import SubHeader from '../../../Components/SubHeader';
import Back from '../../../Components/Back';

import { Div, WarningMessage } from '../../../styles/global';
import Loading from '../../../Components/Loading';
import ErrorMsg from '../../../Components/ErrorMsg';
import { MainWrapper, Content, MsgStatus, ButtonDiv } from './styles';
import moment from 'moment';
import Dashboard from '../../../Components/Dashboard';

class CycleStatus extends React.Component {
  state = {
    cycle: null,
    cycleId: null,
    ready: false,
    NAO_INICIADO: 'Não iniciado',
    EM_ANDAMENTO: 'Em andamento',
  };
  static getDerivedStateFromProps(props, state) {
    if (props.currentCycle.error || props.currentCycle.success) {
      state.ready = true;
      if (props.currentCycle.success) {
        state.cycle = props.currentCycle.details;
        props.setTitle(`Ciclo - ${state.cycle.title}`);
      }
    }
    return state;
  }

  componentDidMount() {
    let params = this.props.match.params;
    if (!params || !params.id || !params.id.match(/^[0-9]+$/)) {
      return this.setState({ ready: true });
    }
    this.setState(
      {
        cycleId: params.id,
      },
      () => {
        this.props.currentCycleRequest({ id: params.id, dashboard: true });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.cycle.success && this.props.cycle.success) {
      this.setState(
        {
          ready: false,
        },
        () => {
          this.props.currentCycleRequest({
            id: this.state.cycleId,
            dashboard: true,
          });
        }
      );
    }
  }
  renderContent = () => {
    return (
      <Content>
        {this.renderMsgStatus()}
        <MainWrapper>
          {this.state.cycle.situation === this.state.NAO_INICIADO ? (
            <Fragment>
              <WarningMessage>
                Ao iniciar o ciclo, será enviado e-mail para todos os
                participantes do ciclo convidando-os a realizar suas avaliações.
              </WarningMessage>
              <ButtonDiv>
                <button onClick={() => this.actionInitCycle()}>
                  Iniciar o Ciclo
                </button>
              </ButtonDiv>
            </Fragment>
          ) : (
            this.renderDashboard()
          )}
        </MainWrapper>
      </Content>
    );
  };

  renderDashboard = () => {
    return (
      <div>
        <h3>{this.state.cycle.title}</h3>
        <Dashboard currentCycle={this.state.cycle} />
      </div>
    );
  };

  renderMsgStatus = () => {
    return (
      <MsgStatus>
        {this.state.cycle.situation === this.state.NAO_INICIADO ? (
          <span>
            <strong>Este ciclo ainda não foi iniciado,</strong> utilize o botão
            abaixo para dar início ao ciclo:
          </span>
        ) : this.state.cycle.situation === this.state.EM_ANDAMENTO ? (
          <span>
            <strong>
              Ciclo iniciado (
              {moment(this.state.cycle.started_at).format('DD/MM/YYYY')}) ,
            </strong>{' '}
            abaixo você pode acompanhar o andamento do ciclo:
          </span>
        ) : (
          <span>
            <strong>Ciclo concluído,</strong> abaixo você pode acompanhar a
            conclusão do ciclo:
          </span>
        )}
      </MsgStatus>
    );
  };

  actionInitCycle = () => {
    this.props.setCycleInit(this.state.cycleId);
  };

  render() {
    if (!this.state.ready || !this.state.cycle) {
      return <Loading loading={true} />;
    }
    if (this.props.currentCycle.error) {
      return <ErrorMsg msg={this.props.currentCycle.error} />;
    }
    return (
      <Fragment>
        <Div>
          <Back path="/ciclos" title="Voltar para listagem" />
          <SubHeader page="status" cycle={this.state.cycle} />
          {this.renderContent()}
        </Div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CurrentCycleActions, ...CycleActions }, dispatch);

const mapStateToProps = state => ({
  currentCycle: state.currentCycle,
  cycle: state.cycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleStatus);
