import styled from 'styled-components';

export const WrapperStyled = styled.div`
  position: relative;

  .label-select {
    font-size: 16px;
    color: #666666;
    text-transform: uppercase;
  }
`;

export const SelectStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 3px;

  > .left-side {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 10px;
    height: 40px;
    cursor: pointer;

    > .placeholder {
      text-transform: uppercase;
      font-size: 12px;
      color: #666;
    }
  }

  > .right-side {
    display: flex;

    > .clear-wrapper {
      display: flex;
      align-items: center;
      padding-right: 10px;

      > button {
        background: transparent;
        font-size: 12px;
        border: 1px solid #ccc;
        border-radius: 3px;
        padding: 3px 10px;
        color: #666;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    > .arrow-wrapper {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #ccc;
      width: 50px;
      height: 40px;
      svg {
        path {
          fill: #666;
        }
      }
    }
  }
`;

export const PopupStyled = styled.div`
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 99;
  position: absolute;
  width: 100%;
  min-width: 300px;
  transform: translate(0, 10px);
  display: ${props => props.isHidden && 'none'};

  > .header {
    display: flex;
    padding: 15px;
    position: relative;
    flex-direction: column;

    > .arrow-wrapper {
      position: absolute;
      transform: translate(8px, -30px);

      > div {
        width: 15px;
        height: 15px;
        display: flex;
        overflow: hidden;

        > div {
          background-color: #fff;
          width: 15px;
          height: 15px;
          transform: rotate(-45deg) translate(-8px, 8px);
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
        }
      }
    }

    > input {
      width: 100%;
      height: 40px;
      padding-left: 10px;
      border: 1px solid #ccc;
      border-radius: 3px;
    }
  }

  > .list-wrapper {
    height: auto;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;

    > .nenhum-item {
      padding: 0 15px 15px;
      display: block;
      font-style: italic;
    }
  }
`;

export const ItemStyled = styled.li`
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    > span {
      opacity: 0.8;
    }
  }

  &:first-child {
    border-top: 1px solid #ccc;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  > svg {
    path {
      fill: #ccc;
    }
  }
`;
