import styled from "styled-components";

export const Back = styled.div`
  height: 60px;
  min-height: 60px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 25px;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    text-transform: uppercase;
    font-size: 114x;
    line-height: 19px;
    color: #00468e;
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 25px 10px 25px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  padding: 60px 100px;

  .form {
    display: flex;
    flex-direction: column;

    > div {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
`;

export const Input = styled.div`
  width: 100%;
  height: 35px;
  border: #ccc 1px solid;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    border: 0;
    width: 100%;
    margin-left: 5px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 80px;
  width: 100%;

  button {
    width: 160px;
    height: 40px;
    background-color: #999999;

    border: 0;
    border-radius: 4px;

    a {
      text-transform: uppercase;
      color: #fff;
      text-decoration: none;
    }

    :last-child {
      margin-left: 60px;
      background-color: #00468e;
    }
  }
`;
