import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OfficesActions from '../../store/ducks/select/offices';
import SelectWithPopupRedux from '../SelectWithPopupRedux';

const mapDispatchToProps = dispatch => bindActionCreators({
  request: OfficesActions.officesRequest,
  clear: OfficesActions.officesResetRequest,
}, dispatch);

const mapStateToProps = state => ({
  error: state.offices.error,
  success: state.offices.success,
  loading: state.offices.loading,
  data: state.offices.data
});

const Component = connect(mapStateToProps, mapDispatchToProps)(SelectWithPopupRedux);

Component.defaultProps = {
  placeholder: 'Escritório'
};

export default Component;
