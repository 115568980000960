import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  cycleQuizzesFormRequest: ["payload"],
  cycleQuizzesFormSuccess: ["message"],
  cycleQuizzesFormError: ["error"],
  cycleQuizzesFormClearError: [],
  cycleQuizzesFormRequestResetStatus: [],
  cycleQuizzesFormResetStatus: [],
  cycleQuizzesFormLoading: null
});

export const CycleQuizzesFormTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  error: false,
  success: false,
  loading: false
});

export const success = (state, { message }) => {
  return state.merge({
    success: message,
    error: false,
    loading: false
  });
};

export const resetStatus = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CYCLE_QUIZZES_FORM_SUCCESS]: success,
  [Types.CYCLE_QUIZZES_FORM_ERROR]: error,
  [Types.CYCLE_QUIZZES_FORM_CLEAR_ERROR]: clearError,
  [Types.CYCLE_QUIZZES_FORM_LOADING]: loading,
  [Types.CYCLE_QUIZZES_FORM_RESET_STATUS]: resetStatus
});
