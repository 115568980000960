import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';

import { ButtonDiv, ButtonDisabled } from './styles';

import {
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../../../Components/Table/styles';

const TableSelecionados = props => {
  const { formularios: forms } = props;

  if (forms.length === 0) {
    return null;
  }

  function handleFormCheck(e, uid, type) {
    props.formChecked(e.target.value, e.target.checked, uid, type);
  }

  return (
    <TableDiv>
      <Thead>
        <Tr>
          <th>ID</th>
          <th>Título</th>
          <th>Descrição</th>
          <th>Tipo</th>
          <th>Criado em</th>
          <th>Edição</th>
          <th> </th>
        </Tr>
      </Thead>
      <Tbody>
        {forms.map(form => (
          <Tr key={form.uid}>
            <td>{form.id}</td>
            <td>
              <Link to={`/formularios/${form.id}/dados`}>{form.title}</Link>
            </td>
            <td>{form.description}</td>
            <td>{form.type ? form.type : 'formulário'}</td>
            <td>{moment(form.created_at).format('DD/MM/YYYY')}</td>
            <td>
              {form.type !== 'modelo' && form.editable === true ? (
                <ButtonDiv>
                  <button onClick={() => props.redirectEditForm(form.id)}>
                    Editar
                  </button>
                </ButtonDiv>
              ) : (
                <>
                  <ReactTooltip effect="solid" />
                  <ButtonDisabled>
                    <button data-tip="Não é possível editar este formulário pois já possuem respostas vinculadas a ele">Editar</button>
                  </ButtonDisabled>
                </>
              )}
            </td>
            <td>
              {form.type !== 'modelo' && form.editable === true && (
                <input
                  type="checkbox"
                  value={form.id}
                  onChange={e => handleFormCheck(e, form.uid, form.type)}
                  defaultChecked={props.isChecked}
                />
              )}
            </td>
          </Tr>
        ))}
      </Tbody>
    </TableDiv>
  );
};

export default TableSelecionados;
