import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "rating-answer/POST_REQUEST",
  GET_SUCCESS: "rating-answer/GET_SUCCESS",
  SET_ERROR: "rating-answer/SET_ERROR",
  SET_RATING: "rating-answer/SET_RATING",
  GET_RATING: "rating-answer/GET_RATING",
  RESET_REQUEST: "rating-answer/RESET_REQUEST",
  RESET_SUCCESS: "rating-answer/RESET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  error: null,
  loading: false,
  success: false,
  value: null
});

export default function ratingAnswer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return {
        ...state,
        loading: true,
        value: null,
        error: null,
        success: false
      };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        success: action.payload.message,
        loading: false,
        value: null,
        error: null
      };
    case Types.SET_RATING:
      return {
        ...state,
        loading: false,
        value: action.payload.value,
        error: null,
        success: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, value: null, success: false };
    case Types.SET_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
        value: null,
        error: action.payload
      };
    case Types.RESET_SUCCESS:
      return {
        data: [],
        success: false,
        error: null,
        loading: false,
        value: null
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postRatingAnswerRequest: (courseRatingId, rating, comment) => ({
    type: Types.POST_REQUEST,
    payload: { courseRatingId, rating, comment }
  }),

  getRatingAnswerSuccess: (data, message) => ({
    type: Types.GET_SUCCESS,
    payload: { data, message }
  }),

  setRatingAnswerError: error => ({
    type: Types.SET_ERROR,
    payload: error
  }),

  setRatingValue: value => ({
    type: Types.SET_RATING,
    payload: { value }
  }),

  resetRatingsAnswerRequest: () => ({
    type: Types.RESET_REQUEST
  }),

  resetRatingsAnswerSuccess: () => ({
    type: Types.RESET_SUCCESS
  })
};
