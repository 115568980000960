import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  cycleDataFormRequest: ["payload"],
  cycleDataFormSuccess: ["cycle", "message"],
  cycleDataFormError: ["error"],
  cycleDataFormClearError: [],
  cycleDataFormRequestResetStatus: [],
  cycleDataFormResetStatus: [],
  cycleDataFormRequestResetAll: [],
  cycleDataFormResetAll: [],
  cycleDataFormLoading: null
});

export const CycleDataFormTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  cycle: null,
  error: false,
  success: false,
  loading: false
});

export const success = (state, { cycle, message }) => {
  return state.merge({
    cycle,
    success: message,
    error: false,
    loading: false
  });
};

export const resetAll = state => {
  return state.merge({
    cycle: null,
    error: false,
    loading: false,
    success: false
  });
};

export const resetStatus = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CYCLE_DATA_FORM_SUCCESS]: success,
  [Types.CYCLE_DATA_FORM_ERROR]: error,
  [Types.CYCLE_DATA_FORM_CLEAR_ERROR]: clearError,
  [Types.CYCLE_DATA_FORM_LOADING]: loading,
  [Types.CYCLE_DATA_FORM_RESET_STATUS]: resetStatus,
  [Types.CYCLE_DATA_FORM_RESET_ALL]: resetAll
});
