import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  padding: 8px;
  width: 100%;
  height: 284px;
  margin-top: 40px;
`;

export const DivInput = styled.div`
  height: 65px;
  width: 360px;
  margin-bottom: 20px;

  label {
    color: #333;
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }

  input {
    border-radius: 4px;
    height: 40px;
    border: #ccc solid 1px;
    font-size: 16px;
    color: #666666;
    width: 100%;
    padding: 10px;
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  padding: 8px;
  width: 100%;
  height: 80px;
  margin-top: 20px;
`;

export const Button = styled.button`
  font-family: "Open Sans";
  height: 40px;
  width: 146px;
  background-color: #295ba6;
  color: #fff;
  text-transform: uppercase;
  margin: 10px 10px;
  padding:10px 12px
  border: 0;
  border-radius: 5px;
  :hover {
    opacity: 0.8;
 }
`;

export const ButtonCancel = styled.button`
  font-family: "Open Sans";
  height: 40px;
  width: 146px;
  background-color: #999999;
  color: #fff;
  text-transform: uppercase;
  margin: 10px 10px;
  padding:10px 12px
  border: 0;
  border-radius: 5px;
  :hover {
    opacity: 0.8;
 }
`;

export const Form = styled.form`
  width: 100px;
  display: block;
  margin: 0;
`;

export const HelpBox = styled.div`
  width: 360px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;
