import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/answer/findAnswer";

export function* resetFindAnswer() {
  yield put(Actions.findAnswersReset());
}

export function* requestFindAnswer({ cycleUserId }) {
  yield put(Actions.findAnswersClearError());
  yield put(Actions.findAnswersLoading());

  try {
    let url = `api/v1/answers/${cycleUserId}`;
    let result = yield call(api.get, url);
    yield put(Actions.findAnswersSuccess(result.data.details));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(Actions.findAnswersError(response.messages[0]));
        }
      }
    }
    yield put(
      Actions.findAnswersError("Ocorreu um erro no servidor, tente mais tarde.")
    );
  }
}
