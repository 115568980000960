import styled from 'styled-components';

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  overflow-y: scroll;
`;

export const InfoDiv = styled.div`
  display: flex;
  margin: 25px;

  span {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
  }
`;

export const Filtro = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 22px 28px;
  margin-bottom: 5px;

  label {
    color: #666666;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  }
`;

export const NoMorePageMargin = styled.div`
  margin-bottom: 60px;
`;

export const Selecionados = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  .geral {
    display: flex;
    align-items: center;

    span {
      font-size: 18px;
      line-height: 24px;
      color: #00468e;
    }
  }

  .buttons {
    display: flex;
  }

  .download-buttons {
    display: flex;
    margin-right: 30px;

    button {
      padding: 10px 20px 0 2px;

      img {
        height: 26px;
        width: 26px;
        margin-right: 5px;
        margin-bottom: 15px;
      }
    }

    .pdf-button {
      background-color: #fe2712;
    }

    .xls-button {
      background-color: #237732;
    }
  }

  .remover {
    display: flex;
    align-items: center;
  }

  button {
    border: 0;
    background-color: #00468e;
    height: 40px;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;

    &[disabled] {
      background-color: #ccc;
    }

    :not(:last-child) {
      margin-right: 10px;
    }

    :not([disabled]):hover {
      opacity: 0.8;
    }
  }
`;

export const SelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  background-color: #fff;

  label {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

export const Selects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;

  .selects {
    flex: 1;
    :not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const Table = styled.table`
  display: table;
  margin: 35px 25px;
  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: middle;
  border-color: inherit;

  th {
    text-align: center;
    margin: 0;
    padding: 0;
    height: 40px;
    background-color: #e7e7e7;
    color: #666666;
    font-size: 14px;

    img {
      height: 15px;
      width: 15px;
      margin-left: 5px;
    }

    input:hover {
      cursor: pointer;
    }
  }
`;

export const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: left;
  border-color: inherit;

  border-spacing: 0px;
  border-collapse: separate;

  tr {
    display: table-row;
    vertical-align: left;
    border-color: inherit;

    td {
      text-align: center;
      height: 50px;
      background-color: #fff;
      border-bottom: #ccc solid 1px;
      font-size: 14px;
      color: #33333333;
      padding-left: 25px;
      vertical-align: left;
    }
  }
`;
