import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AnswerActions from "../../../store/ducks/answer/answer";
import FindAnswerActions from "../../../store/ducks/answer/findAnswer";

import UserDetail from "../UserDetail";

class UserStage extends React.Component {
  state = {
    disabled: false
  };

  render() {
    return (
      <Fragment>
        <UserDetail match={this.props.match} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AnswerActions, ...FindAnswerActions }, dispatch);

const mapStateToProps = state => ({
  requestAnswer: state.answer,
  findAnswer: state.findAnswer
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStage);
