import React from 'react';
import { ErrorStyled } from './styles';

export default function ErrorMsg(props) {
  return (
    <ErrorStyled isWarning={props.isWarning}>
        <span>{props.msg}</span>
    </ErrorStyled>
  )
}
