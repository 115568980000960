import styled from 'styled-components'

export const ResultCountAndFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    font-size: 14px;
    font-family: 'OS Bold', sans-serif;
    color: #535353;
  }

  .dropdown-btn-wrapper {
    position: relative;
    user-select: none;

    > div:first-child {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .dropdown {
      position: absolute;
      left: 0;
      background-color: #ddd;
      border: 1px solid #ccc;
      border-top: 0;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      ul {
        list-style: none;
        margin: 0;
        li {
          padding: 5px 10px;
          font-size: 12px;
          cursor: pointer;
          :hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
`
