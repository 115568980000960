import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as UserActions } from "../../../store/ducks/admin/user";
import UserInfo from "./UserInfo";

const mapDispatchToProps = dispatch => bindActionCreators({
  getUserRequest: UserActions.getUserRequest
}, dispatch);

const mapStateToProps = ({ user }) => ({
  user: user.data,
  success: user.success,
  error: user.error,
  loading: user.loading
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfo);
