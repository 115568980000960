import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Creators from "../../ducks/select/roles";

export function* requestRoles({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.rolesClearError());
  yield put(Creators.rolesLoading());

  try {
    let url = `api/v1/select/roles?paginate=true`;
    if (!!text) {
      url += "&text=" + text;
    }

    let response = yield call(api.get, encodeURI(url));
    yield put(Creators.rolesSuccess(response.data.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.rolesError(error));
      }
    }
    yield put(
      Creators.rolesError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* requestResetRoles(z) {
  yield put(Creators.rolesReset());
}
