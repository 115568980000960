import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as ReasonActions } from '../../ducks/reason/reason';
import { Creators as ReasonsActions } from '../../ducks/reason/reasons';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';

export function* getReason(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/admin/reasons/${action.payload.id}`
    );

    yield put(ReasonActions.getReasonSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* updateReason(action) {
  const { id, notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.put, `/api/v1/admin/reasons/${id}`, data);
    yield put(ReasonActions.updateReasonSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Justificativa atualizada com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* insertReason(action) {
  const { notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.post, '/api/v1/admin/reasons/', data);
    yield put(ReasonActions.insertReasonSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Justificativa incluída com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* deleteReason(action) {
  const { id } = action.payload;
  try {
    yield call(api.delete, `/api/v1/admin/reasons/${id}`);

    yield put(ReasonsActions.getReasonsRequest())
    yield put(
      SuccessActions.setSuccess('Justificativa deletada com sucesso!', {
        toasted: true,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.error, { toasted: true }));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
