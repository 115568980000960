import React, { Component, Fragment } from "react";

import Nav from "../Components/Nav";
import Header from "../Components/Header";

import { MainWrapper, Content } from "../styles/components";
import { AppDiv } from "../styles/app";

import { GlobalStyle } from "../styles/global";

import { Route, Redirect } from "react-router-dom";

export default class AdminRoute extends Component {
  state = {
    title: null,
  };

  setTitle = title => {
    this.setState({ title });
  }

  render() {

    if (this.props.profile !== "admin") {
      return <Redirect to='/' />
    }
    const { component: Component, ...props } = this.props;

    return (
      <Fragment>
        <MainWrapper>
          <GlobalStyle />
          <Nav location={this.props.location} />
          <Content fromDadosGerais={this.props.location.pathname.includes('dadosgerais')}>
            <AppDiv>
              <Header isAdmin location={this.props.location} title={this.state.title} setTitle={this.setTitle} />
              <Route {...props} render={props => <Component {...props} setTitle={this.setTitle} />} />
            </AppDiv>
          </Content>
        </MainWrapper>
      </Fragment>
    );
  }
}
