import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import CycleQuizzesActions from "../../ducks/cycle/cycleQuizzes";

export function* fetchQuizzesByCycle({ cycleId }) {
  yield put(CycleQuizzesActions.cycleQuizzesClearError());
  yield put(CycleQuizzesActions.cycleQuizzesLoading());
  
  try {
    let result = yield call(api.get, `api/v1/admin/cycles/${cycleId}/quizzes`);
    yield put(CycleQuizzesActions.cycleQuizzesSuccess(result.data.details));
  } catch (error) {
    
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            CycleQuizzesActions.cycleQuizzesError(response.messages[0])
          );
        }
      }
    }
    yield put(
      CycleQuizzesActions.cycleQuizzesError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
