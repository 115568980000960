import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;

  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    span {
      color: #00468e;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;

    img {
      height: 120px;
      width: 120px;
    }

    span {
      margin-top: 10px;
      color: #00468e;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    }
  }

  .text {
    display: flex;
    width: 100%;
    padding: 50px 25px;
    justify-content: center;
    align-items: center;

    p {
      color: #666666;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      background-color: #00468e;
      color: #fff;
      padding: 10px 0;
      text-transform: uppercase;
      border-radius: 5px;
      width: 120px;
      text-align: center;

      :hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
`;
