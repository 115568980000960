import React from 'react'
import { MediaNotaBoxWrapper } from './styles';

export default function MediaNotaBox(props) {
  return (
    <MediaNotaBoxWrapper isMediaRevisado={props.isMediaRevisado ? props.isMediaRevisado : null}>
      <span>
        {props.isMediaRevisado ? 'Revisado' : 'Resultado'}
      </span>
      <div>
        <span className='nota'>{props.nota}</span>
      </div>
    </MediaNotaBoxWrapper>
  )
}
