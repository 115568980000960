import React, { Fragment, Component } from "react";

import { Div } from "../../../../styles/global";

import SubHeader from "../../../../Components/SubHeader";

import SearchIcon from "../../../../assets/images/search.svg";

import { Content } from "./styles";
import AtividadeFluxo from "./atividade";

class Fluxo extends Component {

  render() {
    return (
      <Fragment>
        <Div>
          <SubHeader page="fluxo" />

          <Content>
            <div className="main">
              <div className="label">
                <label>SELECIONAR ATIVIDADE</label>
              </div>
              <div className="content">
                <div className="search">
                  <input type="text" placeholder="Buscar" />

                  <img src={SearchIcon} alt="Pesquisar" />
                </div>
                <div className="button">
                  <button>
                    <a href="#!">nova atividade</a>
                  </button>
                </div>
              </div>

              <AtividadeFluxo />

            </div>
          </Content>
        </Div>
      </Fragment>
    )
  }

}

export default Fluxo;
