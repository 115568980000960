import { call, put } from 'redux-saga/effects';

import { Creators as RatingAnswerActions } from '../../../ducks/modules/courses/ratingAnswer';
import { Creators as ErrorActions } from '../../../ducks/error';

import api from '../../../../services/api';

export function* postRatingAnswer(action) {
  try {
    const response = yield call(api.post, '/api/v1/ratinganswers', {
      course_rating_id: action.payload.courseRatingId,
      record: action.payload.rating,
      comment: action.payload.comment,
    });
    yield put(
      RatingAnswerActions.getRatingAnswerSuccess(
        response.data.details,
        response.data.messages[0]
      )
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* setRatingValue(action) {}
export function* resetRatingAnswer() {
  yield put(RatingAnswerActions.resetRatingsAnswerSuccess());
}
