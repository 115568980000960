import api from '../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as UsersActions } from '../../ducks/admin/users';
import { Creators as ErrorActions } from '../../ducks/error';

export function* getUsers(action) {
  try {
    let url = '/api/v1/users?'
    if (action.payload.page) {
      url += `page=${action.payload.page}&`
    }

    if (action.payload.name) {
      url += `text=${action.payload.name}`
    }

    const response = yield call(api.get, url);
    yield put(UsersActions.getUsersSuccess(response.data));
  } catch (err) {
    yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.'));
  }
}
