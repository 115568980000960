import React from 'react';

import { ButtonDiv } from './styles';

const Button = props => (
  <ButtonDiv>
    {props.onCancel ? (
      <button type="button" onClick={props.onCancel}>cancelar</button>
    ) : (
      <button type="button">cancelar</button>
    )}
    {props.onSubmit ? (
      <button type="button" onClick={props.onSubmit} disabled={props.disabled}>
        próximo
      </button>
    ) : (
      <button type="submit" disabled={props.disabled}>
        próximo
      </button>
    )}
  </ButtonDiv>
);

export default Button;
