import styled from "styled-components";

export const BodyStyled = styled.div`
  display: flex;
  flex: 1;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  button {
    padding: 10px 25px;
    border-radius: 5px;
    color: #fff;
    background-color: #999999;
    text-transform: uppercase;
    border: 0;
    :hover {
      opacity: 0.8;
    }

    &.primary {
      background-color: #00468e;
      margin-left: 15px;
    }
  }
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const LogoContainer = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 215px;
    margin-bottom: 25px;
  }
`;
