import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import UpdateCycleUserStatusActions from "./../../../store/ducks/cycleUser/updateCycleUserStatus";
import Loading from "../../../Components/Loading";

class UserSendForm extends React.Component {
  state = {
    init: false,
    ready: false,
    request: false,
    redirectToRevision: false
  };

  componentDidMount() {
    this.props.updateCycleUserStatusRequestReset();
    this.setState({ init: true });
  }

  componentDidUpdate() {
    if (!this.state.ready) {
      return;
    }
    if (this.state.request) {
      if (
        this.props.updateCycleUserStatus.success ||
        this.props.updateCycleUserStatus.error ||
        this.props.userCycle.userCycle.status == "Preenchido"
      ) {
        if (!this.state.redirectToRevision) {
          this.props.updateCycleUserStatusRequestReset();
          this.setState({ redirectToRevision: true });
        }
      }
      return;
    }
    if (this.props.userCycle.userCycle.status == "Preenchido") {
      this.setState({ ready: false, request: true });
    } else {
      this.setState({ request: true }, () => {
        this.props.updateCycleUserStatusRequest(
          this.props.userCycle.userCycle.id,
          "Preenchido"
        );
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.ready) {
      if (
        props.userCycle.success &&
        !props.updateCycleUserStatus.success &&
        !props.updateCycleUserStatus.error &&
        !props.updateCycleUserStatus.loading
      ) {
        state.ready = true;
      }
    }
    return state;
  }

  render() {
    if (this.state.redirectToRevision) {
      return <Redirect to={`/user/send-revision`} />;
    }
    return <Loading loading={true} />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UpdateCycleUserStatusActions }, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
  updateCycleUserStatus: state.updateCycleUserStatus
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSendForm);
