import styled, { css } from 'styled-components';
import DownIcon from '../../assets/images/down.svg';

export const ButtonSearchStyled = styled.div`
  button {
    border-radius: 5px;
    background-color: #fff;
    margin-right: 2px;
    width: 40px;
    :hover {
      background-color: #ddd;
    }
    img {
      height: 30px;
    }
  }
`;

export const LabelStyled = styled.label`
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;

  ${props =>
    props.labelSecondary &&
    css`
      text-transform: none;
      font-size: 14px;
    `}
`;

export const DefaultStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  input,
  select,
  textarea {
    width: 100%;
    height: 36px;
    border: 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;

    &:disabled {
      background-color: rgb(239, 239, 239);
    }
  }

  textarea {
    min-height: ${props => (!props.sizeSecondary ? 180 : 80)}px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding: 0 10px;

    option {
      :first-child {
        color: #ccc;
      }
    }
  }

  input {
    ${props =>
      props.icon &&
      css`
        background-image: url(${props.icon});
        background-repeat: no-repeat;
        background-position: 99%;
        background-size: 20px;
      `}
  }
`;

export const SelectStyled = styled(DefaultStyled)`
  select {
    background-image: url(${DownIcon});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 22px;
  }
`;

export const InputRadioStyled = styled.div`
  label {
    margin: 0 20px 0 3px;
  }
`;
