import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CiclesActions from '../../store/ducks/select/cicles';
import SelectWithPopupRedux from '../SelectWithPopupRedux';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      request: CiclesActions.ciclesRequest,
      clear: CiclesActions.ciclesResetRequest,
    },
    dispatch
  );

const mapStateToProps = state => ({
  error: state.cicles.error,
  success: state.cicles.success,
  loading: state.cicles.loading,
  data: state.cicles.data,
});

const Component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectWithPopupRedux);

Component.defaultProps = {
  placeholder: 'Ciclo',
};

export default Component;
