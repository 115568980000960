import React, { Component } from "react";
import { NumberNotaRevisorWrapper } from "./styles";

import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import MdClose from "react-ionicons/lib/MdClose";

export default class NumberNotaRevisor extends Component {
  state = {
    isAwnsered: false
  };

  handleAcceptNota(e, accepted) {
    this.setState({ isAwnsered: true });
    e.currentTarget.classList.add("chosen");
    this.props.onClick(accepted);
  }

  render() {
    const { isAwnsered } = this.state;
    return (
      <NumberNotaRevisorWrapper>
        <div className="header-wrapper">
          <div className="header">{this.props.nota}</div>
        </div>
        <div
          className={`btns ${!isAwnsered ? `nao-respondida` : "respondida"}`}
        >
          <div
            onClick={!isAwnsered ? e => this.handleAcceptNota(e, true) : null}
          >
            <MdCheckmark fontSize="32px" color="#009B4C" />
          </div>
          <div
            onClick={!isAwnsered ? e => this.handleAcceptNota(e, false) : null}
          >
            <MdClose fontSize="32px" color="#009B4C" />
          </div>
        </div>
      </NumberNotaRevisorWrapper>
    );
  }
}
