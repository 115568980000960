import React from 'react';
import PropTypes from 'prop-types';

import SelectWithPopup from '../SelectWithPopup';

class SelectWithPopupRedux extends React.Component {

  componentDidMount() {
    this.props.request();
  }

  componentWillUnmount() {
    this.props.clear();
  }

  handleOnChangeSearch = text => this.props.request({ text });

  handleOnSelectItem = functionSelected => {
    if (this.props.onSelect instanceof Function) {
      this.props.onSelect(functionSelected);
    }
  }

  renderSelect = () => <SelectWithPopup
    list={this.props.data}
    placeholder={this.props.placeholder}
    onSelectItem={this.handleOnSelectItem}
    onChangeSearch={this.handleOnChangeSearch}
    label={this.props.label}
    value={this.props.value}
    valueBy={this.props.valueBy}
  />

  render() {
    const { error, loading, success } = this.props;

    if (error) { }

    if (loading) { }

    if (success) { }

    return this.renderSelect();
  }
}

SelectWithPopupRedux.propTypes = {
  placeholder: PropTypes.string.isRequired,
  success: PropTypes.any.isRequired,
  error: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  request: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  onSelect: PropTypes.func
}

export default SelectWithPopupRedux;
