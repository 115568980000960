import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  overflow-y: scroll;
`;

export const Content = styled.div`
  margin: 0 25px;
  min-height: 1000px;
  padding: 32px 64px 32px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  min-height: 180px;

  .title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;

    span {
      color: #999999;
      font-size: 16px;
      line-height: 22px;

      :first-child {
        color: #00468e;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }

  .score {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    div {
      height: 60px;
      width: 150px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #467cc2;
      border-radius: 5px;
      margin-right: 5px;

      span {
        font-size: 8px;
        line-height: 11px;
        color: #fff;
        text-transform: uppercase;
        white-space: nowrap;
      }

      p {
        margin-top: 2px;
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        text-transform: uppercase;
      }
    }
  }
`;

export const BackPage = styled.div`
  height: 50px;
  margin-left: 25px;
  display: flex;
  align-items: center;

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  span {
    color: #00468e;
    font-size: 14px;
    line-height: 19px;
  }
`;
