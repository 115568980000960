import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  findAnswersRequest: ["cycleUserId"],
  findAnswersResetRequest: null,
  findAnswersSuccess: ["answers"],
  findAnswersError: ["error"],
  findAnswersClearError: null,
  findAnswersReset: null,
  findAnswersLoading: null
});

export const FindAnswerTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  answers: [],
  error: false,
  success: false,
  loading: false
});

/* Reducers */
export const success = (state, { answers }) => {
  return state.merge({
    answers,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    answer: [],
    loading: false,
    error: false,
    success: false
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIND_ANSWERS_SUCCESS]: success,
  [Types.FIND_ANSWERS_ERROR]: error,
  [Types.FIND_ANSWERS_CLEAR_ERROR]: clearError,
  [Types.FIND_ANSWERS_LOADING]: loading,
  [Types.FIND_ANSWERS_RESET]: reset
});
