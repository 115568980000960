import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "offer/GET_REQUEST",
  GET_SUCCESS: "offer/GET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  currentEnrollment: {}
});

export default function offer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        currentEnrollment: action.payload.data.currentEnrollment,
        data: action.payload.data,
        loading: false,
        success: true
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getOfferRequest: (id, enrollmentId) => ({
    type: Types.GET_REQUEST,
    payload: { id, enrollmentId }
  }),

  getOfferSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  })
};
