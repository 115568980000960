import React from "react";

import { Content } from "./style";

import BackIcon from "../../assets/images/backArrow.svg";

const Back = (props) => (
  <Content>
    <a href={props.path}>
      <img src={BackIcon} alt="voltar" />
      <span style={{ paddingLeft: '5px' }}>{props.title}</span>
    </a>
  </Content>
);

export default Back;
