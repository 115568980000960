import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import AuthActions from "../ducks/auth";
import UserInfoActions from "../ducks/user/userInfo";
import { getUserInfo } from "./user/userInfo";

const axios = require("axios");

export function* signIn({ username, password }) {
  yield put(AuthActions.signInClearError());
  yield put(AuthActions.signInLoading());
  try {
    const response = yield call(api.post, "api/v1/auth/signin", {
      username,
      password
    });

    localStorage.setItem("@emae:token", response.data.token);
    localStorage.setItem("@emae:refreshToken", response.data.refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${
      response.data.token
    }`;
    axios.defaults.headers.common["Accept-Language"] = "pt-BR";
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common.Accept = "application/json";

    yield put(
      AuthActions.signInSuccess(response.data.token, response.data.refreshToken)
    );
    yield* getUserInfo();
  } catch (err) {
    if (!!err.request) {
      let request = err.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        return yield put(AuthActions.signInError(response.messages[0]));
      }
    }
    yield put(
      AuthActions.signInError("Ocorreu um erro no servidor, tente mais tarde.")
    );
  }
}

export function* loadLocalUser() {
  const token = localStorage.getItem("@emae:token");
  const refreshToken = localStorage.getItem("@emae:refreshToken");
  if (!token || !refreshToken) {
    return yield put(AuthActions.signInError("Usuário não encontrado"));
  }
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  axios.defaults.headers.common["Accept-Language"] = "pt-BR";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common.Accept = "application/json";
  yield put(AuthActions.signInSuccess(token, refreshToken));
}

export function* signOut() {
  localStorage.removeItem("@emae:token");
  localStorage.removeItem("@emae:refreshToken");
  localStorage.removeItem("@emae:userinfo");
  yield put(UserInfoActions.userInfoReset());
  yield put(AuthActions.signOut());
}
