import styled from "styled-components";

export const DefinirSenhaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  min-height: 400px;
  width: 80%;
  max-width: 650px;

  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 30px 20px;
  flex: 1;

  img {
    width: 200px;
    margin-bottom: 50px;
    margin-top: 25px;
  }

  p {
    color: #8c8c8c;
    text-transform: uppercase;
    font-size: 14px;
    width: 230px;
    text-align: center;
    font-weight: 600;
  }

  @media screen and (max-width: 560px) {
    padding-bottom: 40px;

    img {
      margin-bottom: 20px;
    }
  }
`;
export const RightSide = styled.div`
  background-color: #ddd;
  padding: 35px;
  display: flex;
  align-items: center;
  flex: 2;

  form {
    width: 100%;

    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      justify-content: space-between;

      label {
        color: #666666;
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      input {
        height: 40px;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid #ccc;
      }

      button {
        border: 0;
        width: 100%;
        height: 40px;
        color: #fff;
        padding: 10px;
        text-transform: uppercase;
        background-color: #00468e;
        border-radius: 5px;
        margin-top: 30px;
        :hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
