import React, { Component } from "react";
import MdMore from "react-ionicons/lib/MdMore";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import MdSearch from "react-ionicons/lib/MdSearch";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";

import HeaderMenu from "./components/HeaderMenu";
import DropdownMenuItem from "../../Courses/Content/components/DropdownMenuItem";
import { BoxWhiteRounded } from "../../../../styles/global";
import {
  HeaderStyled,
  HeaderCourseContentStyled,
  DropdownMenuContent
} from "./styles";

export class Header extends Component {
  render() {
    const { title } = this.props;
    return (
      <BoxWhiteRounded>
        <HeaderStyled>
          <div className="title-wrapper">
            <h1>{title}</h1>
          </div>
        </HeaderStyled>
      </BoxWhiteRounded>
    );
  }
}

export class CategoryHeader extends Component {
  state = {
    isSearchModeActive: false
  };

  handleSearchMode = () => {
    this.setState({ isSearchModeActive: !this.state.isSearchModeActive });
  };

  handleOnChangeSearch = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { title, labelTitle, active, id } = this.props;
    const { isSearchModeActive } = this.state;
    return (
      <BoxWhiteRounded>
        <HeaderStyled>
          <span className="label-title">{labelTitle}</span>
          {!isSearchModeActive ? (
            <div className="title-wrapper">
              <h1>{title}</h1>
              <div
                onClick={this.handleSearchMode}
                className="search-icon-wrapper"
              >
                <MdSearch fontSize="26px" color="#ACACAC" />
              </div>
            </div>
          ) : (
            <div className="hidden-search-wrapper">
              <div
                onClick={this.handleSearchMode}
                className="search-icon-wrapper search-icon-wrapper--back"
              >
                <MdArrowBack fontSize="26px" color="#ACACAC" />
              </div>
              <input
                type="search"
                autoFocus
                placeholder="Digite aqui"
                onChange={this.handleOnChangeSearch}
              />
            </div>
          )}

          <HeaderMenu id={id} active={active} />
        </HeaderStyled>
      </BoxWhiteRounded>
    );
  }
}

export class CourseContentHeader extends Component {
  state = {
    isMenuOpen: false,
    topics: this.props.chapters.length > 0 ? this.props.chapters : []
  };
  render() {
    const { isModalOpen, topics } = this.state;
    return (
      <BoxWhiteRounded style={{ padding: "0", position: "relative" }}>
        <HeaderCourseContentStyled>
          <div onClick={() => this.props.history.push(this.props.backRoute)}>
            <IosArrowBack fontSize="18px" color="#666" />
          </div>
          <div
            onClick={() => this.setState({ isModalOpen: !isModalOpen })}
            className={isModalOpen ? "active" : null}
          >
            <MdMore fontSize="28px" color="#00468e" />
          </div>
          <div className="title-wrapper">
            <h1>{this.props.courseTitle}</h1>
          </div>
        </HeaderCourseContentStyled>
        {isModalOpen && (
          <DropdownMenuContent>
            <div className="header">
              <span>
                {this.props.countCompleted} de {this.props.chapters.length}
                concluído
              </span>
            </div>
            <div className="body">
              <ul>
                {topics.map((item, i) => (
                  <DropdownMenuItem
                    title={item.title}
                    isActive={item.isActive}
                    isCompleted={item.completed}
                    onClick={() => {
                      this.props.topicClick(item);
                    }}
                    key={i}
                  />
                ))}
              </ul>
            </div>
          </DropdownMenuContent>
        )}
      </BoxWhiteRounded>
    );
  }
}
