import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Creators as DepartmentReportActions } from '../../ducks/reports/departmentReport';
import { Creators as ErrorActions } from '../../ducks/error';

export function* requestDepartmentReport({ payload }) {
  const { page, filters = {} } = payload;
  yield put(DepartmentReportActions.departmentReportClear());

  try {
    let url = `api/v1/admin/cycle/report/department?page=${page || 1}`;

    if (!!filters.department) {
      url += '&department=' + filters.department;
    }
    if (!!filters.cycle) {
      url += '&cycle=' + filters.cycle;
    }

    let response = yield call(api.get, url);
    yield put(DepartmentReportActions.departmentReportSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 403) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(DepartmentReportActions.departmentReportError(error));
      }
    }
    yield put(
      DepartmentReportActions.departmentReportError(
        'Nenhum colaborador encontrado com essas informações'
      )
    );
  }
}

export function* requestDepartmentDownload({ payload }) {
  const { filters = {} } = payload;

  try {
    let url = 'api/v1/admin/cycle/report/department/download?all=true';
    if (!!filters.department) {
      url += '&department=' + filters.department;
    }
    if (!!filters.cycle) {
      url += '&cycle=' + filters.cycle;
    }
    if (!!filters.type) {
      url += '&type=' + filters.type;
    }

    let response = yield call(api.get, url, {
      responseType: 'arraybuffer',
    });
    showFile(response.data, response.headers['content-type']);
    yield put(
      DepartmentReportActions.departmentReportDownloadSuccess(response)
    );
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status && request.status === 404) {
        return yield put(
          ErrorActions.setError(
            'Nenhum colaborador encontrado com essas informações',
            {
              toasted: true,
            }
          )
        );
      }
    }
    yield put(
      ErrorActions.setError('Ocorreu um erro ao buscar as informações', {
        toasted: true,
      })
    );
  }
}

function showFile(blob, type) {
  const subType = type.split('/')[1];
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download = `Relatório por departamento - ${new Date().getTime()}.${subType}`;
  link.click();
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}
