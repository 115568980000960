import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  overflow-y: scroll;
`;

export const Back = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 25px 0 25px;

  img {
    height: 25px;
    width: 25px;
  }

  span {
    color: #00468e;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px 25px 10px 25px;
  padding: 47px 158px;

  background-color: #fff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);

  label {
    text-transform: uppercase;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;

export const DivContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;

  .input {
    border: #ccc 1px solid;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    height: 45px;
    width: 1000px;

    input {
      border: 0;
      font-size: 16px;
      line-height: 22px;
      color: #666666;
    }

    img {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 180px;

  border: 0;
  border-radius: 4px;

  background-color: #00468e;

  a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
  }
`;
