import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import ParticipantsActions from '../../ducks/participant/participants';
import { Creators as ErrorActions } from '../../ducks/error';

export function* requestParticipants({ payload }) {
  const { cycleId, page, append = false, filters = {} } = payload;

  yield put(ParticipantsActions.participantsClearError());
  yield put(ParticipantsActions.participantsLoading());
  try {
    let url = `api/v1/cycles/${cycleId}/participants`;
    url += '?page=' + page;
    if (!!filters.search) {
      url += '&text=' + filters.search;
    }
    if (!!filters.quiz_id) {
      url += '&quiz_id=' + filters.quiz_id;
    }
    if (!!filters.status) {
      url += '&status=' + filters.status;
    }
    if (!!filters.registration) {
      url += '&registration=' + filters.registration;
    }
    if (!!filters.function) {
      url += '&function=' + filters.function;
    }
    if (!!filters.office) {
      url += '&office=' + filters.office;
    }
    if (!!filters.organization_unit) {
      url += '&organization_unit=' + filters.organization_unit;
    }
    let response = yield call(api.get, url);
    yield put(ParticipantsActions.participantsSuccess(response.data, append));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(ParticipantsActions.participantsError(error));
      }
    }
    yield put(
      ParticipantsActions.participantsError(
        'Ocorreu um erro no servidor, tente mais tarde.'
      )
    );
  }
}

export function* reportParticipants(payload) {
  const { cycleId, filters = {} } = payload;
  try {
    let url = `/api/v1/admin/cycles/${cycleId}/participants/download?all=true`;
    if (!!filters.search) {
      url += '&text=' + filters.search;
    }
    if (!!filters.quiz_id) {
      url += '&quiz_id=' + filters.quiz_id;
    }
    if (!!filters.status) {
      url += '&status=' + filters.status;
    }
    if (!!filters.registration) {
      url += '&registration=' + filters.registration;
    }
    if (!!filters.function) {
      url += '&function=' + filters.function;
    }
    if (!!filters.office) {
      url += '&office=' + filters.office;
    }
    if (!!filters.organization_unit) {
      url += '&organization_unit=' + filters.organization_unit;
    }
    if (!!filters.type) {
      url += '&type=' + filters.type;
    }
    let response = yield call(api.get, url, {
      responseType: 'arraybuffer',
    });
    showFile(response.data, response.headers['content-type']);
    yield put(ParticipantsActions.participantsReportDownloadSuccess(response));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status && request.status === 404) {
        return yield put(
          ErrorActions.setError(
            'Nenhum participante encontrado com essas informações',
            {
              toasted: true,
            }
          )
        );
      }
    }
    yield put(
      ErrorActions.setError('Ocorreu um erro ao buscar as informações', {
        toasted: true,
      })
    );
  }
}

function showFile(blob, type) {
  const subType = type.split('/')[1];
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');
  link.href = data;
  link.download = `Relatório de Participantes - ${new Date().getTime()}.${subType}`;
  link.click();
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export function* requestResetParticipants() {
  yield put(ParticipantsActions.participantsReset());
}
