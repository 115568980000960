import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  POST_REQUEST: "enroll/POST_REQUEST",
  POST_ERROR: "enroll/POST_ERROR",
  GET_SUCCESS: "enroll/GET_SUCCESS",
  POST_CONCLUDE_REQUEST: "enroll/POST_CONCLUDE_REQUEST",
  POST_CONCLUDE_SUCCESS: "enroll/POST_CONCLUDE_SUCCESS",
  POST_CONCLUDE_ERROR: "enroll/POST_CONCLUDE_ERROR",
  RESET_CONCLUDE_REQUEST: "enroll/RESET_CONCLUDE_REQUEST",
  RESET_CONCLUDE_SUCCESS: "enroll/RESET_CONCLUDE_SUCCESS",
  SET_ENROLLED: "enroll/SET_ENROLLED",
  RESET_REQUEST: "enrollments/RESET_REQUEST",
  RESET_SUCCESS: "enrollments/RESET_SUCCESS",
  GET_FAILURE: "enrollments/GET_FAILURE"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  enrolled: false,
  error: false,
  success: false,
  errorConclude: false,
  successConclude: false
});

export default function enroll(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.POST_REQUEST:
      return { ...state, loading: true };
    case Types.POST_SUCCESS:
      return { data: action.payload.data, loading: false, enrolled: true };
    case Types.POST_CONCLUDE_REQUEST:
      return { ...state, loading: true };
    case Types.SET_ENROLLED:
      return { ...state, loading: false, enrolled: action.payload.flag };
    case Types.POST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
        enrolled: false
      };
    case Types.POST_CONCLUDE_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        errorConclude: false,
        successConclude: action.payload
      };
    case Types.POST_CONCLUDE_ERROR:
      return {
        data: [],
        loading: false,
        errorConclude: action.payload,
        successConclude: false
      };
    case Types.RESET_CONCLUDE_SUCCESS:
      return {
        data: [],
        loading: false,
        errorConclude: false,
        successConclude: false
      };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        error: false,
        enrolled: true,
        success: action.payload.success
      };
    case Types.RESET_SUCCESS:
      return {
        data: [],
        loading: false,
        enrolled: false,
        successConclude: false,
        errorConclude: false,
        error: false,
        success: false
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  postEnrollRequest: offerId => ({
    type: Types.POST_REQUEST,
    payload: { offerId }
  }),

  postEnrollSuccess: (data, success) => ({
    type: Types.GET_SUCCESS,
    payload: { data, success }
  }),

  postEnrollError: error => ({
    type: Types.POST_ERROR,
    payload: error
  }),

  postEnrollConclude: enrollId => ({
    type: Types.POST_CONCLUDE_REQUEST,
    payload: { enrollId }
  }),

  postEnrollConcludeSuccess: data => ({
    type: Types.POST_CONCLUDE_SUCCESS,
    payload: data
  }),

  postEnrollConcludeError: data => ({
    type: Types.POST_CONCLUDE_ERROR,
    payload: data
  }),

  resetEnrollConclude: () => ({
    type: Types.RESET_CONCLUDE_REQUEST
  }),

  resetEnrollConcludeSuccess: () => ({
    type: Types.RESET_CONCLUDE_SUCCESS
  }),

  setEnrolled: flag => ({
    type: Types.SET_ENROLLED,
    payload: { flag }
  }),

  resetEnrollRequest: () => ({
    type: Types.RESET_REQUEST
  }),

  resetEnrollSuccess: () => ({
    type: Types.RESET_SUCCESS
  })
};
