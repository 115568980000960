import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import ParticipantDetailActions from "../../../../store/ducks/participant/participantDetail";
import UserCycleActions from "../../../../store/ducks/cycle/userCycle";
import UpdateCycleUserStatusActions from "../../../../store/ducks/cycleUser/updateCycleUserStatus";

import { Div } from "../../../../styles/global";
import Collaborator from "../../../../Components/Collaborator";
import { Content, Grade } from "./styles";
import Loading from "../../../../Components/Loading";
import ErrorMsg from "../../../../Components/ErrorMsg";
import QuizShow from "../../../../Components/Quiz/Show";
import Back from "../../../../Components/Back";

class DetalhesParticipantes extends React.Component {
  static propTypes = {
    userCycle: PropTypes.object.isRequired,
    userCycleRequest: PropTypes.func.isRequired,
    userCycleResetRequest: PropTypes.func.isRequired,
    participantDetailRequest: PropTypes.func.isRequired,
    participantDetail: PropTypes.object.isRequired
  };

  state = {
    ready: false,
    cycleUserId: null,
    participantDetail: null,
    openModal: false,
    openModalSend: false,
    openModalAgendar: false
  };

  get media() {
    let blocks = this.state.participantDetail.blocks;
    if (blocks.length === 0) {
      return 0;
    }
    let pontuation = blocks
      .map(block => Number(block.avg))
      .reduce((v1, v2) => v1 + v2);
    return Number(pontuation / blocks.length);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.cycleUserId) {
      state.cycleUserId = props.match.params.cycleUserId;
    }
    if (props.match.params.id) {
      state.cycleId = props.match.params.id;
    }
    let participantDetailReady = false;
    if (props.participantDetail.success || props.participantDetail.error) {
      participantDetailReady = true;
      if (props.participantDetail.success) {
        state.participantDetail = props.participantDetail.data;
      }
    }
    if (participantDetailReady) {
      state.ready = true;
    }
    return state;
  }

  componentDidMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.userCycleResetRequest();
  }

  fetchData = () => {
    let { cycleUserId } = this.state;
    this.props.updateCycleUserStatusRequestReset();
    this.props.participantDetailRequest({ cycleUserId });
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onCloseModalEncaminhar = () => {
    this.setState({ openModalSend: false });
  };

  onCloseModalAgendar = () => {
    this.setState({ openModalAgendar: false });
  };

  renderBlock = block => {
    return (
      <div key={`block-${block.id}`} className="points">
        <span>{block.title}</span>
        <label>{Number(block.avg).toFixed(2)} pontos</label>
      </div>
    );
  };

  renderBlocks = () => {
    let blocks = this.state.participantDetail.blocks;
    if (!blocks || blocks.length === 0) {
      return null;
    }
    return (
      <div className="grade">
        {blocks.map(block => this.renderBlock(block))}
      </div>
    );
  };

  renderDate = () => {
    let cycle = this.state.participantDetail.cycle;
    let begin = moment(cycle.begin).format("DD/MM/YYYY");
    let end = moment(cycle.end).format("DD/MM/YYYY");
    let completedFormAt = this.state.participantDetail.completed_form_at;
    if (completedFormAt) {
      completedFormAt = moment(completedFormAt).format("DD/MM/YYYY");
    } else {
      completedFormAt = "Não informado";
    }
    return (
      <div className="options">
        <span>formulário gestor</span>
        <span>
          Prazo: {begin} - {end}
        </span>
        <span>Data de entrega: {completedFormAt}</span>
      </div>
    );
  };

  renderGrade = () => {
    return (
      <Grade>
        {this.renderDate()}
        {this.renderBlocks()}
      </Grade>
    );
  };

  render() {
    let { ready, participantDetail } = this.state;
    let { error } = this.props.participantDetail;
    if (!ready) {
      return <Loading loading={true} />;
    }
    if (error) {
      return <ErrorMsg msg={error} />;
    }
    return (
      <Fragment>
        <Div>
          <Back path={`/ciclos/${this.state.cycleId}/participantes`} title="lista completa" />
          <div className="collaborador">
            <Collaborator
              mode="admin"
              cycleUserId={this.state.cycleUserId}
              userInfo={participantDetail.user}
              media={this.media}
              status={participantDetail.status}
              statusText={participantDetail.statusText}
              onClickGiveBack={this.fetchData}
            />
          </div>
          <Content>
            {this.renderGrade()}
            <QuizShow cycleUserId={this.state.cycleUserId} />
          </Content>
        </Div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ParticipantDetailActions,
      ...UserCycleActions,
      ...UpdateCycleUserStatusActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  updateCycleUserStatus: state.updateCycleUserStatus,
  participantDetail: state.participantDetail
});

export default connect(mapStateToProps, mapDispatchToProps)(DetalhesParticipantes);
