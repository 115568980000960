import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as EnrollmentsActions } from '../../../ducks/modules/courses/enrollments';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* filterEnrollments(action) {
  try {
    let { categoryId, situation, text, order } = action.payload;
    let url = `/api/v1/enrollments/filter/${categoryId}?situation=${situation}`;
    if (!!order) {
      url += '&order=' + order;
    }
    if (!!text) {
      url += '&title=' + text;
    }
    const response = yield call(api.get, url);
    yield put(
      EnrollmentsActions.filterEnrollmentsSuccess(response.data.details)
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* filterEnrollmentsReset() {
  yield put(EnrollmentsActions.filterEnrollmentsResetSuccess());
}
