import api from '../../services/api';
import { call, put } from 'redux-saga/effects';
import { Creators as CycleActions } from '../ducks/cycle';
import { Creators as SuccessActions } from '../ducks/success';
import { Creators as ErrorActions } from '../ducks/error';

export function* getCycle(action) {
  try {
    const response = yield call(
      api.get,
      '/api/v1/admin/cycles/' + action.payload.id
    );

    yield put(CycleActions.getCycleSuccess(response.data.details));
  } catch (err) {
    yield put(
      ErrorActions.setError(
        'Um erro ocorreu, por favor tente novamente mais tarde.'
      )
    );
  }
}

export function* initCycle(action) {
  let response;
  try {
    response = yield call(
      api.put,
      '/api/v1/admin/cycles/init-cycle/' + action.payload.id
    );
    yield put(CycleActions.getCycleSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Ciclo iniciado com sucesso!', {
        toasted: true,
      })
    );
  } catch (err) {
    if (!!err.request) {
      if (err.request.status === 403) {
        let response = JSON.parse(err.request.response);
        yield put(ErrorActions.setError(response.messages[0], { toasted: true }));
      }
    }
  }
}
