import styled from "styled-components";

export const DropdownStyled = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  padding: 25px 0 10px 25px;
  min-width: 350px;
  right: 0;
  top: 45px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  user-select: none;
  z-index: 1;

  ul {
    list-style: none;

    li {
      :not(:last-child) {
        margin-bottom: 15px;
      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        color: #666666;
        font-size: 16px;
        text-transform: uppercase;
        font-weight: light;

        :hover {
          opacity: 0.8;
          text-decoration: none;
        }

        &.inactive {
          color: #d5d5d5;
          cursor: default;
          :hover {
            opacity: 1;
          }
        }

        svg {
          margin-bottom: 18px;
          margin-right: 25px;
        }

        span {
          font-size: 18px;
          padding-bottom: 10px;
          border-bottom: 2px solid #e5e5e5;
          flex: 1;
        }
      }
    }
  }
`;
