import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Creators as MeetingActions } from '../../store/ducks/supervisor/meeting';
import ErrorMsg from '../ErrorMsg';

import {
  Wrapper,
  WrapperButtons,
  Form,
  DivInput,
  Button,
  ButtonCancel,
} from './styles';

class FormReuniao extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      data: '',
      horario: '',
      local: '',
      invited: false,
    };
  }

  componentDidUpdate(prevProps, prevStates) {
    if (!prevProps.meeting.loading && this.props.meeting.loading) {
      this.setState({ invited: true });
    }

    if (!prevProps.meeting.success && this.props.meeting.success) {
      this.props.closeModal();
      this.setState({ invited: false });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { data, horario, local } = this.state;
    const { userCycleId, postMeetingRequest } = this.props;
    if (data.trim() !=='' && horario.trim() !=='' && local.trim() !=='') {
      let dataFormated = moment(data).format('DD/MM/YYYY');
      postMeetingRequest(userCycleId, dataFormated, horario, local);
    }
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderError = () => {
    // if (!this.props.auth.error) {
    //   return null;
    // }
    // return <ErrorMsg msg={this.props.auth.error} />;
  };

  renderLoading = () => {
    // return <Loading loading={this.props.auth.loading} />;
  };

  componentDidMount() {}

  render() {
    const { data, horario, local } = this.state;
    return (
      <Wrapper>
        {this.renderError()}
        {this.renderLoading()}
        <Form onSubmit={this.handleSubmit}>
          <DivInput>
            <label>Data do Agendamento</label>
            <input
              required
              type="date"
              name="data"
              defaultValue={data}
              onChange={this.handleInputChange}
            />
          </DivInput>
          <DivInput>
            <label>Horário</label>
            <input
              required
              type="time"
              name="horario"
              defaultValue={horario}
              onChange={this.handleInputChange}
            />
          </DivInput>
          <DivInput>
            <label>Local</label>
            <input
              required
              type="text"
              name="local"
              value={local}
              onChange={this.handleInputChange}
            />
          </DivInput>
          <WrapperButtons>
            <ButtonCancel type="button" onClick={this.props.closeModal}>
              Cancelar
            </ButtonCancel>
            <Button disable={false} type="submit">
              {this.state.invited ? 'Enviando...' : 'Enviar Convite'}
            </Button>
          </WrapperButtons>
        </Form>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...MeetingActions }, dispatch);

const mapStateToProps = state => ({
  meeting: state.meeting,
});

export default connect(mapStateToProps, mapDispatchToProps)(FormReuniao);
