import styled from "styled-components";

export const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  button {
    font-size: 14px;
    color: #fff;
    background-color: #00468e;
    padding: 5px 30px;
    border: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    :hover {
      opacity: 0.8;
    }
    :disabled {
      background-color: #ccc;
    }
    svg {
      margin-left: 20px;
    }
  }
`;
