import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import UpdateCycleUserStatusActions from './../../../../store/ducks/cycleUser/updateCycleUserStatus';

import Progress from '../../../../Components/UserComponents/Progress';
import Modal from 'react-responsive-modal';

import { Content, ModalDiv } from './styles';
import NotFoundItems from '../../../../Components/NotFoundItems';
import Loading from '../../../../Components/Loading';
import ErrorMsg from '../../../../Components/ErrorMsg';

class RevisaoFinal extends React.Component {
  state = {
    ready: false,
    openModal: false,
    redirectToStage: false,
    redirectToResult: false,
  };

  componentDidMount() {
    this.props.updateCycleUserStatusRequestReset();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.ready) {
      if (
        !props.updateCycleUserStatus.success &&
        !props.updateCycleUserStatus.error &&
        !props.updateCycleUserStatus.loading
      ) {
        state.ready = true;
      }
    }
    if (state.ready && props.updateCycleUserStatus.success) {
      state.openModal = true;
      props.updateCycleUserStatusRequestReset();
    }
    return state;
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onSendQuiz = () => {
    let status = this.props.userCycle.userCycle.status;
    if (status == 'Em Revisão') {
      this.setState({ openModal: true });
    } else {
      this.props.updateCycleUserStatusRequest(
        this.props.userCycle.userCycle.id,
        status == 'Em Revisão Colaborador' ? 'Revisão/Validação' : 'Em Revisão'
      );
    }
  };

  renderModal = () => {
    const { openModal } = this.state;
    return (
      <Modal
        open={openModal}
        onClose={this.onCloseModal}
        center
        showCloseIcon={false}
        styles={{
          modal: {
            padding: 0,
            width: '50%',
            height: '220px',
            borderRadius: '5px',
          },
        }}
      >
        <ModalDiv>
          <div className="titulo">
            <span>sucesso!</span>
          </div>
          <div className="content">
            <span>Avaliação enviada com sucesso.</span>
            <span>
              Aguarde a revisão de seu superior imediato para prosseguir.
            </span>
          </div>
          <div
            className="button"
            onClick={() => {
              this.setState({ redirectToResult: true });
            }}
          >
            <button>ok</button>
          </div>
        </ModalDiv>
      </Modal>
    );
  };

  renderButtons = () => {
    return (
      <div className="botoes">
        <button
          onClick={() => {
            this.setState({ redirectToStage: true });
          }}
        >
          <span>revisar avaliação</span>
        </button>
        <button onClick={() => this.onSendQuiz()}>
          <span> enviar para o meu gesto</span>
        </button>
      </div>
    );
  };

  renderContent = () => {
    let { loading, error } = this.props.updateCycleUserStatus;
    if (loading) {
      return <Loading loading={true} />;
    }
    if (this.props.updateCycleUserStatus.success) {
      return (
        <div className="botoes">
          <button
            onClick={() => {
              this.setState({ redirectToResult: true });
            }}
          >
            <span>Ir para próxima página</span>
          </button>
        </div>
      );
    }
    return (
      <div className="botoes">
        {!error ? null : <ErrorMsg msg={error} />}
        {this.renderButtons()}
      </div>
    );
  };

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.state.redirectToStage) {
      return <Redirect to={`/user/stage`} />;
    }
    if (this.state.redirectToResult) {
      return <Redirect to={`/user/resultados`} />;
    }
    if (this.props.userCycle.error) {
      return <NotFoundItems />;
    }
    let userCycle = this.props.userCycle.userCycle;
    if (!userCycle) {
      return <NotFoundItems />;
    }
    let title = this.props.userCycle.userCycle.cycle.title;
    return (
      <Fragment>
        <Progress
          stages={
            this.props.userCycle.success
              ? this.props.userCycle.userCycle.stages
              : []
          }
          lastStageActive={true}
        />
        <Content>
          <div className="titulo">
            <span>resultado final</span>
            <p>
              Você preencheu todas as questões da avaliação de {title}. Prossiga
              com as seguintes opções.
            </p>
          </div>
          {this.renderContent()}
        </Content>
        {this.renderModal()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UpdateCycleUserStatusActions }, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
  updateCycleUserStatus: state.updateCycleUserStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(RevisaoFinal);
