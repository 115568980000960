import React, { Component } from "react";
import { BoxWhiteRounded } from "../../../../styles/global";
import { SearchBoxStyled } from "./styles";

import MdSearch from "react-ionicons/lib/MdSearch";

export default class SearchBox extends Component {
  render() {
    return (
      <BoxWhiteRounded>
        <SearchBoxStyled>
          <MdSearch fontSize="28px" color="#ACACAC" />
          <input
            type="search"
            placeholder="Buscar cursos ou categorias"
            onChange={e => this.props.onChange(e.target.value)}
          />
        </SearchBoxStyled>
      </BoxWhiteRounded>
    );
  }
}
