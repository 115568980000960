import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { toast } from "react-toastify";
import Loading from "../../../../Components/Loading";
import ImportActions from "../../../../store/ducks/user/importUpload";
import {
  ModalInner,
  ModalHeader,
  ParagrafoInfo,
  ButtonsWrapper
} from "./styles";

class ModalImport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileImport: null,
      imported: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { file } = this.state;
    const { importRequest } = this.props;
    importRequest(file);
  };

  handleSelectedFile = event => {
    this.setState({
      file: event.target.files[0]
    });
  };

  renderLoading = () => {
    return (
      <Loading
        text={"Importando usuários..."}
        loading={this.props.importUpload.loading}
      />
    );
  };

  renderFormUpload() {
    return (
      <form onSubmit={this.handleSubmit}>
        <input
          required
          placeholder={"Arquivo de Importação"}
          name={"file"}
          type={"file"}
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          onChange={this.handleSelectedFile}
        />

        <ButtonsWrapper>
          <button onClick={() => this.props.onClose()} type="button">
            Cancelar
          </button>
          <button className="primary">Importar</button>
        </ButtonsWrapper>
      </form>
    );
  }

  renderUploadSuccess() {
    return (
      <div>
        <p>Importação realizada com sucesso!</p>
      </div>
    );
  }

  componentDidUpdate() {
    if (
      !this.state.imported &&
      !this.props.importUpload.loading &&
      this.props.importUpload.success
    ) {
      toast.success("Importação Concluida!");
      this.props.onClose();
      this.setState({ imported: true });
    }
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open}
          onClose={this.props.onClose}
          styles={{ modal: { width: "80%", padding: 0, borderRadius: "5px" } }}
          showCloseIcon={false}
        >
          <ModalInner>
            <ModalHeader>
              <span>Importar Usuários</span>
            </ModalHeader>
            <ParagrafoInfo className="p-info">
              <p>
                Para realizar a importação de usuários basta escolher o arquivo
                de importação.
              </p>
            </ParagrafoInfo>
            {!this.props.importUpload.loading
              ? this.renderFormUpload()
              : this.renderLoading()}
          </ModalInner>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ImportActions }, dispatch);

const mapStateToProps = state => ({ importUpload: state.importUpload });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalImport);
