import React, { Fragment, useState } from 'react';
import { WrapperStyled, SelectStyled, PopupStyled, ItemStyled } from './styles';
import MdArrowDropdown from 'react-ionicons/lib/MdArrowDropdown';
import MdArrowDropright from 'react-ionicons/lib/MdArrowDropright';

export default function SelectWithPopup({
  placeholder,
  onChangeSearch,
  list,
  onSelectItem,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);

  function handleSelectItem(item) {
    setItemSelected(typeof item === 'object' ? item.name : item);
    setIsOpen(false);
    onSelectItem(item);
  }

  function handleClear() {
    setItemSelected(null);
    onSelectItem(null);
  }

  function getSelectedItem() {
    if (props.valueBy) {
      const search = list.find(e => e[props.valueBy] === props.value);
      if (typeof search === 'object') return search.name;
    }
    return itemSelected || props.value;
  }

  return (
    <WrapperStyled {...props}>
      {props.label && <label className="label-select">{placeholder}</label>}
      <SelectStyled>
        <div className="left-side" onClick={() => setIsOpen(!isOpen)}>
          {!itemSelected && !props.value ? (
            <span className="placeholder">{placeholder}</span>
          ) : (
            <span>{getSelectedItem()}</span>
          )}
        </div>

        <div className="right-side">
          <div className="clear-wrapper">
            {getSelectedItem() && (
              <button onClick={() => handleClear()}>Limpar</button>
            )}
          </div>

          <div className="arrow-wrapper" onClick={() => setIsOpen(!isOpen)}>
            <MdArrowDropdown />
          </div>
        </div>
      </SelectStyled>

      <PopupStyled isHidden={!isOpen}>
        <div className="header">
          {renderPopupArrow()}

          <input
            onChange={e => onChangeSearch(e.target.value)}
            placeholder="Buscar..."
          />
        </div>

        <div className="list-wrapper">
          {list.length > 0 ? (
            <Fragment>
              {list.map((item, i) => (
                <ItemStyled onClick={() => handleSelectItem(item)} key={i}>
                  <MdArrowDropright fontSize={'18'} />
                  <span>{typeof item === 'object' ? item.name : item}</span>
                </ItemStyled>
              ))}
            </Fragment>
          ) : (
            <span className="nenhum-item">Nenhum item a ser mostrado</span>
          )}
        </div>
      </PopupStyled>
    </WrapperStyled>
  );
}

function renderPopupArrow() {
  return (
    <div className="arrow-wrapper">
      <div>
        <div />
      </div>
    </div>
  );
}

SelectWithPopup.defaultProps = {
  placeholder: 'Selecione...',
  list: [],
  onChangeSearch: val => false,
  onSelectItem: item => false,
};
