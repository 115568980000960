import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import ParticipantsActions from "../../../../../../store/ducks/participant/participants";

import NotFoundItems from "../../../../../../Components/NotFoundItems";
import Loading from "../../../../../../Components/Loading";
import ParticipantsTable from "../../../../../Ciclos/Novo/Participantes/ParticipantsTable";
import { Box } from "../../../../../../styles/global";

class List extends React.Component {
  static propTypes = {
    participantsRequest: PropTypes.func.isRequired,
    userCycle: PropTypes.object.isRequired
  };

  state = {
    page: 1,
    participants: [],
    redirectToUser: null
  };

  static getDerivedStateFromProps(props, state) {
    state.participants = props.participants.data;
    state.userCycle = props.userCycle;
    return state;
  }

  componentDidMount() {
    this.setState({ fetch: true });
  }

  componentWillUnmount() {
    this.props.participantsResetRequest();
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.setState({ fetch: false });
      this.props.participantsRequest({
        filters: {
          status: "Em Revisão"
        },
        page: this.state.page,
        cycleId: this.state.userCycle.cycle.id
      });
    }
  }

  renderParticipants = () => {
    if (this.props.participants.loading) {
      return <Loading loading={true} />;
    }
    let countParticipants = this.state.participants.length;
    let content;
    if (countParticipants === 0) {
      content = <NotFoundItems />;
    } else {
      content = (
        <ParticipantsTable
          participantes={this.state.participants}
          onItemClicked={participant => {
            this.setState({ redirectToUser: participant.cycle_user_id });
          }}
          canSelectItem={false}
        />
      );
    }
    return (
      <Box>
        <h3>Avaliações aguardando revisão({countParticipants})</h3>
        {content}
      </Box>
    );
  };

  render() {
    if (this.state.redirectToUser) {
      return (
        <Redirect to={`/gestor/ciclo-usuario/${this.state.redirectToUser}`} />
      );
    }
    return <Fragment>{this.renderParticipants()}</Fragment>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ParticipantsActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  participants: state.participants
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
