import React, { Component } from "react";
import { NumbersWrapper } from "./styles";

export default class Numbers extends Component {
  state = {
    numbers: [],
    activeNumUser: null,
    activeNumReviewer: null,
    isReviewer: false
  };

  handleClick = (e, num) => {
    if (this.props.disabled) {
      return null;
    }
    if (this.state.isReviewer) {
      this.setState({ activeNumReviewer: num });
    } else {
      this.setState({ activeNumUser: num });
    }
    if (this.props.onChange) {
      this.props.onChange(
        this.props.options.find(option => option.value == num),
        this.state.isReviewer
      );
    }
  };

  static getDerivedStateFromProps(props, state) {
    state.numbers = props.options.map(option => option.value);
    state.isReviewer = props.isReviewer;
    if (state.activeNumReviewer === null && props.defaultNumReviewer) {
      state.activeNumReviewer = props.defaultNumReviewer;
    }
    if (state.activeNumUser === null && props.defaultNumUser) {
      state.activeNumUser = props.defaultNumUser;
    }
    return state;
  }

  renderNumber = num => {
    let className = "";
    if (this.state.activeNumUser == num) {
      className = "active-user";
    } else if (this.state.activeNumReviewer == num) {
      className = "active-reviewer";
    }
    return (
      <li
        key={num}
        className={className}
        onClick={e => this.handleClick(e, num)}
      >
        {num}
      </li>
    );
  };

  render() {
    return (
      <NumbersWrapper>
        <ul>{this.state.numbers.map(num => this.renderNumber(num))}</ul>
      </NumbersWrapper>
    );
  }
}
