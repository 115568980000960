import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'hierarchy/GET_REQUEST',
  GET_SUCCESS: 'hierarchy/GET_SUCCESS',
  PUT_REQUEST: 'hierarchy/PUT_REQUEST',
  PUT_SUCCESS: 'hierarchy/PUT_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  loading: false,
  success: false,
  error: false,
  updated: false,
  details: {},
});

export default function hierarchy(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false, updated: false };
    case Types.GET_SUCCESS:
      return {
        ...action.payload.details,
        updated: false,
        loading: false,
        success: true,
      };
    case Types.PUT_REQUEST:
      return { ...state, loading: true, success: false, updated: false };
    case Types.PUT_SUCCESS:
      return {
        details: { ...state.details, superior: action.payload.details },
        updated: true,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getHierarchyRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getHierarchySuccess: details => ({
    type: Types.GET_SUCCESS,
    payload: { details },
  }),

  putHierachyRequest: (id, value) => ({
    type: Types.PUT_REQUEST,
    payload: { id, value },
  }),

  putHierarchySuccess: superior => ({
    type: Types.PUT_SUCCESS,
    payload: { superior },
  }),
};
