import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Creators as HierarchyActions } from '../ducks/hierarchy';
import { Creators as ErrorActions } from '../ducks/error';
import { Creators as SuccessActions } from '../ducks/success';

export function* getHierarchy(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/admin/hierarchy/${action.payload.id}`
    );
    yield put(HierarchyActions.getHierarchySuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.',
          { toasted: true }
        )
      );
    }
  }
}

export function* putHierarchy(action) {
  try {
    const response = yield call(
      api.put,
      `/api/v1/admin/hierarchy/${action.payload.id}?user_id=${action.payload.value}`
    );
    yield put(
      SuccessActions.setSuccess(response.data.messages[0], { toasted: true })
    );
    yield put(HierarchyActions.putHierarchySuccess(response.data.details));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.',
          { toasted: true }
        )
      );
    }
  }
}
