import React from 'react';

import { Link } from 'react-router-dom';
import moment from "moment";

import AuthActions from '../../store/ducks/auth';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Sidebar, List, Logo, Wrapper, ExitDiv } from './styles';

import LogoEmae from '../../assets/images/logo-emae.png';
import HomeIcon from '../../assets/images/home.svg';
import CicloIcon from '../../assets/images/ico-ciclos.svg';
import CheckIcon from '../../assets/images/Check.svg';
import RelatorioIcon from '../../assets/images/Relatorio.svg';
import LogoffIcon from '../../assets/images/logoff.svg';
import ConfigIcon from '../../assets/images/Config.svg';
import Logout from '../Logout';

class Nav extends React.Component {
  verifyLocation = url => {
    const { pathname } = this.props.location;
    const path = pathname.split('/')[1];

    return path === url;
  };

  render() {
    const fromDadosGerais = this.verifyLocation('dadosgerais');
    return (
      <Sidebar fromDadosGerais={fromDadosGerais}>
        <Logo>
          <img src={LogoEmae} alt="emae" />
        </Logo>

        <Wrapper>
          <List>
            <li className={this.verifyLocation('home') ? 'active' : 'inactive'}>
              <Link to="/home">
                <img src={HomeIcon} alt="home" /> Home
              </Link>
            </li>
            <li
              className={this.verifyLocation('ciclos') || this.verifyLocation('formularios') ? 'active' : 'inactive'}
            >
              <Link to="/ciclos">
                <img src={CicloIcon} alt="ciclos" /> ciclos
              </Link>
            </li>
            <li
              className={
                this.verifyLocation('modelos') ? 'active' : 'inactive'
              }
            >
              <Link to="/modelos">
                <img src={CheckIcon} alt="formularios" /> formulários
              </Link>
            </li>
            <li
              className={
                this.verifyLocation('relatorios') ? 'active' : 'inactive'
              }
            >
              <Link to="/relatorios">
                <img src={RelatorioIcon} alt="relatorios" /> relatórios
              </Link>
            </li>

            <li
              className={
                this.verifyLocation('dadosgerais') ? 'active' : 'inactive'
              }
            >
              <Link to="/dadosgerais/usuarios">
                <img src={ConfigIcon} alt="dados gerais" /> dados gerais
              </Link>
            </li>
          </List>

          <ExitDiv>
            <Logout
              renderItem={
                <button>
                  <img src={LogoffIcon} alt="Exit" />
                  <span style={{ textTransform: 'uppercase' }}>Sair</span>
                </button>
              }
            />

            <div>
              <span>RDP Manager {moment().format("YYYY.MM.DD")}</span>
            </div>
          </ExitDiv>
        </Wrapper>
      </Sidebar>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);

export default connect(null, mapDispatchToProps)(Nav);
