import React, { Component, Fragment } from 'react';

import {
  Tr,
  Tbody,
  Thead,
  TableDiv,
} from '../../../../../Components/Table/styles';
import UsuarioSVG from '../../../../../assets/images/usuario.svg';
import { TrJustificativa } from './styles';
import ReasonFormSelect from '../../../../../Components/ReasonFormSelect';
import FormError from '../../../../../Components/Form/Error';

export default class JustificativaTodos extends Component {
  state = {
    reasonId: null,
  };

  getFormData = () => {
    let result = [];
    for (let i = 0; i < this.props.participantes.length; i++) {
      let participant = this.props.participantes[i];
      result.push({
        id: participant.id,
        reason_id: this.state.reasonId,
      });
    }
    return result;
  };

  render() {
    const { participantes, submitted } = this.props;
    return (
      <Fragment>
        <TableDiv>
          <Thead>
            <Tr>
              <th />
              <th>ID</th>
              <th>Nome</th>
              <th className="center">Matrícula</th>
              <th>Escritório</th>
            </Tr>
          </Thead>
          <Tbody>
            {participantes.map(part => (
              <tr key={part.id}>
                <td className="center">
                  <img
                    src={UsuarioSVG}
                    alt={part.name}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                    }}
                  />
                </td>
                <td>{part.id}</td>
                <td style={{ width: '40%' }}>{part.name}</td>
                <td className="center">{part.registration}</td>
                <td>{part.role_name}</td>
              </tr>
            ))}
          </Tbody>
        </TableDiv>
        <TrJustificativa>
          {submitted && !this.state.reasonId && (
            <FormError error="É necessário preencher esse campo!" />
          )}
          <ReasonFormSelect
            onChange={reasonId => {
              this.setState({ reasonId });
            }}
          />
        </TrJustificativa>
      </Fragment>
    );
  }
}
