import React, { Fragment } from "react";

import SubHeader from "../../../../Components/SubHeader";
import Back from "../../../../Components/Back";
import { Div } from "../../../../styles/global";

import { Content, DivContent, Button } from "./styles";

import SearchIcon from "../../../../assets/images/search.svg";

const NovoFluxo = () => (
  <Fragment>
    <Div>
      <Back />
      <SubHeader />

      <Content>
        <label>selecionar atividade</label>
        <DivContent>
          <div className="input">
            <input type="text" placeholder="Buscar" />
            <img src={SearchIcon} alt="procurar" />
          </div>
          <Button>
            <a href="#!">nova ativade</a>
          </Button>
        </DivContent>
      </Content>
    </Div>
  </Fragment>
);

export default NovoFluxo;
