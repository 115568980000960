import styled from 'styled-components'

export const AvaliacaoBlockWrapper = styled.div`

  margin-bottom: 40px;

  h2 {
    color: #00468E;
    font-size: 18px;
    text-transform: uppercase;
  }

  p {
    color: #666666;
    font-size: 14px;
    padding-left: 10px;
  }
`
