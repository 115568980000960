import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import AnswerActions from '../../../store/ducks/answer/answer';

import Progress from '../../../Components/UserComponents/Progress';

import {
  MainContent,
  ButtonsWrapper,
  HorizantalItems,
  VerificacaoBox,
} from './styles';

import AvaliacaoBlock from '../../../Components/UserComponents/AvaliacaoBlock';
import NumericInterval from '../../../Components/UserComponents/AvaliacaoQuestion/NumericInterval';
import Descriptive from '../../../Components/UserComponents/AvaliacaoQuestion/Descriptive';
import SingleChoice from '../../../Components/UserComponents/AvaliacaoQuestion/SingleChoice';
import Loading from '../../../Components/Loading';
import MediaNotaBox from '../../../Components/UserComponents/MediaNotaBox';
import { Grade } from '../Grade';
import QuizDownload from '../Download';

class QuizForm extends React.Component {
  static propTypes = {
    cycleUserId: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    stages: PropTypes.array.isRequired,
    answersUser: PropTypes.array.isRequired,
    answersReviewer: PropTypes.array.isRequired,
    isReviewer: PropTypes.bool.isRequired,
    updateUserCycle: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    onlyShow: PropTypes.bool,
  };

  state = {
    onCompleteSended: false,
    diffAnswers: 0,
    isReviewer: false,
    disabled: false,
    stageIndex: 0,
    currentStage: null,
    currentBlock: null,
    currentBlockIndex: null,
    goPreviousBlock: false,
    goNextBlock: false,
    goNextStage: false,
    answers: [],
    stages: [],
    isDisabled: false,
  };

  static getDerivedStateFromPropsGoPreviousBlock(props, state) {
    state.goPreviousBlock = false;
    let stage = props.stages[state.stageIndex];
    let nextCurrentBlockIndex = state.currentBlockIndex - 1;
    let block = stage.blocks[nextCurrentBlockIndex];
    if (block) {
      state.currentBlockIndex = nextCurrentBlockIndex;
      state.answers = [];
    } else {
      let nextStageIndex = state.stageIndex - 1;
      let nextStage = state.stages[nextStageIndex];
      if (!nextStage) {
        return;
      }
      state.stageIndex = nextStageIndex;
      state.currentBlockIndex = nextStage.blocks.length - 1;
    }
  }

  static getDerivedStateFromPropsGoNextBlock(props, state) {
    state.goNextBlock = false;
    let stage = props.stages[state.stageIndex];
    let block = stage.blocks[state.currentBlockIndex + 1];
    if (block) {
      state.currentBlockIndex = state.currentBlockIndex + 1;
      state.answers = [];
    } else {
      let nextStageIndex = state.stageIndex + 1;
      let nextStage = state.stages[nextStageIndex];
      if (!nextStage) {
        return;
      }
      state.answers = [];
      state.blocks = nextStage.blocks;
      state.currentBlock = null;
      state.goNextStage = nextStageIndex + 1;
      state.stageIndex = nextStageIndex;
      state.currentBlockIndex = 0;
    }
  }

  static getDerivedStateFromProps(props, state) {
    state.isReviewer = props.isReviewer;
    state.answersUser = props.answersUser;
    state.cycleUserId = props.cycleUserId;
    state.status = props.status;
    state.disabled = false;
    if (state.isReviewer) {
      state.answersReviewer = state.answersUser.map(answerUser => {
        let answerReviewer = props.answersReviewer.find(
          answerReviewer => answerReviewer.question_awnser_id === answerUser.id
        );
        if (!answerReviewer && !!answerUser.text) {
          return { ...answerUser, text: null };
        }
        return answerReviewer ? answerReviewer : answerUser;
      });
    } else {
      state.answersReviewer = props.answersReviewer;
    }
    state.stages = props.stages;
    state.ready = true;
    state.notFound = false;
    if (state.stages instanceof Array && state.stages.length == 0) {
      state.ready = false;
      state.notFound = true;
      return state;
    }
    if (
      state.requestNextBlock &&
      (props.requestAnswer.success || props.requestAnswer.error)
    ) {
      state.requestNextBlock = false;
      state.goNextBlock = true;
      if (!state.onlyShow) {
        props.updateUserCycle();
        props.answersResetRequest();
      }
    }
    if (state.currentBlock === null) {
      state.goNextBlock = true;
      state.currentBlockIndex = -1;
    }
    if (state.goNextBlock) {
      QuizForm.getDerivedStateFromPropsGoNextBlock(props, state);
    }
    if (state.goPreviousBlock) {
      QuizForm.getDerivedStateFromPropsGoPreviousBlock(props, state);
    }
    let stage = state.stages[state.stageIndex];
    state.currentStage = stage;
    if (stage) {
      state.blocks = stage.blocks;
    }
    if (state.ready) {
      let block = state.blocks[state.currentBlockIndex];
      if (state.isReviewer && state.status !== 'Em Revisão') {
        state.disabled = true;
      } else if (!state.isReviewer && state.status === 'Em Revisão') {
        state.disabled = true;
      }
      if (block) {
        state.currentBlock = block;
        if (block.type === 'comentarios' || block.type === 'mediacao') {
          if (state.isReviewer) {
            state.disabled =
              ['Ativo', 'Em Preenchimento'].findIndex(
                status => status === state.status
              ) !== -1;
          } else {
            state.disabled = false;
          }
        } else if (block.type === 'verificacao' && state.isReviewer) {
          //state.disabled = true;
        }
      }
      if (
        state.status === 'Finalizado' ||
        state.status === 'Recursos Humanos'
      ) {
        state.disabled = true;
      }
    }
    if (
      !state.onCompleteSended &&
      state.currentBlock &&
      (state.currentBlock.type === 'resultado' ||
        state.currentBlock.type === 'result')
    ) {
      state.onCompleteSended = true;
      props.onComplete();
    }
    state.onlyShow = !!props.onlyShow;
    state.disabled = state.disabled || state.onlyShow;
    return state;
  }
  /*
  static getDiffAnswers = (block, answersUser, answersReviewer) => {
    let diffAnswers = 0;
    diffAnswers = block.questions
      .filter(question => {
        return question.type === "numeric_interval";
      })
      .map(question => {
        let reviewerAnswer = answersReviewer.find(
          answer => answer.question_id === question.id
        );
        let userAnswer = answersUser.find(
          answer => answer.question_id === question.id
        );
        return (
          reviewerAnswer.question_interval_numerics_id === userAnswer.answer
        );
      })
      .filter(v => false === v);
    return diffAnswers.length;
  };
*/
  getDefaultAnswers = () => {
    return this.state.isReviewer
      ? this.state.answersReviewer
      : this.state.answersUser;
  };

  getDefaultValueToNumericIntervalQuestion = (answers, question) => {
    let answer = answers.find(answer => answer.question_id === question.id);
    if (answer) {
      let questionIntervalNumeric = question.questionsIntervalNumeric.find(
        q => q.id === answer.question_interval_numerics_id
      );
      if (questionIntervalNumeric) {
        return questionIntervalNumeric;
      }
    }
    return null;
  };

  getDefaultValueToSingleChoiceQuestion = (answers, question) => {
    let answer = answers.find(answer => answer.question_id === question.id);
    if (answer) {
      let questionsSingleChoice = question.questionsSingleChoice.find(
        q => q.id === answer.question_single_choices_id
      );
      if (questionsSingleChoice) {
        return questionsSingleChoice;
      }
    }
    return null;
  };

  getDefaultValueToDescriptiveQuestion = (question, isReviewer) => {
    let answer;
    if (isReviewer) {
      answer = this.state.answersReviewer.find(
        answer => answer.question_id === question.id
      );
    } else {
      answer = this.state.answersUser.find(
        answer => answer.question_id === question.id
      );
    }
    return answer ? answer : null;
  };

  getAnwersFormattedToAPI = (answers, defaultAnswers) => {
    return this.state.currentBlock.questions
      .map(question => {
        let answer = answers.find(answer => answer.question_id === question.id);
        if (!answer) {
          answer = defaultAnswers.find(
            answer => answer.question_id === question.id
          );
        }
        if (!answer) {
          return null;
        }
        let value = null;
        switch (question.type) {
          case 'numeric_interval':
            value = answer.question_interval_numerics_id;
            break;
          case 'single_choice':
            value = answer.question_single_choices_id;
            break;
          default:
            value = answer.text;
        }
        return {
          question_id: answer.question_id,
          answer: value,
        };
      })
      .filter(answer => answer);
  };

  onPreviousBlock = () => {
    this.setState({ goPreviousBlock: true });
  };

  onNextBlock = () => {
    if (this.state.disabled) {
      return this.setState({ goNextBlock: true });
    }
    let answers = this.getAnwersFormattedToAPI(
      this.state.answers,
      this.getDefaultAnswers()
    );

    this.props.answersRequest(this.state.cycleUserId, answers);
    this.setState({ requestNextBlock: true });
  };

  onAnswerChange = (question, value) => {
    let data = { question_id: question.id };
    switch (question.type) {
      case 'numeric_interval':
        data.question_interval_numerics_id = value.id;
        break;
      case 'single_choice':
        data.question_single_choices_id = value.id;
        break;
      default:
        data.text = value.text;
    }
    let answers = [...this.state.answers];
    let index = answers.findIndex(answer => answer.question_id === question.id);
    if (index === -1) {
      answers.push(data);
    } else {
      answers[index] = data;
    }
    this.setState({ answers });
  };

  renderMedia = (answers, isReviewer) => {
    let block = this.state.currentBlock;
    let questions = block.questions.filter(q => q.type === 'numeric_interval');
    if (questions.length === 0) {
      return null;
    }
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id === answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id === answer.answer
      );
      if (!answer) {
        return 0;
      }
      return parseInt(answer.value);
    });
    let media = Number(0);
    if (grades.length > 0) {
      let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
      media = Number(sum / questions.length);
    }
    return (
      <MediaNotaBox
        key={`media-${isReviewer}`}
        isMediaRevisado={isReviewer}
        nota={media.toFixed(2)}
      />
    );
  };

  renderMedias = () => {
    let answersUser = this.state.answersUser;
    let answersReviewer = this.state.answersReviewer;
    if (this.state.isReviewer) {
      answersReviewer = this.getAnwersFormattedToAPI(
        this.state.answers,
        answersReviewer
      );
      answersUser = this.getAnwersFormattedToAPI([], answersUser);
    } else {
      answersReviewer = this.getAnwersFormattedToAPI([], answersReviewer);
      answersUser = this.getAnwersFormattedToAPI(
        this.state.answers,
        answersUser
      );
    }
    return (
      <HorizantalItems>
        {this.renderMedia(answersUser)}
        {this.renderMedia(answersReviewer, true)}
      </HorizantalItems>
    );
  };

  getAllQuestions = stages => {
    let questions = [];
    for (let i = 0; i < stages.length; i++) {
      let stage = stages[i];
      let blocks = stage.blocks;
      for (let k = 0; k < blocks.length; k++) {
        let block = blocks[k];
        let blockQuestions = block.questions.filter(
          q => q.type === 'numeric_interval'
        );
        questions = questions.concat(blockQuestions);
      }
    }
    return questions;
  };

  renderTextoVerificacao = () => {
    return (
      <VerificacaoBox>
        <p>
          <b>Status da Avaliação:</b> Indica a condição de realização da
          avaliação.
        </p>
        <ul>
          <li>
            Avaliação realizada pelo gestor que acompanhou o empregado no
            período.
          </li>
          <li>Avaliação realizada em comitê de gestores.</li>
          <li>
            Avaliação realizada. Avaliador ou avaliado há menos de 3 meses na
            área, houve consulta ao gestor anterior.
          </li>
          <li>
            Avaliação não realizada - empregado há menos de 3 meses no
            cargo/Empresa.
          </li>
          <li>
            Avaliação não realizada - empregado em Licença Médica. Trabalhou
            menos de 3 meses no período avaliado(últimos 12 meses).
          </li>
        </ul>
      </VerificacaoBox>
    );
  };

  renderCurrentBlock = () => {
    let block = this.state.currentBlock;
    if (block.type === 'resultado' || block.type === 'result') {
      let questions = this.getAllQuestions(this.state.stages);
      let downloadQuiz = null;
      if (
        this.state.isReviewer &&
        (this.props.status == 'Finalizado' ||
          this.props.status == 'Recursos Humanos')
      ) {
        downloadQuiz = <QuizDownload cycleUserId={this.state.cycleUserId} />;
      }
      return (
        <Fragment key={block.id}>
          <HorizantalItems>
            <Grade
              key={`grade-user`}
              questions={questions}
              isReviewer={false}
              answers={this.state.answersUser}
            />
            <Grade
              key={`grade-reviewer`}
              questions={questions}
              isReviewer={true}
              answers={this.state.answersReviewer}
            />
          </HorizantalItems>
          {downloadQuiz}
        </Fragment>
      );
    }
    return (
      <Fragment key={block.id}>
        <AvaliacaoBlock title={block.title} description={block.description} />
        {block.questions.map((question, i) =>
          this.renderQuestion(question, i, block.type)
        )}
        {block.type === 'verificacao' ? this.renderTextoVerificacao() : null}
        {this.renderMedias()}
      </Fragment>
    );
  };

  renderQuestion = (question, i, blockType) => {
    return (
      <Fragment key={`question-${question.id}`}>
        {question.type === 'numeric_interval' &&
          this.renderNumericIntervalQuestion(question, i)}
        {(question.type === 'descriptive' ||
          question.type === 'text' ||
          question.type === 'text_supervisor') && [
          this.renderDescriptiveQuestion(question, false, i, blockType),
          this.renderDescriptiveQuestion(question, true, i, blockType),
        ]}
        {question.type === 'single_choice' &&
          this.renderSingleChoiceQuestion(question, i)}
      </Fragment>
    );
  };

  renderNumericIntervalQuestion = (question, i) => {
    let options = { isReviewer: this.state.isReviewer };
    let optionUser = this.getDefaultValueToNumericIntervalQuestion(
      this.state.answersUser,
      question
    );
    let optionReviewer = this.getDefaultValueToNumericIntervalQuestion(
      this.state.answersReviewer,
      question
    );
    if (optionUser) {
      options.notaUser = optionUser.value;
    }
    if (optionReviewer) {
      options.notaReviewer = optionReviewer.value;
    }
    return (
      <Fragment>
        <NumericInterval
          cycleUserStatus={this.state.status}
          disabled={this.state.disabled}
          onChange={value => this.onAnswerChange(question, value)}
          options={question.questionsIntervalNumeric}
          question={question}
          number={i + 1}
          key={i}
          {...options}
        />
      </Fragment>
    );
  };

  renderSingleChoiceQuestion = (question, i) => {
    let options = {};
    let defaultChoice = this.getDefaultValueToSingleChoiceQuestion(
      this.state.answersUser,
      question
    );
    let defaultChoiceReviewer = this.getDefaultValueToSingleChoiceQuestion(
      this.state.answersReviewer,
      question
    );
    if (defaultChoice) {
      options.defaultChoice = defaultChoice;
    }
    if (defaultChoiceReviewer) {
      options.defaultChoiceReviewer = defaultChoiceReviewer;
    }
    return (
      <Fragment>
        <SingleChoice
          disabled={this.state.disabled}
          isReviewer={this.state.isReviewer}
          onChange={value => this.onAnswerChange(question, value)}
          cycleUserStatus={this.state.status}
          question={question}
          key={i}
          {...options}
        />
      </Fragment>
    );
  };

  canEditTextQuestion = (question, isReviewer) => {
    if (this.state.disabled) {
      return false;
    }
    return isReviewer === this.state.isReviewer;
  };

  renderDescriptiveQuestion = (question, isReviewer, i, blockType) => {
    let key = isReviewer ? `supervisor-${i}` : `collaborator-${i}`;
    let options = {};
    let answer = this.getDefaultValueToDescriptiveQuestion(
      question,
      isReviewer
    );
    if (answer) {
      options.defaultText = answer.text;
    }
    return (
      <Fragment key={key}>
        <Descriptive
          disabled={!this.canEditTextQuestion(question, isReviewer)}
          onChange={(value, isDisabled) => {
            this.onAnswerChange(question, value, isDisabled);
            this.setState({ isDisabled: isDisabled });
          }}
          question={question}
          isReviewer={isReviewer}
          blockType={blockType}
          key={key}
          {...options}
        />
      </Fragment>
    );
  };

  renderButtonBack = () => {
    return (
      <button
        onClick={() => this.onPreviousBlock()}
        disabled={
          this.state.stageIndex === 0 && this.state.currentBlockIndex === 0
        }
      >
        Anterior
      </button>
    );
  };

  renderButtonNext = () => {
    let block = this.state.currentBlock;
    let onlyShow = this.state.onlyShow;
    let disabled = false;
    let isDisabled = this.state.isDisabled;

    if (block) {
      if (this.state.isReviewer) {
        disabled = block.type === 'resultado' || block.type === 'result';
      } else {
        let answers = this.getAnwersFormattedToAPI(
          this.state.answers,
          this.getDefaultAnswers()
        );
        answers = answers.filter(answer => {
          let question = block.questions.find(
            question => question.id === answer.question_id
          );
          return question.type !== 'text_supervisor';
        });
        disabled =
          answers.length !==
          block.questions.filter(
            question => question.type !== 'text_supervisor'
          ).length;
      }
    }
    if (onlyShow) {
      disabled = block.type === 'resultado' || block.type === 'result';
    }
    return (
      <button onClick={() => this.onNextBlock()} disabled={isDisabled}>
        Próximo
      </button>
    );
  };

  renderButtonCloseSup = () => {
    let block = this.state.currentBlock;
    let disabled = false;
    if (block) {
      if (
        this.state.isReviewer &&
        (block.type === 'resultado' || block.type === 'result')
      ) {
        return (
          <button
            onClick={() => this.props.supervisorComplete()}
            disabled={disabled}
          >
            Concluir Revisão
          </button>
        );
      }
    }

    return null;
  };

  render() {
    const { currentStage, ready, notFound } = this.state;
    if (!ready && !notFound) {
      return <Loading loading={true} />;
    }
    if (!ready && notFound) {
      return (
        <Loading
          text="Não foram encontradas os formulários da avaliação"
          loading={true}
        />
      );
    }
    return (
      <Fragment>
        <Progress stages={this.state.stages} activeStageId={currentStage.id} />
        <MainContent>
          {this.renderCurrentBlock()}
          <ButtonsWrapper>
            {this.renderButtonBack()}
            {this.renderButtonCloseSup()
              ? this.renderButtonCloseSup()
              : this.renderButtonNext()}
          </ButtonsWrapper>
        </MainContent>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AnswerActions }, dispatch);

const mapStateToProps = state => ({
  requestAnswer: state.answer,
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizForm);
