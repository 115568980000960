import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
`;

export const SubHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;

  h1 {
    width: 417px;
    height: 30px;
    color: #00468e;
    font-family: "Open Sans";
    font-size: 22px;
    line-height: 30px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  .search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  a {
    text-align: center;
    line-height: 40px;
    height: 37px;
    width: 100px;
    border: 0;
    border-radius: 5px;
    background: #00468e;
    color: #fff;
    margin-right: 25px;
    font-family: "Open Sans";
    text-decoration: none;
  }

  .inputSearch {
    display: flex;
    height: 40px;
    width: 250px;
    background: #fff;
    margin-right: 35px;
    border: #fff solid 1px;
    border-radius: 10px;

    input {
      border: 0;
      margin-left: 10px;
    }
  }
`;

export const Table = styled.table`
  display: table;
  margin: 35px 25px;
  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: left;
  border-color: inherit;
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: left;
  border-color: inherit;

  th {
    margin: 0;
    padding: 0;
    height: 40px;
    background-color: #e7e7e7;
    color: #666666;
    font-size: 14px;
  }
`;

export const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  border-spacing: 0px;
  border-collapse: separate;

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  tr td {
    height: 50px;
    background-color: #fff;
    border-bottom: #ccc solid 1px;
    font-size: 14px;
    color: #333333;
    padding-left: 25px;

    font-family: "Open Sans";
  }
`;

export const ResponsiveWrapper = styled.div`
  @media (max-width: 768px) {
    .hideResponsive {
      display: none;
      width: 100%;
    }
  }
`;
