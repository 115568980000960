import styled from "styled-components";

export const SideMenuStyled = styled.div`
  width: 300px;
  padding-top: 80px;

  .tablist {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 20px;

    li {
      display: flex;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      &.react-tabs__tab--selected {
        background-color: #fff;
        color: #4a90e2;
        border-left: 3px solid #4a90e2;
        padding-right: 3px;
      }

      > a {
        flex: 1;
        padding: 20px 10px;
        color: #333333;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
`;
