import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Creators as CurrentCycleActions } from '../../../store/ducks/cycle/currentCycle';

export function* currentCycle(action) {
  try {
    let id = !action.payload || isNaN(action.payload.id) ? 'current' : parseInt(action.payload.id);
    let url = 'api/v1/cycles/' + id;
    if (action && action.payload && !!action.payload.dashboard) {
      url += `?dashboard=${action.payload.dashboard}`;
    }
    let result = yield call(api.get, url);
    yield put(CurrentCycleActions.currentCycleSuccess(result.data.details));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            CurrentCycleActions.currentCycleFailure(response.messages[0])
          );
        }
      }
    }
    yield put(
      CurrentCycleActions.currentCycleFailure(
        'Ocorreu um erro no servidor, tente mais tarde.'
      )
    );
  }
}
