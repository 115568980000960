import React, { Component, Fragment } from 'react';

import Nav from '../Components/Manager/Nav';
import Header from '../Components/Header';

import { MainWrapper, Content } from '../styles/components';
import { AppDiv } from '../styles/app';

import { GlobalStyle } from '../styles/global';

import { Route, Redirect } from 'react-router-dom';
import UserNav from '../Components/UserComponents/UserNav';
import DrawerToggleButton from '../Components/SideDrawer/ToggleButton';
import Backdrop from '../Components/SideDrawer/BackDrop';
import rightArrow from '../assets/images/rightArrow.svg';

export default class ManagerRoute extends Component {
  state = {
    SideDrawerOpen: false,
    title: null,
  };

  setTitle = title => {
    this.setState({ title });
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { SideDrawerOpen: !prevState.SideDrawerOpen };
    });
  };

  backDropClickHandler = () => {
    this.setState({ SideDrawerOpen: false });
  };

  render() {
    const {
      component: Component,
      location,
      hasSubordinates,
      ...props
    } = this.props;

    if (!props.profile || !hasSubordinates) {
      return <Redirect to="/" />;
    }
    let backDrop = null;
    let isActive = null;

    if (this.state.SideDrawerOpen) {
      isActive = true;
      backDrop = <Backdrop click={this.backDropClickHandler} />;
    }
    return (
      <Fragment>
        <MainWrapper>
          <GlobalStyle />
          {/* <Nav /> */}
          <UserNav
            isActive={isActive}
            location={location.pathname}
            hasSubordinates={hasSubordinates}
          />
          <DrawerToggleButton
            click={this.drawerToggleClickHandler}
            img={rightArrow}
          />
          {backDrop}
          <Content isActive={isActive}>
            <AppDiv>
              <Header location={this.props.location} title={this.state.title} setTitle={this.setTitle} />
              <Route {...props} render={props => <Component {...props} setTitle={this.setTitle} />} />
            </AppDiv>
          </Content>
        </MainWrapper>
      </Fragment>
    );
  }
}
