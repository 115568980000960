import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 10px 25px;
`;

export const Options = styled.div`
  width: 100%;
  height: 80px;

  min-height: 50px;

  display: flex;
`;

export const Items = styled.div`
  width: 50%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-left: 50px;
  }
`;

export const Sidebuttons = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  min-height: 80px;
  margin-right: 25px;

  .input {
    display: flex;
    align-items: center;
    height: 35px;

    background-color: #fff;
    width: 315px;
    border-radius: 5px;

    input {
      border: 0;
      margin-left: 5px;
    }
  }

  button {
    margin-left: 8px;
    background-color: #00468e;
    height: 35px;
    width: 96px;
    border-radius: 5px;
    border: 0;

    :last-child {
      background-color: #ccc;
    }

    a {
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
    }
  }
`;

export const SideList = styled.div`
  width: 250px;
  height: 400px;
  display: flex;
  flex-direction: column;
  margin-right: 0px;
`;

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  list-style: none;

  width: 100%;

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 16px 0 16px;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    color: #666666;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;

    border-bottom: #e5ecf3 1px solid;

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  li:hover {
    font-weight: 600;
  }

  .active {
    color: #00468e;
    font-weight: bold;
    border-left: #00468e 4px solid;
    background-color: #fff;
  }
`;

export const DivContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-bottom: #ccc 2px solid;
    border-radius: 4px;
    margin-top: 10px;
    min-height: 75px;

    span {
      margin-right: 50px;
      color: #666666;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    }

    span:hover {
      font-weight: bold;
      cursor: pointer;
    }

    .active {
      color: #4a90e2;
      border-bottom: #4a90e2 3px solid;
    }
  }

  .question {
    display: flex;
    flex-direction: column;
    background-color: #fff;

    span {
    }
  }
`;

export const Preview = styled.div`
  display: flex;
  margin-top: 10px;

  flex-direction: column;

  span {
    margin: 5px;
    color: #999999;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const Button = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding-top: 5px;
    background-color: #999999;
    border: 0;
    border-radius: 4px;
    width: 230px;
    height: 36px;

    a {
      color: #fff;
      text-decoration: none;
    }

    img {
      height: 25px;
      width: 25px;
      margin-left: 2px;
    }
  }
`;
