import axios from 'axios';
import AuthActions from '../store/ducks/auth';
import { baseURL } from '../config';

const api = axios.create({
  baseURL: baseURL,
  headers: { 'Accept-Language': 'pt-BR' },
});

async function refreshToken(originalRequest, dispatch, getState) {
  let state = getState();
  let refreshToken = state.auth.refreshToken || state.auth.refresh_token;
  if (!refreshToken) {
    throw new Error('Token not found');
  }
  let response = await api.post('/api/v1/auth/token/refresh', {
    refresh_token: refreshToken,
  });
  if (!response.data || response.status !== 200) {
    throw new Error();
  }
  const dataToken = response.data.token;
  refreshToken = response.data.refresh_token || response.data.refreshToken;
  localStorage.setItem('@emae:token', `Bearer ${dataToken}`);
  localStorage.setItem('@emae:refreshToken', refreshToken);

  api.defaults.headers.common.Authorization = `Bearer ${dataToken}`;
  originalRequest.headers.Authorization = `Bearer ${dataToken}`;

  dispatch(AuthActions.signInSuccess(dataToken, refreshToken));
  return api.request(originalRequest);
}

export function createRefreshTokenInterceptor(dispatch, getState) {
  api.interceptors.response.use(
    response => response,
    error => {
      return new Promise((resolve, reject) => {
        const originalRequest = error.config;
        if (error.response.status !== 401 || originalRequest._retry) {
          return reject(error);
        }
        refreshToken(originalRequest, dispatch, getState)
          .then(request => {
            resolve(request);
          })
          .catch(err => {
            setTimeout(() => {
              refreshToken(originalRequest, dispatch, getState)
                .then(request => {
                  resolve(request);
                })
                .catch(err => {
                  setTimeout(() => {
                    refreshToken(originalRequest, dispatch, getState)
                      .then(request => {
                        resolve(request);
                      })
                      .catch(err => {
                        dispatch(AuthActions.signOutRequest());
                        reject(error);
                      });
                  }, 200);
                });
            }, 200);
          });
      });
    }
  );
}

export default api;
