import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  updateCycleUserStatusRequest: ["cycleUserId", "status"],
  updateCycleUserStatusSuccess: ["success"],
  updateCycleUserStatusError: ["error"],
  updateCycleUserStatusRequestReset: null,
  updateCycleUserStatusReset: null,
  updateCycleUserStatusLoading: null
});

export const UpdateCycleUserStatusTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  error: false,
  success: false,
  loading: false
});

/* Reducers */
export const success = (state, { success }) => {
  return state.merge({
    error: false,
    loading: false,
    success
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const reset = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_CYCLE_USER_STATUS_SUCCESS]: success,
  [Types.UPDATE_CYCLE_USER_STATUS_ERROR]: error,
  [Types.UPDATE_CYCLE_USER_STATUS_RESET]: reset,
  [Types.UPDATE_CYCLE_USER_STATUS_LOADING]: loading
});
