import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Back = styled.div`
  margin-top: 30px;
  margin-left: 25px;

  a {
    text-decoration: none;
    color: #00468e;
    font-family: "Open Sans";
    line-height: 19px;
  }
`;

export const InfoDiv = styled.div`
  display: flex;

  margin: 15px 0 0 25px;

  span {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 22px;
    color: #4d4d4d;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 25px 0 10px;

  padding: 25px 32px;
  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);

  label {
    text-transform: uppercase;
    color: #666666;
    line-height: 22px;
    font-size: 16px;
    font-family: "Open Sans";
  }

  .itensButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    span {
      color: #00468e;
      font-size: 18px;
      font-family: "Open Sans";
      line-height: 24px;
      text-transform: uppercase;
    }

    button {
      border: 0;
      background-color: #00468e;
      border-radius: 5px;
      padding: 5px 10px;
      height: 36px;

      a {
        text-decoration: none;
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        font-family: "Open Sans";

        :hover {
          opacity: 0.8;
        }
      }
    }
  }

  .selected {
    margin-top: 20px;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: #cccccc 1px solid;
  border-radius: 4px;
  margin-top: 5px;
  padding: 0 10px;

  input {
    border: 0;
    height: 22px;
    flex: 1;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 22px;
  }

  img {
    height: 30px;
    width: 30px;
  }
`;

export const Table = styled.table`
  display: table;
  margin: 5px 25px;
  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: middle;
  border-color: inherit;

  th {
    text-align: left;
    margin: 0;
    padding: 0;
    height: 40px;
    background-color: #e7e7e7;
    color: #666666;
    font-size: 14px;
  }
`;
export const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  border-spacing: 0px;
  border-collapse: separate;

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  tr td {
    text-align: center;
    height: 50px;
    background-color: #fff;
    border-bottom: #ccc solid 1px;
    font-size: 14px;
    color: #333333;
    padding-left: 25px;

    font-family: "Open Sans";
  }

  tr td img {
    height: 40px;
    width: 40px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
`;

export const Button = styled.button`
  background-color: #00468e;
  height: 40px;
  width: 160px;
  border: 0;
  border-radius: 5px;

  :first-child {
    margin-right: 40px;
    background-color: #999999;
  }

  a {
    text-decoration: none;
    color: #fff;
  }
`;

export const Itens = styled.span`
  color: #00468e;
  font-size: 18px;
  font-family: "Open Sans";
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: ${props => props.marginBottom ? "20px" : "0px"};
`;
