import styled from "styled-components";

export const Options = styled.div`
  width: 100%;
  min-height: 80px;

  display: flex;
  justify-content: space-between;
`;

export const Items = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }
`;

export const Sidebuttons = styled.div`
  display: flex;

  align-items: center;
  min-height: 80px;

  .input {
    display: flex;
    align-items: center;
    height: 35px;

    background-color: #fff;
    width: 315px;
    border-radius: 5px;

    input {
      border: 0;
      margin-left: 5px;
    }
  }

  button {
    margin-left: 8px;
    background-color: #00468e;
    height: 35px;
    width: 96px;
    border-radius: 5px;
    border: 0;

    span {
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
    }
  }
`;
