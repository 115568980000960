import React, { Component } from "react";
import { NumberRevisaoWrapper } from "./styles";
import NumberNotaRevisor from "../NumberNotaRevisor";

export default class NumbersRevisao extends Component {
  state = {
    numbers: [],
    notaUser: null,
    notaReviewer: null,
    isAwnsered: false
  };

  static getDerivedStateFromProps(props, state) {
    state.numbers = props.options.map(option => option.value);
    if (state.notaReviewer === null && props.defaultNumReviewer) {
      state.notaReviewer = props.defaultNumReviewer;
    }
    if (state.notaUser === null && props.defaultNumUser) {
      state.notaUser = props.defaultNumUser;
    }
    return state;
  }

  onClickItem = num => {
    this.setState({ notaUser: num, isAwnsered: true });
  };

  renderSquare = (num, i) => {
    const { notaUser, notaReviewer } = this.state;
    if (notaUser == num) {
      return this.renderSquareUser(num, i);
    }
    if (notaReviewer == num) {
      return this.renderSquareRevisor(num, i);
    }
    return this.renderSquareNormal(num, i);
  };

  renderSquareRevisor(num, i) {
    return (
      <NumberNotaRevisor
        onClick={accepted => {
          if (accepted) {
            this.setState({ notaUser: num, isAwnsered: true });
            this.props.onChange(
              this.props.options.find(option => option.value == num),
              false
            );
          } else {
            this.setState({ isAwnsered: true });
          }
        }}
        key={i}
        nota={num}
      />
    );
  }

  renderSquareUser(num, i) {
    return (
      <li key={i} className="active" onClick={() => this.onClickItem(num)}>
        {num}
      </li>
    );
  }

  renderSquareNormal(num, i) {
    return <li key={i}>{num}</li>;
  }

  render() {
    const { numbers } = this.state;
    return (
      <NumberRevisaoWrapper>
        <ul>{numbers.map((num, i) => this.renderSquare(num, i))}</ul>
      </NumberRevisaoWrapper>
    );
  }
}
