import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ParticipantsActions from '../../../../store/ducks/participant/participants';
import { Creators as CycleActions } from '../../../../store/ducks/cycle';

import { InfoDiv, Filtro, Selecionados, NoMorePageMargin } from './styles';
import { Div } from '../../../../styles/global';

import PdfIcon from '../../../../assets/images/pdf-file.svg';
import XlsIcon from '../../../../assets/images/xls-file.svg';
import NotFoundItems from '../../../../Components/NotFoundItems';
import Back from '../../../../Components/Back';
import SubHeader from '../../../../Components/SubHeader';
import FilterParticipants from '../../../../Components/FilterParticipants';
import FilterQuiz from '../../../../Components/FilterQuiz';
import Pagination from '../../../../Components/Pagination';
import ParticipantsTable from './ParticipantsTable';
import ModalRemove from './ModalRemove';

class Participantes extends Component {
  static propTypes = {
    participantsRequest: PropTypes.func.isRequired,
  };

  state = {
    filters: {},
    page: 1,
    cycleId: null,
    isModalRemoveOpen: false,
    participantes: [],
    participantesSelecionados: [],
  };

  onFilterParticipants = filters => {
    this.setState({
      page: 1,
      append: false,
      fetch: true,
      filters: { ...this.state.filters, ...filters },
    });
  };

  onFilterQuiz = (quiz_id, status) => {
    if (!quiz_id || quiz_id === 'Selecione') {
      quiz_id = null;
    }
    if (!status || status === '') {
      status = null;
    }
    this.setState({
      filters: { ...this.state.filters, quiz_id, status },
      fetch: true,
      append: false,
      page: 1,
    });
  };

  handleParticipantesSelecionados = arrayParticipantes => {
    this.setState({ participantesSelecionados: arrayParticipantes });
  };

  openModalRemoveParticipantes = () => {
    this.setState({ isModalRemoveOpen: true });
  };

  closeModalRemoveParticipantes = () => {
    this.setState({ isModalRemoveOpen: false });
  };

  renderParticipants = () => {
    const { participants } = this.props;
    if (this.state.participantes.length === 0) {
      return <NotFoundItems />;
    }
    return (
      <Div>
        <ParticipantsTable
          onItemClicked={participant => {
            this.props.history.push(
              `/ciclos/${this.state.cycleId}/participantes/detalhes/${participant.cycle_user_id}`
            );
          }}
          participantes={this.state.participantes}
          handleParticipantesSelecionados={this.handleParticipantesSelecionados}
        />
        {participants && participants.page !== participants.lastPage ? (
          <Pagination
            paginate={this.props.participants}
            onClick={page => {
              this.setState({ page, append: true, fetch: true });
            }}
          />
        ) : (
          <NoMorePageMargin />
        )}
      </Div>
    );
  };

  static getDerivedStateFromProps(props, state) {
    state.participantes = props.participants.data;
    return state;
  }

  componentDidMount() {
    let params = this.props.match.params;
    if (!params || !params.id || !params.id.match(/^[0-9]+$/)) {
      return this.setState({ ready: true });
    }
    this.props.getCycleRequest(params.id);
    this.setState({ cycleId: params.id, fetch: true, fetchCount: true });
  }

  componentDidUpdate(prevProps) {
    if (this.state.fetch) {
      this.setState({ fetch: false });
      this.props.participantsRequest({ ...this.state });
    }
    if (!prevProps.cycle.success && this.props.cycle.success) {
      this.props.setTitle(`Ciclo - ${this.props.cycle.data.title}`);
    }
  }

  onDownloadRequest = (e, type) => {
    e.preventDefault();
    const filters = this.state.filters;
    filters.type = type;
    this.props.participantsReportDownloadRequest(this.state.cycleId, filters);
  };

  renderCount = () => {
    let participants = this.props.participants;
    let count = null;
    if (participants.success) {
      count = Number(participants.total);
    }
    let message;
    if (participants.loading) {
      message = 'Carregando...';
    } else if (count === null || count === 0) {
      message = 'Nenhum participante';
    } else if (count === 1) {
      message = '1 participante';
    } else {
      message = `${count} participantes`;
    }
    return (
      <div className="geral">
        <span>{message}</span>
      </div>
    );
  };

  render() {
    let participantesSelecionados = this.state.participantesSelecionados;
    return (
      <Fragment>
        <Div>
          <Back path="/ciclos" title="Voltar para listagem" />
          <SubHeader cycle={{ id: this.state.cycleId }} page="participantes" />
          <InfoDiv>
            <span>
              Os participantes foram adicionados automaticamente, com base na
              lista de colaboradores mais recente. Caso necessário, é possível
              remover participantes da lista abaixo.
            </span>
          </InfoDiv>
          <Filtro>
            <FilterQuiz
              cycleId={this.state.cycleId}
              onFilterQuiz={this.onFilterQuiz}
            />
          </Filtro>
          <Filtro>
            <FilterParticipants onSubmit={this.onFilterParticipants} />
          </Filtro>
          <Filtro>
            <Selecionados>
              {this.renderCount()}

              <div className="buttons">
                <div className="download-buttons">
                  <button
                    type="button"
                    className="pdf-button"
                    onClick={e => this.onDownloadRequest(e, 'pdf')}
                  >
                    <img src={PdfIcon} alt="PDF" />
                    PDF
                  </button>
                  <button
                    type="button"
                    className="xls-button"
                    onClick={e => this.onDownloadRequest(e, 'xls')}
                  >
                    <img src={XlsIcon} alt="XLS" />
                    XLS
                  </button>
                </div>
                <div className="remover">
                  <button
                    onClick={this.openModalRemoveParticipantes}
                    disabled={
                      participantesSelecionados.length === 0 ? true : false
                    }
                  >
                    REMOVER SELECIONADOS
                  </button>
                  <ModalRemove
                    cycleId={this.state.cycleId}
                    closeModal={this.closeModalRemoveParticipantes}
                    isModalRemoveOpen={this.state.isModalRemoveOpen}
                    participantesSelecionados={participantesSelecionados}
                    updateParticipants={() => {
                      this.setState({
                        participantesSelecionados: [],
                        fetch: true,
                        fetchCount: true,
                      });
                    }}
                  />
                </div>
              </div>
            </Selecionados>
            {this.renderParticipants()}
          </Filtro>
        </Div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ParticipantsActions, ...CycleActions }, dispatch);

const mapStateToProps = state => ({
  participants: state.participants,
  cycle: state.cycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(Participantes);
