import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";
import {
  convertCycleStatus,
  getLevelStatus
} from "../../../services/cycle/status";

/* Types & Action Creators */
const { Types, Creators } = createActions({
  participantDetailRequest: ["payload"],
  participantDetailSuccess: ["data"],
  participantDetailError: ["error"],
  participantDetailClearError: null,
  participantDetailLoading: null
});

export const ParticipantDetailTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  data: null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */
export const success = (state, { data }) => {
  return state.merge({
    data: {
      ...data,
      statusText: convertCycleStatus(data.status),
      statusLevel: getLevelStatus(data.status)
    },
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.PARTICIPANT_DETAIL_SUCCESS]: success,
  [Types.PARTICIPANT_DETAIL_ERROR]: error,
  [Types.PARTICIPANT_DETAIL_CLEAR_ERROR]: clearError,
  [Types.PARTICIPANT_DETAIL_LOADING]: loading
});
