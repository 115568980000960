import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as OfferActions } from '../../../ducks/modules/courses/offer';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* getOffer(action) {
  try {
    const response = yield call(api.get, '/api/v1/offers/' + action.payload.id);

    if (action.payload.enrollmentId) {
      const currentEnrollment = yield call(
        api.get,
        '/api/v1/enrollments/' + action.payload.enrollmentId
      );
      yield put(
        OfferActions.getOfferSuccess({
          ...response.data.details,
          currentEnrollment: currentEnrollment.data.details,
        })
      );
    } else {
      yield put(OfferActions.getOfferSuccess(response.data.details));
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
