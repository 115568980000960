import styled from "styled-components";

export const Sidebar = styled.nav`
  height: 100%;
  width: 200px;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  z-index: 10;
  padding-bottom: 10px;
  padding-left: 15px;
  position: fixed;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const Toggle = styled.div`
  margin-top: 15px;
`;

export const List = styled.ul`
  width: 100%;
  padding: 0;
  list-style: none;

  li {
    margin-top: 15px;

    a {
      height: 40px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #666666;
      font-family: "Open Sans";
      text-transform: uppercase;
      font-size: 13px;
      padding-left: 15px;
      font-weight: lighter;
    }

    a:hover {
      font-weight: 600;
    }

    img {
      height: 40px;
      width: 40px;
      padding-right: 15px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const ExitDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    display: flex;
    align-items: center;

    span {
      color: #666666;
      font-size: 13px;
      text-transform: uppercase;
      font-family: "Open Sans";
      line-height: 18px;
      padding-left: 15px;
    }

    :last-child {
      margin-top: 5px;

      span {
        font-size: 10px;
        line-height: 14px;
        color: #999999;
      }
    }
  }
  button {
    border: 0;
    background-color: transparent;
    display:flex;
    align-items: center;
    margin-left: 25px;

    margin-bottom: 15px;
    img {
      height: 22px;
      width: 20px;
      margin-right: 10px;
    }
  }
`;
