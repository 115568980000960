import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "rating/GET_REQUEST",
  GET_SUCCESS: "rating/GET_SUCCESS",
  SET_ERROR: "rating/SET_ERROR",
  RESET_REQUEST: "rating/RESET_REQUEST",
  RESET_SUCCESS: "rating/RESET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  error: null
});

export default function ratings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, error: null };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        error: null
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    case Types.SET_ERROR:
      return { ...state, loading: false, error: action.payload };
    case Types.RESET_SUCCESS:
      return { data: [], loading: true, error: null };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getRatingsRequest: (courseId, type, answers) => ({
    type: Types.GET_REQUEST,
    payload: { courseId, type, answers }
  }),

  getRatingsSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  setRatingsError: error => ({
    type: Types.SET_ERROR,
    payload: error
  }),

  resetRatingsRequest: () => ({
    type: Types.RESET_REQUEST
  }),

  resetRatingsSuccess: () => ({
    type: Types.RESET_SUCCESS
  })
};
