import styled from 'styled-components';

export const CourseItemList = styled.div`
  background-color: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const CourseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 20px 0;
  border-bottom: 1px solid #ddd;
`;

export const CourseItemRatingAndNewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .badge {
    color: #fff;
    background-color: #f03b42;
    font-size: 10px;
    border-radius: 3px;
    padding: 5px 10px;
    text-align: center;
  }
`;

export const CourseItemTitleAndNotaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    a {
      font-family: 'OS Bold', sans-serif;
      color: #535353;
      font-size: 22px;
    }

    margin: 0;
  }

  .nota-wrapper {
    display: flex;
    flex-direction: column;

    span {
      color: #ccc;
      font-size: 12px;
      margin-bottom: 0;
    }

    span:nth-child(2) {
      font-size: 22px;
      color: #535353;
      font-family: 'OS Bold', sans-serif;
    }
  }
`;

export const CourseItemInfosWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;

  .info-wrapper {
    display: flex;
    flex-direction: column;
    :not(:last-child) {
      margin-right: 35px;
    }

    .title {
      color: #ccc;
      font-size: 12px;
    }
    .info {
      color: #aaa;
      font-family: 'OS Bold', sans-serif;
      font-size: 14px;
      margin-top: 5px;

      .view-cert {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background-color: #00468e;
        border-radius: 4px;
        :hover {
          opacity: 0.8;
        }
        > span {
          color: #fff;
          font-size: 12px;
          margin-left: 5px;
          font-family: 'OS Bold', sans-serif;
        }
      }
    }
  }
`;
