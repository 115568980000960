import React from 'react';

import { FormInputIconized, FormInput } from '../FormInputs';
import SearchIcon from '../../assets/images/search.svg';
import { SelectDiv, Selects } from './styles';
import SelectWithPopupFunction from '../SelectWithPopupFunction';
import SelectWithPopupOffice from '../SelectWithPopupOffice';
import SelectWithPopupOrganizationUnit from '../SelectWithPopupOrganizationUnit';

export default class FilterParticipants extends React.Component {
  state = {
    search: null,
    organization_unit: null,
    office: null,
    function: null,
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit({
      search: this.state.search,
      manager: this.state.manager,
      function: this.state.function,
      registration: this.state.registration,
      organization_unit: this.state.organization_unit,
      office: this.state.office,
    });
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <SelectDiv>
          <label>selecionar participantes</label>
          <FormInputIconized
            placeholder="Buscar"
            name="search"
            type="text"
            icon={SearchIcon}
            onChange={e => this.setState({ search: e.target.value })}
            style={{ height: '40px', borderRadius: '3px' }}
          />

          <Selects>
            <div className="selects">
              <FormInput
                placeholder="Matrícula"
                name="Matrícula"
                type="text"
                onChange={e => this.setState({ registration: e.target.value })}
                style={{ height: '40px', borderRadius: '3px' }}
              />
            </div>
            <div className="selects">
              <SelectWithPopupOrganizationUnit
                onSelect={value => this.setState({ organization_unit: value })}
              />
            </div>
            <div className="selects">
              <SelectWithPopupFunction
                onSelect={value => this.setState({ function: value })}
              />
            </div>
            <div className="selects">
              <SelectWithPopupOffice
                onSelect={value => this.setState({ office: value })}
              />
            </div>
          </Selects>

          <button type="submit">Filtrar</button>
        </SelectDiv>
      </form>
    );
  }
}
