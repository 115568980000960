import React, { Component } from 'react';
import {
  TableDivScroll,
  Thead,
  Tbody,
  Tr,
} from '../../../Components/Table/styles';
import { DataTableWrapper } from '../styles';

export default class TableDataRelatorio extends Component {
  renderRowColaborador(data) {
    return (
      <Tr key={data.id}>
        <td>{data.id}</td>
        <td>{data.title}</td>
        <td>{data.role}</td>
        <td>{data.department}</td>
        <td className="center">{data.media}</td>
        {this.renderBlocks(data)}
      </Tr>
    );
  }

  renderRowDepartment(data) {
    return (
      <Tr key={data.id}>
        <td>{data.id}</td>
        <td>{data.title}</td>
        <td>{data.name}</td>
        <td className="center">{data.registration}</td>
        <td>{data.role}</td>
        <td className="center">{data.media}</td>
        {this.renderBlocks(data)}
      </Tr>
    );
  }

  renderBlocks(data) {
    return data.blocks.map((b, i) => (
      <td key={data.id + i} className="center">
        {b}
      </td>
    ));
  }

  render() {
    const { dataHeader, dataRow, entity, currentWidth } = this.props;
    return (
      <DataTableWrapper currentWidth={currentWidth}>
        <TableDivScroll>
          <Thead>
            <Tr>
              {dataHeader.map(h => (
                <th key={h}>{h}</th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {dataRow.map(data =>
              entity === 'colaborador'
                ? this.renderRowColaborador(data)
                : this.renderRowDepartment(data)
            )}
          </Tbody>
        </TableDivScroll>
      </DataTableWrapper>
    );
  }
}
