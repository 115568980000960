import React, { Component } from "react";
import { DropdownMenuItemStyled } from "./styles";

export default class DropdownMenuItem extends Component {
  render() {
    const props = this.props;
    return (
      <DropdownMenuItemStyled
        onClick={this.props.onClick}
        isActive={props.isActive}
        completed={props.isCompleted}
      >
        <div className="left-side">
          <div className="ball-wrapper">
            <div className="ball" />
          </div>
          <div className="stroke" />
        </div>
        <div className="right-side">
          <span>{props.title}</span>
        </div>
      </DropdownMenuItemStyled>
    );
  }
}
