import React, { Component } from "react";

import { Provider } from "react-redux";
import store from "./store";
import "./config/ReactotronConfig";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./styles/fonts.css"

import LoadingApp from "./pages/LoadingApp";
import { createRefreshTokenInterceptor } from "./services/api";

createRefreshTokenInterceptor(store.dispatch, store.getState);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <LoadingApp />
      </Provider>
    );
  }
}

export default App;
