import styled from 'styled-components';

export const TableDefault = styled.table`
  width: 100%;
  font-size: 14px;

  thead {
    background-color: #e7e7e7;
    font-weight: 600;
    border-bottom: 1px solid #e5e5e5;

    th {
      padding: 5px 10px;
      color: #666666;
    }
  }

  tbody {
    tr {
      background-color: #fff;
      border-bottom: 1px solid #e5e5e5;

      td {
        padding: 10px;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }
  }
`;

export const TableDiv = styled.table`
  display: table;
  width: 100%;

  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;
`;

export const TableDivScroll = styled.table`
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0px;
  border-collapse: separate;

  border-color: grey;

  th:nth-child(n + 4):nth-child(-n + 100),
  .center {
    min-width: 140px;
    text-align: center !important;
  }

  th,
  td {
    min-width: 80px;
    padding: 5px !important;
  }

  overflow-x: scroll !important;

  @media (max-width: 768px) {
    ::-webkit-scrollbar {
      width: 1em;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
`;

export const Thead = styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
`;

export const Tr = styled.tr`
  display: table-row;
  vertical-align: middle;
  border-color: inherit;

  :hover {
    td {
      background: #efefef;
    }
  }

  td.check-wrapper {
    width: 40px;
  }

  th {
    text-align: left;
    margin: 0;
    padding: 0;
    height: 40px;
    background-color: #e7e7e7;
    color: #666666;
    font-size: 14px;
    :first-child {
      text-align: center;
    }
    &.center {
      text-align: center;
    }
  }
  .btn-click:hover {
    cursor: pointer;
  }
`;
export const Tbody = styled.tbody`
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;

  border-spacing: 0px;
  border-collapse: separate;

  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }

  tr td {
    height: 50px;
    background-color: #fff;
    border-bottom: #ccc solid 1px;
    font-size: 14px;
    color: #333333;
    font-family: 'Open Sans';

    :first-child {
      text-align: center;
    }
  }

  td.center {
    text-align: center;
  }

  tr td img {
    height: 40px;
    width: 40px;

    &.avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  button.delete {
    background-color: red;
    border: 0;
    padding: 5px;
    color: #fff;
  }
`;
