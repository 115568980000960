import React, { Fragment } from 'react';

import ItensAndButtons from '../../../Components/ItensAndButtons';
import TableUsuario from './Table';
import ModalImport from './ModalImport';

import SideMenu from '../SideMenu';
import { ContentWrapper } from '../styles';

import { Creators as UsersActions } from '../../../store/ducks/admin/users';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Pagination from '../../../Components/Pagination';
import NotFoundItems from '../../../Components/NotFoundItems';

class DadosGeraisUsuarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalImportOpen: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.fetch) {
      state.fetch = false;
      props.getUsersRequest({ page: state.page, name: state.search });
    }

    return state;
  }

  componentDidMount() {
    this.props.getUsersRequest({ page: 1 });
  }

  search = text => {
    this.setState(
      {
        page: 1,
        search: text,
      },
      () => {
        this.props.getUsersRequest({ page: 1, name: text });
      }
    );
  };

  onOpenModal = () => {
    this.setState({ modalImportOpen: true });
  };

  closeModal = () => {
    this.setState({ modalImportOpen: false });
  };

  nextPage = page => {
    this.setState({ page, fetch: true });
  };

  render() {
    const { users, history } = this.props;
    return (
      <Fragment>
        <ContentWrapper>
          <SideMenu page={1} />

          <div className="content">
            <ItensAndButtons
              history={history}
              newUrl="/dadosgerais/usuarios/novo"
              dadosGerais={true}
              openModal={this.onOpenModal}
              onSubmit={this.search}
              total={users.total}
              isLoading={users.loading}
            />

            {users && users.data.length > 0 ? (
              <>
                <TableUsuario usuarios={users} />
                {users.page !== users.lastPage && (
                  <Pagination
                    paginate={users}
                    onClick={page => this.nextPage(page)}
                  />
                )}
              </>
            ) : users.loading ? (
              ''
            ) : (
              <NotFoundItems />
            )}
          </div>
          <ModalImport
            open={this.state.modalImportOpen}
            onClose={this.closeModal}
          />
        </ContentWrapper>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UsersActions }, dispatch);

const mapStateToProps = ({ users }) => ({
  users,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DadosGeraisUsuarios);
