import React from "react";

import { GradeDiv, Ul, Show, Li } from "./styles";

export default class Grade extends React.Component {
  state = {
    value: null,
    selected: false,
    id: null
  };

  clickGrade(e) {
    e.preventDefault();
    this.setState({ value: e.target.value });

    this.setState(prevState => {
      return { selected: !prevState.selected };
    });

    if (this.props.onChange instanceof Function) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    return (
      <GradeDiv>
        <span>{this.state.value}</span>
        <Ul>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "1" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="1">
              1
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "2" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="2">
              2
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "3" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="3">
              3
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "4" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="4">
              4
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "5" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="5">
              5
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "6" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="6">
              6
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "7" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="7">
              7
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "8" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="8">
              8
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "9" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="9">
              9
            </button>
          </Li>
          <Li
            isSelected={this.state.selected}
            className={this.state.value === "10" ? "li-active" : ""}
          >
            <button onClick={this.clickGrade.bind(this)} value="10">
              10
            </button>
          </Li>
        </Ul>
        <Show>
          <span>demonstra pouco</span>
          <span>demonstra com excelência</span>
        </Show>
      </GradeDiv>
    );
  }
}
