import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  userCyclesRequest: ["payload"],
  userCyclesResetRequest: null,
  userCyclesReset: null,
  userCyclesSuccess: ["payload", "append"],
  userCyclesError: ["error"],
  userCyclesClearError: null,
  userCyclesLoading: null
});

export const UserCyclesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  total: null,
  perPage: null,
  page: null,
  lastPage: null,
  data: [],
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { payload, append }) => {
  let data = state.data;
  if (!!append) {
    data = data.concat(payload.data);
    if (payload.data.length === 0) {
      payload.page--;
    }
  } else {
    data = payload.data;
  }
  return state.merge({
    ...payload,
    data,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    total: null,
    perPage: null,
    page: null,
    lastPage: null,
    data: [],
    error: false,
    success: false,
    loading: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_CYCLES_SUCCESS]: success,
  [Types.USER_CYCLES_ERROR]: error,
  [Types.USER_CYCLES_CLEAR_ERROR]: clearError,
  [Types.USER_CYCLES_RESET]: reset,
  [Types.USER_CYCLES_LOADING]: loading
});
