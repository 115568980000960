import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "enrollment-in-progress/POST_REQUEST",
  GET_SUCCESS: "enrollment-in-progress/GET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: true
});

export default function enrollmentInProgress(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, logged: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getEnrollmentInProgressRequest: status => ({
    type: Types.GET_REQUEST,
    payload: { status }
  }),

  getEnrollmentInProgressSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  })
};
