import React from "react";
import moment from "moment";

import DpLogo from "../../assets/images/ico-digital-pages-white.svg";

import { FooterStyled } from "./styles";

function FooterOutisde() {
  return (
    <FooterStyled>
      <span>RDP Manager {moment().format("YYYY.MM.DD")}</span>
      <span>
        <a href="https://portugues.digitalpages.com.br/">
          <img src={DpLogo} alt="Digital Pages" />
          Digital Pages
        </a>
      </span>
    </FooterStyled>
  );
}

export default FooterOutisde;
