import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as ReasonsActions } from '../../store/ducks/reason/reasons';
import { FormSelectLabelled } from '../FormInputs';

class ReasonFormSelect extends React.Component {
  state = {
    fetch: false,
    reasons: [],
  };

  componentDidMount() {
    if (
      !this.state.fetch &&
      !this.props.reasons.success &&
      !this.props.reasons.loading
    ) {
      this.setState({ fetch: true });
      this.props.clearReasons();
      this.props.getReasonsRequest({ all: true });
    }
  }

  handleChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { reasons } = this.props;
    return (
      <Fragment>
        {reasons && reasons.success && (
          <FormSelectLabelled
            label="JUSTIFICATIVAS"
            name="justificativas"
            onChange={this.handleChange}
            options={reasons.data}
            defaultoption="Selecione..."
            nameattr="title"
            valueattr="id"
          />
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ReasonsActions }, dispatch);

const mapStateToProps = state => ({
  reasons: state.reasons,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReasonFormSelect);
