import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  cycleQuizzesRequest: ["cycleId"],
  cycleQuizzesSuccess: ["quizzes"],
  cycleQuizzesError: ["error"],
  cycleQuizzesClearError: null,
  cycleQuizzesLoading: null
});

export const CycleQuizzesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  quizzes: null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { quizzes }) => {
  return state.merge({
    quizzes,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CYCLE_QUIZZES_SUCCESS]: success,
  [Types.CYCLE_QUIZZES_ERROR]: error,
  [Types.CYCLE_QUIZZES_CLEAR_ERROR]: clearError,
  [Types.CYCLE_QUIZZES_LOADING]: loading
});
