import styled, { css } from 'styled-components';

export const SquaresWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
  > div {
    border: 1px solid #00468e;
    flex: 1;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #00468a;
    background-color: #e5ecf3;

    ${props =>
      !props.isNumbers &&
      css`
        flex: unset;
        width: 140px;
        height: 80px;
      `}
  }
`;
