import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'roles/GET_REQUEST',
  GET_SUCCESS: 'roles/GET_SUCCESS',
  CLEAR: 'roles/CLEAR',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  total: null,
  page: 1,
  lastPage: null,
});

export default function roles(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        ...action.payload.data,
        data: [...state.data, ...action.payload.data.data],
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.CLEAR:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getRolesRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  getRolesSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  clearRoles: () => ({
    type: Types.CLEAR,
  }),
};
