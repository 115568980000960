import styled, {css} from 'styled-components'

export const MediaNotaBoxWrapper = styled.div`
  > span {
    text-transform: uppercase;
    font-weight: bold;
    color: ${props => props.isMediaRevisado ? '#009B4C' : '#00468e'};
    font-size: 18px;
    line-height: 14px;
  }

  > div {
    background-color: ${props => props.isMediaRevisado ? '#009B4C' : '#00468e'};
    padding: 20px 40px;
    max-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -5px;

    span {
      color: #fff;
      font-weight: bold;
      font-size: 24px;
    }
  }
`
