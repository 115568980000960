import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    padding: 10px 20px;
    background-color: #00468e;
    color: #fff;
    border: 0;
    border-radius: 5px;

    &[disabled] {
      background-color: #eee;
    }

    :not([disabled]):hover {
      opacity: 0.8;
    }
  }
`;
