import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  functionsRequest: ["payload"],
  functionsResetRequest: null,
  functionsReset: null,
  functionsSuccess: ["payload", "append"],
  functionsError: ["error"],
  functionsClearError: null,
  functionsLoading: null
});

export const FunctionsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { payload }) => {
  return state.merge({
    data: payload.map(item => item.name),
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    data: [],
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    data: [],
    error: false,
    success: false,
    loading: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FUNCTIONS_SUCCESS]: success,
  [Types.FUNCTIONS_ERROR]: error,
  [Types.FUNCTIONS_CLEAR_ERROR]: clearError,
  [Types.FUNCTIONS_RESET]: reset,
  [Types.FUNCTIONS_LOADING]: loading
});
