import React, { Component } from "react";
import ErrorMsg from "../../ErrorMsg";

export default class FormError extends Component {
  render() {
    let formError = this.props.error;
    const {isWarning} = this.props;
    let error = null;
    if (formError) {
      if (typeof formError === 'string') {
        error = <ErrorMsg isWarning={isWarning} msg={formError} />;
      } else {
        error = <ErrorMsg isWarning={isWarning} msg={formError.title} />;
      }
    }
    return <div>{error}</div>;
  }
}
