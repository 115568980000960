import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/answer/answerAgreeRevision";

export function* requestAnswerAgreeRevision({ cycleUserId }) {
  yield put(Actions.answerAgreeRevisionReset());
  yield put(Actions.answerAgreeRevisionLoading());
  try {
    let url = `api/v1/cycles/users/${cycleUserId}/agree`;
    let result = yield call(api.put, url);
    yield put(Actions.answerAgreeRevisionSuccess(result.data.messages[0]));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (!!request) {
        let request = error.request;
        if (request.status === 400 || request.status === 404) {
          let response = JSON.parse(request.response);
          return yield put(
            Actions.answerAgreeRevisionError(response.messages[0])
          );
        }
      }
    }
    yield put(
      Actions.answerAgreeRevisionError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
