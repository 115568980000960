import { call, put } from "redux-saga/effects";
import api from "../../services/api";
import ForgotPasswordActions from "../ducks/forgotPassword";

export function* forgotPassword({ username }) {
  yield put(ForgotPasswordActions.forgotPasswordClearError());
  yield put(ForgotPasswordActions.forgotPasswordLoading());
  let callback = window.location.origin + "/definir-senha";
  try {
    yield call(api.post, "api/v1/forgot-password", {
      username,
      callback
    });

    yield put(ForgotPasswordActions.forgotPasswordSuccess());
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        return yield put(
          ForgotPasswordActions.forgotPasswordError(response.messages[0])
        );
      }
    }
    yield put(
      ForgotPasswordActions.forgotPasswordError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
