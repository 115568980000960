import styled from "styled-components";

export const GradeDiv = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 50px;

  min-height: 100px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
`;

export const Numbers = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  background: #e5ecf3;
  border: #00468e 1px solid;

  span {
    color: #00468e;
    font-size: 24px;
    line-height: 33px;
    font-weight: 600;
    text-align: center;
  }
`;

export const Show = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;

  span {
    color: #00468e;
    line-height: 17px;
    font-size: 12px;
    text-transform: uppercase;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  display: flex;
`;

export const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  background: #e5ecf3;
  border: #00468e 1px solid;
  min-height: 80px;
  cursor: pointer;

  :hover {
    border: #00468e 3px solid;
  }

  button {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 0;
  }

  &.li-active {
    background-color: #195899;
    color: #fff;
    border: #00468e 3px solid;
  }
`;
