import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';

import UserCycleActions from '../../../store/ducks/cycle/userCycle';
import FindAnswerActions from '../../../store/ducks/answer/findAnswer';
import Progress from '../../../Components/UserComponents/Progress';
import QuizDownload from '../../../Components/Quiz/Download';

import { Content } from './styles';
import Loading from '../../../Components/Loading';

class UserResults extends Component {
  state = {
    ready: false,
    findAnswers: [],
    userCycle: null,
    goToReview: false,
  };

  constructor(props) {
    super(props);
    props.userCycleResetRequest();
  }

  static getDerivedStateFromProps(props, state) {
    state.findAnswers = props.findAnswer.answers;
    state.userCycle = props.userCycle.userCycle;
    if (!state.ready) {
      if (props.findAnswer.success) {
        state.ready = true;
      } else if (props.findAnswer.error) {
        state.ready = true;
      } else if (!props.findAnswer.loading && props.userCycle.success) {
        props.findAnswersRequest(props.userCycle.userCycle.id);
      }
    }
    return state;
  }

  componentDidMount() {
    this.props.userCycleRequest();
  }


  getMedia = () => {
    let stages = this.state.userCycle.stages;
    let questions = [];
    for (let i = 0; i < stages.length; i++) {
      let stage = stages[i];
      let blocks = stage.blocks;
      for (let k = 0; k < blocks.length; k++) {
        let block = blocks[k];
        let blockQuestions = block.questions.filter(
          q => q.type == 'numeric_interval'
        );
        questions = questions.concat(blockQuestions);
      }
    }
    if (questions.length === 0) {
      return null;
    }
    let answers = this.state.findAnswers;
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id == answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id == answer.question_interval_numerics_id
      );
      return parseInt(answer.value);
    });
    if (grades.length == 0) {
      return 0;
    }
    let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
    return Number(sum / questions.length).toFixed(2);
  };

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.state.goToReview) {
      return <Redirect to={`/user/stage`} />;
    }
    const { userCycle } = this.state;
    return (
      <Fragment>
        <Progress
          stages={this.state.userCycle ? this.state.userCycle.stages : []}
          lastStageActive={true}
        />
        <Content>
          <div className="text">
            <span>avaliação preenchida com sucesso</span>
            <p>
              Sua Avaliação de {this.state.userCycle.cycle.title} já foi
              preenchida e enviada para o seu superior imediato.
            </p>
          </div>
          <div className="content">
            <div className="content-inner">
              <div className="media">
                <span>resultado final</span>
                <span>{this.getMedia()} pontos</span>
              </div>
              <div
                className="button"
                onClick={() => this.setState({ goToReview: true })}
              >
                <button>rever respostas</button>
              </div>
              {(userCycle.status === 'Finalizado' ||
                userCycle.status === 'Recursos Humanos') && (
                  <QuizDownload
                    cycleUserId={this.state.userCycle.id}
                    renderButton={(onClick, loading) => (
                      <div className="button">
                        <button onClick={onClick}>
                          {loading ? 'Aguarde ...' : 'Baixar Avaliação'}
                        </button>
                      </div>
                    )}
                  />
                )}
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...FindAnswerActions, ...UserCycleActions }, dispatch);

const mapStateToProps = state => ({
  userCycle: state.userCycle,
  findAnswer: state.findAnswer,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserResults);
