import React, { Fragment } from "react";

import { MainWrapper, Title, Buttons, Button } from "./styles";

const PerfilLogin = () => (
  <Fragment>
    <MainWrapper>
      <Title>
        <span>perfis</span>
      </Title>

      <Buttons>
        <Button>
          <a href="/">rh</a>
        </Button>
        <Button>
          <a href="/">gestor</a>
        </Button>
        <Button>
          <a href="/">gestor master</a>
        </Button>
      </Buttons>
    </MainWrapper>
  </Fragment>
);
export default PerfilLogin;
