import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  signInRequest: ["username", "password"],
  signInSuccess: ["token", "refreshToken"],
  signInError: ["error"],
  signInClearError: null,
  signInLoading: null,
  signOutRequest: null,
  signOut: null,
  loadLocalUser: null
});

export const AuthTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  signedIn: !!localStorage.getItem("@emae:token"),
  token: localStorage.getItem("@emae:token") || null,
  refreshToken: localStorage.getItem("@emae:refreshToken") || null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { token, refreshToken }) => {
  return state.merge({
    signedIn: true,
    token,
    refreshToken,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    token: null,
    refreshToken: null,
    signedIn: false,
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true
  });
};

export const logOut = state => {
  return state.merge({
    signedIn: false,
    token: null,
    refreshToken: null,
    error: false,
    success: false,
    loading: false
  });
}

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_SUCCESS]: success,
  [Types.SIGN_IN_ERROR]: error,
  [Types.SIGN_IN_CLEAR_ERROR]: clearError,
  [Types.SIGN_IN_LOADING]: loading,
  [Types.SIGN_OUT]: logOut
});
