import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

const { Types, Creators } = createActions({
  selectUsersRequest: ["payload"],
  selectUsersResetRequest: null,
  selectUsersReset: null,
  selectUsersSuccess: ["payload", "append"],
  selectUsersError: ["error"],
  selectUsersClearError: null,
  selectUsersLoading: null
});

export const SelectUsersTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  data: [],
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { payload }) => {
  return state.merge({
    data: payload,
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    data: [],
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    data: [],
    error: false,
    success: false,
    loading: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SELECT_USERS_SUCCESS]: success,
  [Types.SELECT_USERS_ERROR]: error,
  [Types.SELECT_USERS_CLEAR_ERROR]: clearError,
  [Types.SELECT_USERS_RESET]: reset,
  [Types.SELECT_USERS_LOADING]: loading
});
