import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  SET_UID: 'question/SET_UID',

  GET_TREE_REQUEST: 'question/GET_TREE_REQUEST',
  GET_TREE_SUCCESS: 'question/GET_TREE_SUCCESS',

  DEFINE_TREE_REQUEST: 'question/DEFINE_TREE_REQUEST',
  DEFINE_TREE_SUCCESS: 'question/DEFINE_TREE_SUCCESS',

  GET_TEMPLATE_TREE_REQUEST: 'question/GET_TEMPLATE_TREE_REQUEST',
  GET_TEMPLATE_TREE_SUCCESS: 'question/GET_TEMPLATE_TREE_SUCCESS',

  DEFINE_TEMPLATE_TREE_REQUEST: 'question/DEFINE_TEMPLATE_TREE_REQUEST',
  DEFINE_TEMPLATE_TREE_SUCCESS: 'question/DEFINE_TEMPLATE_TREE_SUCCESS',

  CREATE_STAGE: 'question/CREATE_STAGE',
  CHANGE_STAGE_TITLE: 'question/CHANGE_STAGE_TITLE',
  REMOVE_STAGE: 'question/REMOVE_STAGE',
  ORDER_STAGE: 'question/ORDER_STAGE',

  CREATE_BLOCK: 'question/CREATE_BLOCK',
  CHANGE_BLOCK_TITLE: 'question/CHANGE_BLOCK_TITLE',
  CHANGE_BLOCK_DESC: 'question/CHANGE_BLOCK_DESC',
  REMOVE_BLOCK: 'question/REMOVE_BLOCK',
  ORDER_BLOCK: 'question/ORDER_BLOCK',

  CREATE_QUESTION: 'question/CREATE_QUESTION',
  CHANGE_QUESTION_ENUN: 'question/CHANGE_QUESTION_ENUN',
  CHANGE_QUESTION_DESC: 'question/CHANGE_QUESTION_DESC',
  REMOVE_QUESTION: 'question/REMOVE_QUESTION',
  ORDER_QUESTION: 'question/ORDER_QUESTION',

  SET_FAILURE: 'question/SET_FAILURE',
};

/**
 * Reducer
 */
const initialState = Immutable({
  tree: [],
  lastUid: 0,
  loading: false,
  success: false,
  saved: false,
  quiz: {},
  templateQuiz: {},
});

export default function questionCreation(state = initialState, action) {
  switch (action.type) {
    case Types.GET_TREE_REQUEST:
      return { ...state, loading: true, success: false, quiz: {} };

    case Types.GET_TREE_SUCCESS:
      let arrayTagged = tagUid(action.payload.data.details.stages);
      return {
        tree: arrayTagged.stages,
        lastUid: arrayTagged.counter,
        loading: false,
        success: true,
        editable: action.payload.data.details.quiz.editable,
        quiz: action.payload.data.details.quiz,
      };

    case Types.DEFINE_TREE_REQUEST:
      return { ...state, loading: true };

    case Types.DEFINE_TREE_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
      };

    case Types.GET_TEMPLATE_TREE_REQUEST:
      return { ...state, loading: true, success: false, templateQuiz: {} };

    case Types.GET_TEMPLATE_TREE_SUCCESS:
      let arrayTagged2 = tagUid(action.payload.data.details.stages);
      return {
        tree: arrayTagged2.stages,
        lastUid: arrayTagged2.counter,
        loading: false,
        success: true,
        editable: action.payload.data.details.quiz.editable,
        templateQuiz: action.payload.data.details.quiz,
      };

    case Types.DEFINE_TEMPLATE_TREE_REQUEST:
      return { ...state, loading: true };

    case Types.DEFINE_TEMPLATE_TREE_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
      };

    case Types.CREATE_STAGE:
      let newTree = [
        ...state.tree,
        {
          uid: state.lastUid + 1,
          ordem: state.tree.length === 0 ? 1 : state.tree.length + 1,
          title: '',
          blocks: [],
          isNew: true,
        },
      ];
      return {
        ...state,
        lastUid: state.lastUid + 1,
        tree: newTree,
      };
    case Types.CHANGE_STAGE_TITLE: {
      const { uid, title } = action.payload;
      state.tree.find(stage => {
        if (stage.uid === uid) {
          stage.title = title;
        }
      });
      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.REMOVE_STAGE: {
      const { uid } = action.payload;
      const chosen = state.tree.filter(stage => stage.uid !== uid);
      let ordered = refreshOrder(chosen);
      return {
        ...state,
        tree: ordered,
      };
    }
    case Types.ORDER_STAGE: {
      const { from, to } = action.payload;
      let stages = [...state.tree];
      let newArray = arrayMove(stages, from, to);
      let arrRefreshed = refreshOrder(newArray);

      return {
        ...state,
        tree: arrRefreshed,
      };
    }

    case Types.CREATE_BLOCK: {
      const { uidStage } = action.payload;
      state.tree.find(stage => {
        if (uidStage === stage.uid) {
          stage.blocks = [
            ...stage.blocks,
            {
              uid: state.lastUid + 1,
              ordem: stage.blocks.length === 0 ? 1 : stage.blocks.length + 1,
              title: '',
              description: '',
              questions: [],
            },
          ];
          return;
        }
      });

      return {
        ...state,
        tree: [...state.tree],
        lastUid: state.lastUid + 1,
      };
    }
    case Types.CHANGE_BLOCK_TITLE: {
      const { uidStage, uid, title } = action.payload;

      let _topic = state.tree.find(stage => uidStage === stage.uid);
      _topic.blocks.find(grupo => {
        if (uid === grupo.uid) {
          grupo.title = title;
        }
      });

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.CHANGE_BLOCK_DESC: {
      const { uidStage, uid, desc } = action.payload;

      let _topic = state.tree.find(stage => uidStage === stage.uid);
      _topic.blocks.find(grupo => {
        if (uid === grupo.uid) {
          grupo.description = desc;
        }
      });

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.REMOVE_BLOCK: {
      const { uidStage, uid } = action.payload;
      let stage = state.tree.find(stage => stage.uid === uidStage);
      let blocksOk = stage.blocks.filter(group => group.uid !== uid);
      stage.blocks = refreshOrder(blocksOk);

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.ORDER_BLOCK: {
      const { from, to, uidStage } = action.payload;
      let stages = [...state.tree];
      let stage = stages.find(stage => stage.uid === uidStage);
      let blocks = [...stage.blocks];
      let newArray = arrayMove(blocks, from, to);
      let arrRefreshed = refreshOrder(newArray);
      stage.blocks = arrRefreshed;
      return {
        ...state,
        tree: stages,
      };
    }

    case Types.CREATE_QUESTION: {
      const { uidStage, uidGroup, type } = action.payload;

      let stage = state.tree.find(stage => stage.uid === uidStage);
      stage.blocks.find(grupo => {
        if (grupo.uid === uidGroup) {
          grupo.questions = [
            ...grupo.questions,
            {
              uid: state.lastUid + 1,
              ordem:
                grupo.questions.length === 0 ? 1 : grupo.questions.length + 1,
              title: '',
              type: type,
            },
          ];
          stage.countQuestions = grupo.questions.length; // Please do not remove this line because _Counter.api may need it.
        }
      });

      return {
        ...state,
        tree: [...state.tree],
        lastUid: state.lastUid + 1,
      };
    }
    case Types.CHANGE_QUESTION_ENUN: {
      const { uidStage, uidGroup, uid, enun } = action.payload;

      let topico = state.tree.find(topico => topico.uid === uidStage);
      let group = topico.blocks.find(grupo => grupo.uid === uidGroup);
      group.questions.find(question => {
        if (question.uid === uid) {
          question.title = enun;
        }
      });

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.CHANGE_QUESTION_DESC: {
      const { uidStage, uidGroup, uid, desc } = action.payload;

      let topico = state.tree.find(topico => topico.uid === uidStage);
      let group = topico.blocks.find(grupo => grupo.uid === uidGroup);
      group.questions.find(question => {
        if (question.uid === uid) {
          question.description = desc;
        }
      });

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.REMOVE_QUESTION: {
      const { uidStage, uidGroup, uid } = action.payload;

      let topico = state.tree.find(topico => topico.uid === uidStage);
      let group = topico.blocks.find(grupo => grupo.uid === uidGroup);
      let questions = group.questions.filter(question => question.uid !== uid);

      group.questions = refreshOrder(questions);
      topico.countQuestions = topico.countQuestions - 1;

      return {
        ...state,
        tree: [...state.tree],
      };
    }
    case Types.ORDER_QUESTION: {
      const { from, to, uidStage, uidBlock } = action.payload;

      let stages = [...state.tree];
      let stage = stages.find(stage => stage.uid === uidStage);
      let blocks = [...stage.blocks];
      let block = blocks.find(block => block.uid === uidBlock);
      let questions = [...block.questions];

      let newArray = arrayMove(questions, from, to);
      let arrRefreshed = refreshOrder(newArray);
      block.questions = arrRefreshed;

      return {
        ...state,
        tree: stages,
      };
    }

    case Types.SET_FAILURE: {
      return {
        ...state,
        loading: false,
        getError: action.payload.getError,
      };
    }

    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  setUids: tree => ({
    type: Types.SET_UID,
    payload: { tree },
  }),

  questionCreateStage: () => {
    return {
      type: Types.CREATE_STAGE,
    };
  },
  questionChangeStageTitle: (title, uid) => ({
    type: Types.CHANGE_STAGE_TITLE,
    payload: { title, uid },
  }),
  questionRemoveStage: uid => ({
    type: Types.REMOVE_STAGE,
    payload: { uid },
  }),
  questionOrderStage: (from, to) => ({
    type: Types.ORDER_STAGE,
    payload: { from, to },
  }),

  questionCreateBlock: uidStage => {
    return {
      type: Types.CREATE_BLOCK,
      payload: { uidStage },
    };
  },
  questionChangeBlockTitle: (title, uidStage, uid) => ({
    type: Types.CHANGE_BLOCK_TITLE,
    payload: { uidStage, uid, title },
  }),
  questionChangeBlockDesc: (desc, uidStage, uid) => ({
    type: Types.CHANGE_BLOCK_DESC,
    payload: { uidStage, uid, desc },
  }),
  questionRemoveBlock: (uidStage, uid) => ({
    type: Types.REMOVE_BLOCK,
    payload: { uidStage, uid },
  }),
  questionOrderBlock: (from, to, uidStage) => ({
    type: Types.ORDER_BLOCK,
    payload: { from, to, uidStage },
  }),

  questionCreateQuestion: (uidStage, uidGroup, type) => ({
    type: Types.CREATE_QUESTION,
    payload: { uidStage, uidGroup, type },
  }),
  questionChangeQuestionEnun: (enun, uidStage, uidGroup, uid) => ({
    type: Types.CHANGE_QUESTION_ENUN,
    payload: { uidStage, uidGroup, uid, enun },
  }),
  questionChangeQuestionDesc: (desc, uidStage, uidGroup, uid) => ({
    type: Types.CHANGE_QUESTION_DESC,
    payload: { uidStage, uidGroup, uid, desc },
  }),
  questionRemoveQuestion: (uidStage, uidGroup, uid) => ({
    type: Types.REMOVE_QUESTION,
    payload: { uidStage, uidGroup, uid },
  }),
  questionOrderQuestion: (from, to, uidStage, uidBlock) => ({
    type: Types.ORDER_QUESTION,
    payload: { from, to, uidStage, uidBlock },
  }),

  getQuestionsTreeRequest: id => ({
    type: Types.GET_TREE_REQUEST,
    payload: { id },
  }),

  getQuestionsTreeSuccess: data => ({
    type: Types.GET_TREE_SUCCESS,
    payload: { data },
  }),

  defineQuestionsTreeRequest: (id, data) => ({
    type: Types.DEFINE_TREE_REQUEST,
    payload: { id, data },
  }),

  defineQuestionsTreeSuccess: data => ({
    type: Types.DEFINE_TREE_SUCCESS,
    payload: { data },
  }),

  getTemplateQuestionsTreeRequest: id => ({
    type: Types.GET_TEMPLATE_TREE_REQUEST,
    payload: { id },
  }),

  getTemplateQuestionsTreeSuccess: data => ({
    type: Types.GET_TEMPLATE_TREE_SUCCESS,
    payload: { data },
  }),

  defineTemplateQuestionsTreeRequest: (id, data) => ({
    type: Types.DEFINE_TEMPLATE_TREE_REQUEST,
    payload: { id, data },
  }),

  defineTemplateQuestionsTreeSuccess: data => ({
    type: Types.DEFINE_TEMPLATE_TREE_SUCCESS,
    payload: { data },
  }),

  setFailure: ({ getError }) => ({
    type: Types.SET_FAILURE,
    payload: { getError },
  }),
};

/**
 * Helpers
 */

function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

function refreshOrder(arr) {
  return arr.map((item, i) => {
    item.ordem = i + 1;
    return item;
  });
}

function tagUid(stages) {
  let counter = 0;
  stages.forEach(stage => {
    stage.blocks.forEach(block => {
      block.questions.forEach(question => {
        question.uid = counter++;
      });
      block.uid = counter++;
    });
    stage.uid = counter++;
  });

  return { stages, counter };
}
