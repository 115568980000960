import React from 'react'
import { AvaliacaoBlockWrapper } from './styles';

function AvaliacaoBlock(props) {
  return (
    <AvaliacaoBlockWrapper>
      <h2 className='header'>
        {props.title}
      </h2>
      <p>
        {props.description}
      </p>

    </AvaliacaoBlockWrapper>
  )
}

export default AvaliacaoBlock;
