import React from "react";
import PropTypes from "prop-types";

import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import { ShowMore } from "./styles";
import Loading from "../Loading";

class Pagination extends React.Component {
  static propTypes = {
    paginate: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
  };

  isLoading = () => this.props.paginate.loading;

  onClick = () => this.props.onClick(this.props.paginate.page + 1);

  renderLoading = () => <Loading loading={this.isLoading()} />;

  render() {
    return (
      <ShowMore>
        <button disabled={this.isLoading()} onClick={this.onClick}>
          <span>Mostrar mais</span>
          <IosArrowDown color="#fff" fontSize="25px" />
        </button>
      </ShowMore>
    );
  }
}

export default Pagination;
