import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Creators as TemplateQuizzesActions } from '../../store/ducks/quiz/templateQuizzes';

import ItensAndButtons from '../../Components/ItensAndButtons';
import { TableDiv, Thead, Tbody, Tr } from '../../Components/Table/styles';
import Pagination from '../../Components/Pagination';
import NotFoundItems from '../../Components/NotFoundItems';

export class TabelaFormularios extends Component {
  state = {
    text: '',
  };

  componentDidMount() {
    this.props.clearTemplateQuizzes();
    this.props.getTemplateQuizzesRequest();
  }

  render() {
    const { templateQuizzes } = this.props;
    return (
      <Fragment>
        <ItensAndButtons
          history={this.props.history}
          newUrl="/modelos/novo/dados"
          total={this.props.templateQuizzes.total}
          onSubmit={text => {
            this.setState({ text });
            this.props.clearTemplateQuizzes();
            this.props.getTemplateQuizzesRequest({ page: 1, text });
          }}
          isLoading={templateQuizzes.loading}
        />
        {templateQuizzes && templateQuizzes.data.length > 0 ? (
          <>
            <TableDiv>
              <Thead>
                <Tr>
                  <th>ID</th>
                  <th>Título</th>
                  <th>Descrição</th>
                  <th>Status</th>
                  <th>Criado em</th>
                </Tr>
              </Thead>
              <Tbody>
                {templateQuizzes.data.map(r => (
                  <Tr key={r.id}>
                    <td>{r.id}</td>
                    <td>
                      <Link to={`/modelos/${r.id}/dados`}>{r.title}</Link>
                    </td>
                    <td>{r.description}</td>
                    <td>{r.status}</td>
                    <td>{moment(r.created_at).format('DD/MM/YYYY')}</td>
                  </Tr>
                ))}
              </Tbody>
            </TableDiv>
            {templateQuizzes.lastPage !== templateQuizzes.page &&
              templateQuizzes.data.length > 0 && (
                <Pagination
                  onClick={page =>
                    this.props.getQuizzesRequest({
                      page,
                      text: this.state.text,
                    })
                  }
                  paginate={templateQuizzes}
                />
              )}
          </>
        ) : templateQuizzes.loading ? (
          ''
        ) : (
          <NotFoundItems />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  templateQuizzes: state.templateQuizzes,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...TemplateQuizzesActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TabelaFormularios);
