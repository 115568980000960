import styled from "styled-components";

export const Nav = styled.nav`
  height: 100%;
  width: 70%;
  max-width: 300px;
  background-color: #fff;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 500;

  display: flex;
  align-items: center;
  flex-direction: column;

  ul {
    width: 100%;
    margin-left: 10px;
    padding: 0;
    list-style: none;

    li {
      margin-top: 15px;

      a {
        height: 40px;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #666666;
        font-family: "Open Sans";
        text-transform: uppercase;
        font-size: 13px;
        padding-left: 15px;
        font-weight: lighter;
      }

      a:hover {
        font-weight: 600;
      }

      img {
        height: 40px;
        width: 40px;
        padding-right: 15px;
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;

  img {
    width: 100%;
  }
`;
