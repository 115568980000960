import { takeLatest, takeEvery } from "redux-saga/effects";

import { Types as SearchTypes } from "../../../ducks/modules/courses/search";
import { Types as InfoTypes } from "../../../ducks/modules/courses/info";
import { Types as OfferTypes } from "../../../ducks/modules/courses/offer";
import { Types as CoursesTypes } from "../../../ducks/modules/courses/courses";
import { Types as CategoriesTypes } from "../../../ducks/modules/courses/categories";
import { Types as CategoryTypes } from "../../../ducks/modules/courses/category";
import { Types as EnrollmentsTypes } from "../../../ducks/modules/courses/enrollments";
import { Types as EnrollTypes } from "../../../ducks/modules/courses/enroll";
import { Types as RatingTypes } from "../../../ducks/modules/courses/rating";
import { Types as RatingAnswerTypes } from "../../../ducks/modules/courses/ratingAnswer";
import { Types as CertificateTypes } from "../../../ducks/modules/courses/certificate";
import { Types as ScormTypes } from "../../../ducks/modules/courses/scorm";
import { Types as TopicsTypes } from "../../../ducks/modules/courses/topics";

import { getSearch } from "./search";
import { getCourse } from "./info";
import { getOffer } from "./offer";
import { getCourses } from "./courses";
import { getCategories } from "./categories";
import { getCategory } from "./category";
import { filterEnrollments } from "./enrollments";
import { downloadCertificate } from "./certificate";
import {
  postEnroll,
  resetEnroll,
  postConcludeEnroll,
  resetConcludeEnroll
} from "./enroll";
import { postRatingAnswer, resetRatingAnswer, setRatingValue } from "./ratingAnswer";
import { getRatings, resetRatings } from "./rating";
import { getScorm, postScorm, putScorm, updateScorm } from "./scorm";
import { getTopics } from "./topics";

export default [
  takeLatest(SearchTypes.GET_REQUEST, getSearch),
  takeLatest(CoursesTypes.GET_REQUEST, getCourses),
  takeLatest(CategoriesTypes.GET_REQUEST, getCategories),
  takeLatest(InfoTypes.GET_REQUEST, getCourse),
  takeLatest(OfferTypes.GET_REQUEST, getOffer),
  takeLatest(CategoryTypes.GET_REQUEST, getCategory),
  takeLatest(EnrollmentsTypes.FILTER_REQUEST, filterEnrollments),
  takeLatest(EnrollTypes.POST_REQUEST, postEnroll),
  takeLatest(EnrollTypes.RESET_REQUEST, resetEnroll),
  takeLatest(EnrollTypes.POST_CONCLUDE_REQUEST, postConcludeEnroll),
  takeLatest(EnrollTypes.RESET_CONCLUDE_REQUEST, resetConcludeEnroll),
  takeLatest(RatingAnswerTypes.POST_REQUEST, postRatingAnswer),
  takeLatest(CertificateTypes.DOWNLOAD_CERTIFICATE_REQUEST, downloadCertificate),
  takeLatest(RatingAnswerTypes.RESET_REQUEST, resetRatingAnswer),
  takeLatest(RatingTypes.GET_REQUEST, getRatings),
  takeLatest(RatingTypes.RESET_REQUEST, resetRatings),
  takeLatest(RatingAnswerTypes.POST_REQUEST, postRatingAnswer),
  takeLatest(RatingAnswerTypes.SET_RATING, setRatingValue),
  takeEvery(ScormTypes.POST_REQUEST, updateScorm),
  takeEvery(ScormTypes.GET_REQUEST, getScorm),
  takeLatest(TopicsTypes.GET_REQUEST, getTopics),
];
