import styled from 'styled-components';

export const NotFoundText = styled.p`
  text-align: center;
  width: 100%;
  background-color: #e7e7e7;
  padding: 8px 0px;
  font-size: 16px;
  color: #666666;
`;

export const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 20px 30px 50px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;

  .titles {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      width: 800px;
    }

    h4 {
      width: 20%;
      padding: 20px 0 0 20px;
    }

    @media (max-width: 1240px) {
      .hideResponsive {
        display: none;
      }
      h3 {
        width: 100%;
      }
    }
  }

  .selects-and-search {
    max-width: 800px;
    margin-right: 10px;
    display: flex;
    width: 100%;

    input {
      height: 42px;
      border-radius: 3px;
      margin: 10px 0 0;
    }

    :last-child {
      margin-right: 0;
    }

    @media (max-width: 1110px) {
      flex-direction: column;
      max-width: none;
      width: 100%;
      button {
        margin-left: 0 !important;
        max-width: 100px;
      }
    }
  }

  .selects-and-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      display: flex;
      margin: 10px 0;
      border: 0;
      height: 40px;
      color: #fff;
      text-transform: uppercase;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #00468e;
      margin-left: 20px;

      :not([disabled]):hover {
        opacity: 0.8;
      }

      &[disabled] {
        background-color: #eee;
      }
    }

    .download-buttons {
      display: flex;
      width: 20%;

      button {
        padding: 10px 20px 0 2px;

        img {
          height: 26px;
          width: 26px;
          margin-right: 5px;
          margin-bottom: 15px;
        }
      }

      .pdf-button {
        background-color: #fe2712;
      }

      .xls-button {
        background-color: #237732;
      }
    }

    @media (max-width: 1240px) {
      flex-direction: column;
      button {
        :first-child {
          margin-left: 0;
        }
      }
    }
    @media (max-width: 1110px) {
      .select {
        max-width: 660px;
      }
      button {
        :first-child {
          max-width: 100px;
        }
      }
    }
  }

  .select {
    padding-top: 10px;
    width: 100%;

    :not(:first-child) {
      margin-left: 20px;

      @media (max-width: 1110px) {
        margin-left: 0;
      }
    }
  }

  .user-data {
    margin-top: 10px;
    list-style: none;

    li {
      margin-bottom: 5px;
    }

    span {
      display: block;
      color: #00468e;
      font-size: 15px;
      text-transform: uppercase;
    }
  }
`;
