import search from "./search";
import course from "./info";
import offer from "./offer";
import courses from "./courses";
import categories from "./categories";
import category from "./category";
import enrollmentInProgress from "./enrollmentInProgress";
import enrollmentCompleted from "./enrollmentCompleted";
import enrollments from "./enrollments";
import enroll from "./enroll";
import rating from "./rating";
import ratingAnswer from "./ratingAnswer";
import certificate from "./certificate";
import scorm from "./scorm";
import topics from "./topics";

export default {
  courses,
  categories,
  category,
  search,
  course,
  offer,
  enrollmentInProgress,
  enrollmentCompleted,
  enrollments,
  enroll,
  rating,
  ratingAnswer,
  certificate,
  scorm,topics
};
