import { all, takeLatest, takeEvery } from 'redux-saga/effects';

// Auth
import { signIn, loadLocalUser, signOut } from './auth';
import { AuthTypes } from '../ducks/auth';

//UserInfo
import { getUserInfo } from './user/userInfo';
import { UserInfoTypes } from '../ducks/user/userInfo';

//ForgotPassword
import { forgotPassword } from './forgotPassword';
import { ForgotPasswordTypes } from '../ducks/forgotPassword';

//DefinePassword
import { definePassword } from './definePassword';
import { DefinePasswordTypes } from '../ducks/definePassword';

//Cycles
import { requestCycles } from './cycle/cycles';
import { Types as CyclesTypes } from '../ducks/cycle/cycles';

//Cycle Data
import { cycleData } from './cycle/cycleData';
import { CycleDataTypes } from '../ducks/cycle/cycleData';

//Current Cycle
import { currentCycle } from './cycle/currentCycle';
import { Types as CurrentCycleTypes } from '../ducks/cycle/currentCycle';

//User Cycle
import { requestUserCycle } from './cycle/userCycle';
import { UserCycleTypes } from '../ducks/cycle/userCycle';

//Cycle Data Form
import {
  createOrUpdateCycle,
  resetStatus,
  cycleDataFormResetAll,
} from './cycle/cycleDataForm';
import { CycleDataFormTypes } from '../ducks/cycle/cycleDataForm';

//Cycle Quizzes
import { fetchQuizzesByCycle } from './cycle/cycleQuizzes';
import { CycleQuizzesTypes } from '../ducks/cycle/cycleQuizzes';

//Cycle Quizzes Form
import {
  updateCycleQuizzes,
  resetStatusCycleQuizzesForm,
} from './cycle/cycleQuizzesForm';
import { CycleQuizzesFormTypes } from '../ducks/cycle/cycleQuizzesForm';

//Update Cycle User Status
import {
  requestUpdateCycleUserStatus,
  resetUpdateCycleUserStatus,
} from './cycleUser/updateCycleUserStatus';
import { UpdateCycleUserStatusTypes } from '../ducks/cycleUser/updateCycleUserStatus';

//Quiz By Cycles
import { quizByCycleData } from './quiz/quizByCycle';
import { QuizByCycleTypes } from '../ducks/quiz/quizByCycle';

//Participants
import {
  requestParticipants,
  reportParticipants,
  requestResetParticipants,
} from './participant/participants';
import { ParticipantsTypes } from '../ducks/participant/participants';

//Remove Participants
import { requestRemoveParticipants } from './participant/removeParticipants';
import { RemoveParticipantsTypes } from '../ducks/participant/removeParticipants';

//Participant Detail
import { requestParticipantDetail } from './participant/participantDetail';
import { ParticipantDetailTypes } from '../ducks/participant/participantDetail';

//Answer
import { requestAnswer } from './answer/answer';
import { AnswerTypes } from '../ducks/answer/answer';

//AnswerAgree
import { requestAnswerAgreeRevision } from './answer/answerAgreeRevision';
import { AnswerAgreeRevisionTypes } from '../ducks/answer/answerAgreeRevision';

//FindAnswer
import { requestFindAnswer, resetFindAnswer } from './answer/findAnswer';
import { FindAnswerTypes } from '../ducks/answer/findAnswer';

//Import Users
import { importRequest } from './user/importUpload';
import { ImportTypes } from '../ducks/user/importUpload';

//User Cycle
import { downloadGrade, resetDownloadGrade } from './grade/download';
import { GradeDownloadTypes } from '../ducks/grade/download';

import { getCycle, initCycle } from './cycle';
import { Types as CycleTypes } from '../ducks/cycle';
import ModuleCourseSaga from './modules/courses';

// Users
import { getUsers } from './admin/users';
import { Types as UsersTypes } from '../ducks/admin/users';

// User
import { getUser, updateUser, createUser } from './admin/user';
import { Types as UserTypes } from '../ducks/admin/user';

import { Types as MeetingTypes } from '../ducks/supervisor/meeting';
import { postMeeting } from './supervisor/meeting';
//Roles
import { getRoles } from './role/roles';
import { Types as RolesTypes } from '../ducks/role/roles';

//Role
import { getRole, updateRole, insertRole } from './role/role';
import { Types as RoleTypes } from '../ducks/role/role';

//Reasons
import { getReasons } from './reason/reasons';
import { Types as ReasonsTypes } from '../ducks/reason/reasons';

//Reason
import { getReason, updateReason, insertReason, deleteReason } from './reason/reason';
import { Types as ReasonTypes } from '../ducks/reason/reason';

//Quizzes
import { getQuizzes } from './quiz/quizzes';
import { Types as QuizzesTypes } from '../ducks/quiz/quizzes';

//Functions
import { requestFunctions, requestResetFunctions } from './select/functions';
import { FunctionsTypes } from '../ducks/select/functions';

//Roles
import { requestRoles, requestResetRoles } from './select/roles';
import { SelectRolesTypes } from '../ducks/select/roles';

//Offices
import { requestOffices, requestResetOffices } from './select/offices';
import { OfficesTypes } from '../ducks/select/offices';

//Cycles Select
import { requestCicles, requestResetCicles } from './select/cicles';
import { CiclesTypes } from '../ducks/select/cicles';

//UserCycles
import {
  requestUserCycles,
  requestResetUserCycles,
} from './userCycles/userCycles';
import { UserCyclesTypes } from '../ducks/userCycles/userCycles';

//OrganizationsUnit
import {
  requestOrganizationsUnit,
  requestResetOrganizationsUnit,
} from './select/organizationsUnit';
import { OrganizationsUnitTypes } from '../ducks/select/organizationsUnit';
//Quiz
import { getQuiz, updateQuiz, insertQuiz, defineRoles } from './quiz/quiz';
import { Types as QuizTypes } from '../ducks/quiz/quiz';
import { Types as QuizRolesTypes } from '../ducks/quiz/quizRoles';

import { requestParticipantReport } from './reports/participantReport';
import { Types as ParticipantReportTypes } from '../ducks/reports/participantReport';

import { requestDepartmentReport, requestDepartmentDownload } from './reports/departmentReport';
import { Types as DepartmentReportTypes } from '../ducks/reports/departmentReport';

import { getQuestions, defineQuestions } from './quiz/question';
import {
  getTemplateQuestions,
  defineTemplateQuestions,
} from './quiz/templateQuestion';
import { Types as QuestionTypes } from '../ducks/questionCreation/questionCreation';

//TemplateQuiz
import {
  getTemplateQuiz,
  updateTemplateQuiz,
  insertTemplateQuiz,
  templateDefineRoles,
} from './quiz/templateQuiz';
import { Types as TemplateQuizTypes } from '../ducks/quiz/templateQuiz';
import { Types as TemplateQuizRolesTypes } from '../ducks/quiz/templateQuizRoles';

//TemplateQuizzes
import { getTemplateQuizzes } from './quiz/templateQuizzes';
import { Types as TemplateQuizzesTypes } from '../ducks/quiz/templateQuizzes';

//Hierarchy
import { getHierarchy, putHierarchy } from './hierarchy';
import { Types as HierarchyTypes } from '../ducks/hierarchy';

//SelectUsers
import { requestUsers, requestResetUsers } from './select/selectUsers';
import { SelectUsersTypes } from '../ducks/select/selectUsers';

export default function* rootSaga() {
  return yield all([
    //Download Grade
    takeLatest(GradeDownloadTypes.GRADE_DOWNLOAD_REQUEST, downloadGrade),
    takeLatest(
      GradeDownloadTypes.GRADE_DOWNLOAD_REQUEST_RESET_ALL,
      resetDownloadGrade
    ),

    //Auth
    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_OUT_REQUEST, signOut),
    takeLatest(AuthTypes.LOAD_LOCAL_USER, loadLocalUser),

    //UserInfo
    takeLatest(UserInfoTypes.USER_INFO_REQUEST, getUserInfo),

    //Answer
    takeLatest(AnswerTypes.ANSWERS_REQUEST, requestAnswer),

    //AnswerAgree
    takeLatest(
      AnswerAgreeRevisionTypes.ANSWER_AGREE_REVISION_REQUEST,
      requestAnswerAgreeRevision
    ),

    //FindAnswer
    takeLatest(FindAnswerTypes.FIND_ANSWERS_REQUEST, requestFindAnswer),
    takeLatest(FindAnswerTypes.FIND_ANSWERS_RESET_REQUEST, resetFindAnswer),

    //ForgotPassword
    takeLatest(ForgotPasswordTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),

    //DefinePassword
    takeLatest(DefinePasswordTypes.DEFINE_PASSWORD_REQUEST, definePassword),

    //Cicle
    takeLatest(CycleTypes.GET_REQUEST, getCycle),
    takeLatest(CycleTypes.PUT_REQUEST, initCycle),

    //Cycles
    takeLatest(CyclesTypes.GET_REQUEST, requestCycles),

    //Update Cycle User Status
    takeLatest(
      UpdateCycleUserStatusTypes.UPDATE_CYCLE_USER_STATUS_REQUEST,
      requestUpdateCycleUserStatus
    ),
    takeLatest(
      UpdateCycleUserStatusTypes.UPDATE_CYCLE_USER_STATUS_REQUEST_RESET,
      resetUpdateCycleUserStatus
    ),

    //CycleData
    takeLatest(CycleDataTypes.CYCLE_DATA_REQUEST, cycleData),

    //CurrentCycle
    takeLatest(CurrentCycleTypes.GET_REQUEST, currentCycle),

    //UserCycle
    takeLatest(UserCycleTypes.USER_CYCLE_REQUEST, requestUserCycle),

    //CycleDataForm
    takeLatest(CycleDataFormTypes.CYCLE_DATA_FORM_REQUEST, createOrUpdateCycle),
    takeLatest(
      CycleDataFormTypes.CYCLE_DATA_FORM_REQUEST_RESET_ALL,
      cycleDataFormResetAll
    ),
    takeLatest(
      CycleDataFormTypes.CYCLE_DATA_FORM_REQUEST_RESET_STATUS,
      resetStatus
    ),

    //CycleQuizzes
    takeLatest(CycleQuizzesTypes.CYCLE_QUIZZES_REQUEST, fetchQuizzesByCycle),

    //CycleQuizzesForm
    takeLatest(
      CycleQuizzesFormTypes.CYCLE_QUIZZES_FORM_REQUEST,
      updateCycleQuizzes
    ),

    takeLatest(
      CycleQuizzesFormTypes.CYCLE_QUIZZES_FORM_REQUEST_RESET_STATUS,
      resetStatusCycleQuizzesForm
    ),

    //Quiz by Cycle
    takeLatest(QuizByCycleTypes.QUIZ_BY_CYCLE_REQUEST, quizByCycleData),

    //Participants
    takeLatest(ParticipantsTypes.PARTICIPANTS_REQUEST, requestParticipants),
    takeLatest(
      ParticipantsTypes.PARTICIPANTS_RESET_REQUEST,
      requestResetParticipants
    ),
    takeLatest(ParticipantsTypes.PARTICIPANTS_REPORT_DOWNLOAD_REQUEST, reportParticipants),

    //Functions
    takeLatest(FunctionsTypes.FUNCTIONS_REQUEST, requestFunctions),
    takeLatest(FunctionsTypes.FUNCTIONS_RESET_REQUEST, requestResetFunctions),

    //Roles
    takeLatest(SelectRolesTypes.ROLES_REQUEST, requestRoles),
    takeLatest(SelectRolesTypes.ROLES_RESET_REQUEST, requestResetRoles),

    //Offices
    takeLatest(OfficesTypes.OFFICES_REQUEST, requestOffices),
    takeLatest(OfficesTypes.OFFICES_RESET_REQUEST, requestResetOffices),

    //Cycles Select
    takeLatest(CiclesTypes.CICLES_REQUEST, requestCicles),
    takeLatest(CiclesTypes.CICLES_RESET, requestResetCicles),

    //UserCycles
    takeLatest(UserCyclesTypes.USER_CYCLES_REQUEST, requestUserCycles),
    takeLatest(
      UserCyclesTypes.USER_CYCLES_RESET_REQUEST,
      requestResetUserCycles
    ),

    //OrganizationsUnit
    takeLatest(
      OrganizationsUnitTypes.ORGANIZATIONS_UNIT_REQUEST,
      requestOrganizationsUnit
    ),
    takeLatest(
      OrganizationsUnitTypes.ORGANIZATIONS_UNIT_RESET_REQUEST,
      requestResetOrganizationsUnit
    ),

    //Participant Detail
    takeLatest(
      ParticipantDetailTypes.PARTICIPANT_DETAIL_REQUEST,
      requestParticipantDetail
    ),

    //Remove Participants
    takeLatest(
      RemoveParticipantsTypes.REMOVE_PARTICIPANTS_REQUEST,
      requestRemoveParticipants
    ),

    //Participants
    takeLatest(ImportTypes.IMPORT_REQUEST, importRequest),

    // Users
    takeEvery(UsersTypes.GET_REQUEST, getUsers),
    takeEvery(UserTypes.GET_REQUEST, getUser),
    takeEvery(UserTypes.PUT_REQUEST, updateUser),
    takeEvery(UserTypes.POST_REQUEST, createUser),
    takeLatest(MeetingTypes.POST_REQUEST, postMeeting),

    //Roles
    takeLatest(RolesTypes.GET_REQUEST, getRoles),

    //Role
    takeLatest(RoleTypes.GET_REQUEST, getRole),
    takeLatest(RoleTypes.UPDATE_REQUEST, updateRole),
    takeLatest(RoleTypes.INSERT_REQUEST, insertRole),

    //Reasons
    takeLatest(ReasonsTypes.GET_REQUEST, getReasons),

    //Reason
    takeLatest(ReasonTypes.GET_REQUEST, getReason),
    takeLatest(ReasonTypes.UPDATE_REQUEST, updateReason),
    takeLatest(ReasonTypes.INSERT_REQUEST, insertReason),

    //Quizzes
    takeLatest(QuizzesTypes.GET_REQUEST, getQuizzes),

    //Quiz
    takeLatest(QuizTypes.GET_REQUEST, getQuiz),
    takeLatest(QuizTypes.UPDATE_REQUEST, updateQuiz),
    takeLatest(QuizTypes.INSERT_REQUEST, insertQuiz),
    takeLatest(QuizRolesTypes.UPDATE_REQUEST, defineRoles),

    takeLatest(ParticipantReportTypes.GET_REQUEST, requestParticipantReport),
    takeLatest(DepartmentReportTypes.GET_REQUEST, requestDepartmentReport),
    takeLatest(DepartmentReportTypes.DOWNLOAD_REQUEST, requestDepartmentDownload),

    //Question
    takeLatest(QuestionTypes.GET_TREE_REQUEST, getQuestions),
    takeLatest(QuestionTypes.DEFINE_TREE_REQUEST, defineQuestions),

    //TemplateQuestion
    takeLatest(QuestionTypes.GET_TEMPLATE_TREE_REQUEST, getTemplateQuestions),
    takeLatest(
      QuestionTypes.DEFINE_TEMPLATE_TREE_REQUEST,
      defineTemplateQuestions
    ),

    //TemplateQuiz
    takeLatest(TemplateQuizTypes.GET_REQUEST, getTemplateQuiz),
    takeLatest(TemplateQuizTypes.UPDATE_REQUEST, updateTemplateQuiz),
    takeLatest(TemplateQuizTypes.INSERT_REQUEST, insertTemplateQuiz),
    takeLatest(TemplateQuizRolesTypes.UPDATE_REQUEST, templateDefineRoles),

    //TemplateQuizzes
    takeLatest(TemplateQuizzesTypes.GET_REQUEST, getTemplateQuizzes),

    //Hierarchy
    takeLatest(HierarchyTypes.GET_REQUEST, getHierarchy),
    takeLatest(HierarchyTypes.PUT_REQUEST, putHierarchy),

    //SelectUsers
    takeLatest(SelectUsersTypes.SELECT_USERS_REQUEST, requestUsers),
    takeLatest(SelectUsersTypes.SELECT_USERS_RESET_REQUEST, requestResetUsers),

    takeLatest(ReasonTypes.DELETE_REQUEST, deleteReason),

    //Module Course
    ...ModuleCourseSaga,
  ]);
}
