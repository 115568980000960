import React, { Fragment } from "react";

import { Div } from "../../../styles/global";

import SubHeader from "../../../Components/SubHeader";
import Back from "../../../Components/Back";
import Table from "../../../Components/Table";

import SearchIcon from "../../../assets/images/search.svg";

import { Content, ContentOptions, SearchDiv, Items } from "./styles";

const Emails = () => (
  <Fragment>
    <Div>
      <Back />
      <SubHeader page="email" />

      <Content>
        <ContentOptions>
          <span>modelos</span>
          <span>envios</span>
        </ContentOptions>

        <SearchDiv>
          <label>selecionar destinatário</label>
          <div className="input">
            <input type="text" placeholder="Buscar" />
            <img src={SearchIcon} alt="adicionar" />
          </div>
        </SearchDiv>

        <Items>
          <span>806 itens</span>
          <button>
            <a href="#!">adicionar selecionados</a>
          </button>
        </Items>
      </Content>
      <Table />
    </Div>
  </Fragment>
);

export default Emails;
