import styled from "styled-components";

export const Wrapper = styled.div`
  min-height: 135px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  padding: 16px 40px 16px 16px;

  background: linear-gradient(to right, #3e6eb6 0, #234983 100%);
`;

export const Back = styled.div`
  display: flex;
  min-width: 22px;
  max-width: 22px;
  flex-direction: column;
  justify-content: center;

  img {
    height: 25px;
    width: 25px;
    fill: #fff;
  }

  span {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
  }
`;

export const User = styled.div`
  height: 100%;
  width: 600px;
  display: flex;
  margin-left: 45px;

  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }

  .data {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      color: #fff;
      line-height: 36px;
      font-size: 26px;
      font-weight: 600;
    }

    span {
      color: #fff;
      line-height: 22px;
      font-size: 16px;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 300px;
  min-height: 100px;

  .score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 15px 10px 8px;
    border-radius: 4px;
    background-color: #009b4c;
    margin-bottom: 6px;

    span {
      font-size: 10px;
      color: #fff;
      line-height: 12px;
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .options {
    display: flex;
    width: 100%;

    .send {
      width: 100%;
      background-color: #ccc;
      border-radius: 4px;
      justify-content: center;
      align-items: center;

      button {
        width: 100%;
        border-radius: 4px;
        height: 48px;
        border: 0;
        color: #666666;
      }

      .send-title {
        color: #666666;
        text-align: center;
      }
      .send-status {
        color: #666666;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }

    .more {
      height: 40px;
      width: 40px;
      background-color: #ccc;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;

      img {
        height: 20px;
        width: 20px;
      }
    }
  }
`;

export const Div = styled.div`
  display: flex;
`;
