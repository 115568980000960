import React, { Fragment } from "react";

import { Div } from "../../../../styles/global";

import SubHeader from "../../../../Components/SubHeader";

import Button from "../../../../Components/Button";
import Question from "../../../../Components/Question";

import BackArrowIcon from "../../../../assets/images/backArrow.svg";
import BasketIcon from "../../../../assets/images/basket.svg";
import ExpandIcon from "../../../../assets/images/expand.png";

import { Content, Back, TopContent } from "./styles.js";

const Selecionado = () => (
  <Fragment>
    <Div>
      <SubHeader />

      <Content>
        <Back>
          <img src={BackArrowIcon} alt="back" />
          <span>voltar</span>
        </Back>
        <TopContent>
          <label>formulário</label>
          <div>
            <input
              type="text"
              placeholder="ID. Nome do formulário selecionado"
            />
            <img src={BasketIcon} alt="delete" />
          </div>
        </TopContent>

        <div className="title">
          <span>Preview do formulário</span>
          <img src={ExpandIcon} alt="expandir" />
        </div>

        <Question />
      </Content>
      <Button />
    </Div>
  </Fragment>
);

export default Selecionado;
