import React, { Component } from "react";
import { CategorieItemStyled } from "./styles";
import { Link } from "react-router-dom";

export default class CategorieItem extends Component {
  get lengthNewCourses() {
    const { item: category } = this.props;
    if (category.hasOwnProperty("count_new_courses")) {
      return category.count_new_courses;
    }
    if (category.hasOwnProperty("__meta__")) {
      return category.__meta__.count_new_courses;
    }
    return 0;
  }

  render() {
    const { item } = this.props;
    let lengthNewCourses = this.lengthNewCourses;
    return (
      <CategorieItemStyled>
        <Link to={`/usuario/cursos/categoria/${item.id}`}>
          <h1>{item.title}</h1>
          {lengthNewCourses !== null && lengthNewCourses !== 0 && (
            <span>
              {lengthNewCourses === 1
                ? `${lengthNewCourses} novo curso`
                : `${lengthNewCourses} novos cursos`}
            </span>
          )}
        </Link>
      </CategorieItemStyled>
    );
  }
}
