import Immutable from 'seamless-immutable';

import {
  convertCycleStatus,
  getLevelStatus,
} from '../../../services/cycle/status';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'currentCycle/GET_REQUEST',
  GET_SUCCESS: 'currentCycle/GET_SUCCESS',
  SET_FAILURE: 'currentCycle/SET_FAILURE',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  details: {},
  error: false,
  success: false,
  loading: false,
  statusText: null,
  statusLevel: null,
});

export default function currentCycle(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false, error: false };
    case Types.GET_SUCCESS:
      const { currentCycle } = action.payload;
      return {
        loading: false,
        success: true,
        details: currentCycle,
        statusText: convertCycleStatus(currentCycle.status),
        statusLevel: getLevelStatus(currentCycle.status),
      };
    case Types.SET_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  currentCycleRequest: payload => ({
    type: Types.GET_REQUEST,
    payload,
  }),

  currentCycleSuccess: currentCycle => ({
    type: Types.GET_SUCCESS,
    payload: { currentCycle },
  }),

  currentCycleFailure: error => ({
    type: Types.SET_FAILURE,
    payload: { error },
  }),
};
