import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import CycleDataFormActions from "../../ducks/cycle/cycleDataForm";
import { parseErrorResponse } from "../../../utils/FormErrorResponse";

export function* createOrUpdateCycle({ payload }) {
  yield put(CycleDataFormActions.cycleDataFormClearError());
  yield put(CycleDataFormActions.cycleDataFormLoading());
  try {
    let result;
    if (!payload.id) {
      result = yield call(api.post, "api/v1/admin/cycles", {
        ...payload
      });
    } else {
      result = yield call(api.put, `api/v1/admin/cycles/${payload.id}`, {
        ...payload
      });
    }
    yield put(
      CycleDataFormActions.cycleDataFormSuccess(
        result.data.details,
        result.data.messages[0]
      )
    );
  } catch (e) {
    if (!!e && !!e.request) {
      let request = e.request;
      if (request.status === 400) {
        let error = parseErrorResponse(request);
        return yield put(CycleDataFormActions.cycleDataFormError(error));
      }
    }
    yield put(
      CycleDataFormActions.cycleDataFormError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* resetStatus() {
  yield put(CycleDataFormActions.cycleDataFormResetStatus());
}

export function* cycleDataFormResetAll() {
  yield put(CycleDataFormActions.cycleDataFormResetAll());
}
