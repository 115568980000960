import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  userInfoRequest: [],
  userInfoLoading: [],
  userInfoReset: [],
  userInfoSuccess: ["data"],
  userInfoError: ["error"]
});

export const UserInfoTypes = Types;
export default Creators;

const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  error: false
});

/* Reducers */
export const success = (state, { data }) => {
  return state.merge({
    data,
    loading: false,
    success: true,
    error: false
  });
};

export const reset = state => {
  return state.merge({
    data: {},
    loading: false,
    success: false,
    error: false
  });
};

export const error = (state, { error }) => {
  return state.merge({
    loading: false,
    success: false,
    error: true
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    success: false,
    error: false
  });
};

/* Reducers to types */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_INFO_SUCCESS]: success,
  [Types.USER_INFO_ERROR]: error,
  [Types.USER_INFO_LOADING]: loading,
  [Types.USER_INFO_RESET]: reset
});
