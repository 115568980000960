import React from "react";
import { DefaultStyled } from "./styles.js";

export const FormItemError = props => {
  const { reducer, fieldName } = props;
  let error = reducer.error;
  if (!error || error instanceof String || !error.formErrors) {
    return null;
  }
  let itemError = error.formErrors[fieldName];
  if (!itemError) {
    return null;
  }
  return (
    <DefaultStyled>
      <li>{itemError}</li>
    </DefaultStyled>
  );
};
