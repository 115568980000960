import api from '../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as UserActions } from '../../ducks/admin/user';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';

export function* getUser(action) {
  try {
    let url = '/api/v1/admin/users/' + action.payload.id
    const response = yield call(api.get, url);
    yield put(UserActions.getUserSuccess(response.data.details));
  } catch (err) {
    yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.'));
  }
}

export function* updateUser(action) {
  try {
    const url = '/api/v1/admin/users/' + action.payload.id
    const response = yield call(api.put, url, action.payload.data);
    yield put(SuccessActions.setSuccess('Usuário atualizado com sucesso!', { toasted: true }));
    yield put(UserActions.getUserSuccess(response.data.details));
  } catch (err) {
    yield put(UserActions.setFailedUser());

    if (err.response) {
      if (err.response.data && err.response.data.details) {
        return yield put(ErrorActions.setError(err.response.data.details[0].detail, { toasted: true }));
      }
    }
    yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.', { toasted: true }));
  }
}

export function* createUser(action) {
  try {
    const url = '/api/v1/admin/users/'
    const response = yield call(api.post, url, action.payload.data);
    yield put(SuccessActions.setSuccess('Usuário criado com sucesso!', { toasted: true }));
    yield put(UserActions.createUserSuccess(response.data.details));
  } catch (err) {
    if (err.response) {
      yield put(UserActions.setFailedUser());

      if (err.response.data && err.response.data.details) {
        return yield put(ErrorActions.setError(err.response.data.details[0].detail, { toasted: true }));
      }
    }
    yield put(ErrorActions.setError('Um erro ocorreu, por favor tente novamente mais tarde.', { toasted: true }));
  }
}
