import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'role/GET_REQUEST',
  GET_SUCCESS: 'role/GET_SUCCESS',
  UPDATE_REQUEST: 'role/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'role/UPDATE_SUCCESS',
  INSERT_REQUEST: 'role/INSERT_REQUEST',
  INSERT_SUCCESS: 'role/INSERT_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  total: null,
  page: null,
  lastPage: null,
  updated: false,
  inserted: false,
});

export default function role(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        ...action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        updated: false,
      };
    case Types.UPDATE_SUCCESS:
      return {
        data: { ...state, ...action.payload.data },
        loading: false,
        success: true,
        updated: true,
      };
    case Types.INSERT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        inserted: false,
      };
    case Types.INSERT_SUCCESS:
      return {
        data: { ...state, ...action.payload.data },
        loading: false,
        success: true,
        inserted: true,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getRoleRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getRoleSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  updateRoleRequest: payload => ({
    type: Types.UPDATE_REQUEST,
    payload,
  }),

  updateRoleSuccess: payload => ({
    type: Types.UPDATE_SUCCESS,
    payload,
  }),

  insertRoleRequest: payload => ({
    type: Types.INSERT_REQUEST,
    payload,
  }),

  insertRoleSuccess: payload => ({
    type: Types.INSERT_SUCCESS,
    payload,
  }),
};
