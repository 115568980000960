import React, { Component } from "react";
import RatingStars from "../../../../../../../Components/RatingStars";

import { CommentaryStyled } from "./styles";

import Avatar from "../../../../../../../assets/images/avatar.jpg";

export default class Commentary extends Component {
  render() {
    const { comment } = this.props;

    return (
      <CommentaryStyled>
        <div className="left-side">
          <div className="img-wrapper">
            <img src={Avatar} alt={comment.user.name} />
          </div>
        </div>
        <div className="right-side">
          <div>
            <RatingStars
              color="#F03B42"
              fontSize="14px"
              rating={comment.rating}
            />
          </div>
          <p>{comment.text}</p>
          <div className="user-info">
            <span>{comment.user.name}</span>
            <span>{comment.comment}</span>
          </div>
        </div>
      </CommentaryStyled>
    );
  }
}
