import React, { Fragment } from "react";

import Table from "../../../Components/Table";

import { Div } from "../../../styles/global";

import DownIcon from "../../../assets/images/down.svg";

import {
  Content,
  DivContent,
  Options,
  Items,
  Button,
  Sidebuttons,
  Preview
} from "./styles";

const TabelaEmail = () => (
  <Fragment>
    <Div>
      <Options>
        <Items>
          <span>182 itens</span>
        </Items>

        <Sidebuttons>
          <div className="input">
            <input type="text" placeholder="Pesquisar" />
          </div>
          <button>
            <a href="#!">novo</a>
          </button>
          <button>
            <a href="#!">remover</a>
          </button>
        </Sidebuttons>
      </Options>

      <Content>
        <DivContent>
          <Preview>
            <Table />
            <Button>
              <button>
                <a href="#!">Mostrar mais</a>
                <img src={DownIcon} alt="mais" />
              </button>
            </Button>
          </Preview>
        </DivContent>
      </Content>
    </Div>
  </Fragment>
);

export default TabelaEmail;
