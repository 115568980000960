import styled from 'styled-components';

export const DetailBody = styled.div`
  .react-tabs {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      height: 50px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: #fff;
      box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);

      li {
        padding: 0 40px;
        display: flex;
        align-items: center;
        color: #666666;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;

        :not(:last-child) {
          margin-right: 20px;
        }

        &.react-tabs__tab--selected {
          color: #00468e;
          border-bottom: 6px solid #00468e;
          padding-top: 6px;
        }
      }
    }
  }
`;

export const DetailWrapper = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 45px;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.25);

  > div {
    margin-bottom: 20px;
  }

  button {
    height: 40px;
    margin: 0 auto;
    display: block;
    padding: 10px 25px;
    color: #fff;
    background-color: #00468e;
    border: 0;
    border-radius: 5px;
    font-size: 15px;
    text-transform: uppercase;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 30px;
`;

export const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  span {
    padding-left: 14px;
  }
`;
