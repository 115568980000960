import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import ParticipantDetailActions from "../../ducks/participant/participantDetail";

export function* requestParticipantDetail({ payload }) {
  const { cycleUserId } = payload;
  yield put(ParticipantDetailActions.participantDetailClearError());
  yield put(ParticipantDetailActions.participantDetailLoading());
  try {
    let url = `api/v1/cycles/participants/${cycleUserId}`;
    let response = yield call(api.get, url);
    yield put(
      ParticipantDetailActions.participantDetailSuccess(response.data.details)
    );
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(
          ParticipantDetailActions.participantDetailError(error)
        );
      }
    }
    yield put(
      ParticipantDetailActions.participantDetailError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}
