import React, { Component } from 'react';

import IconFlag from '../../assets/images/questoes/ico-flag.svg';
import IconEscala from '../../assets/images/questoes/ico-escala.svg';
import IconGroup from '../../assets/images/questoes/ico-group.svg';
import IconText from '../../assets/images/questoes/ico-textsize.svg';
import IconSimNao from '../../assets/images/questoes/ico-simnao.svg';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../store/ducks/questionCreation/questionCreation';

class PopupOptions extends Component {
  render() {
    const {
      etapa,
      uidStage,
      uidBlock,
      questionCreateStage,
      questionCreateBlock,
      questionCreateQuestion,
    } = this.props;

    return (
      <>
        {(etapa === 1 || etapa === 2) && (
          <li
            onClick={() => {
              questionCreateStage();
            }}
          >
            <img src={IconFlag} alt="Tópico" />
            <span>Tópico</span>
          </li>
        )}

        {etapa === 2 && (
          <li
            onClick={() => {
              questionCreateBlock(uidStage);
            }}
          >
            <img src={IconGroup} alt="Grupo" />
            <span>Grupo</span>
          </li>
        )}

        {etapa === 3 && (
          <>
            <li
              onClick={() => {
                questionCreateQuestion(
                  uidStage,
                  uidBlock,
                  QUESTION_TYPES.SINGLE_CHOICE
                );
              }}
            >
              <img src={IconSimNao} alt="Sim/Não" />
              <span>Sim/Não</span>
            </li>
            <li
              onClick={() => {
                questionCreateQuestion(
                  uidStage,
                  uidBlock,
                  QUESTION_TYPES.NUMERIC_INTERVAL
                );
              }}
            >
              <img
                src={IconEscala}
                className="icon-big"
                alt="Escala de opinião"
              />
              <span>Escala de opinião</span>
            </li>
            <li
              onClick={() => {
                questionCreateQuestion(uidStage, uidBlock, QUESTION_TYPES.TEXT);
              }}
            >
              <img src={IconText} className="icon-big" alt="Texto livre" />
              <span>Texto livre</span>
            </li>
          </>
        )}
      </>
    );
  }
}

const QUESTION_TYPES = {
  SINGLE_CHOICE: 'single_choice',
  NUMERIC_INTERVAL: 'numeric_interval',
  TEXT: 'text',
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions }, dispatch);

export default connect(null, mapDispatchToProps)(PopupOptions);
