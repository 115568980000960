import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import UserInfo from "../../../../Components/UserComponents/UserInfo"
import UserHeaderDetail from "../../../../Components/UserComponents/UserHeaderDetail"

import SideMenu from "../../SideMenu"

import { ContentWrapper } from "../../styles"
import {
  DetailWrapper,
  DetailBody,
  ModeloWrapper,
  HeaderWrapper,
  BackWrapper
} from "./styles"
import FormUser from '../Form'

import MdArrowBack from 'react-ionicons/lib/MdArrowBack'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Creators as UserActions } from '../../../../store/ducks/admin/user'
import { Creators as RolesActions } from '../../../../store/ducks/role/roles'

import { Formik } from 'formik'
import * as Yup from 'yup'

const ValidationSchema = Yup.object().shape({
  registration: Yup.string()
    .nullable()
    .required('É necessário preencher a matricula!')
    .max(255, 'O tamanho máximo da matrícula é de 255 caracteres'),
  initials: Yup.string()
    .nullable()
    .required('É necessário preencher as iniciais!')
    .max(255, 'O tamanho máximo das iniciais é de 255 caracteres'),
  functionRole: Yup.string()
    .nullable()
    .required('É necessário preencher a função!'),
  office: Yup.string()
    .nullable()
    .required('É necessário preencher o escritório!'),
  organization_unit: Yup.string()
    .nullable()
    .required('É necessário preencher a unidade organizacional!'),
  role_id: Yup.string()
    .nullable()
    .required('É necessário preencher o cargo!'),
  name: Yup.string()
    .nullable()
    .required('É necessário preencher o nome!'),
  cpf: Yup.string()
    .nullable()
    .matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'O CPF não é válido'),
  email: Yup.string()
    .nullable()
    .required('É necessário preencher o email!')
    .max(255, 'O tamanho máximo da matrícula é de 255 caracteres'),
  phone: Yup.string()
    .nullable(),
  cellphone: Yup.string()
    .nullable(),
  birthday: Yup.string()
    .nullable()
});

class DetailUsuario extends Component {
  state = {
    form: {},
  }

  componentDidMount() {
    this.setState({ form: {} })
    this.props.clearUserRequest()
    if (this.props.match.params.id !== "novo") {
      this.props.getUserRequest(this.props.match.params.id)
    }
  }

  componentDidUpdate(prevProps) {
    const { user, history } = this.props;
    if (user && user.created && !prevProps.created) {
      history.push('/dadosgerais/usuarios');
    }
  }

  handleSubmit(data) {
    if (data.birthday === '') {
      data.birthday = null;
    }
    const { id } = this.props.match.params
    this.props[id === "novo" ? "createUserRequest" : "updateUserRequest"]({
      id,
      data
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user.success) {
      const { data } = props.user
      state.form = {
        ...data,
        functionRole: data.function,
        roles: Array.isArray(data.roles) ? JSON.stringify(data.roles) : data.roles
      }
      state.initializeUser = true
    }

    return state
  }

  renderFormik = (key) => (
    <Formik
      enableReinitialize
      render={(props) => <FormUser key={key} {...props} user={this.props.user} />}
      validationSchema={ValidationSchema}
      onSubmit={values => this.handleSubmit(values)}
      initialValues={this.state.form}
      validateOnBlur={false}
      validateOnChange={false}
    />
  )

  renderUpdateForm = ({ id }) => (
    <>
      <UserInfo userId={id}>
        <UserHeaderDetail />
      </UserInfo>
      <DetailWrapper>
        <DetailBody>
          <Tabs>
            <TabList>
              <Tab>
                <Link to={`/dadosgerais/usuarios/${id}`}>Dados</Link>
              </Tab>
              <Tab>
                <Link to={`/dadosgerais/usuarios/${id}/ciclos`}>Ciclos</Link>
              </Tab>
              <Tab>
                <Link to={`/dadosgerais/usuarios/${id}/hierarquias`}>Hierarquias</Link>
              </Tab>
            </TabList>
            <TabPanel>
              <ModeloWrapper>
                {!this.props.user.loading && this.renderFormik()}
              </ModeloWrapper>
            </TabPanel>
            <TabPanel />
            <TabPanel />
          </Tabs>
        </DetailBody>
      </DetailWrapper>
    </>
  )

  renderCreateForm = () => (
    <DetailWrapper>
      <DetailBody>
        <ModeloWrapper novo={true}>
          <HeaderWrapper>
            <BackWrapper
              onClick={() => this.props.history.push('/dadosgerais/usuarios')}
            >
              <MdArrowBack color="#666" fontSize="26px" />
              <span>VOLTAR</span>
            </BackWrapper>
          </HeaderWrapper>
          {this.renderFormik()}
        </ModeloWrapper>
      </DetailBody>
    </DetailWrapper>
  )

  render() {
    return (
      <ContentWrapper>
        <SideMenu page={1} />
        <div className='content'>
          {
            this.props.match.params.id === "novo" ?
              this.renderCreateForm() :
              this.renderUpdateForm(this.props.match.params)
          }
        </div>
      </ContentWrapper>
    )
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({
  ...UserActions,
  ...RolesActions
}, dispatch);

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailUsuario);
