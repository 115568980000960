import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: -webkit-fill-available;
  background-color: #325d9f;
`;

export const Title = styled.div`
  height: 165px;
  width: 375px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;
    font-size: 120px;
    line-height: 163px;
    text-transform: uppercase;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Button = styled.button`
  width: 300px;
  height: 100px;
  background-color: #e0e0e0;
  border: 0;
  border-radius: 4px;
  margin-top: 50px;

  a {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    color: #666666;
    text-decoration: none;
  }
`;
