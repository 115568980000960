import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  FILTER_REQUEST: "enrollments/FILTER_REQUEST",
  FILTER_SUCCESS: "enrollments/FILTER_SUCCESS",
  FILTER_RESET_REQUEST: "enrollments/FILTER_RESET_REQUEST",
  FILTER_RESET: "enrollments/FILTER_RESET"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false
});

export default function enrollments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FILTER_REQUEST:
      return { ...state, loading: true };
    case Types.FILTER_SUCCESS:
      return {
        data: action.payload.data,
        loading: false
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false };
    case Types.FILTER_RESET:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  filterEnrollmentsRequest: data => ({
    type: Types.FILTER_REQUEST,
    payload: data
  }),

  filterEnrollmentsSuccess: data => ({
    type: Types.FILTER_SUCCESS,
    payload: { data }
  }),

  filterEnrollmentsResetRequest: () => ({
    type: Types.FILTER_RESET_REQUEST
  }),

  filterEnrollmentsResetSuccess: () => ({
    type: Types.FILTER_RESET
  })
};
