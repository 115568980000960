import React, { Fragment } from "react";

import SubHeader from "../../../../Components/SubHeader";
import Back from "../../../../Components/Back";
import Switch from "../../../../Components/Switch";

import { Content, ActivityBox, ActivityDetailsBox } from "./styles";

import { Div } from "../../../../styles/global";

import Mais from "../../../../assets/images/mais.svg";
import Sair from "../../../../assets/images/sair.svg";

const AtividadeFluxo = () => (
  <Fragment>
    <Div>
      <Back />
      <SubHeader />

      <Content>
        <ActivityBox>
          <div className="top">
            <span>Nome da atividade</span>
            <div>
              <img src={Mais} alt="opções" />
              <img src={Sair} alt="cancelar" />
            </div>
          </div>

          <div className="mid">
            <span>Tipo da atividade:</span>
            <span>Tipo da atividade selecionada</span>
          </div>

          <div className="bot">
            <span>Ações</span>
            <Switch />
          </div>
        </ActivityBox>
        <ActivityBox>
          <div className="top">
            <span>Nome da atividade</span>
            <div>
              <img src={Mais} alt="opções" />
              <img src={Sair} alt="cancelar" />
            </div>
          </div>

          <div className="mid">
            <span>Tipo da atividade:</span>
            <span>Tipo da atividade selecionada</span>
          </div>

          <div className="bot">
            <span>Ações</span>
            <Switch />
          </div>
        </ActivityBox>
        <ActivityBox>
          <div className="top">
            <span>Nome da atividade</span>
            <div>
              <img src={Mais} alt="opções" />
              <img src={Sair} alt="cancelar" />
            </div>
          </div>

          <div className="mid">
            <span>Tipo da atividade:</span>
            <span>Tipo da atividade selecionada</span>
          </div>

          <div className="bot">
            <span>Ações</span>
            <Switch />
          </div>
        </ActivityBox>
        <ActivityDetailsBox>
          <div className="top">
            <span>Nome da atividade</span>
            <div>
              <img src={Mais} alt="opções" />
              <img src={Sair} alt="cancelar" />
            </div>
          </div>

          <div className="mid">
            <div>
              <span>Tipo da atividade:</span>
              <span>Tipo da atividade selecionada</span>
            </div>

            <label>Descrição</label>
            <div className="text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                sed dui vestibulum, suscipit mauris ac, mollis nisi. Suspendisse
                potenti. Aliquam tincidunt nisl ligula, in ultrices mauris
                iaculis id. Vestibulum maximus enim non massa fringilla
                imperdiet.
              </p>
            </div>

            <div className="url">
              <label>URL da atividade</label>
              <div className="input">
                <p>http://www.atividade.com.br</p>
              </div>
            </div>
          </div>

          <div className="bot">
            <span>Ações</span>
            <Switch />
          </div>
        </ActivityDetailsBox>
      </Content>
    </Div>
  </Fragment>
);

export default AtividadeFluxo;
