import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import ParticipantsActions from '../../../../../store/ducks/participant/removeParticipants';

import Modal from 'react-responsive-modal';

import {
  ModalInner,
  ModalHeader,
  ParagrafoInfo,
  TableWrapper,
  ButtonsWrapper,
} from './styles';
import Switch from 'react-switch';
import JustificativaIndividual from './justificativaIndividual';
import JustificativaTodos from './justificativaTodos';
import Loading from '../../../../../Components/Loading';

class ModalRemove extends Component {
  state = {
    switchRemoveMesmoMotivo: false,
    submitted: false,
  };

  handleRemoveMesmoMotivo = checked => {
    this.setState({ switchRemoveMesmoMotivo: checked });
  };

  onSubmit = () => {
    this.setState({ submitted: true });
    let data = this._child.getFormData();
    if (data.every(reason => !!reason.reason_id)) {
      this.props.removeParticipantsRequest(this.props.cycleId, data);
      this.setState({ submitted: false });
    }
  };

  onCancel = () => {
    this.props.closeModal();
    this.setState({ submitted: false });
  };

  renderFooter = () => {
    if (this.props.removeParticipants.loading) {
      return <Loading loading={true} />;
    }
    return (
      <ButtonsWrapper>
        <button type="button" onClick={this.onCancel}>
          Cancelar
        </button>
        <button
          type="button"
          className="primary"
          onClick={() => {
            this.onSubmit();
          }}
        >
          Prosseguir
        </button>
      </ButtonsWrapper>
    );
  };

  componentDidUpdate() {
    if (this.props.removeParticipants.success) {
      toast.success(this.props.removeParticipants.success);
      this.props.removeParticipantsResetStatus();
      this.props.updateParticipants();
      this.props.closeModal();
    } else if (this.props.removeParticipants.error) {
      toast.error(this.props.removeParticipants.error);
      this.props.removeParticipantsResetStatus();
    }
  }

  render() {
    const { participantesSelecionados: participantes } = this.props;
    const { switchRemoveMesmoMotivo, submitted } = this.state;
    return (
      <div>
        <Modal
          open={this.props.isModalRemoveOpen}
          onClose={this.props.closeModal}
          styles={{ modal: { width: '80%', padding: 0, borderRadius: '5px' } }}
          showCloseIcon={false}
        >
          <ModalInner>
            <ModalHeader>
              <span>Justificativa da remoção</span>
              <div>
                <span>Aplicar a mesma justificativa para todos</span>
                <Switch
                  onChange={this.handleRemoveMesmoMotivo}
                  checked={this.state.switchRemoveMesmoMotivo}
                  onColor="#00468E"
                  id="normal-switch"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            </ModalHeader>
            <ParagrafoInfo className="p-info">
              <p>
                Para prosseguir, favor justificar o motivo da remoção dos
                destinatários listados do ciclo.
              </p>
            </ParagrafoInfo>
            <TableWrapper>
              {!switchRemoveMesmoMotivo ? (
                <JustificativaIndividual
                  ref={component => (this._child = component)}
                  participantes={participantes}
                  onChangeReason={this.onChangeReason}
                  submitted={submitted}
                />
              ) : (
                <JustificativaTodos
                  ref={component => (this._child = component)}
                  participantes={participantes}
                  onChangeReason={this.onChangeReason}
                  submitted={submitted}
                />
              )}
            </TableWrapper>
            {this.renderFooter()}
          </ModalInner>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ParticipantsActions }, dispatch);

const mapStateToProps = state => ({
  removeParticipants: state.removeParticipants,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRemove);
