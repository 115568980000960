import styled from "styled-components";

export const CourseItemStyled = styled.li`
  border-left: 5px solid #00468e;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;

  > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    :hover {
      text-decoration: none;
      opacity: 0.8;
    }

    h1 {
      color: #595959;
      font-size: 22px;
      font-family: "OS Bold", sans-serif;
      margin: 0;
      max-width: 80%;
    }

    span {
      font-size: 14px;
      color: #00468e;
      font-family: "OS Bold", sans-serif;
    }
  }
`;
