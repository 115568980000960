import React, { Component, Fragment } from 'react';

import Button from '../../../Components/Button';
import Back from '../../../Components/Back';
import SubHeader from '../components/SubHeader';

import { Div, WarningMessage } from '../../../styles/global';
import { Content } from './styles';
import QuestionTree from '../../../containers/QuestionTree';
import QuestionAddBtn from '../../../Components/QuestionAddBtn';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as QuestionCreationActions } from '../../../store/ducks/questionCreation/questionCreation';
import { Creators as ErrorActions } from '../../../store/ducks/error';

import { toast } from 'react-toastify';

class DadosFormularios extends Component {
  state = {
    tree: [],
    ready: false,
    isTemplate: true,
    cycleId: null,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.success && this.props.success) {
      this.setState({ ready: true });
    }
    if (this.state.isTemplate) {
      if (!prevProps.saved && this.props.saved) {
        this.props.history.push(
          `/modelos/${this.props.match.params.id}/destinatarios`
        );
      }
      if (!prevProps.success && this.props.success) {
        this.props.setTitle(
          `Modelo de formulário - ${this.props.templateQuiz.title}`
        );
      }
    } else {
      if (!prevProps.saved && this.props.saved) {
        this.props.history.push(
          `/formularios/${this.props.match.params.id}/destinatarios`
        );
      }
      if (!prevProps.success && this.props.success) {
        const cycle = this.props.quiz.cycles[0];
        this.setState({ cycleId: cycle.id });
        this.props.setTitle(`Formulário do ciclo - ${cycle.title}`);
      }
    }
  }

  componentDidMount() {
    if (String(this.props.location.pathname).includes('formularios')) {
      this.setState({ isTemplate: false });
      this.props.getQuestionsTreeRequest(this.props.match.params.id);
    } else {
      this.props.getTemplateQuestionsTreeRequest(this.props.match.params.id);
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    let emptyField = this.verifyEmptyFields();
    if (emptyField) {
      toast.error('Por favor, preencha todos os campos antes de prosseguir.');
    } else {
      if (this.props.success) {
        toast.success('Questões do formulário atualizadas com sucesso.');
      }
      let fullArrayForTheBackEndCriers = { stages: this.props.tree };
      const id = this.props.match.params.id;
      if (this.state.isTemplate) {
        this.props.defineTemplateQuestionsTreeRequest(
          id,
          fullArrayForTheBackEndCriers
        );
      } else {
        this.props.defineQuestionsTreeRequest(id, fullArrayForTheBackEndCriers);
      }
    }
  };

  handleCancel = e => {
    e.preventDefault();
    if (this.state.isTemplate) {
      this.props.history.push('/modelos/');
    } else {
      this.props.history.push(`/ciclos/${this.state.cycleId}/formularios`);
    }
  };

  verifyEmptyFields = () => {
    let topicosWrapper = document.getElementsByClassName('topics-wrapper')[0];
    if (!topicosWrapper) return;

    let inputs = topicosWrapper.getElementsByTagName('input');
    let textAreas = topicosWrapper.getElementsByTagName('textarea');
    let isEmpty = false;

    for (let index = 0; index < inputs.length; index++) {
      const element = inputs[index];
      if (element.value === '') {
        isEmpty = true;
        return isEmpty;
      }
    }

    for (let index = 0; index < textAreas.length; index++) {
      const element = textAreas[index];
      if (element.required && element.value === '') {
        isEmpty = true;
        return isEmpty;
      }
    }
  };

  renderWelcomeMsg = () => {
    const { tree } = this.state;
    const style = {
      span: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.8,
        marginBottom: 30,
      },
      lineWrapper: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      },
      line: {
        position: 'absolute',
        width: '80%',
        height: 1,
        backgroundColor: '#ddd',
        top: 10,
      },
    };
    return (
      <>
        <span style={style.span}>
          Inicie a construção do formulário, selecionando um dos itens no menu
          abaixo.
        </span>

        <div style={style.lineWrapper}>
          <div style={style.line} />
          <QuestionAddBtn
            startOpen={true}
            etapa={1}
            tree={tree}
            createItem={type => {
              this.createItem(type);
            }}
          />
        </div>
      </>
    );
  };

  renderNoEditableMsg = () => {
    return (
      <WarningMessage>
        Este formulário já foi respondido e por esse motivo não pode ser
        alterado.
      </WarningMessage>
    );
  };

  renderLoadingOrErrorMsg = () => {
    const style = {
      background: 'red',
      padding: 10,
      borderRadius: 4,
      color: '#fff',
      fontSize: 14,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 15,
      width: '100%',
    };
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        {this.props.getError ? (
          <div style={style}>Ocorreu um erro ao carregar a página</div>
        ) : (
          <p>Carregando...</p>
        )}
      </div>
    );
  };

  render() {
    const { tree, loading, editable } = this.props;
    const { ready } = this.state;
    return (
      <Div>
        {this.state.isTemplate ? (
          <Back path="/modelos" title="Voltar para listagem" />
        ) : (
          <Back
            path={`/ciclos/${this.state.cycleId}/formularios`}
            title="Voltar para listagem"
          />
        )}
        <SubHeader
          page="questoes"
          isTemplate={this.state.isTemplate}
          id={this.props.match.params.id}
        />

        <Content>
          {!ready && this.renderLoadingOrErrorMsg()}
          {editable === false && ready && this.renderNoEditableMsg()}

          {this.state.isTemplate ? (
            <Fragment>
              {ready && tree.length < 1 && this.renderWelcomeMsg()}
            </Fragment>
          ) : (
            <Fragment>
              {ready && editable && tree.length < 1 && this.renderWelcomeMsg()}
            </Fragment>
          )}

          {ready && <QuestionTree topicos={tree} editable={editable !== false} />}
        </Content>

        <Button
          onSubmit={this.handleSubmit}
          onCancel={e => this.handleCancel(e)}
          disabled={loading || editable === false}
        />
      </Div>
    );
  }
}

const mapStateToProps = state => ({
  tree: state.questionCreation.tree,
  success: state.questionCreation.success,
  saved: state.questionCreation.saved,
  loading: state.questionCreation.loading,
  editable: state.questionCreation.editable,
  getError: state.questionCreation.getError,
  quiz: state.questionCreation.quiz,
  templateQuiz: state.questionCreation.templateQuiz,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...QuestionCreationActions, ...ErrorActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DadosFormularios);
