import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Creators as QuizActions } from '../../../store/ducks/quiz/quiz';
import { Creators as TemplateQuizActions } from '../../../store/ducks/quiz/templateQuiz';

import Button from '../../../Components/Button';
import Back from '../../../Components/Back';
import SubHeader from '../components/SubHeader';
import {
  FormInputLabelled,
  FormTextArea,
  FormSelectLabelled,
} from '../../../Components/FormInputs';
import FormError from '../../../Components/Form/Error';

import { Div, WarningMessage } from '../../../styles/global';
import { Content } from './styles';

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
  description: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
});

const initialState = {
  title: '',
  description: '',
  status: '',
  isNew: false,
  isTemplate: true,
  editable: true,
  cycleId: null,
};

export class DadosFormularios extends Component {
  state = Object.assign({}, initialState);

  componentDidMount() {
    const { id } = this.props.match.params;
    if (String(this.props.location.pathname).includes('formularios')) {
      this.setState({ ...initialState, isTemplate: false });
      this.props.getQuizRequest(id);
    } else {
      if (id !== 'novo') {
        this.setState({ ...initialState });
        this.props.getTemplateQuizRequest(id);
      } else {
        this.setState({ ...initialState, isNew: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { quiz, templateQuiz, history, match } = this.props;
    if (this.state.isTemplate) {
      if (!prevProps.templateQuiz.success && templateQuiz.success) {
        this.props.setTitle(
          `Modelo de formulário - ${templateQuiz.details.title}`
        );
      }
      if (
        prevProps.templateQuiz &&
        templateQuiz &&
        ((!prevProps.templateQuiz.updated && templateQuiz.updated) ||
          (!prevProps.templateQuiz.inserted && templateQuiz.inserted))
      ) {
        if (templateQuiz && templateQuiz.details && templateQuiz.details.id) {
          history.push(`/modelos/${templateQuiz.details.id}/questoes`);
        } else {
          history.push(`/modelos/${match.params.id}/questoes`);
        }
      }
    } else {
      if (!prevProps.quiz.updated && quiz.updated) {
        history.push(`/formularios/${quiz.details.id}/questoes`);
      }
      if (!prevProps.quiz.success && quiz.success) {
        const cycle = quiz.details.cycles[0];
        this.setState({ editable: quiz.details.editable, cycleId: cycle.id });
        this.props.setTitle(`Formulário do ciclo - ${cycle.title}`);
      }
    }
  }

  handleSubmit = values => {
    if (this.state.isTemplate) {
      if (this.state.isNew) {
        this.props.insertTemplateQuizRequest(values);
      } else {
        this.props.updateTemplateQuizRequest(values);
      }
    } else {
      this.props.updateQuizRequest(values);
    }
  };

  handleCancel = e => {
    e.preventDefault();
    if (this.state.isTemplate) {
      this.props.history.push('/modelos/');
    } else {
      this.props.history.push(`/ciclos/${this.state.cycleId}/formularios`);
    }
  };

  renderForm = ({ handleSubmit, errors, handleChange, values }) => {
    const { title, description, status } = values;

    const statusValues = [
      {
        value: 'Ativo',
        name: 'Ativo',
      },
      {
        value: 'Inativo',
        name: 'Inativo',
      },
    ];

    return (
      <Fragment>
        {errors.title && <FormError isWarning error={errors.title} />}
        <FormInputLabelled
          label="Título"
          name="title"
          type="text"
          value={title}
          onChange={handleChange}
          disabled={!this.state.editable}
        />
        {errors.description && <FormError isWarning error={errors.description} />}
        <FormTextArea
          label="Descrição"
          name="description"
          value={description}
          onChange={handleChange}
          disabled={!this.state.editable}
        />
        {!this.state.isNew && this.state.isTemplate && (
          <FormSelectLabelled
            label="Status"
            name="status"
            value={status}
            onChange={handleChange}
            options={statusValues}
            disabled={!this.state.editable}
            style={{ maxWidth: '100px' }}
          />
        )}
        <Button
          onSubmit={handleSubmit}
          onCancel={e => this.handleCancel(e)}
          disabled={this.state.editable === false}
        />
      </Fragment>
    );
  };

  render() {
    let { quiz, templateQuiz } = this.props;
    if (this.state.isTemplate) {
      quiz = templateQuiz;
    }
    return (
      <Div>
        {this.state.isTemplate ? (
          <Back path="/modelos" title="Voltar para listagem" />
        ) : (
          <Back
            path={`/ciclos/${this.state.cycleId}/formularios`}
            title="Voltar para listagem"
          />
        )}
        <SubHeader
          page="dados"
          isTemplate={this.state.isTemplate}
          id={this.props.match.params.id}
        />

        <Content>
          <div className="body">
            {!this.state.editable && (
              <WarningMessage>
                Este formulário já foi respondido e por esse motivo não pode ser
                alterado.
              </WarningMessage>
            )}
            {!this.state.isNew ? (
              quiz &&
              quiz.success && (
                <Formik
                  enableReinitialize
                  render={this.renderForm}
                  validationSchema={ValidationSchema}
                  onSubmit={values => this.handleSubmit(values)}
                  initialValues={{ ...quiz.details }}
                />
              )
            ) : (
              <Formik
                enableReinitialize
                render={this.renderForm}
                validationSchema={ValidationSchema}
                onSubmit={values => this.handleSubmit(values)}
                initialValues={{ ...initialState }}
              />
            )}
          </div>
        </Content>
      </Div>
    );
  }
}

const mapStateToProps = state => ({
  quiz: state.quiz,
  templateQuiz: state.templateQuiz,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...QuizActions,
      ...TemplateQuizActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DadosFormularios);
