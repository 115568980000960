import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/cycleUser/updateCycleUserStatus";

export function* requestUpdateCycleUserStatus({ cycleUserId, status }) {
  yield put(Actions.updateCycleUserStatusReset());
  yield put(Actions.updateCycleUserStatusLoading());
  try {
    let result = yield call(api.put, `/api/v1/cycles/users/${cycleUserId || 'current'}`, {
      status
    });
    yield put(Actions.updateCycleUserStatusSuccess(result.data.messages[0]));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Actions.updateCycleUserStatusError(error));
      }
    }
    yield put(
      Actions.updateCycleUserStatusError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* resetUpdateCycleUserStatus() {
  yield put(Actions.updateCycleUserStatusReset());
}
