import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Actions from "../../ducks/grade/download";

export function* downloadGrade({ payload: cycleUserId }) {
  yield put(Actions.gradeDownloadResetAll());
  yield put(Actions.gradeDownloadLoading());
  try {
    let result = yield call(api.get, `/api/v1/quiz-download/${cycleUserId}`, {
      responseType: "arraybuffer"
    });
    showFile(result.data, cycleUserId, result.headers["content-type"]);
    yield put(Actions.gradeDownloadSuccess(result));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let textDecoder = new TextDecoder("utf-8");
        var uint8Array = new Uint8Array(request.response);
        textDecoder.decode(uint8Array);
        let response = JSON.parse(textDecoder.decode(uint8Array));
        let error = response.messages[0];
        return yield put(Actions.gradeDownloadError(error));
      }
    }
    yield put(
      Actions.gradeDownloadError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* resetDownloadGrade() {
  yield put(Actions.gradeDownloadResetAll());
}

function showFile(blob, cycleUserId, type) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], { type });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement("a");
  link.href = data;
  link.download = `Avaliação de desempenho - ${cycleUserId}.pdf`;
  link.click();
  setTimeout(function() {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}
