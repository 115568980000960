import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'participantReport/GET_REQUEST',
  GET_SUCCESS: 'participantReport/GET_SUCCESS',
  GET_ERROR: 'participantReport/GET_ERROR',
  RESET_REQUEST: 'participantReport/RESET_REQUEST',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  error: false,
});

export default function participantReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_ERROR:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        success: false,
        error: true,
      };
    case Types.RESET_REQUEST:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  requestParticipantReport: (page, filters) => ({
    type: Types.GET_REQUEST,
    payload: { page, filters },
  }),

  participantReportSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  participantReportError: data => ({
    type: Types.GET_ERROR,
    payload: { data },
  }),

  participantReportClear: () => ({
    type: Types.RESET_REQUEST,
  }),
};
