import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import CycleDataFormActions from '../../../../store/ducks/cycle/cycleDataForm';

import Loading from '../../../../Components/Loading';
import ErrorMsg from '../../../../Components/ErrorMsg';
import { FormInputLabelled, FormTextArea } from '../../../../Components/FormInputs';

import Button from '../../../../Components/Button';
import ScheduleIcon from '../../../../assets/images/schedule.svg';
import { Content, Form, Label, Dates, Field } from './styles';
import { FormItemError } from '../../../../Components/FormFieldError';

class CycleDataForm extends React.Component {
  constructor(props) {
    super(props);
    if (props.cycle) {
      this.state = {
        id: props.cycle.id,
        idCiclo: props.cycle.cycle,
        titulo: props.cycle.title,
        descricao: props.cycle.description,
        dataInicio: moment(props.cycle.begin).format('YYYY-MM-DD'),
        dataConclusao: moment(props.cycle.end).format('YYYY-MM-DD'),
        errorMsg: '',
        allValid: true,
      };
    } else {
      let currentDate = moment();
      this.state = {
        idCiclo: `ECC${currentDate.format('YY')}`,
        titulo: '',
        descricao: '',
        dataInicio: currentDate.format('YYYY-MM-DD'),
        dataConclusao: currentDate.add('1', 'days').format('YYYY-MM-DD'),
        errorMsg: '',
        allValid: false,
      };
    }
  }

  isLoading = () => this.props.form.loading;

  handleInputChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.isFieldsValid();
      }
    );
  };

  handleDataInicio = e => {
    const data = e.target.value;
    this.isDataValida(data, 'dataInicio');
  };

  handleDataConclusao = e => {
    const data = e.target.value;
    this.isDataValida(data, 'dataConclusao');
  };

  isDataValida(data, tipo) {
    if (!moment(data).isValid()) {
      this.setState({
        errorMsg: 'Selecione uma data válida',
        allValid: false,
      });
    } else {
      this.setState(
        {
          errorMsg: '',
          allValid: true,
          [tipo]: data,
        },
        () => {
          this.isDataSameOrBefore();
        }
      );
    }
  }

  isFieldsValid() {
    const {
      idCiclo,
      titulo,
      descricao,
      dataInicio,
      dataConclusao,
    } = this.state;

    var isValid;
    if (!idCiclo || !titulo || !descricao || !dataInicio || !dataConclusao) {
      isValid = false;
    } else {
      isValid = true;
    }

    this.setState({
      allValid: isValid,
    });
  }

  isDataSameOrBefore = () => {
    this.isFieldsValid();
    const { dataInicio, dataConclusao } = this.state;
    const isSame = moment(dataConclusao).isSame(dataInicio);
    const isBefore = moment(dataConclusao).isBefore(dataInicio);
    if (isSame || isBefore) {
      this.setState({
        errorMsg: 'A data de conclusão deve ser maior que a data de início.',
        allValid: false,
      });
    }
  };

  onSubmitForm = e => {
    e.preventDefault();
    let data = {
      id: this.state.id || null,
      title: this.state.titulo,
      description: this.state.descricao,
      begin: this.state.dataInicio,
      end: this.state.dataConclusao,
    };
    this.props.cycleDataFormRequest(data);
  };

  renderFormError = fieldName => {
    let reducer = this.props.form;
    return <FormItemError reducer={reducer} fieldName={fieldName} />;
  };

  renderError = () => {
    let formError = this.props.form.error;
    let error = null;
    if (formError) {
      if (formError instanceof String) {
        error = <ErrorMsg msg={formError} />;
      } else {
        error = <ErrorMsg msg={formError.title} />;
      }
    }
    let { errorMsg } = this.state;
    return (
      <div>
        {error}
        {errorMsg && <ErrorMsg msg={errorMsg} />}
      </div>
    );
  };

  handleCancel = e => {
    e.preventDefault();
    this.props.history.push('/ciclos/');
  };

  renderFooter = () => {
    let allValid = this.state.allValid;
    if (this.isLoading()) {
      return <Loading loading={true} />;
    }
    return (
      <Button
        disabled={!allValid || this.isLoading()}
        onCancel={this.handleCancel}
      />
    );
  };

  componentDidUpdate() {
    if (this.props.form.success) {
      toast.success(this.props.form.success);
      this.props.cycleDataFormResetStatus();
      let cycleId = this.props.form.cycle.id;
      this.props.history.push(`/ciclos/${cycleId}/formularios`);
    }
  }

  componentWillUnmount() {
    this.props.cycleDataFormResetAll();
  }

  render() {
    return (
      <Content>
        <Form onSubmit={this.onSubmitForm}>
          <div className="form-inner">
            <div className="form-inner-content">
              {this.renderError()}
              <Field>
                <FormInputLabelled
                  label="ID"
                  name="id"
                  type="text"
                  onChange={this.handleInputChange}
                  value={this.state.idCiclo}
                  required
                  disabled
                  style={{ maxWidth: '100px' }}
                />
              </Field>
              <Field>
                <FormInputLabelled
                  label="Título"
                  name="titulo"
                  type="text"
                  defaultValue={this.state.titulo}
                  onChange={this.handleInputChange}
                  required
                  placeholder="Nome do ciclo"
                  style={{ maxWidth: '800px' }}
                />
                {this.renderFormError('title')}
              </Field>
              <Field className="formControl">
                <FormTextArea
                  label="Descrição"
                  name="descricao"
                  defaultValue={this.state.descricao}
                  onChange={this.handleInputChange}
                  required
                  placeholder="Descrição do Ciclo"
                  style={{ maxWidth: '800px' }}
                />
                {this.renderFormError('description')}
              </Field>

              <Dates>
                <div className="bot">
                  <div>
                    <Label htmlFor="data-inicio">data de início</Label>
                    <div className="inputDate">
                      <input
                        id="data-inicio"
                        required={true}
                        type="date"
                        defaultValue={this.state.dataInicio}
                        onBlur={this.handleDataInicio}
                        placeholder="dd/mm/aaaa"
                        style={{ maxWidth: '160px' }}
                      />
                      <img src={ScheduleIcon} alt="agenda" />
                    </div>
                  </div>
                  <div>
                    <Label htmlFor="data-conclusao">data de conclusão</Label>
                    <div className="inputDate">
                      <input
                        id="data-conclusao"
                        required={true}
                        defaultValue={this.state.dataConclusao}
                        onBlur={this.handleDataConclusao}
                        type="date"
                        placeholder="dd/mm/aaaa"
                      />
                      <img src={ScheduleIcon} alt="agenda" />
                    </div>
                  </div>
                  <div />
                </div>
              </Dates>
              <Dates>
                <div className="bot">
                  <div>{this.renderFormError('begin')}</div>
                  <div>{this.renderFormError('end')}</div>
                </div>
              </Dates>
            </div>
          </div>
          {this.renderFooter()}
        </Form>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...CycleDataFormActions }, dispatch);

const mapStateToProps = state => ({
  form: state.cycleDataForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleDataForm);
