import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PdfIcon from '../../../assets/images/pdf-file.svg';
import XlsIcon from '../../../assets/images/xls-file.svg';
import { SearchBoxWrapper, NotFoundText } from './styles';

import Pagination from '../../../Components/Pagination';
import SelectWithPopupCycle from '../../../Components/SelectWithPopupCycle';
import Loading from '../../../Components/Loading';
import SelectWithPopupOrganizationUnit from '../../../Components/SelectWithPopupOrganizationUnit';

import { Creators as DepartmentReportActions } from '../../../store/ducks/reports/departmentReport';

import TableDataRelatorio from '../tableData';

class DepartamentoRelatorio extends Component {
  state = {
    department: '',
    cycle: null,
    ready: false,
    loading: false,
    page: 1,
    lastPage: 1,
    append: false,
    report: null,
    notFound: true,
  };

  componentDidMount() {
    this.props.requestDepartmentReport(this.state.page);
  }
  componentDidUpdate(prevProps, prevStates) {
    if (
      !prevProps.departmentReport.success &&
      this.props.departmentReport.success
    ) {
      if (this.state.append) {
        this.setState({
          report: this.state.report.concat(
            this.props.departmentReport.data.details.data
          ),
          ready: true,
          append: false,
          loading: false,
          notFound: false,
        });
      } else {
        this.setState({
          report: this.props.departmentReport.data.details.data,
          lastPage: this.props.departmentReport.data.details.lastPage,
          ready: true,
          append: false,
          loading: false,
          notFound: false,
        });
      }
    } else if (
      !prevProps.departmentReport.error &&
      this.props.departmentReport.error
    ) {
      this.setState({
        report: null,
        ready: false,
        loading: false,
        append: false,
        notFound: true,
      });
    }
  }

  changeFilter = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onDownloadRequest = (e, type) => {
    e.preventDefault();
    let filters;
    if (this.state.department != '' || this.state.cycle != '') {
      filters = {
        department: this.state.department,
        cycle: this.state.cycle,
      };
    }
    filters.type = type;
    this.props.departmentReportDownloadRequest(filters);
  };

  search = e => {
    e.preventDefault();
    if (this.state.department != '' || this.state.cycle != '') {
      let filters = {
        department: this.state.department,
        cycle: this.state.cycle,
      };
      this.setState(
        {
          page: 1,
          loading: true,
          append: false,
        },
        () => {
          this.props.requestDepartmentReport(this.state.page, filters);
        }
      );
    }
  };

  loadMoreData = page => {
    let filters = {
      department: this.state.department,
      cycle: this.state.cycle,
    };
    this.props.requestDepartmentReport(page, filters);
    this.setState({ page, append: true });
  };

  handleKeyPress = event => {
    this.props.eventSearch(this.state.text);
  };

  renderPagination() {
    let { page, loading } = this.props;
    if (this.state.report && this.state.page < this.state.lastPage) {
      return (
        <Pagination
          paginate={{ page, loading }}
          onClick={() => {
            this.loadMoreData(this.state.page + 1);
          }}
        />
      );
    }
  }

  renderUserInfo() {
    const { report } = this.state;
    if (!!report) {
      let cycle = report[0];
      return (
        <div>
          <ul className="user-data">
            <li>
              <span>
                <strong>Sumário de Avaliações</strong>
              </span>
            </li>
            <li>
              <span>
                <strong>Departamento:</strong>{' '}
                {this.state.department != ''
                  ? cycle.user.organization_unit
                  : ' - '}
              </span>
            </li>
            <li>
              <span>
                <strong>Ciclo:</strong>{' '}
                {this.state.cycle ? cycle.cycle.title : ' - '}
              </span>
            </li>
          </ul>
        </div>
      );
    }
  }

  prepareData = () => {
    const departmentReport = this.state.report;
    if (departmentReport.length > 0) {
      let lastSize = 0;
      departmentReport.map(data => {
        if (data.blocks.length > lastSize) {
          lastSize = data.blocks.length;
        }
      });
      let header = ['ID', 'Ciclo', 'Nome', 'Matrícula', 'Cargo', 'Média Final'];
      for (let i = 1; i <= lastSize; i++) {
        header.push('Competência ' + i);
      }
      let dataCycle = [];
      departmentReport.map(data => {
        if (data.user) {
          let cycle = {
            id: data.id,
            title: data.cycle.title,
            name: data.user.name,
            role: data.user.office,
            registration: data.user.registration,
            media: 0,
          };
          let blocks = [];
          let mediaComp = 0;
          let contTotal = 0;
          for (let i = 0; i < lastSize; i++) {
            if (data.blocks[i]) {
              contTotal++;
              let avg = parseFloat(data.blocks[i].avg);
              mediaComp += avg;
              blocks.push(avg.toFixed(2));
            } else {
              blocks.push(' - ');
            }
          }
          cycle.media =
            mediaComp > 0 ? (mediaComp / contTotal).toFixed(2) : '0.00';
          cycle.blocks = blocks;
          dataCycle.push(cycle);
        }
        return false;
      });
      return [header, dataCycle];
    }
    return [];
  };

  render() {
    const { report } = this.state;
    let tableData = [];
    if (this.state.ready && report) {
      tableData = this.prepareData();
    }

    return (
      <Fragment>
        <form onSubmit={this.search}>
          <SearchBoxWrapper>
            <div className="titles">
              <h3>Histórico por Departamento</h3>
              <h4 className="hideResponsive">Downloads</h4>
            </div>
            <div className="selects-and-buttons">
              <div className="selects-and-search">
                <div className="select">
                  <SelectWithPopupOrganizationUnit
                    onSelect={value => this.setState({ department: value })}
                  />
                </div>
                <div className="select">
                  <SelectWithPopupCycle
                    name="cycle"
                    value={this.state.cycle || ''}
                    valueBy={'id'}
                    onSelect={value =>
                      value
                        ? this.setState({ cycle: value.id })
                        : this.setState({ cycle: null })
                    }
                  />
                </div>
                <button type="submit">Buscar</button>
              </div>
              <div className="download-buttons">
                <button
                  type="button"
                  className="pdf-button"
                  onClick={e => this.onDownloadRequest(e, 'pdf')}
                >
                  <img src={PdfIcon} alt="PDF" />
                  PDF
                </button>
                <button
                  type="button"
                  className="xls-button"
                  onClick={e => this.onDownloadRequest(e, 'xls')}
                >
                  <img src={XlsIcon} alt="XLS" />
                  XLS
                </button>
              </div>
            </div>
            {this.renderUserInfo()}
          </SearchBoxWrapper>
        </form>
        {this.state.loading && <Loading text={'Buscando...'} loading={true} />}

        {(!this.state.loading && this.state.notFound) ||
        tableData.length == 0 ? (
          <NotFoundText>Nenhum colaborador encontrado.</NotFoundText>
        ) : (
          <TableDataRelatorio
            entity="department"
            dataHeader={tableData[0]}
            dataRow={tableData[1]}
            currentWidth={this.props.currentWidth}
          />
        )}
        {!this.state.loading && !this.state.notFound && this.renderPagination()}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...DepartmentReportActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  departmentReport: state.departmentReport,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartamentoRelatorio);
