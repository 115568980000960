import styled from 'styled-components';

export const TreeWrapper = styled.div`
  width: 100%;
  > .topics-wrapper {
    > div {
      height: 51px;
      background-color: #fff;
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      &.active {
        height: auto;
      }
    }

    .blocks-wrapper {
      > div {
        height: 51px;
        &:last-child {
          margin-bottom: 20px;
        }
        &.active {
          height: auto;
        }
      }
    }
  }

  .question-add-wrapper {
    display: flex;
    justify-content: center;
    transform: translate(0, -12px);
    height: 20px;
    flex: 1;

    &.hidden {
      display: none;
    }

    &::after {
      content: '+';
      height: 20px;
      width: 20px;
      border: 2px solid #bbb;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bbb;
      font-size: 30px;
      padding: 9px;
    }

    > div {
      display: none;
    }

    &:hover {
      > div {
        display: block;
        z-index: 9999999;
      }

      &::after {
        display: none;
      }
    }
  }
`;
