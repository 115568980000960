import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loading from '../../../Components/Loading';
import QuizForm from '../../../Components/Quiz/Form';

import { CicloDetailWrapper } from './styles';

class QuizShow extends Component {
  state = {
    redirect: false,
    ready: false,
    stageIndex: 0,
    cycleUserId: null
  };

  static getDerivedStateFromProps(props, state) {
    state.cycleUserId = props.cycleUserId;
    if (props.userCycle.success || props.userCycle.error) {
      if (props.userCycle.success) {
        state.userCycle = props.userCycle.userCycle;
        state.ready = true;
      }
    }
    return state;
  }

  componentDidMount() {
    let { cycleUserId } = this.state;
    this.props.userCycleRequest(cycleUserId);
  }

  componentWillUnmount() {
    this.props.userCycleResetRequest();
  }

  renderTitle = () => {
    const { userCycle } = this.state;
    const title = userCycle.cycle ? userCycle.cycle.title : null;
    return (
      <CicloDetailWrapper>
        <span>Preview - {title}</span>
      </CicloDetailWrapper>
    );
  };

  renderQuiz = () => {
    const { userCycle } = this.state;
    return (
      <QuizForm
        key={userCycle.id}
        cycleUserId={userCycle.id}
        status={userCycle.status}
        stages={userCycle.stages}
        answersUser={userCycle.answers}
        answersReviewer={userCycle.answersReviewer}
        isReviewer={false}
        onlyShow={true}
        onComplete={() => { }}
        updateUserCycle={() => { }}
      />
    );
  };

  render() {
    let { ready } = this.state;
    if (!ready) {
      return <Loading loading={true} />;
    }
    return (
      <>
        {this.renderTitle()}
        {this.renderQuiz()}
      </>
    );
  }
}

QuizShow.propTypes = {
  cycleUserId: PropTypes.any.isRequired,
  userCycle: PropTypes.object.isRequired,
  userCycleRequest: PropTypes.func.isRequired,
  userCycleResetRequest: PropTypes.func.isRequired
};

export default QuizShow;