import React, { Fragment } from "react";

import SubHeader from "../../../../Components/SubHeader";
import Question from "../../../../Components/Question";
import Collaborator from "../../../../Components/Collaborator";

import { Div } from "../../../../styles/global";

import BackIcon from "../../../../assets/images/backArrow.svg";

import { Content, Data, BackPage } from "./styles";

const Detalhes = () => (
  <Fragment>
    <Div>
      <BackPage>
        <img src={BackIcon} alt="Voltar" />
        <span>lista completa</span>
      </BackPage>
      <SubHeader />
      <Collaborator />
      <Content>
        <Data>
          <div className="title">
            <span>formulário gestor</span>
            <span>Prazo: 10/01/2018 - 01/03/2018 </span>
            <span>Data de entrega: 15/01/218</span>
          </div>

          <div className="score">
            <div>
              <span>visão estratégica</span>
              <p>8 pontos</p>
            </div>
            <div>
              <span>inovação</span>
              <p>5 pontos</p>
            </div>
            <div>
              <span>tomada de decisão</span>
              <p>10 pontos</p>
            </div>
            <div>
              <span>gestão de processos</span>
              <p>1 pontos</p>
            </div>
            <div>
              <span>liderança transformadora</span>
              <p>10 pontos</p>
            </div>
            <div>
              <span>gestão de pessoas</span>
              <p>8 pontos</p>
            </div>
            <div>
              <span>compromisso com resultados</span>
              <p>1 pontos</p>
            </div>
          </div>
        </Data>
        <Question />
      </Content>
    </Div>
  </Fragment>
);

export default Detalhes;
