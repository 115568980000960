import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'departmentReport/GET_REQUEST',
  GET_SUCCESS: 'departmentReport/GET_SUCCESS',
  GET_ERROR: 'departmentReport/GET_ERROR',
  RESET_REQUEST: 'departmentReport/RESET_REQUEST',
  DOWNLOAD_REQUEST: 'departmentReport/DOWNLOAD_REQUEST',
  DOWNLOAD_SUCCESS: 'departmentReport/DOWNLOAD_SUCCESS',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  error: false,
  download_loading: false,
  download_success: false,
  download_error: false,
});

export default function departmentReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_ERROR:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        success: false,
        error: true,
      };
    case Types.RESET_REQUEST:
      return { ...INITIAL_STATE };
    case Types.DOWNLOAD_REQUEST:
      return { ...state, download_loading: true, download_success: false };
    case Types.DOWNLOAD_SUCCESS:
      return {
        data: action.payload.data,
        download_loading: false,
        download_success: true,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  requestDepartmentReport: (page, filters) => ({
    type: Types.GET_REQUEST,
    payload: { page, filters },
  }),

  departmentReportSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  departmentReportError: data => ({
    type: Types.GET_ERROR,
    payload: { data },
  }),

  departmentReportClear: () => ({
    type: Types.RESET_REQUEST,
  }),

  departmentReportDownloadRequest: filters => ({
    type: Types.DOWNLOAD_REQUEST,
    payload: { filters }
  }),

  departmentReportDownloadSuccess: data => ({
    type: Types.DOWNLOAD_SUCCESS,
    payload: { data },
  }),
};
