export function prepare(params) {
  if (!params || !Array.isArray(params) || params.length === 0) {
    return '';
  }

  let result = '';

  for (let i = 0; i < params.length; i++) {
    const element = params[i];
    if (i === 0) {
      result = `?${element}`
    } else {
      result += `&${element}`
    }
  }

  return result;
}
