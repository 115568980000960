import { call, put } from "redux-saga/effects";
import { Creators as ScormActions } from "../../../ducks/modules/courses/scorm";
import { Creators as ErrorActions } from "../../../ducks/error";

import api from "../../../../services/api";

export function* postScorm(data, enrollmentId, topicId) {
  try {
    const response = yield call(api.post, "/api/v1/scorm-user", {
      session_time: data.session_time,
      session_location: data.lesson_location,
      session_status: data.lesson_status,
      score: data.score_raw,
      score_max: data.score_max,
      score_min: data.score_min,
      suspend_data: data.suspend_data,
      enrollment_id: enrollmentId,
      topic_id: topicId
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return false;
  }
}

export function* putScorm(data, enrollmentId, topicId) {
  try {
    const response = yield call(api.put, "/api/v1/scorm-user", {
      session_time: data.session_time,
      session_location: data.lesson_location,
      session_status: data.lesson_status,
      score: data.score_raw,
      score_max: data.score_max,
      score_min: data.score_min,
      suspend_data: data.suspend_data,
      enrollment_id: enrollmentId,
      topic_id: topicId
    });
    if (response.data && response.status === 200) {
      return response.data;
    }
  } catch (err) {
    return false;
  }
}

export function* updateScorm(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/scorm-user?enrollment_id=${
        action.payload.enrollmentId
      }&topic_id=${action.payload.topicId}`
    );
    if (response.status === 200) {
      let scormExist = response.data;
      const idTopic = action.payload.topicId;
      let data = action.payload.data;

      if (scormExist.status === "ok") {
        yield* postScorm(
          data,
          action.payload.enrollmentId,
          action.payload.topicId
        );
      } else if (scormExist.topic_id == idTopic) {
        yield* putScorm(
          data,
          action.payload.enrollmentId,
          action.payload.topicId
        );
      }
    }
    yield put(ScormActions.getScormSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro na requisição get"));
    }
  }
}

export function* getScorm(action) {
  try {
    const response = yield call(
      api.get,
      `/api/v1/scorm-user?enrollment_id=${
        action.payload.enrollmentId
      }&topic_id=${action.payload.topicId}`
    );

    yield put(ScormActions.getScormDataSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else {
      yield put(ErrorActions.setError("Erro na requisição get"));
    }
  }
}
