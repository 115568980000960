import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  GET_REQUEST: 'reason/GET_REQUEST',
  GET_SUCCESS: 'reason/GET_SUCCESS',
  UPDATE_REQUEST: 'reason/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'reason/UPDATE_SUCCESS',
  INSERT_REQUEST: 'reason/INSERT_REQUEST',
  INSERT_SUCCESS: 'reason/INSERT_SUCCESS',
  DELETE_REQUEST: 'reason/DELETE_REQUEST',
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  success: false,
  total: null,
  page: null,
  lastPage: null,
  updated: false,
  inserted: false,
});

export default function reason(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      return {
        ...action.payload.data,
        loading: false,
        success: true,
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    case Types.UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        updated: false,
      };
    case Types.UPDATE_SUCCESS:
      return {
        data: { ...state, ...action.payload.data },
        loading: false,
        success: true,
        updated: true,
      };
    case Types.INSERT_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        inserted: false,
      };
    case Types.INSERT_SUCCESS:
      return {
        data: { ...state, ...action.payload.data },
        loading: false,
        success: true,
        inserted: true,
      };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getReasonRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id },
  }),

  getReasonSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data },
  }),

  updateReasonRequest: payload => ({
    type: Types.UPDATE_REQUEST,
    payload,
  }),

  updateReasonSuccess: payload => ({
    type: Types.UPDATE_SUCCESS,
    payload,
  }),

  insertReasonRequest: payload => ({
    type: Types.INSERT_REQUEST,
    payload,
  }),

  insertReasonSuccess: payload => ({
    type: Types.INSERT_SUCCESS,
    payload,
  }),

  getDeleteRequest: id => ({
    type: Types.DELETE_REQUEST,
    payload: { id },
  }),
};
