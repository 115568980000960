import styled from 'styled-components'

export const SearchBoxStyled = styled.div`
  display: flex;
  align-items: center;

  input {
    flex: 1;
    margin-left: 15px;
    border: 0;
    font-size: 18px;
    color: #595959;
    font-family: "OS Bold", sans-serif;

    ::placeholder {
      color: #ccc;
      font-family: "Open Sans", sans-serif;
    }
  }
`
