import React from 'react';

import moment from "moment"
import { FormInputLabelled, InputRadioLabelled } from '../../../../Components/FormInputs'

import SelectWithPopupFunction from '../../../../Components/SelectWithPopupFunction';
import SelectWithPopupOffice from '../../../../Components/SelectWithPopupOffice';
import SelectWithPopupOrganizationUnit from '../../../../Components/SelectWithPopupOrganizationUnit';
import SelectWithPopupRole from '../../../../Components/SelectWithPopupRole';
import FormError from "../../../../Components/Form/Error";

export default function FormUser({
  handleSubmit,
  errors,
  handleChange,
  values,
  setFieldValue,
  user
}) {
  return (
    <>
      {errors.registration && <FormError error={errors.registration}/>}
      <FormInputLabelled
        label='Matrícula'
        name='registration'
        type='text'
        onChange={handleChange}
        value={values.registration || ""}
        style={{ maxWidth: '150px' }}
      />

      {errors.name && <FormError error={errors.name}/>}
      <FormInputLabelled
        label='Nome'
        name='name'
        type='text'
        onChange={handleChange}
        value={values.name || ""}
        style={{ maxWidth: '400px' }}
      />

      {errors.initials && <FormError error={errors.initials}/>}
      <FormInputLabelled
        label='Iniciais'
        name='initials'
        type='text'
        onChange={handleChange}
        value={values.initials || ""}
        style={{ maxWidth: '100px' }}
      />

      {errors.functionRole && <FormError error={errors.functionRole}/>}
      <div className="selects">
        <SelectWithPopupFunction
          name='functionRole'
          label={"true"}
          value={values.functionRole || ""}
          onSelect={v => {
            setFieldValue('functionRole', v);
          }}
        />
      </div>

      {errors.office && <FormError error={errors.office}/>}
      <div className="selects">
        <SelectWithPopupOffice
          name='office'
          label={"true"}
          value={values.office || ""}
          onSelect={v => {
            setFieldValue('office', v);
          }}
        />
      </div>

      {errors.organization_unit && <FormError error={errors.organization_unit}/>}
      <div className="selects">
        <SelectWithPopupOrganizationUnit
          name='organization_unit'
          label={"true"}
          value={values.organization_unit || ""}
          onSelect={v => {
            setFieldValue('organization_unit', v);
          }}
        />
      </div>

      {errors.role_id && <FormError error={errors.role_id}/>}
      <div className="selects">
        <SelectWithPopupRole
          name='role_id'
          label={"true"}
          value={values.role_id || ""}
          valueBy={"id"}
          onSelect={v => {
            setFieldValue('role_id', v.id);
          }}
        />
      </div>

      {errors.email && <FormError error={errors.email}/>}
      <FormInputLabelled
        label='E-mail'
        name='email'
        type='text'
        onChange={handleChange}
        value={values.email || ""}
        style={{ maxWidth: '400px' }}
      />

      {errors.phone && <FormError error={errors.phone}/>}
      <FormInputLabelled
        label='Telefone'
        name='phone'
        type='text'
        onChange={handleChange}
        value={values.phone || ""}
        style={{ maxWidth: '130px' }}
        mask={'(99) 9999-9999'}
      />

      {errors.cellphone && <FormError error={errors.cellphone}/>}
      <FormInputLabelled
        label='Celular'
        name='cellphone'
        type='text'
        onChange={handleChange}
        value={values.cellphone || ""}
        style={{ maxWidth: '130px' }}
        mask={'(99) 99999-9999'}
      />

      {errors.birthday && <FormError error={errors.birthday}/>}
      <FormInputLabelled
        label='Data de aniversário'
        name='birthday'
        type='date'
        onChange={handleChange}
        value={values.birthday ? moment(values.birthday).utc().format('YYYY-MM-DD') : ""}
        style={{ maxWidth: '160px' }}
      />

      <InputRadioLabelled
        label="Papel"
        name='roles'
        data={[
          { name: 'cargo', value: '["ROLE_ADMIN"]', title: 'Administrador', checked: values.roles && values.roles === '["ROLE_ADMIN"]' },
          { name: 'cargo', value: '["ROLE_USER"]', title: 'Usuário', checked: values.roles && values.roles === '["ROLE_USER"]' }
        ]}
        onChange={({ target }) => setFieldValue('roles', target.value)}
        defaultChecked={'["ROLE_USER"]'}
      />

      <button onClick={handleSubmit} type='button'>{user.loading ? 'Salvando...' : 'Salvar'}</button>
    </>
  )
}
