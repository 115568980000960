import styled from "styled-components";

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  min-height: 600px;
  background-color: #fff;
`;

export const HorizantalItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    padding: 10px 20px;
    background-color: #00468e;
    color: #fff;
    border: 0;
    border-radius: 5px;

    &[disabled] {
      background-color: #eee;
    }

    :not([disabled]):hover {
      opacity: 0.8;
    }
  }
`;

export const VerificacaoBox = styled.div`
  ul {
    list-style-type: decimal;
    margin-left: 30px;
  }
`;
