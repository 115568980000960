import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Actions from '../../store/ducks/select/selectUsers';
import SelectWithPopupRedux from '../SelectWithPopupRedux';

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      request: Actions.selectUsersRequest,
      clear: Actions.selectUsersResetRequest,
    },
    dispatch
  );

const mapStateToProps = state => ({
  error: state.selectUsers.error,
  success: state.selectUsers.success,
  loading: state.selectUsers.loading,
  data: state.selectUsers.data,
});

const Component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectWithPopupRedux);

Component.defaultProps = {
  placeholder: 'Usuário',
};

export default Component;
