import React, { Component } from "react";

import { ContentWrapper } from "../styles";

import SideMenu from "../SideMenu";

export default class DadosGeraisPerfis extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ContentWrapper>
        <SideMenu page={3} />

        <div className="content">perfis</div>
      </ContentWrapper>
    );
  }
}
