import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "users/GET_REQUEST",
  GET_SUCCESS: "users/GET_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  perPage: null,
  lastPage: null,
  loading: false,
  success: false
});

export default function users(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false };
    case Types.GET_SUCCESS:
      let { data, ...payload } = action.payload
      if (payload.page > state.page) {
        data = [ ...state.data, ...data ]
      }

      return {
        data,
        ...payload,
        loading: false,
        success: true
      };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getUsersRequest: payload => ({
    type: Types.GET_REQUEST,
    payload
  }),

  getUsersSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: data
  })
};
