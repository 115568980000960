import React from "react";

import Grade from "../Grade";

import { MainContent, Preview } from "./styles";

export default class Question extends React.Component {
  render() {
    return (
      <MainContent>
        <Preview>
          <h1>visão estratégica</h1>
          <p>
            Analisa e compreende os fatores internos e externos que afetam os
            objetivos presentes e futuros da Empresa, com foco na
            sustentabilidade financeira (custo-benefício) e socioambiental.
            Cuida para disseminar as diretrizes estratégicas da organização à
            sua equipe.
          </p>

          <div className="questao">
            <span>a)</span>
            <p>
              Disseminação: Dissemina as diretrizes estratégicas junto à sua
              equipe.
            </p>
          </div>
          <Grade />

          <div className="button">
            <button>
              <a href="#!">próximo</a>
            </button>
          </div>
        </Preview>
      </MainContent>
    );
  }
}
