import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./ducks";
import rootSaga from "./sagas";

import ToastMiddleware from "../middlewares/toast";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [ sagaMiddleware, ToastMiddleware ];

const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export default store;
