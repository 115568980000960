import React from 'react'
import { ResultadoRevisadoBoxWrapper } from './styles';

export default function ResultadoRevisadoBox(props) {
  return (
    <ResultadoRevisadoBoxWrapper>
      <span>Resultado final revisado</span>
      <span className='points'>{props.points} pontos</span>
    </ResultadoRevisadoBoxWrapper>
  )
}
