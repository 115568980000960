import styled from 'styled-components';

export const Back = styled.div`
  display: flex;
  height: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: flex-start;

  .content {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 25px;

    img {
      height: 30px;
      width: 30px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      line-height: 19px;
      color: #00468e;
      text-transform: uppercase;
    }
  }
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e7e7e7;
  border-bottom: #ccc 2px solid;
  margin: 20px 25px;
  min-height: 70px;

  span {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;

    margin-right: 70px;
  }
`;

export const Content = styled.div`
  display: flex;
  margin: 10px 0 10px;
  border-radius: 5px;
  flex-direction: column;
  padding: 25px 32px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);

  label {
    text-transform: uppercase;
    color: #666666;
    line-height: 22px;
    font-size: 16px;
    font-family: 'Open Sans';
  }

  .itensButton {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #00468e;
      font-size: 18px;
      font-family: 'Open Sans';
      line-height: 24px;
      text-transform: uppercase;
    }

    button {
      border: 0;
      background-color: #00468e;
      border-radius: 5px;
      padding: 5px 10px;
      height: 36px;
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      font-size: 16px;
      font-family: 'Open Sans';

      :enabled {
        :hover {
          opacity: 0.8;
          cursor: pointer;
        }
      }

      :disabled {
        background-color: #ccc;
      }
    }
  }

  .selected {
    margin-top: 20px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 30px 10px;

  span {
    color: #4d4d4d;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const TabWrapper = styled.div`
  .tablist {
    display: flex;
    list-style: none;
    height: 50px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);

    li {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 50px;
      cursor: pointer;
      font-size: 16px;
      text-transform: uppercase;
      color: #666666;

      &.react-tabs__tab--selected {
        color: #00468e;
        border-bottom: 6px solid #00468e;
        padding-top: 6px;
      }
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 20px 0 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 10px;

  > div {
    :first-child {
      margin-bottom: 20px;
      label {
        font-size: 16px;
        color: #666666;
        text-transform: uppercase;
      }
    }

    :nth-child(2) {
      display: flex;
      justify-content: space-between;

      span {
        color: #00468e;
        font-size: 18px;
        text-transform: uppercase;
      }

      button {
        border: 0;
        height: 40px;
        color: #fff;
        text-transform: uppercase;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #00468e;

        :not([disabled]):hover {
          opacity: 0.8;
        }

        &[disabled] {
          background-color: #eee;
        }
      }
    }
  }
`;

export const ResponsiveWrapper = styled.div`
  @media (max-width: 768px) {
    .hideResponsive {
      display: none;
      width: 100%;
    }
  }
`;
