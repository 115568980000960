import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import AuthActions from "../../store/ducks/auth";
import Loading from "../../Components/Loading";

class LogoutPage extends React.Component {
  componentDidMount() {
    this.props.signOutRequest();
  }

  render() {
    if (this.props.auth.signedIn) {
      return (
        <div>
          <Loading loading={true} />
          <Redirect to="/" />
          {window.location.href='/'}
        </div>
      );
    }
    return <Redirect to="/" />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);

const mapStateToProps = state => ({ auth: state.auth });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutPage);
