import React from "react";
import PropTypes from "prop-types";

import { Wrapper, User, Buttons, Div, DivSendRH } from "./styles";

import Avatar from "../../assets/images/avatar.jpg";
import MoreIcon from "../../assets/images/GreyMore.svg";
import Dropdown from "./components/Dropdown";
import ButtonCycleUser from "../ButtonCycleUser";

export default class Collaborator extends React.Component {
  static propTypes = {
    cycleUserId: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    statusText: PropTypes.string.isRequired,
    userInfo: PropTypes.object.isRequired,
    media: PropTypes.number.isRequired,
    onClickAvaliar: PropTypes.func,
    onClickGiveBack: PropTypes.func,
    onSendRH: PropTypes.func,
  };

  static defaultProps = {
    mode: "default"
  }

  state = {
    isDropdownOpen: false
  };

  static getDerivedStateFromProps(props, state) {
    state.userInfo = props.userInfo;
    state.media = props.media;
    state.status = props.status;
    state.statusText = props.statusText;
    state.cycleUserId = props.cycleUserId;
    return state;
  }

  renderStatus() {
    const { cycleUserId, status, statusText } = this.state;
    const { mode } = this.props;
    if (status !== "Finalizado" || mode !== "default") {
      return (
        <div className="send">
          <div className="send-title">status</div>
          <div className="send-status">{statusText}</div>
        </div>
      );
    }
    return (
      <div className="send">
        <ButtonCycleUser
          disabled={false}
          status="Recursos Humanos"
          cycleUserId={cycleUserId}
          renderButton={(onClick, loading, disabled) => (
            <button onClick={onClick} disabled={disabled}>{loading ? "Aguarde ..." : "Enviar para RH"}</button>
          )}
          onSuccess={this.props.onSendRH}
        />
      </div>
    );
  }

  render() {
    let userInfo = this.state.userInfo;
    let media = this.state.media;
    let status = this.state.status;
    let statusText = this.state.statusText;
    let cycleUserId = this.state.cycleUserId;
    const canGiveBack = this.props.status === 'Em Revisão';
    const canEvaluate = this.props.status === 'Em Revisão';
    return (
      <Wrapper>
        <Div>
          <User>
            <img src={Avatar} alt="Avatar" />
            <div className="data">
              <label>{userInfo.name}</label>
              <span>{userInfo.office}</span>
              <span>{userInfo.function}</span>
            </div>
          </User>
        </Div>
        <Div>

          <Buttons>
            <div className="score">
              <span>pontuação final</span>
              <p>{Number(media || 0).toFixed(2)}</p>
            </div>
            <div className="options">
              {this.renderStatus()}
              <div style={{ position: "relative", cursor: "pointer" }}>
                <div
                  className="more"
                  onClick={() =>
                    this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
                  }
                >
                  <img src={MoreIcon} alt="Mais" />
                </div>
                {this.state.isDropdownOpen && (
                  <Dropdown
                    cycleUserId={cycleUserId}
                    mode={this.props.mode}
                    status={status}
                    statusText={statusText}
                    canGiveBack={canGiveBack}
                    canEvaluate={canEvaluate}
                    onClickAgendar={() => {
                      if (!canEvaluate) return;
                      this.setState({ isDropdownOpen: false });
                      if (this.props.onClickAgendar) {
                        this.props.onClickAgendar();
                      }
                    }}
                    onClickAvaliar={() => {
                      if (!canEvaluate) return;
                      this.setState({ isDropdownOpen: false });
                      if (this.props.onClickAvaliar) {
                        this.props.onClickAvaliar();
                      }
                    }}
                    onClickGiveBack={() => {
                      if (!canGiveBack) return;
                      this.setState({ isDropdownOpen: false });
                      if (this.props.onClickGiveBack) {
                        this.props.onClickGiveBack();
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </Buttons>
        </Div>
      </Wrapper>
    );
  }
}
