import styled from 'styled-components'

export const NumericIntervalWrapper = styled.div`
  display:flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 92%;

  @media screen and (max-width: 996px){
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    > div {
      :first-child{
        color: #00468E;
        font-size: 24px;
        margin-right: 20px;
      }

      :nth-child(2) {
        font-size: 16px;
        color: #333333;

        p {
          margin: 0;
          b {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    padding: 0 15px;
    color: #00468E;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 0px;

    span {
      border-bottom: #00468E solid 1px;
      width:30%;
      text-align: center;
    }

    span.borderLeft {
      border-left: #00468E solid 1px;
    }

    span.borderRight {
      border-right: #00468E solid 1px;
    }

    span.small {
      width:10%;

    }
  }

  .header {
    display: flex;
    padding: 0 15px;
    color: #00468E;
    font-size: 12px;
    text-transform: uppercase;
    margin: 10px 0 0 0;

    span {
      border-top: #00468E solid 1px;
      border-left: #00468E solid 1px;
      border-right: #00468E solid 1px;
      width:100%;
      text-align: center;
    }
  }
`
