import React from "react";

import { Link } from "react-router-dom";
import moment from "moment";

import AuthActions from "../../../store/ducks/auth";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Sidebar, List, Logo, Wrapper, ExitDiv } from "./styles";

import LogoEmae from "../../../assets/images/logo-emae.png";
import HomeIcon from "../../../assets/images/home.svg";
import LogoffIcon from "../../../assets/images/logoff.svg";
import Logout from "../../Logout";

class Nav extends React.Component {
  render() {
    return (
      <Sidebar>
        <Logo>
          <img src={LogoEmae} alt="emae" />
        </Logo>

        <Wrapper>
          <List>
            <li>
              <Link to="/gestor">
                <img src={HomeIcon} alt="home" /> Home
              </Link>
            </li>
          </List>

          <ExitDiv>
            <Logout
              renderItem={
                <button>
                  <img src={LogoffIcon} alt="Exit" />
                  <span style={{ textTransform: "uppercase" }}>Sair</span>
                </button>
              }
            />

            <div>
              <span>RDP Manager {moment().format("YYYY.MM.DD")}</span>
            </div>
          </ExitDiv>
        </Wrapper>
      </Sidebar>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(Nav);
