import React from "react";
import { SuccessStyled } from "./styles";

export default function SuccessMsg(props) {
  return (
    <SuccessStyled>
      <span>{props.msg}</span>
    </SuccessStyled>
  );
}
