import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  DOWNLOAD_CERTIFICATE_REQUEST:
    "certificate/DOWNLOAD_REQUEST",
  DOWNLOAD_CERTIFICATE_SUCCESS:
    "certificate/DOWNLOAD_SUCCESS"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: [],
  downloading: false,
  page: 0
});

export default function certificado(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.DOWNLOAD_CERTIFICATE_REQUEST:
      return {
        ...state,
        downloading: true
    }
    case Types.DOWNLOAD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        downloading: false
      }
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  downloadCertificateRequest: id => ({
    type: Types.DOWNLOAD_CERTIFICATE_REQUEST,
    payload: { id }
  }),

  downloadCertificateSuccess: data => ({
    type: Types.DOWNLOAD_CERTIFICATE_SUCCESS,
    payload: data
  })
};
