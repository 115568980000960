import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import { SideMenuStyled } from "./styles";

export default function SideMenu({ page }) {
  return (
    <SideMenuStyled>
      <TabList className="tablist">
        <Tab className={page === 1 ? "react-tabs__tab--selected" : undefined}>
          <Link to="/dadosgerais/usuarios">Usuário</Link>
        </Tab>
        <Tab className={page === 2 ? "react-tabs__tab--selected" : undefined}>
          <Link to="/dadosgerais/cargos">Cargos</Link>
        </Tab>
        <Tab className={page === 4 ? "react-tabs__tab--selected" : undefined}>
          <Link to="/dadosgerais/justificativas">Justificativas</Link>
        </Tab>
      </TabList>
    </SideMenuStyled>
  );
}
