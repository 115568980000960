import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";

import { DetailWrapper, DetailHeader } from "./styles";
import ImgAvatar from "../../../assets/images/avatar.jpg";

class UserHeaderDetail extends React.Component {
  render() {
    let user = this.props.user || {};
    return (
      <DetailWrapper>
        <DetailHeader>
          <Link className='back-wrapper' to="/dadosgerais/usuarios">
            <MdArrowBack color='#fff' fontSize='28px' />
            <span>Lista</span>
          </Link>
          <div className='detail-info'>
            <div>
              <img src={ImgAvatar} alt='' />
            </div>

            <div>
              <span>{user.name}</span>
              <span>{user.office}</span>
              <span>{user.function}</span>
            </div>
          </div>
        </DetailHeader>
      </DetailWrapper>
    );
  }
};

UserHeaderDetail.propTypes = {
  user: PropTypes.object
};

export default UserHeaderDetail;