import React, { Fragment } from 'react';

import BackIcon from '../../../assets/images/backArrow.svg';

import { Div } from '../../../styles/global';
import { Back, Content, Input, Buttons } from './styles';
import {
  FormInputLabelled,
  FormTextArea,
} from '../../../Components/FormInputs';

const NovoEmail = () => (
  <Fragment>
    <Div>
      <Content>
        <div className="form">
          <div>
            <FormInputLabelled
              label="Nome do modelo"
              placeholder="Digite aqui..."
            />
          </div>

          <div>
            <FormInputLabelled
              label="Título do e-mail"
              placeholder="Digite aqui..."
            />
          </div>

          <div>
            <FormTextArea
              label="Corpo do e-mail"
              placeholder="Estrutura do modelo de e-mail"
            />
          </div>
        </div>
      </Content>

      <Buttons>
        <button>
          <a href="#!">cancelar</a>
        </button>
        <button>
          <a href="#!">salvar modelo</a>
        </button>
      </Buttons>
    </Div>
  </Fragment>
);

export default NovoEmail;
