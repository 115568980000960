import Immutable from "seamless-immutable";

/**
 * Types
 */
export const Types = {
  GET_REQUEST: "user/GET_REQUEST",
  GET_SUCCESS: "user/GET_SUCCESS",
  PUT_REQUEST: "user/PUT_REQUEST",
  PUT_SUCCESS: "user/PUT_SUCCESS",
  POST_REQUEST: "user/POST_REQUEST",
  POST_SUCCESS: "user/POST_SUCCESS",
  CLEAR_REQUEST: "user/CLEAR",
  GET_FAILURE: "user/GET_FAILURE"
};

/**
 * Reducer
 */
const INITIAL_STATE = Immutable({
  data: {},
  loading: false,
  success: false,
  created: false,
});

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_REQUEST:
      return { ...state, loading: true, success: false, created: false, };
    case Types.GET_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        success: true,
        created: false,
      };
    case Types.POST_REQUEST:
      return { ...state, loading: true, success: false, created: false, };
    case Types.POST_SUCCESS:
      return { ...state, loading: true, success: true, created: true, };
    case Types.PUT_REQUEST:
      return { ...state, loading: true, success: false, created: false, };
    case Types.GET_FAILURE:
      return { ...state, loading: false, success: false, created: false, };
    case Types.CLEAR_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  getUserRequest: id => ({
    type: Types.GET_REQUEST,
    payload: { id }
  }),

  getUserSuccess: data => ({
    type: Types.GET_SUCCESS,
    payload: { data }
  }),

  updateUserRequest: ({ id, data }) => ({
    type: Types.PUT_REQUEST,
    payload: { id, data }
  }),

  createUserRequest: ({ data }) => ({
    type: Types.POST_REQUEST,
    payload: { data }
  }),

  createUserSuccess: data => ({
    type: Types.POST_SUCCESS,
    payload: { data }
  }),

  clearUserRequest: () => ({
    type: Types.CLEAR_REQUEST
  }),

  setFailedUser: () => ({
    type: Types.GET_FAILURE
  })
};
