import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MdArrowBack from 'react-ionicons/lib/MdArrowBack';

import { Creators as ReasonActions } from '../../../../store/ducks/reason/reason';

import SideMenu from '../../SideMenu';
import { ContentWrapper } from '../../styles';

import {
  FormInputLabelled,
  FormTextArea,
  FormSelectLabelled,
} from '../../../../Components/FormInputs';
import FormError from '../../../../Components/Form/Error';

import {
  DetailBody,
  DetailWrapper,
  HeaderWrapper,
  BackWrapper,
} from './styles';

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
  description: Yup.string()
    .nullable()
    .required('É necessário preencher esse campo!'),
});

const initialState = {
  title: '',
  description: '',
  status: '',
  isNew: false,
};

export class DetailJustificativas extends Component {
  state = Object.assign({}, initialState);

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== 'novo') {
      this.props.getReasonRequest(id);
    } else {
      this.setState({ ...initialState, isNew: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.reason.updated && this.props.reason.updated) ||
      (!prevProps.reason.inserted && this.props.reason.inserted)
    ) {
      this.props.history.push('/dadosgerais/justificativas');
    }
  }

  handleSubmit = values => {
    if (this.state.isNew) {
      this.props.insertReasonRequest(values);
    } else {
      this.props.updateReasonRequest(values);
    }
  };

  renderForm = ({ handleSubmit, errors, handleChange, values }) => {
    const { title, description, status } = values;

    const statusValues = [
      {
        value: true,
        name: 'Ativo',
      },
      {
        value: false,
        name: 'Inativo',
      },
    ];

    return (
      <Fragment>
        {errors.title && <FormError error={errors.title} />}
        <FormInputLabelled
          label="Título"
          name="title"
          type="text"
          value={title}
          onChange={handleChange}
        />
        {errors.description && <FormError error={errors.description} />}
        <FormTextArea
          label="Descrição"
          name="description"
          value={description}
          onChange={handleChange}
        />
        {!this.state.isNew && (
          <FormSelectLabelled
            label="Status"
            name="status"
            value={status}
            onChange={handleChange}
            options={statusValues}
            style={{ maxWidth: '100px' }}
          />
        )}
        <button type="button" onClick={handleSubmit}>
          SALVAR
        </button>
      </Fragment>
    );
  };

  render() {
    return (
      <ContentWrapper>
        <SideMenu page={4} />

        <div className="content">
          <DetailBody>
            <DetailWrapper>
              <HeaderWrapper>
                <BackWrapper
                  onClick={() => this.props.history.push('/dadosgerais/justificativas')}
                >
                  <MdArrowBack color="#666" fontSize="26px" />
                  <span>VOLTAR</span>
                </BackWrapper>
              </HeaderWrapper>
              {!this.state.isNew ? (
                this.props.reason &&
                this.props.reason.success && (
                  <Formik
                    enableReinitialize
                    render={this.renderForm}
                    validationSchema={ValidationSchema}
                    onSubmit={values => this.handleSubmit(values)}
                    initialValues={{ ...this.props.reason.details }}
                  />
                )
              ) : (
                <Formik
                  enableReinitialize
                  render={this.renderForm}
                  validationSchema={ValidationSchema}
                  onSubmit={values => this.handleSubmit(values)}
                  initialValues={{ ...initialState }}
                />
              )}
            </DetailWrapper>
          </DetailBody>
        </div>
      </ContentWrapper>
    );
  }
}

const mapStateToProps = state => ({
  reason: state.reason,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...ReasonActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailJustificativas);
