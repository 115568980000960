import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Creators from "../../ducks/select/functions";

export function* requestFunctions({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.functionsClearError());
  yield put(Creators.functionsLoading());

  try {
    let url = `api/v1/select/functions`;
    if (!!text) {
      url += "?text=" + text;
    }

    let response = yield call(api.get, encodeURI(url));

    yield put(Creators.functionsSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.functionsError(error));
      }
    }
    yield put(
      Creators.functionsError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* requestResetFunctions() {
  yield put(Creators.functionsReset());
}
