import React from "react";
import PropTypes from "prop-types";

class UserInfo extends React.Component {

  componentDidMount() {
    this.props.getUserRequest(this.props.userId);
  }

  renderChildren() {
    return React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        user: this.props.user,
        userSuccess: this.props.success,
        userError: this.props.error,
        userLoading: this.props.loading
      });
    });
  }

  render() {
    return this.renderChildren();
  }
}

UserInfo.propTypes = {
  getUserRequest: PropTypes.func.isRequired,
  success: PropTypes.any,
  error: PropTypes.any,
  loading: PropTypes.bool,
  user: PropTypes.object.isRequired,
  userId: PropTypes.any.isRequired
};

export default UserInfo;