import styled from "styled-components";

export const Content = styled.div`
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);

  border-radius: 4px;
  padding: 25px 15px;

  span {
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  p {
    color: #666666;
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
    padding-left: 5px;
  }

  div label {
    color: #666666;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }
`;

export const TextArea = styled.textarea`
  border-radius: 4px;
  height: 80px;
  border: #ccc solid 1px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666666;
  width: 100%;
  min-height: 250px;
  padding: 15px;

  @media screen and (max-height: 900px) {
    min-height: 50px;
  }
`;
