import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import Creators from "../../ducks/select/organizationsUnit";

export function* requestOrganizationsUnit({ payload = {} }) {
  const { text = null } = payload;

  yield put(Creators.organizationsUnitClearError());
  yield put(Creators.organizationsUnitLoading());

  try {
    let url = `api/v1/select/organizations-unit`;
    if (!!text) {
      url += "?text=" + text;
    }

    let response = yield call(api.get, encodeURI(url));

    yield put(Creators.organizationsUnitSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(Creators.organizationsUnitError(error));
      }
    }
    yield put(
      Creators.organizationsUnitError(
        "Ocorreu um erro no servidor, tente mais tarde."
      )
    );
  }
}

export function* requestResetOrganizationsUnit() {
  yield put(Creators.organizationsUnitReset());
}
