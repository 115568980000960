import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AnswerActions from '../../../store/ducks/answer/answer';
import FindAnswerActions from '../../../store/ducks/answer/findAnswer';

import Progress from '../../UserComponents/Progress';

import { MainContent, ButtonsWrapper } from './styles';
import AvaliacaoBlock from '../../UserComponents/AvaliacaoBlock';
import NumericInterval from '../../UserComponents/AvaliacaoQuestion/NumericInterval';
import Descriptive from '../../UserComponents/AvaliacaoQuestion/Descriptive';
import SingleChoice from '../../UserComponents/AvaliacaoQuestion/SingleChoice';
import Loading from '../../Loading';
import MediaNotaBox from '../../UserComponents/MediaNotaBox';

class QuizList extends React.Component {
  static propTypes = {
    userCycle: PropTypes.object.isRequired,
  };

  state = {
    disabled: true,
    stageIndex: 0,
    userCycle: null,
    currentStage: null,
    currentBlock: null,
    currentBlockIndex: null,
    goPreviousBlock: false,
    goNextBlock: false,
    goSendQuestion: false,
    answers: [],
  };

  get finalMedia() {
    let stages = this.state.userCycle.stages;
    let questions = [];
    for (let i = 0; i < stages.length; i++) {
      let stage = stages[i];
      let blocks = stage.blocks;
      for (let k = 0; k < blocks.length; k++) {
        let block = blocks[k];
        let blockQuestions = block.questions.filter(
          q => q.type == 'numeric_interval'
        );
        questions = questions.concat(blockQuestions);
      }
    }
    if (questions.length === 0) {
      return null;
    }
    let answers = this.state.findAnswers;
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id == answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id == answer.question_interval_numerics_id
      );
      return parseInt(answer.value);
    });
    if (grades.length == 0) {
      return 0;
    }
    let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
    return Number(sum / questions.length).toFixed(2);
  }

  static getDerivedStateFromPropsGoPreviousBlock(props, state) {
    state.goPreviousBlock = false;
    let nextCurrentBlockIndex = state.currentBlockIndex - 1;
    let block = state.blocks[nextCurrentBlockIndex];
    if (block) {
      state.currentBlockIndex = nextCurrentBlockIndex;
      state.answers = [];
    } else {
      let nextStageIndex = state.stageIndex - 1;
      let nextStage = state.userCycle.stages[nextStageIndex];
      if (!nextStage) {
        return;
      }
      state.stageIndex = nextStageIndex;
      state.currentBlockIndex = nextStage.blocks.length - 1;
    }
  }

  static getDerivedStateFromPropsGoNextBlock(props, state) {
    state.goNextBlock = false;
    let stage = props.userCycle.stages[state.stageIndex];
    let block = stage.blocks[state.currentBlockIndex + 1];
    if (block) {
      state.currentBlockIndex = state.currentBlockIndex + 1;
      state.answers = [];
    } else {
      let nextStageIndex = state.stageIndex + 1;
      let nextStage = state.userCycle.stages[nextStageIndex];
      if (!nextStage) {
        return;
      }
      state.stageIndex = nextStageIndex;
      state.currentBlockIndex = 0;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.userCycle) {
      return state;
    }

    state.findAnswers = props.userCycle.answers;
    state.userCycle = props.userCycle;

    state.ready = true;
    state.notFound = false;
    if (
      state.userCycle.stages instanceof Array &&
      state.userCycle.stages.length == 0
    ) {
      state.ready = false;
      state.notFound = true;
      return state;
    }

    if (state.currentBlock === null) {
      state.goNextBlock = true;
      state.currentBlockIndex = -1;
    }
    if (state.goNextBlock) {
      QuizList.getDerivedStateFromPropsGoNextBlock(props, state);
    }
    if (state.goPreviousBlock) {
      QuizList.getDerivedStateFromPropsGoPreviousBlock(props, state);
    }
    let stage = state.userCycle.stages[state.stageIndex];
    state.currentStage = stage;
    if (stage) {
      state.blocks = stage.blocks;
    }
    let block = state.blocks[state.currentBlockIndex];
    if (block) {
      state.currentBlock = block;
    }
    return state;
  }

  getDefaultValueToNumericIntervalQuestion = question => {
    let answer = this.state.findAnswers.find(
      answer => answer.question_id == question.id
    );
    if (answer) {
      let questionIntervalNumeric = question.questionsIntervalNumeric.find(
        q => q.id == answer.question_interval_numerics_id
      );
      if (questionIntervalNumeric) {
        return questionIntervalNumeric;
      }
    }
    return null;
  };

  getDefaultValueToSingleChoiceQuestion = question => {
    let answer = this.state.findAnswers.find(
      answer => answer.question_id == question.id
    );
    if (answer) {
      let questionsSingleChoice = question.questionsSingleChoice.find(
        q => q.id == answer.question_single_choices_id
      );
      if (questionsSingleChoice) {
        return questionsSingleChoice;
      }
    }
    return null;
  };

  getDefaultValueToDescriptiveQuestion = question => {
    let answer = this.state.findAnswers.find(
      answer => answer.question_id == question.id
    );
    return answer ? answer : null;
  };

  getAnwersFormattedToAPI = () => {
    return this.state.currentBlock.questions
      .map(question => {
        let answer = this.state.answers.find(
          answer => answer.question_id == question.id
        );
        if (!answer) {
          answer = this.state.findAnswers.find(
            answer => answer.question_id == question.id
          );
        }
        if (!answer) {
          return null;
        }
        let value = null;
        switch (question.type) {
          case 'numeric_interval':
            value = answer.question_interval_numerics_id;
            break;
          case 'single_choice':
            value = answer.question_single_choices_id;
            break;
          default:
            value = answer.text;
        }
        return {
          question_id: answer.question_id,
          answer: value,
        };
      })
      .filter(answer => answer);
  };

  onPreviousBlock = () => {
    this.setState({ goPreviousBlock: true });
  };

  onNextBlock = () => {
    return this.setState({ goNextBlock: true });
  };

  renderMedia = () => {
    let block = this.state.currentBlock;
    let questions = block.questions.filter(q => q.type == 'numeric_interval');
    if (questions.length === 0) {
      return null;
    }
    let answers = this.getAnwersFormattedToAPI();
    let grades = answers.map(answer => {
      let question = questions.find(q => q.id == answer.question_id);
      if (!question) {
        return null;
      }
      answer = question.questionsIntervalNumeric.find(
        q => q.id == answer.answer
      );
      if (!answer) {
        return 0;
      }
      return parseInt(answer.value);
    });
    let media = Number(0);
    if (grades.length > 0) {
      let sum = grades.reduce((rate1, rate2) => rate1 + rate2);
      media = Number(sum / questions.length);
    }
    return <MediaNotaBox nota={media.toFixed(2)} />;
  };

  renderCurrentBlock = () => {
    let block = this.state.currentBlock;
    if (block.type == 'resultado' || block.type == 'result') {
      return <MediaNotaBox nota={this.finalMedia} />;
    }
    return (
      <Fragment key={block.id}>
        <AvaliacaoBlock title={block.title} description={block.description} />
        {block.questions.map((question, i) => this.renderQuestion(question, i))}
        {this.renderMedia()}
      </Fragment>
    );
  };

  renderQuestion = (question, i) => {
    return (
      <Fragment key={`question-${question.id}`}>
        {question.type === 'numeric_interval' &&
          this.renderNumericIntervalQuestion(question, i)}
        {(question.type === 'descriptive' || question.type === 'text') &&
          this.renderDescriptiveQuestion(question, i)}
        {question.type === 'single_choice' &&
          this.renderSingleChoiceQuestion(question, i)}
      </Fragment>
    );
  };

  renderNumericIntervalQuestion = (question, i) => {
    let options = {};
    let option = this.getDefaultValueToNumericIntervalQuestion(question);
    if (option) {
      options.notaUser = option.value;
    }
    return (
      <Fragment>
        <NumericInterval
          disabled={this.state.disabled}
          options={question.questionsIntervalNumeric}
          question={question}
          number={i + 1}
          key={i}
          {...options}
        />
      </Fragment>
    );
  };

  renderSingleChoiceQuestion = (question, i) => {
    let options = {};
    let defaultChoice = this.getDefaultValueToSingleChoiceQuestion(question);
    if (defaultChoice) {
      options.defaultChoice = defaultChoice;
    }
    return (
      <Fragment>
        <SingleChoice
          disabled={this.state.disabled}
          question={question}
          key={i}
          {...options}
        />
      </Fragment>
    );
  };

  renderDescriptiveQuestion = (question, i) => {
    let options = {};
    let answer = this.getDefaultValueToDescriptiveQuestion(question);
    if (answer) {
      options.defaultText = answer.text;
    }
    return (
      <Fragment>
        <Descriptive
          disabled={this.state.disabled}
          question={question}
          key={i}
          {...options}
        />
      </Fragment>
    );
  };

  renderButtonBack = () => {
    const { stageIndex, currentBlockIndex } = this.state;
    return (
      <button
        disabled={stageIndex == 0 && currentBlockIndex == 0}
        onClick={this.onPreviousBlock}
      >
        Anterior
      </button>
    );
  };

  renderButtonNext = () => {
    let block = this.state.currentBlock;
    let disabled = false;
    if (block) {
      //disabled = this.getAnwersFormattedToAPI().length != block.questions.length;
    }
    return (
      <button onClick={this.onNextBlock} disabled={disabled}>
        Próximo
      </button>
    );
  };

  render() {
    const { currentStage, ready, notFound } = this.state;
    if (!ready && !notFound) {
      return <Loading loading={true} />;
    }
    if (!ready && notFound) {
      return (
        <Loading
          text="Não foram encontradas os formulários da avaliação"
          loading={true}
        />
      );
    }
    return (
      <Fragment>
        <Progress
          stages={this.props.userCycle.stages}
          activeStageId={currentStage.id}
        />
        <MainContent>
          {this.renderCurrentBlock()}
          <ButtonsWrapper>
            {this.renderButtonBack()}
            {this.renderButtonNext()}
          </ButtonsWrapper>
        </MainContent>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AnswerActions, ...FindAnswerActions }, dispatch);

const mapStateToProps = state => ({
  requestAnswer: state.answer,
  findAnswer: state.findAnswer,
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
