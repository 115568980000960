import React, { Component } from 'react'
import { HeaderMenuStyled } from './styles';
import { Link } from 'react-router-dom'

export default class HeaderMenu extends Component {
  render() {
    const { active, id } = this.props;
    const TodosActiveClass = active === 1 ? 'active' : null;
    const InProgressActiveClass = active === 2 ? 'active' : null;
    const FinishedActiveClass = active === 3 ? 'active' : null;

    return (
      <HeaderMenuStyled>
        <ul>
          <li className={TodosActiveClass}>
            <Link to={`/usuario/cursos/categoria/${id}`}>
              Todos
            </Link>
          </li>
          <li className={InProgressActiveClass}>
            <Link to={`/usuario/cursos/categoria/${id}/em-andamento`}>
              Em andamento
            </Link>
          </li>
          <li className={FinishedActiveClass}>
            <Link to={`/usuario/cursos/categoria/${id}/concluidos`}>
              Concluídos
            </Link>
          </li>
        </ul>
      </HeaderMenuStyled>
    )
  }
}
