import styled, { css } from "styled-components";

export const Nav = styled.nav`
  display: flex;
  min-height: 100%;
  width: 230px;
  position: absolute;
  flex-direction: column;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding-top: 8px;
  z-index: 500;
  justify-content: space-between;

  background-color: #00468e;

  @media screen and (max-width: 768px) {
    display: ${props => (props.isActive ? `block` : `none`)};
  }
`;
export const Notification = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .pic-progress-wrapper {
    position: relative;
  }

  .profile-pic {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .progress-wrapper {
    width: 75px;
    height: 75px;
  }

  img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
  }

  span {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    margin-top: 10px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    font-weight: 600;
    color: #fff;
  }
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .ProgressBalls {
      position: relative;
      height: 100%;
      .Risco {
        width: 1px;
        background-color: #fff;
        height: 100%;
        position: absolute;
        left: 25px;
      }
    }
  }

  .text {
    span {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
`;

export const Bola = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: #ccc solid 1px;

  ${props =>
    props.isActive &&
    css`
      background-color: #fff;
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .carregando {
    color: white;
    text-align: center;
  }
`;

export const MenuListSidenav = styled.div`
  margin-top: 30px;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    li {
      margin-left: 20px;
      :not(:last-child) {
        margin-bottom: 20px;
      }

      &.active {
        background-color: #009b4c;
        padding: 10px 0 10px 10px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-left: 10px;
        a:hover {
          cursor: default;
          opacity: 1;
        }
      }

      a {
        display: flex;
        align-items: center;
        :hover {
          text-decoration: none;
          opacity: 0.8;
        }
        :active,
        :focus {
          text-decoration: none;
        }
        svg {
          path,
          g {
            fill: #fff;
          }
        }

        span {
          font-size: 14px;
          color: #fff;
          margin-left: 20px;
        }
      }
    }
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;

  button {
    border: 0;
    border-radius: 4px;
    background-color: #0e5198;
    /* width: 160px;
    height: 30px; */
    padding: 10px 30px;

    text-transform: uppercase;
    color: ${props =>
      props.isButtonActive ? "#fff" : "rgba(255,255,255,0.3)"};
    cursor: ${props => (props.isButtonActive ? "pointer" : "default")};
    font-size: 12px;
    line-height: 22px;

    ${props =>
      props.isActive &&
      css`
        color: #fff;
      `}
  }
`;

export const FooterItensWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BtnExitWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  cursor: pointer;
  margin-top: 50px;
  width: 100px;
  :hover {
    opacity: 0.8;
  }
  span {
    color: #fff;
    font-size: 14px;
    margin-left: 15px;
  }
`;

export const Logo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0 30px;
  img {
    width: 100px;
  }
`;

export const DpLogo = styled.div`
  margin: 0 15px 0 15px;
  display: flex;
  min-height: 30px;
  align-items: center;
  justify-content: space-between;

  div img {
    height: 17px;
    width: 17px;
    margin-right: 2px;
  }

  span {
    font-size: 10px;
    color: #999999;
    line-height: 14px;
  }
`;

export const ItemSidenav = styled.div`
  display: flex;
  padding-right: 25px;
  min-height: 40px;
  position: relative;
  padding-bottom: 25px;
  cursor: pointer;
  :hover {
    .right-side {
      span {
        opacity: 0.8;
      }
    }
  }

  .left-side {
    padding-left: 23px;
    margin-right: 25px;

    .ball-wrapper {
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      z-index: 1;
      position: absolute;
      .ball {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        border-radius: 50%;
        padding: 0;
        background-color: ${props => (props.isActive ? "#fff" : "#00468E")};
      }
    }

    .stroke {
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: rgba(255, 255, 255, 0.6);
      top: 0;
      left: 32px;
      z-index: 0;
    }
  }

  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;

    span {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 19px;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  :first-child {
    .stroke {
      top: 20px;
    }
  }

  :last-child {
    .stroke {
      height: 0;
    }
  }

  /* ${props =>
    props.pageAtual === "avaliacaoDesempenho" &&
    css`
      :first-child {
        .left-side {
          .ball-wrapper {
            border: 1px solid #fff;
            .ball {
              background-color: #fff;
            }
          }
          .stroke {
            background-color: #fff;
          }
        }
      }
    `} */
`;
