import styled from "styled-components";

export const NumbersWrapper = styled.div`
  ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0 15px;

    li {
      flex: 1;
      padding: 3%;
      background: #e5ecf3;
      color: #00468e;
      border: 1px solid #00468e;
      cursor: pointer;
      text-align: center;
      font-size: 24px;
      font-weight: 600;

      :not(:last-child) {
        border-right: 0;
      }

      :hover {
        opacity: 0.8;
      }

      &.active-user {
        background-color: #195899;
        color: #fff;
      }

      &.active-reviewer {
        background-color: #009b4c;
        color: #fff;
      }
    }
  }
`;
