import { call, put } from "redux-saga/effects";

import api from "../../../services/api";
import UserInfoActions from "../../ducks/user/userInfo";

export function* getUserInfo() {
  yield put(UserInfoActions.userInfoLoading());
  try {
    const response = yield call(api.get, "/api/v1/user-info/me");
    let details = response.data.details;
    localStorage.setItem("@emae:userinfo", JSON.stringify(details));
    yield put(UserInfoActions.userInfoSuccess(details));
  } catch (err) {
    yield put(UserInfoActions.userInfoError());
    if (!!err.response && err.response.status === 401) {
      yield put(UserInfoActions.userInfoError(err.response.data.messages[0]));
    } else {
      yield put(UserInfoActions.userInfoError("ERRO"));
    }
  }
}
