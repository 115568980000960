import React, { Component } from 'react';
import moment from 'moment';

import {
  TableDiv,
  Thead,
  Tbody,
  Tr,
} from '../../../../../Components/Table/styles';

export default class TableNaoSelecionados extends Component {
  handleFormCheck = (e, uid) => {
    this.props.formChecked(e.target.value, e.target.checked, uid);
  };

  render() {
    const { formularios: forms } = this.props;
    if (forms.length === 0) {
      return null;
    }

    return (
      <TableDiv>
        <Thead>
          <Tr>
            <th>ID</th>
            <th>Título</th>
            <th>Descrição</th>
            <th>Tipo</th>
            <th>Criado em</th>
            <th> </th>
          </Tr>
        </Thead>
        <Tbody>
          {forms.map(form => (
            <Tr key={form.uid}>
              <td>{form.id}</td>
              <td>
                <span>{form.title}</span>
              </td>
              <td>{form.description}</td>
              <td>{form.type}</td>
              <td>{moment(form.created_at).format('DD/MM/YYYY')}</td>
              <td>
                <input
                  type="checkbox"
                  value={form.id}
                  onChange={e => this.handleFormCheck(e, form.uid)}
                  defaultChecked={this.props.isChecked}
                  id={`input-nao-selecionado-${form.id}`}
                />
              </td>
            </Tr>
          ))}
        </Tbody>
      </TableDiv>
    );
  }
}
