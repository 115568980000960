import React, { Component } from "react";
import { SingleChoiceWrapper } from "./styles";
import SingleChoiceDefault from './SingleChoiceDefault';
import SingleChoiceRevision from './SingleChoiceRevision';

export default class SingleChoice extends Component {

  /*
    {!isReviewer && cycleUserStatus == "Em Revisão Colaborador" ? (
      <NumbersRevisao
        defaultNumUser={this.props.notaUser}
        defaultNumReviewer={this.props.notaReviewer}
        disabled={this.props.disabled}
        options={this.props.options}
        onChange={this.props.onChange}
      />
    ) : (
      <Numbers
        disabled={this.props.disabled}
        isReviewer={this.props.isReviewer}
        defaultNumUser={this.props.notaUser}
        defaultNumReviewer={this.props.notaReviewer}
        options={this.props.options}
        onChange={this.props.onChange}
      />
    )}
  */

  renderDefault() {
    return (
      <SingleChoiceDefault
        question={this.props.question}
        disabled={this.props.disabled}
        isReviewer={this.props.isReviewer}
        defaultChoice={this.props.defaultChoice}
        defaultChoiceReviewer={this.props.defaultChoiceReviewer}
        options={this.props.options}
        onChange={this.props.onChange}
      />
    );
  }

  renderRevision() {
    return (
      <SingleChoiceRevision
        question={this.props.question}
        disabled={this.props.disabled}
        isReviewer={this.props.isReviewer}
        defaultChoice={this.props.defaultChoice}
        defaultChoiceReviewer={this.props.defaultChoiceReviewer}
        options={this.props.options}
        onChange={this.props.onChange}
      />
    );
  }

  render() {
    const { question, isReviewer, cycleUserStatus } = this.props;
    const showRevision = !isReviewer && cycleUserStatus == "Em Revisão Colaborador";
    return (
      <SingleChoiceWrapper>
        <div className="header">
          <p>
            <b>{question.title}:</b> {question.description}
          </p>
        </div>
        <div className="choices">
          {showRevision ? this.renderRevision() : this.renderDefault()}
        </div>
      </SingleChoiceWrapper>
    );
  }
}
