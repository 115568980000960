import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FunctionsActions from '../../store/ducks/select/functions';
import SelectWithPopupRedux from '../SelectWithPopupRedux';

const mapDispatchToProps = dispatch => bindActionCreators({
  request: FunctionsActions.functionsRequest,
  clear: FunctionsActions.functionsResetRequest,
}, dispatch);

const mapStateToProps = state => ({
  error: state.functions.error,
  success: state.functions.success,
  loading: state.functions.loading,
  data: state.functions.data
});

const Component = connect(mapStateToProps, mapDispatchToProps)(SelectWithPopupRedux);

Component.defaultProps = {
  placeholder: 'Função'
};

export default Component;
