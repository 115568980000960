import React from "react";

import { Button } from "./styles";

const DrawerToggleButton = props => (
  <Button onClick={props.click}>
    <img src={props.img} alt="menu" />
  </Button>
);

export default DrawerToggleButton;
