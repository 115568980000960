import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as CategoriesActions } from '../../../ducks/modules/courses/categories';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* getCategories() {
  try {
    const response = yield call(api.get, '/api/v1/categories');

    yield put(CategoriesActions.getCategoriesSuccess(response.data.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
