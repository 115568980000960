import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  z-index: 1;
`;

export const Content = styled.div`
  display: block;
  flex-direction: column;

  margin: 8px 25px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  padding: 16px 32px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 8px;

    span {
      color: #999999;
      line-height: 22px;
      font-size: 16px;
    }

    img {
      height: 20px;
      width: 20px;
    }
  }
`;

export const Back = styled.div`
  display: flex;
  align-items: center;

  span {
    text-transform: uppercase;
    color: #666666;
    font-size: 14px;
    line-height: 19px;
  }

  img {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  label {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    text-transform: uppercase;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border: #ccc 1px solid;
    border-radius: 5px;
    padding: 0 5px;

    input {
      border: 0;
      width: 280px;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }

  p {
    margin-top: 5px;
    margin-left: 10px;
    color: #666666;
    font-size: 14px;
    line-height: 19px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    button {
      width: 160px;
      height: 40px;
      border: 0;
      border-radius: 5px;
      background-color: #00468e;
      margin-top: 40px;

      a {
        text-decoration: none;
        color: #fff;

        background-color: #00468e;
        height: 40px;
        width: 160px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 5px;
        font-family: "Open Sans";
      }
    }
  }

  .questao {
    display: flex;
    align-items: center;
    height: 200px;

    span {
      text-transform: uppercase;
      color: #00468e;
      line-height: 33px;
      font-size: 24px;
      font-weight: 600;
      float: left;
      margin-right: 5px;
    }

    p {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;
