import React, { Component, Fragment } from "react";

export default class SingleChoiceDefault extends Component {
  state = {
    choiceSelected: null,
    reviewChoiceSelected: null
  };

  handleClickChoice = (e, choice) => {
    if (this.props.disabled) {
      return null;
    }
    if (this.props.isReviewer) {
      this.setState({ reviewChoiceSelected: choice });
    } else {
      this.setState({ choiceSelected: choice });
    }
    if (this.props.onChange) {
      this.props.onChange(choice);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (state.choiceSelected === null && props.defaultChoice) {
      state.choiceSelected = props.defaultChoice;
    }
    if (state.reviewChoiceSelected === null && props.defaultChoiceReviewer) {
      state.reviewChoiceSelected = props.defaultChoiceReviewer;
    }
    return state;
  }

  renderSingleChoice = (choice, i) => {
    const {
      choiceSelected,
      reviewChoiceSelected
    } = this.state;
    let className = "";
    if (choiceSelected && choiceSelected.id == choice.id) {
      className = "active"
    } else if (reviewChoiceSelected && reviewChoiceSelected.id == choice.id) {
      className = "active-reviwer"
    }
    return (
      <div
        key={i}
        className={className}
        onClick={e => this.handleClickChoice(e, choice)}
      >
        {choice.title}
      </div>
    );
  }

  render() {
    const { question } = this.props;
    return (
      <Fragment>
        {question.questionsSingleChoice.map(this.renderSingleChoice)}
      </Fragment>
    );
  }


}
