import styled from "styled-components";

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  button {
    width: 160px;
    height: 40px;
    border: 0;
    border-radius: 5px;
    background-color: #00468e;
    padding: 5px;

    :first-child {
      background-color: #999999;
      margin-right: 50px;
    }
      text-transform: uppercase;
      color: #fff;

    &[disabled] {
      background-color: #ccc
    }
  }
`;
