import styled from 'styled-components';

export const SelectDiv = styled.div`
  label {
    color: #666666;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
  }
  > div {
  }
  > button {
    margin-top: 10px;
    width: min-content;
    font-size: 14px;
    color: #fff;
    background-color: #00468e;
    padding: 5px 30px;
    border: 0;
    border-radius: 5px;
    :hover {
      opacity: 0.8;
    }
  }
`;

export const Selects = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;

  .selects {
    flex: 1;
    :not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .selects {
      margin-bottom: 10px;
      flex: 1;
      :not(:last-child) {
        margin-right: 0;
      }
    }
  }
`;
