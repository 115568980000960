import styled from 'styled-components'

export const CicloDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;

  > span {
    color: #999999;
    font-size: 16px;
    margin-bottom: 20px;
  }
`