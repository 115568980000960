import styled, { css } from "styled-components";

export const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ul {
    width: 100%;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
      display: flex;

      p {
        text-transform: uppercase;
        color: #00468e;
        display: none;
      }

      .active {
        display: flex;
      }

      span {
        color: #999;
      }
    }
  }
`;

export const ProgressBarWrapper = styled.div`
  display: block;
  width: 100%;
  position: relative;

  > div {
    position: absolute;
  }
  .progress-back {
    width: 100%;
    height: 1px;
    background-color: #00468e;
  }

  .progress-fill {
    height: 5px;
    background-color: #009B4C;
    width: ${props => props.progress}%;
    top: -2px;
  }
`;

export const ProgressIndicator = styled.div`
  display: flex;
  width: 100%;

  ol {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    justify-content: space-around;
    list-style: upper-roman;

    li {
      font-size: 14px;
      color: #999999;

      &.active {
        text-transform: uppercase;
        color: #00468e;
        font-weight: bold;
      }
    }
  }
`
