import api from '../../../../services/api';
import { call, put } from 'redux-saga/effects';

import { Creators as CoursesActions } from '../../../ducks/modules/courses/courses';
import { Creators as ErrorActions } from '../../../ducks/error';

export function* getCourses(action) {
  try {
    let { categoryId, order, text } = action.payload;
    let url = '/api/v1/offers?category_id=' + categoryId;
    if (!!order) {
      url += '&order=' + order;
    }
    if (!!text) {
      url += '&title=' + text;
    }
    const response = yield call(api.get, url);

    yield put(CoursesActions.getCoursesSuccess(response.data.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
