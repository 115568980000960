import styled from "styled-components";

export const Content = styled.div`
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  img {
    height: 25px;
    width: 25px;
  }

  span {
    color: #00468e;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    margin-left: 5px;
  }
`;
