import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Actions from '../../store/ducks/select/organizationsUnit';
import SelectWithPopupRedux from '../SelectWithPopupRedux';

const mapDispatchToProps = dispatch => bindActionCreators({
  request: Actions.organizationsUnitRequest,
  clear: Actions.organizationsUnitResetRequest,
}, dispatch);

const mapStateToProps = state => ({
  error: state.organizationsUnit.error,
  success: state.organizationsUnit.success,
  loading: state.organizationsUnit.loading,
  data: state.organizationsUnit.data
});

const Component = connect(mapStateToProps, mapDispatchToProps)(SelectWithPopupRedux);

Component.defaultProps = {
  placeholder: 'Unidade Organizacional'
};

export default Component;
