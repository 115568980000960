import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;

    span {
      color: #00468e;
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    align-items: center;

    img {
      height: 130px;
      width: 130px;
    }

    span {
      margin-top: 15px;
      color: #00468e;
      font-size: 16px;
      line-height: 22px;
      text-transform: uppercase;
    }
  }

  .text {
    display: flex;
    width: 100%;

    p {
      color: #666666;
      font-size: 14px;
      line-height: 19px;
      width: 90%;
      margin: 30px auto;

      b {
        color: #009B4C;
        :last-child {
          color: #00468e;
        }
      }
    }
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      height: 40px;
      padding: 10px;
      width: 300px;
      background-color: #00468e;
      border-radius: 4px;
      border: 0;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      text-align: center;

      :hover {
        opacity: 0.8;
      }

      :last-child {
        background-color: #999999;
        margin-top: 25px;
      }
    }
  }

  .notas {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 50px;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      :first-child {
        margin-right: 20px;
      }

      > span {
        font-size:14px;
        color: #666666;
        margin-bottom: 10px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .bloco {
        width: 90px;
        color: #fff;
        background-color: #00468e;
        padding: 5px;

        .number {
          background-color: #195899;
          height: 85px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: bold;
        }

        &.revisada {
          background-color: #009B4C;

          .number {
            background-color: #19A55E;
          }
        }

      }
    }

  }
`;
