import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Creators as ParticipantReportActions } from '../../ducks/reports/participantReport';

export function* requestParticipantReport({ payload }) {
  const { page, filters = {} } = payload;
  yield put(ParticipantReportActions.participantReportClear());

  try {
    let url = `api/v1/admin/cycles/report/participant`;
    url += '?page=' + page;
    if (!!filters.name) {
      url += '&text=' + filters.name;
    }
    if (!!filters.registration) {
      url += '&registration=' + filters.registration;
    }
    if (!!filters.role) {
      url += '&role=' + filters.role;
    }

    let response = yield call(api.get, url);
    yield put(ParticipantReportActions.participantReportSuccess(response.data));
  } catch (error) {
    if (!!error.request) {
      let request = error.request;
      if (request.status === 400) {
        let response = JSON.parse(request.response);
        let error = response.messages[0];
        return yield put(
          ParticipantReportActions.participantReportError(error)
        );
      }
    }
    yield put(
      ParticipantReportActions.participantReportError(
        'Nenhum colaborador encontrado com essas informações'
      )
    );
  }
}
