import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import CycleQuizzesActions from '../../../store/ducks/cycle/cycleQuizzes';
import { Creators as TemplateQuizzesActions } from '../../../store/ducks/quiz/templateQuizzes';
import CycleQuizzesFormActions from '../../../store/ducks/cycle/cycleQuizzesForm';
import { Creators as CycleActions } from '../../../store/ducks/cycle';

import { Content, InfoDiv, /*SearchBox,*/ Itens } from './styles';

import Back from '../../../Components/Back';

import { Div } from '../../../styles/global';

import SubHeader from '../../../Components/SubHeader';

// import SearchIcon from '../../../assets/images/search.svg';

import TableNaoSelecionados from './components/FormularioTable/tableNaoSelecionados';
import TableSelecionados from './components/FormularioTable/tableSelecionados';
import Loading from '../../../Components/Loading';
import ErrorMsg from '../../../Components/ErrorMsg';

class CycleQuizzes extends React.Component {
  state = {
    cycleId: null,
    ready: false,
    itemsSelected: [],
    items: [],
    modelsSelected: [],
    models: [],
  };

  submit = () => {
    let data = {
      cycleId: this.state.cycleId,
      templates_to_add: this.state.itemsSelected
        .filter(item => item.type === 'modelo' && item.id)
        .map(item => item.id),
      forms_to_remove: this.state.models
        .filter(item => item.type === 'formulário' && item.id)
        .map(item => item.id),
    };

    this.props.cycleQuizzesFormRequest(data);
  };

  handleFormChecked = (val, checked, uid) => {
    this.selectForm(uid);
  };

  selectForm = uid => {
    let newModels = this.state.models.filter(model => model.uid === uid);
    this.setState({
      itemsSelected: [...this.state.itemsSelected, ...newModels],
      models: [...this.state.models.filter(model => model.uid !== uid)],
    });
  };

  unselectForm = (uid, type) => {
    let newModels = this.state.itemsSelected.filter(model => model.uid !== uid);

    this.setState({
      itemsSelected: newModels,
      models: [
        ...this.state.models,
        ...this.state.itemsSelected.filter(model => model.uid === uid),
      ],
    });
  };

  handleUncheckSelected = (val, checked, uid, type) => {
    this.unselectForm(uid, type);
  };

  redirectEditForm = id => {
    return this.props.history.push(`/formularios/${id}/dados`);
  };

  renderSelecionados = () => {
    if (this.props.cycleQuizzesForm.loading) {
      return <Loading loading={true} />;
    }
    return (
      <TableSelecionados
        formularios={this.state.itemsSelected}
        formChecked={this.handleUncheckSelected}
        isChecked={true}
        redirectEditForm={this.redirectEditForm}
      />
    );
  };

  renderNaoSelecionados = () => {
    if (this.props.templateQuizzes.loading) {
      return <Loading loading={true} />;
    }
    return (
      <TableNaoSelecionados
        formularios={this.getItemsNotSelected()}
        formChecked={this.handleFormChecked}
        isChecked={false}
      />
    );
  };

  getItemsNotSelected = () => {
    let items = this.state.models.filter(item => {
      let filter = this.state.modelsSelected.filter(
        modelSelected => modelSelected.id === item.id
      );
      return filter.length === 0;
    });
    return items;
  };

  componentDidUpdate(prevProps) {
    const { cycleQuizzesForm, cycleQuizzes, templateQuizzes, cycle } = this.props;
    if (!prevProps.templateQuizzes.success && templateQuizzes.success) {
      this.setState({
        models: [
          ...templateQuizzes.data.map((template, index) => {
            template.uid = index + '_model';
            template.type = 'modelo';
            return template;
          }),
        ],
      });
    }
    if (
      (!prevProps.cycleQuizzes.error && cycleQuizzes.error) ||
      (!prevProps.cycleQuizzes.success && cycleQuizzes.success)
    ) {
      this.setState({ ready: true });
    }
    if (!prevProps.cycleQuizzesForm.success && cycleQuizzesForm.success) {
      toast.success(cycleQuizzesForm.success);
      this.setState({ fetch: true });
    }
    if (this.state.fetch) {
      this.setState({ fetch: false });
      this.props.cycleQuizzesRequest(this.state.cycleId);
      this.props.getTemplateQuizzesRequest({ cycleId: this.state.cycleId, status: 'Ativo' });
    }
    if (!prevProps.cycleQuizzes.success && cycleQuizzes.success) {
      this.setState({
        itemsSelected: cycleQuizzes.quizzes.has.map((form, index) => {
          let newObj = Object.assign({}, form);
          newObj.uid = index + '_form';
          newObj.type = 'formulário';
          return newObj;
        }),
      });
    }
    if (!prevProps.cycle.success && cycle.success) {
      this.props.setTitle(`Ciclo - ${cycle.data.title}`);
    }
  }

  componentDidMount() {
    let params = this.props.match.params;
    if (!params || !params.id || !params.id.match(/^[0-9]+$/)) {
      return this.setState({ ready: true });
    }
    this.props.getCycleRequest(params.id);
    this.setState({ cycleId: params.id, models: [], fetch: true });
  }

  render() {
    if (!this.state.ready) {
      return <Loading loading={true} />;
    }
    if (this.props.cycleQuizzes.error) {
      return <ErrorMsg msg={this.props.cycleQuizzes.error} />;
    }
    const { itemsSelected } = this.state;
    const itemsNotSelected = this.getItemsNotSelected();
    return (
      <Fragment>
        <Div>
          <Back path="/ciclos" title="Voltar para listagem" />
          <SubHeader cycle={{ id: this.state.cycleId }} page="formulario" />
          <InfoDiv>
            <span>
              Selecione um ou mais modelos de formulários que farão parte do
              ciclo
            </span>
          </InfoDiv>
          <Content>
            {/* <label>selecionar formulário</label>
            <SearchBox>
              <input type="text" placeholder="Buscar" />
              <img src={SearchIcon} alt="pesquisar" />
            </SearchBox> */}
            <div className="itensButton">
              <span>
                {itemsSelected.length === 0
                  ? 'Nenhum formulário vinculado'
                  : null}
                {itemsSelected.length === 1 ? '1 formulário vinculado' : null}
                {itemsSelected.length > 1
                  ? `${itemsSelected.length} formulários vinculados`
                  : null}
              </span>
              <button
                disabled={this.props.cycleQuizzesForm.loading}
                onClick={this.submit}
              >
                <a href="#!">Salvar</a>
              </button>
            </div>

            <div className="selected">{this.renderSelecionados()}</div>
          </Content>
          <Content style={{ margin: '4px 0 10px' }}>
            <Itens marginBottom={itemsNotSelected.length !== 0}>
              {itemsNotSelected.length === 0
                ? 'Nenhum modelo disponível'
                : null}
              {itemsNotSelected.length === 1 ? '1 modelo' : null}
              {itemsNotSelected.length > 1
                ? `${itemsNotSelected.length} modelos de formulários`
                : null}
            </Itens>
            {this.renderNaoSelecionados()}
          </Content>
        </Div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...CycleQuizzesActions,
      ...TemplateQuizzesActions,
      ...CycleQuizzesFormActions,
      ...CycleActions,
    },
    dispatch
  );

const mapStateToProps = state => ({
  cycleQuizzes: state.cycleQuizzes,
  templateQuizzes: state.templateQuizzes,
  cycleQuizzesForm: state.cycleQuizzesForm,
  cycle: state.cycle,
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleQuizzes);
