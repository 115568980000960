import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Box, SpaceBetween } from "../../../../../styles/global";
import { StatusText } from "./styles";

export default class UserCycleInfo extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    userCycle: PropTypes.object.isRequired
  };

  render() {
    let userCycle = this.props.userCycle;
    let date = moment(userCycle.completed_form_at);
    return (
      <Box>
        <h3>{this.props.title}</h3>
        <SpaceBetween>
          {userCycle.completed_form_at ? (<div>Preenchido em {date.format("DD/MM/YYYY")}</div>) : (<div>Não Preenchido</div>)}
          <StatusText>{this.props.userCycle.status}</StatusText>
        </SpaceBetween>
      </Box>
    );
  }
}
