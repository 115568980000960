import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as OfferActions } from "../../../../../store/ducks/modules/courses/offer";
import { Creators as CertificateActions } from "../../../../../store/ducks/modules/courses/certificate";

import CourseHeader from "../../../../../Components/Modules/Courses/Headers/CourseHeader";

import {
  CourseInfoWrapper,
  CourseContentWrapper,
  CourseContentTopicsWrapper,
  CourseCommentariesWrapper
} from "./styles";
import { BoxWhiteRounded } from "../../../../../styles/global";
import CourseContentItem from "./components/CourseContentItem";
import { CourseContentTopicsList } from "./components/CourseContentItem/styles";
import ItemsCarousel from "react-items-carousel";
import Commentary from "./components/Commentary";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";

class Course extends Component {
  state = {
    activeItemIndex: 0
  };

  componentDidMount() {
    const {
      match: {
        params: { id, idMatricula = null }
      },
      getOfferRequest
    } = this.props;

    getOfferRequest(id, idMatricula);
  }

  static getDerivedStateFromProps(props, state) {
    let arr = [];
    const {
      offer: {
        data: { course }
      }
    } = props;

    course &&
      course.courseRatings[0].ratingAnswers.map(comment => {
        arr.push(<Commentary key={comment.id} comment={comment} />);
      });
    state.children = arr;

    return state;
  }

  changeActiveItem = activeItemIndex => this.setState({ activeItemIndex });

  renderCommentariesArrow = isReverse => (
    <div className="arrow-wrapper">
      {!isReverse ? (
        <IosArrowForward fontSize="18px" color="#fff" />
      ) : (
        <IosArrowBack fontSize="18px" color="#fff" />
      )}
    </div>
  );

  calcProgress = (enrollId, topics) => {
    let completed = 0;
    if (topics && topics.length > 0) {
      for (let i = 0; i < topics.length; i++) {
        if (topics[i].scormUser) {
          if (
            topics[i].scormUser.enrollment_id === enrollId &&
            (topics[i].scormUser.session_status === "completed" ||
              topics[i].scormUser.session_status === "passed")
          ) {
            completed++;
          }
        }
      }
      return (completed / topics.length) * 100;
    }
    return 0;
  };

  verifyTopicCompleted(enrollId, topic) {
    if (topic.scormUser) {
      if (
        enrollId === topic.scormUser.enrollment_id && (
          topic.scormUser.session_status === "completed" ||
          topic.scormUser.session_status === "passed"
        )
      ) {
        return true;
      }
    }
    return false;
  }

  getRealizacoes(course, offerId) {
    let oferta = course.offers.filter(item => {
      return item.id === parseInt(offerId) ? item : null;
    });

    if (oferta[0] && oferta[0].__meta__) {
      return oferta[0].__meta__.count_enrollments;
    }

    return 0;
  }

  checkActiveEnrollment = status => {
    const { offer } = this.props;
    if (!offer.currentEnrollment) {
      return false;
    }

    switch (status) {
      case "success":
        return !offer.loading && offer.currentEnrollment.end;

      case "inprogress":
        return !offer.loading && !offer.currentEnrollment.end;

      default:
        return false;
    }
  };

  renderCertificate = () => {
    const { certificate } = this.props.offer.currentEnrollment || {};
    const certificateDownload = this.props.certificate;

    if (certificate) {
      return (
        <a
          href="#!"
          onClick={() => this.props.downloadCertificateRequest(certificate.id)}
        >
          {certificateDownload.downloading ? "Salvando..." : "Download"}
        </a>
      );
    }

    return <span>Indisponível</span>;
  };

  render() {
    const { activeItemIndex, children } = this.state;
    const {
      offer,
      offer: {
        data: { course },
        currentEnrollment
      }
    } = this.props;

    if (offer.loading || !course) {
      return null;
    }
    return (
      <Fragment>
        <CourseHeader
          history={this.props.history}
          rating={course.rating}
          labelTitle={course.category.title}
          title={course.title}
          offer={offer.data}
          enrollmentSuccess={this.checkActiveEnrollment("success")}
          enrollmentProgress={this.checkActiveEnrollment("inprogress")}
        />

        <CourseInfoWrapper>
          <BoxWhiteRounded>
            <span>Progresso</span>
            <span>{currentEnrollment ? this.calcProgress(currentEnrollment.id, course.topics) : 0}%</span>
          </BoxWhiteRounded>
          <BoxWhiteRounded>
            <span>Carga Horária</span>
            <span>{course.duration}</span>
          </BoxWhiteRounded>
          <BoxWhiteRounded>
            <span>Realizações</span>
            <span>
              {this.getRealizacoes(course, this.props.match.params.id)}
            </span>
          </BoxWhiteRounded>
          <BoxWhiteRounded>
            <span>Certificado</span>
            {this.renderCertificate()}
          </BoxWhiteRounded>
        </CourseInfoWrapper>

        <CourseContentWrapper>
          <div>
            <h1>Sobre este curso</h1>
            <p>{course.description}</p>
          </div>

          <div>
            <h1>Conteúdo do curso</h1>

            <CourseContentTopicsWrapper>
              <CourseContentTopicsList>
                {course.topics.map((item, i) => (
                  <CourseContentItem
                    titulo={item.title}
                    duracao={0}
                    isFeito={currentEnrollment ? this.verifyTopicCompleted(currentEnrollment.id, item) : false}
                    topicLink={
                      currentEnrollment ?
                      `/usuario/curso/conteudo/${course.id}/oferta/${
                        offer.data.id
                      }/matricula/${currentEnrollment.id}/topico/${item.id}/rating/${
                        course.courseRatings[0].id
                      }/rever/${currentEnrollment.end ? 'true' : 'false'}`
                      :
                      '#!'
                    }
                    key={i}
                  />
                ))}
              </CourseContentTopicsList>
            </CourseContentTopicsWrapper>
          </div>
        </CourseContentWrapper>

        <CourseCommentariesWrapper>
          <h1>Depoimentos</h1>
          <div className="carousel">
            <ItemsCarousel
              // Placeholder configurations
              enablePlaceholder
              numberOfPlaceholderItems={5}
              minimumPlaceholderTime={1000}
              placeholderItem={<span>Nenhum depoimento</span>}
              // Carousel configurations
              numberOfCards={1}
              gutter={12}
              showSlither={false}
              firstAndLastGutter={true}
              freeScrolling={false}
              // Active item configurations
              requestToChangeActive={this.changeActiveItem}
              activeItemIndex={activeItemIndex}
              activePosition={"center"}
              chevronWidth={24}
              rightChevron={this.renderCommentariesArrow()}
              leftChevron={this.renderCommentariesArrow(true)}
              outsideChevron={true}
            >
              {children}
            </ItemsCarousel>
          </div>
        </CourseCommentariesWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  offer: state.offer,
  certificate: state.certificate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...OfferActions, ...CertificateActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Course);
