import { call, put } from "redux-saga/effects";
import api from "../../../services/api";
import ImportActions from "../../ducks/user/importUpload";

export function* importRequest(file) {
  yield put(ImportActions.importLoading(true));
  let callback = window.location.origin + "/definir-senha";
  let formData = new FormData();
  formData.append("file", file.file);
  formData.append("callback", callback);
  try {
    const response = yield call(
      api.post,
      "/api/v1/admin/import-users",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
    if (response.status === 200) {
      yield put(ImportActions.importSuccess(response.data));
    } else {
      yield put(ImportActions.importError(true));
    }
  } catch (error) {
    yield put(ImportActions.importError(true));
  }
}
