import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgressbar from 'react-circular-progressbar';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  Nav,
  Notification,
  Profile,
  Content,
  Button,
  ItemSidenav,
  DpLogo,
  Logo,
  BtnExitWrapper,
  FooterItensWrapper,
  MenuListSidenav,
} from './styles';

import AvatarIcon from '../../../assets/images/avatar.jpg';
import EmaeLogo from '../../../assets/images/logo-emae-branca.png';
import DpIcon from '../../../assets/images/dpLogo.svg';
import ImgExit from '../../../assets/images/ico-sair.svg';
import UserCycleActions from '../../../store/ducks/cycle/userCycle';
import 'react-circular-progressbar/dist/styles.css';
import { IconNotifications, IconUsers, IconQuestionary } from '../../SVGs';

class UserNav extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('@emae:userinfo'));
    this.props.userCycleRequest();
    this.state = {
      user,
      isNavActive: false,
      pageAtual: 'avaliacaoDesempenho',
      stage: null,
      showMyCycleMenu: false,
    };
  }

  renderBalls() {
    let nivelStatus = 0;
    if (this.props.userCycle.userCycle) {
      nivelStatus = this.props.userCycle.userCycle.statusLevel;
    }
    return (
      <Content>
        <ul>
          <ItemSidenav isActive={nivelStatus >= 1 ? true : false}>
            <div className="left-side">
              <div className="ball-wrapper">
                <div className="ball" />
              </div>
              <div className="stroke" />
            </div>
            <div className="right-side">
              <span>AVALIAÇÃO DO EMPREGADO</span>
            </div>
          </ItemSidenav>
          <ItemSidenav isActive={nivelStatus >= 2 ? true : false}>
            <div className="left-side">
              <div className="ball-wrapper">
                <div className="ball" />
              </div>
              <div className="stroke" />
            </div>
            <div className="right-side">
              <span>Avaliação do gestor</span>
            </div>
          </ItemSidenav>
          <ItemSidenav isActive={nivelStatus >= 3 ? true : false}>
            <div className="left-side">
              <div className="ball-wrapper">
                <div className="ball" />
              </div>
              <div className="stroke" />
            </div>
            <div className="right-side">
              <span>Ciência do empregado</span>
            </div>
          </ItemSidenav>
          <ItemSidenav isActive={nivelStatus >= 4 ? true : false}>
            <div className="left-side">
              <div className="ball-wrapper">
                <div className="ball" />
              </div>
              <div className="stroke" />
            </div>
            <div className="right-side">
              <span>Concluído</span>
            </div>
          </ItemSidenav>
        </ul>
        <Button>
          <button>enviar avaliação</button>
        </Button>
      </Content>
    );
  }

  render() {
    const { userCycle, location, hasSubordinates } = this.props;
    const percentage = '0';
    const isActive = this.props.isActive;

    const MySubordinatesActiveClass =
      location === '/gestor' || location.split('/')[2] === 'ciclo-usuario'
        ? 'active'
        : null;
    const NotificationsActiveClass =
      location === '/gestor/notificacao' ||
      location.split('/')[2] === 'notificacao'
        ? 'active'
        : 'null';
    const MyCycleActiveClass =
      location === '/user/stage' || location === '/user/home' ? 'active' : null;
    const CoursesActiveClass =
      location === '/usuario/cursos' || location.split('/')[2] === 'cursos'
        ? 'active'
        : null;

    return (
      <Nav isActive={isActive}>
        <div>
          <div>
            <Notification />
            <Profile>
              <div className="pic-progress-wrapper">
                <div className="progress-wrapper">
                  <CircularProgressbar
                    styles={{
                      path: {
                        stroke: '#fff',
                        strokeLinecap: 'butt',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                      },
                      trail: {
                        stroke: 'transparent',
                      },
                    }}
                    counterClockwise
                    percentage={percentage}
                  />
                </div>
                <div className="profile-pic">
                  <img src={AvatarIcon} alt="avatar" />
                </div>
              </div>
              {this.state.user ? (
                <Fragment>
                  <span>{this.state.user.name}</span>
                  <p>{this.state.user.office}</p>
                </Fragment>
              ) : (
                <div />
              )}
            </Profile>
          </div>

          <MenuListSidenav>
            <ul>
              {hasSubordinates && (
                <Fragment>
                  <li className={MySubordinatesActiveClass}>
                    <Link to="/gestor">
                      <IconUsers />
                      <span>Meus Colaboradores</span>
                    </Link>
                  </li>
                  <li className={NotificationsActiveClass}>
                    <Link to="/gestor/notificacao">
                      <IconNotifications />
                      <span>Notificações</span>
                    </Link>
                  </li>
                </Fragment>
              )}

              {/* <li className={CoursesActiveClass}>
                <Link to="/usuario/cursos">
                  <IosSchoolOutline color="#fff" fontSize="28px" />
                  <span>Cursos</span>
                </Link>
              </li> */}

              <li className={MyCycleActiveClass}>
                <Link to="/user/home">
                  <IconQuestionary />
                  <span>Minha Avaliação</span>
                </Link>
              </li>
            </ul>
          </MenuListSidenav>

          {MyCycleActiveClass === 'active'
            ? userCycle.error
              ? null
              : userCycle.success
              ? this.renderBalls()
              : 'Carregando...'
            : null}
        </div>

        <FooterItensWrapper>
          <BtnExitWrapper>
            <Link to="/logout">
              <img src={ImgExit} alt="Sair" />
              <span>Sair</span>
            </Link>
          </BtnExitWrapper>

          <Logo>
            <img src={EmaeLogo} alt="emae" />
          </Logo>

          <DpLogo>
            <div>
              <img src={DpIcon} alt="Digital Pages" />
              <span>Digital Pages</span>
            </div>
            <span>{moment().format('YYYY.MM.DD')}</span>
          </DpLogo>
        </FooterItensWrapper>
      </Nav>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...UserCycleActions }, dispatch);

const mapStateToProps = state => ({ userCycle: state.userCycle });

export default connect(mapStateToProps, mapDispatchToProps)(UserNav);
