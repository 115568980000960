import styled from "styled-components";

export const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h1 {
    height: 30px;
    color: #333333;
    font-family: "Open Sans";
    font-size: 22px;
    line-height: 30px;
    text-transform: uppercase;
  }

  @media (min-width: 768px) {
    height: ${props => props.removeHeight ? '0' : '70px'}
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Notification = styled.img`
  height: 24px;
  width: 22px;
  cursor: pointer;
  margin-right: 25px;
`;

export const Avatar = styled.img`
  height: 42px;
  width: 42px;
  border-radius: 50%;

  border: #00468e solid 1px;
`;
