import React, { Component } from "react";
import Switch from "react-switch";

export default class SwitchExample extends Component {
  constructor(props) {
    super(props);
    this.state = { checked: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.props.checked(checked);
  }

  render() {
    return (
      <label htmlFor="normal-switch">
        <Switch
          onChange={this.handleChange}
          checked={this.state.checked}
          onColor="#00468E"
          id="normal-switch"
          uncheckedIcon={false}
          checkedIcon={false}
        />
      </label>
    );
  }
}
