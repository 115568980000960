import Immutable from 'seamless-immutable';

/**
 * Types
 */
export const Types = {
  SET: 'success/SET',
  HIDE: 'success/HIDE',
};

/**
 * Reducer
 */
const initialState = Immutable({
  visible: false,
  message: null,
  toasted: false
});

export default function success(state = initialState, action) {
  switch (action.type) {
    case Types.SET:
      const { message, toasted } = action.payload;
      return { ...state, visible: true, message, toasted };
    case Types.HIDE:
      return { ...state, visible: false, toasted: false };
    default:
      return state;
  }
}

/**
 * Actions
 */
export const Creators = {
  setSuccess: (message, options) => ({
    type: Types.SET,
    payload: { message, ...options },
  }),

  hideSuccess: () => ({ type: Types.HIDE }),
};
