import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import UserCycleActions from "../../../store/ducks/cycle/userCycle";
import UpdateCycleUserStatusActions from "../../../store/ducks/cycleUser/updateCycleUserStatus";

import Loading from "../../../Components/Loading";

import QuizForm from "../../../Components/Quiz/Form";

class UserDetail extends React.Component {
  static propTypes = {
    userCycle: PropTypes.object.isRequired,
    userCycleRequest: PropTypes.func.isRequired,
    userCycleResetRequest: PropTypes.func.isRequired
  };

  state = {
    redirect: false,
    ready: false,
    stageIndex: 0,
    cycleUserId: null
  };

  get media() {
    let blocks = this.state.participantDetail.blocks;
    if (blocks.length === 0) {
      return 0;
    }
    let pontuation = blocks
      .map(block => Number(block.avg))
      .reduce((v1, v2) => v1 + v2);
    return Number(pontuation / blocks.length);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.match.params.id) {
      state.stageIndex = props.match.params.id - 1;
    }
    if (props.currentCycle && props.currentCycle.currentCycle) {
      state.cycleUserId = props.currentCycle.currentCycle.id;
    }
    if (props.userCycle.success || props.userCycle.error) {
      if (props.userCycle.success) {
        state.userCycle = props.userCycle.userCycle;
        state.ready = true;
      }
    }
    return state;
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  componentDidMount() {
    this.props.updateCycleUserStatusRequestReset();
    this.props.userCycleRequest();
  }

  componentWillUnmount() {
    this.props.userCycleResetRequest();
  }

  renderQuiz = () => {
    const { userCycle } = this.state;
    let options = {
      cycleUserId: userCycle.id,
      status: userCycle.status,
      stages: userCycle.stages,
      answersUser: userCycle.answers,
      answersReviewer: userCycle.answersReviewer,
      isReviewer: false
    };
    return (
      <QuizForm
        {...options}
        updateUserCycle={() => this.props.userCycleRequest(userCycle.id)}
        onComplete={() => {
          this.setState({ redirect: true });
        }}
      />
    );
  };

  render() {
    let { ready } = this.state;
    if (!ready) {
      return <Loading loading={true} />;
    }
    if (this.state.redirect) {
      return <Redirect to="/user/send" />;
    }
    return <Fragment>{this.renderQuiz()}</Fragment>;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...UserCycleActions,
      ...UpdateCycleUserStatusActions
    },
    dispatch
  );

const mapStateToProps = state => ({
  updateCycleUserStatus: state.updateCycleUserStatus,
  userCycle: state.userCycle,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetail);
