import styled from "styled-components";

export const ResultadoFimMiddleWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 50px auto;
  display: flex;
  flex-direction: column;

  .btns {
    margin-top: 50px;
    display: flex;
    flex-direction: column;

    a {
      width: 100%;
      border-radius: 5px;
      padding: 10px;
      text-align: center;
      background-color: #00468e;
      color: #fff;

      :nth-child(2) {
        background-color: #999;
        margin-top: 20px;
        text-transform: uppercase;
      }

      :hover {
        opacity: 0.8;
        text-decoration: none;
      }
    }
  }
`;
