import styled, { css } from 'styled-components'

export const HeaderStyled = styled.div`
  .label-title {
    font-size: 12px;
    color: ${props => props.isCourse ? '#aaa' : '#ccc'};
    margin-bottom: 5px;
    display: block;


  }

  .title-wrapper {
    display: flex;
  }

  .search-icon-wrapper {
      cursor: pointer;
      margin-left: 20px;
      :hover {
        opacity: 0.8;
      }

      &--back {
        cursor: pointer;
        margin-left: 0px;
      }
    }

  h1 {
    color: #00468e;
    font-size: 24px;
    margin: 0;
    font-family: "OS Bold", sans-serif;
  }

  .hidden-search-wrapper {
    display: flex;
    align-items: center;

    input {
      flex: 1;
      margin-left: 15px;
      border: 0;
      font-size: 18px;
      color: #595959;
      font-family: "OS Bold", sans-serif;

      ::placeholder {
        color: #ccc;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  ${props => props.isCourse && css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .access-course-wrapper {
      a {
        background-color: #009B4C;
        color: #fff;
        padding: 12px 20px;
        border-radius: 5px;

        :hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }

  `}
`

export const HeaderCourseContentStyled = styled.header`
  display: flex;
  min-height: 50px;
  user-select: none;
  > div {
    display: flex;
    align-items: center;

    &.active {
      background-color: #009B4C;
      svg path {
        fill: #fff;
      }
    }
  }
  > div:nth-child(1), > div:nth-child(2) {
    border-right: 1px solid #DDD;
    padding: 0 15px;
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
  }

  .title-wrapper {
    padding-left: 15px;
    flex: 1;
    h1 {
      margin: 0;
      color: #535353;
      font-size: 18px;
      font-family: 'OS Bold', sans-serif;
    }
  }
`

export const DropdownMenuContent = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  background-color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
  min-width: 300px;

  .header {
    padding: 15px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;

    span {
      color: #009B4C;
      font-size: 12px;
    }
  }

  .body {
    padding: 15px 20px;
    ul {
      margin:0;
    }
  }

`
