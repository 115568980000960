import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  answersRequest: ["cycleUserId", "answers"],
  answersResetRequest: null,
  answersSuccess: ["success"],
  answersError: ["error"],
  answersClearError: null,
  answersReset: null,
  answersLoading: null
});

export const AnswerTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  answers: [],
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { success }) => {
  return state.merge({
    error: false,
    loading: false,
    success
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    answer: [],
    loading: false,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ANSWERS_SUCCESS]: success,
  [Types.ANSWERS_ERROR]: error,
  [Types.ANSWERS_CLEAR_ERROR]: clearError,
  [Types.ANSWERS_LOADING]: loading,
  [Types.ANSWERS_RESET]: reset
});
