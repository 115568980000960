import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

export default class CourseContentItem extends Component {
    render() {
        const { titulo, duracao, isFeito, topicLink } = this.props
        return (
            <li>
                <div className='marker-wrapper'>
                    <div className='marker' style={!isFeito ? { backgroundColor: '#D8D8D8' } : { backgroundColor: '#00468e' }}></div>
                    <div className='reta'></div>
                </div>
                <div className='info-and-seta-wrapper'>
                    <div className='info-wrapper'>
                        <span>{titulo}</span>
                        <span>Duração: {duracao} min</span>
                    </div>

                    <div className='seta-wrapper'>
                        <Link to={topicLink}>
                            <IosArrowForward color='#00468e' fontSize='24px' />
                        </Link>
                    </div>
                </div>
            </li>
        )
    }
}
