import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

import {
  convertCycleStatus,
  getLevelStatus
} from "../../../services/cycle/status";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  userCycleRequest: ["userCycleId"],
  userCycleResetRequest: null,
  userCycleReset: null,
  userCycleSuccess: ["userCycle"],
  userCycleError: ["error"],
  userCycleClearError: null,
  userCycleLoading: null
});

export const UserCycleTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  userCycle: null,
  error: false,
  success: false,
  loading: false
});

/* Reducers */

export const success = (state, { userCycle }) => {
  return state.merge({
    userCycle: {
      ...userCycle,
      statusText: convertCycleStatus(userCycle.status),
      statusLevel: getLevelStatus(userCycle.status)
    },
    error: false,
    loading: false,
    success: true
  });
};

export const error = (state, { error }) => {
  return state.merge({
    error,
    success: false,
    loading: false
  });
};

export const clearError = state => {
  return state.merge({
    error: false,
    loading: false,
    success: false
  });
};

export const reset = state => {
  return state.merge({
    userCycle: null,
    error: false,
    loading: false,
    success: false
  });
};

export const loading = state => {
  return state.merge({
    loading: true,
    error: false,
    success: false
  });
};

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.USER_CYCLE_SUCCESS]: success,
  [Types.USER_CYCLE_ERROR]: error,
  [Types.USER_CYCLE_CLEAR_ERROR]: clearError,
  [Types.USER_CYCLE_LOADING]: loading,
  [Types.USER_CYCLE_RESET]: reset
});
