import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { TableDefault, Thead, Tbody, Tr } from '../../../../Components/Table/styles';
import ImgAvatar from '../../../../assets/images/avatar.jpg';

export default class TableUsuario extends Component {
  render() {
    const { usuarios } = this.props;
    return (
      <Fragment>
        <TableDefault>
          <Thead>
            <Tr>
              <th></th>
              <th>ID</th>
              <th>Nome</th>
              <th>Matrícula</th>
              <th>Função</th>
            </Tr>
          </Thead>
          <Tbody>
            {usuarios.data.map(u => (
              <Tr key={u.id}>
                <td>
                  <img src={ImgAvatar} alt={u.name} />
                </td>
                <td>{u.id}</td>
                <td>
                  <Link to={`/dadosgerais/usuarios/${u.id}`}>{u.name}</Link>
                </td>
                <td>{u.registration}</td>
                <td>{u.function}</td>
              </Tr>
            ))}
          </Tbody>
        </TableDefault>
      </Fragment>
    );
  }
}
