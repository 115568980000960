import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RecuperarSenhaWrapper, LeftSide, RightSide } from './styles';
import Brand from '../../assets/images/logo-emae.png';

import ForgotPasswordActions from '../../store/ducks/forgotPassword';
import ErrorMsg from '../../Components/ErrorMsg';
import SuccessMsg from '../../Components/SuccessMsg';
import Loading from '../../Components/Loading';

class RecuperarSenha extends Component {
  static propTypes = {
    forgotPasswordRequest: PropTypes.func.isRequired,
  };

  state = {
    success: false,
    username: '',
  };

  handleSubmitForm = e => {
    e.preventDefault();
    const { username } = this.state;
    const { forgotPasswordRequest } = this.props;
    this.setState({ success: false });
    forgotPasswordRequest(username);
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isLoading = () => {
    return this.props.forgotPassword.loading;
  };

  renderError = () => {
    if (!this.props.forgotPassword.error) {
      return null;
    }
    return <ErrorMsg msg={this.props.forgotPassword.error} />;
  };

  renderSuccess = () => {
    if (!this.props.forgotPassword.success) {
      return null;
    }
    return (
      <SuccessMsg
        msg={'Solicitação realizada com sucesso, verifique seu e-mail.'}
      />
    );
  };

  renderLoading = () => {
    return <Loading loading={this.isLoading()} />;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.forgotPassword.success && !state.success) {
      state.username = '';
      state.success = true;
    }
    return state;
  }

  render() {
    let username = this.state.username;
    return (
      <RecuperarSenhaWrapper>
        <LeftSide>
          <img src={Brand} alt="" />
          <p>Gestão de ciclos e avaliações de desempenho</p>
        </LeftSide>

        <RightSide>
          <form onSubmit={this.handleSubmitForm}>
            <h3>Recuperar Senha</h3>
            {this.renderError()}
            {this.renderLoading()}
            {this.renderSuccess()}
            <div>
              <label htmlFor="username">E-mail ou Matrícula</label>
              <input
                type="text"
                name="username"
                id="username"
                value={username}
                onChange={this.handleInputChange}
              />
            </div>
            <div>
              <button disabled={this.isLoading()} type="submit">
                Recuperar senha
              </button>
            </div>
            <a href="/">Voltar para a tela inicial</a>
          </form>
        </RightSide>
      </RecuperarSenhaWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...ForgotPasswordActions }, dispatch);

const mapStateToProps = state => ({ forgotPassword: state.forgotPassword });

export default connect(mapStateToProps, mapDispatchToProps)(RecuperarSenha);
