import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';

import { Creators as QuizActions } from '../../ducks/quiz/quiz';
import { Creators as QuizRolesActions } from '../../ducks/quiz/quizRoles';
import { Creators as ErrorActions } from '../../ducks/error';
import { Creators as SuccessActions } from '../../ducks/success';

export function* getQuiz(action) {
  let withRoles = '';
  if (action.payload.roles) {
    withRoles = '?roles=true';
  }
  try {
    const response = yield call(
      api.get,
      `/api/v1/admin/quizzes/${action.payload.id}` + withRoles
    );
    yield put(QuizActions.getQuizSuccess(response.data));
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* updateQuiz(action) {
  const { id, notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.put, `/api/v1/admin/quizzes/${id}`, data);
    yield put(QuizActions.updateQuizSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Formulário atualizado com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* insertQuiz(action) {
  const { notify = true, ...data } = action.payload;
  try {
    const response = yield call(api.post, '/api/v1/admin/quizzes/', data);
    yield put(QuizActions.insertQuizSuccess(response.data.details));
    yield put(
      SuccessActions.setSuccess('Formulário incluído com sucesso!', {
        toasted: notify,
      })
    );
  } catch (err) {
    if (err.response.status === 400) {
      yield put(ErrorActions.setError(err.response.data.messages[0]));
    } else if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* defineRoles(action) {
  const { id, role_ids } = action.payload;
  try {
    const response = yield call(
      api.post,
      `/api/v1/admin/quiz/${id}/define-roles`,
      { role_ids: role_ids }
    );
    yield put(QuizRolesActions.updateQuizRolesSuccess(response.data.details));
    if (response.data.details.type !== 'denied') {
      yield put(
        SuccessActions.setSuccess('Destinatários atualizados com sucesso!', {
          toasted: true,
        })
      );
    }
  } catch (err) {
    if (err.response.status === 403) {
      yield put(
        ErrorActions.setError(err.response.data.messages[0], { toasted: true })
      );
    } else {
      yield put(
        ErrorActions.setError(
          'Um erro ocorreu, por favor tente novamente mais tarde.'
        )
      );
    }
  }
}
