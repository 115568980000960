import React from 'react';
import { MainBox, BoxWrapper } from './styles';

export default class Dashboard extends React.Component {
  state = {
    loading: true,
  };

  getProcessos(item, index) {
    if (item.type === 'processos') {
      if (item.status == 'Não iniciaram o preenchimento') {
        return (
          <div className="box-item long-name" key={index}>
            <span className="box-title">{item.status}</span>
            <span className="amount">{item.count}</span>
          </div>
        );
      }
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  getRevisoes(item, index) {
    if (item.type === 'revisao') {
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  getExclusoes(item, index) {
    if (item.type === 'exclusao') {
      return (
        <div className="box-item" key={index}>
          <span className="box-title">{item.status}</span>
          <span className="amount">{item.count}</span>
        </div>
      );
    }
  }

  componentDidMount() {
    if (this.props.currentCycle) {
      this.setState({ loading: false });
    }
  }

  render() {
    const { currentCycle } = this.props;

    if (!currentCycle || this.state.loading) {
      return <div>Carregando...</div>;
    }

    return (
      <MainBox>
        <BoxWrapper>
          <span className="title">processos</span>
          <div className="boxes">
            {!currentCycle.dashboard
              ? 'Não computado'
              : currentCycle.dashboard.map((item, index) =>
                  this.getProcessos(item, index)
                )}
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <span className="title">revisões</span>
          <div className="boxes">
            {!currentCycle.dashboard
              ? 'Não computado'
              : currentCycle.dashboard.map((item, index) =>
                  this.getRevisoes(item, index)
                )}
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <span className="title">exclusões</span>
          <div className="boxes">
            {!currentCycle.dashboard
              ? 'Não computado'
              : currentCycle.dashboard.map((item, index) =>
                  this.getExclusoes(item, index)
                )}
          </div>
        </BoxWrapper>
      </MainBox>
    );
  }
}
