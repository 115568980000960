import styled from "styled-components";

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 16px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  margin-top: 10px;

  min-height: 600px;
  background-color: #fff;
`;

export const Preview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    color: #00468e;
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
  }

  p {
    margin-top: 5px;
    margin-left: 10px;
    color: #666666;
    font-size: 14px;
    line-height: 19px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 160px;
      height: 40px;
      border: 0;
      border-radius: 5px;
      background-color: #00468e;
      margin-top: 40px;

      a {
        text-decoration: none;
        color: #fff;

        background-color: #00468e;
        height: 40px;
        width: 160px;
        font-size: 16px;
        line-height: 22px;
        border-radius: 5px;
        font-family: "Open Sans";
      }
    }
  }

  .questao {
    display: flex;
    align-items: center;
    min-height: 100px;

    span {
      text-transform: uppercase;
      color: #00468e;
      line-height: 33px;
      font-size: 24px;
      font-weight: 600;
      float: left;
      margin-right: 5px;
    }

    p {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
    }
  }
`;
