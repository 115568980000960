import React, { Component } from 'react';
import { DropdownStyled } from './styles';
import MdCreate from 'react-ionicons/lib/MdCreate';
import MdRefresh from 'react-ionicons/lib/MdRefresh';
import MdCalendar from 'react-ionicons/lib/MdCalendar';
import MdDownload from 'react-ionicons/lib/MdDownload';

import QuizDownload from '../../../Quiz/Download';
import ButtonCycleUser from '../../../ButtonCycleUser';

export default class Dropdown extends Component {
  renderButtonDownload() {
    if (
      !this.props.cycleUserId ||
      this.props.statusText.toLowerCase() !== 'concluído'
    ) {
      return null;
    }
    return (
      <QuizDownload
        cycleUserId={this.props.cycleUserId}
        renderButton={(onClick, isLoading) => {
          return (
            <li>
              <a href="#!" onClick={onClick}>
                <MdDownload fontSize="26px" color="#666" />
                <span>{isLoading ? 'Aguarde ...' : 'Baixar avaliação'}</span>
              </a>
            </li>
          );
        }}
      />
    );
  }

  renderAdmin() {
    const { status, cycleUserId, onClickGiveBack } = this.props;
    return (
      <DropdownStyled>
        <ul>
          <li>
            <a href="#!" className="inactive">
              <MdCreate fontSize="26px" color="#d5d5d5" />
              <span>Avaliar</span>
            </a>
          </li>
          <li>
            <ButtonCycleUser
              disabled={!this.props.canGiveBack}
              status="Em Revisão Colaborador"
              cycleUserId={cycleUserId}
              renderButton={(onClick, loading, disabled) => (
                <a
                  href="#!"
                  className={disabled || loading ? 'inactive' : ''}
                  onClick={onClick}
                >
                  <MdRefresh
                    fontSize="26px"
                    color={disabled || loading ? '#d5d5d5' : '#666'}
                  />
                  <span>{loading ? 'Aguarde ...' : 'Devolver'}</span>
                </a>
              )}
              onSuccess={onClickGiveBack}
            />
          </li>
          <li>
            <a href="#!" className="inactive">
              <MdCalendar fontSize="26px" color="#d5d5d5" />
              <span>Alterar data de entrega</span>
            </a>
          </li>
          {this.renderButtonDownload()}
          <li>
            <a href="#!" className="inactive">
              <MdCalendar fontSize="26px" color="#666" color="#d5d5d5" />
              <span>Agendar Revisão Conjunta</span>
            </a>
          </li>
        </ul>
      </DropdownStyled>
    );
  }

  render() {
    if (this.props.mode === 'admin') {
      return this.renderAdmin();
    }
    return (
      <DropdownStyled>
        <ul>
          <li onClick={this.props.onClickAvaliar}>
            <a href="#!" className={!this.props.canEvaluate ? 'inactive' : ''}>
              <MdCreate
                fontSize="26px"
                color={!this.props.canEvaluate ? '#d5d5d5' : '#666'}
              />
              <span>Avaliar</span>
            </a>
          </li>
          <li>
            <ButtonCycleUser
              disabled={!this.props.canGiveBack}
              status="Em Revisão Colaborador"
              cycleUserId={this.props.cycleUserId}
              renderButton={(onClick, loading, disabled) => (
                <a
                  href="#!"
                  className={disabled || loading ? 'inactive' : ''}
                  onClick={onClick}
                >
                  <MdRefresh
                    fontSize="26px"
                    color={disabled || loading ? '#d5d5d5' : '#666'}
                  />
                  <span>{loading ? 'Aguarde ...' : 'Devolver'}</span>
                </a>
              )}
              onSuccess={this.props.onClickGiveBack}
            />
          </li>
          <li>
            <a href="#!" className="inactive">
              <MdCalendar fontSize="26px" color="#d5d5d5" />
              <span>Alterar data de entrega</span>
            </a>
          </li>
          {this.renderButtonDownload()}
          <li onClick={this.props.onClickAgendar}>
            <a href="#!" className={!this.props.canEvaluate ? 'inactive' : ''}>
              <MdCalendar
                fontSize="26px"
                color={!this.props.canEvaluate ? '#d5d5d5' : '#666'}
              />
              <span>Agendar Revisão Conjunta</span>
            </a>
          </li>
        </ul>
      </DropdownStyled>
    );
  }
}
