import styled from "styled-components";

export const ModalInner = styled.div``;
export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border-bottom: 2px solid #eee;

  > span {
    font-size: 18px;
    color: #666666;
    text-transform: uppercase;
  }

  div {
    display: flex;
    align-items: center;

    span {
      color: #4d4d4d;
      font-size: 16px;
      margin-right: 10px;
    }
  }
`;

export const ParagrafoInfo = styled.div`
  width: 100%;
  margin: 20px 0 30px;

  p {
    text-align: center;
    color: #4d4d4d;
    font-size: 16px;
  }
`;

export const TableWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export const TrJustificativa = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eee;
  padding: 20px 20px;
  margin-bottom:10px;

  h3 {
    color: #666666;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 0;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content:center;
  margin: 20px 0;

  button {
    padding: 10px 25px;
    border-radius: 5px;
    color: #fff;
    background-color:#999999;
    text-transform: uppercase;
    border:0;
    :hover {
      opacity: 0.8;
    }

    &.primary {
      background-color: #00468E;
      margin-left: 15px;
    }
  }
`
