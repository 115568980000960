import React from 'react';

import { Link } from 'react-router-dom';

import { Menu, Ul } from './styles';

class SubHeader extends React.Component {
  state = {
    active: false,
  };

  renderNavItem = (name, to, isActive) => {
    if (!this.props.cycle) {
      return (
        <li className={isActive ? 'active' : ''}>
          <a href="#!">{name}</a>
        </li>
      );
    }
    return (
      <li className={isActive ? 'active' : ''}>
        <Link to={to}>{name}</Link>
      </li>
    );
  };

  render() {
    const { page } = this.props;
    const cycle = this.props.cycle;
    const id = cycle ? cycle.id : 'novo';
    return (
      <Menu>
        <Ul>
          <li className={page === 'dados' ? 'active' : ''}>
            <Link to={`/ciclos/${id}/dados`}>Dados</Link>
          </li>
          {this.renderNavItem(
            'Formulários',
            `/ciclos/${id}/formularios`,
            page === 'formulario'
          )}
          {this.renderNavItem(
            'Status',
            `/ciclos/${id}/status`,
            page === 'status'
          )}
          {this.renderNavItem(
            'Participantes',
            `/ciclos/${id}/participantes`,
            page === 'participantes'
          )}
        </Ul>
      </Menu>
    );
  }
}

export default SubHeader;
